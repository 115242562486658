import React from "react";
import {ReactComponent as Break} from "../../assets/icons/Break.svg";
import {ShiftBatRowNotePriority, ShiftBatRowType} from "../../model/shiftBat";
import {ReactComponent as DeadRunning} from "../../assets/icons/DeadRunning.svg";
import {ReactComponent as Warning} from '../../assets/icons/Warning.svg';
import "./Itinerary.css";
import RouteNumber from "../../components/RouteNumber";
import {SyncOutlined} from "@ant-design/icons";
import {uniq} from "lodash/array";
import {useDroppable} from "@dnd-kit/core";
import {toHrsMinsSecs, toKmMs} from "../../libs/formatLib";
import ItineraryStopRowPrintSm from "./ItineraryStopRowPrintSm";

function ItineraryRowPrintSm({
                              itineraryRow,
                              editMode, printMode,
                              viewing,
                              updating,
                              setViewing,
                              setSelectedStop, setHighlightedRouteIds,
                              setFocusStop, selectedStop,
                              onSave
                          }) {

    const {isOver, setNodeRef} = useDroppable({
        id: itineraryRow.id,
    });
    const style = {
        color: isOver ? 'green' : undefined,
    };

    return (
        <div ref={setNodeRef} style={style}
             onMouseOver={() => {
                 if (!editMode) {
                     return
                 }
                 if (itineraryRow.route) {
                     setHighlightedRouteIds(highlightedRouteIds => uniq(highlightedRouteIds.concat(itineraryRow.route.routeId)));
                 }
                 if (itineraryRow.stop) {
                     setFocusStop(itineraryRow.stop);
                 }
             }}
             onMouseLeave={() => {
                 if (!editMode) {
                     return
                 }
                 if (itineraryRow.route) {
                     setHighlightedRouteIds(highlightedRouteIds => highlightedRouteIds.filter(rId => viewing[itineraryRow.id] || rId !== itineraryRow.route?.routeId));
                 }
                 if (itineraryRow.stop) {
                     setFocusStop(null);
                 }
             }}
             key={itineraryRow.id}
             className={`sb-row ${itineraryRow?.priority === ShiftBatRowNotePriority.high ? "sb-important" : ""} ${itineraryRow.type === ShiftBatRowType.service ? "sb-row-service" : ""}`}>
            <div className={`row d-flex align-items-center ml-0 mr-0`}>
                <div
                    className="col-1 sb-time">{!itineraryRow.hideTime && itineraryRow.type !== ShiftBatRowType.charter && Number.isFinite(itineraryRow.time) &&
                    <span>{updating ?
                        <SyncOutlined spin={true}/> : itineraryRow.toTimeString()}</span>}</div>
                <div className="col-2 sb-icon">
                    <div className={`RouteIcon`}>
                        {itineraryRow.type !== ShiftBatRowType.note && itineraryRow.type !== ShiftBatRowType.stop &&
                        [ShiftBatRowType.break, ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken].includes(itineraryRow.type) ?
                            <Break/> : itineraryRow.type === ShiftBatRowType.charter ?
                                <DeadRunning/> : itineraryRow.type === ShiftBatRowType.service ?
                                    <RouteNumber route={itineraryRow.route}/> : <></>}
                        {itineraryRow?.priority === ShiftBatRowNotePriority.high ? <Warning/> : ""}
                    </div>
                </div>
                <div className="col-9 d-flex RouteTitle align-items-center sb-info" style={{cursor: 'pointer'}}
                     onClick={() => {
                         if (printMode) {
                             return
                         }
                         if (printMode) {
                             return
                         }
                         if (itineraryRow.type === ShiftBatRowType.service) {
                             setViewing(viewing => ({...viewing, [itineraryRow.id]: !viewing[itineraryRow.id]}));
                         }
                         setFocusStop(null);
                     }}>

                    <div className={`RouteInfo`}>
                        <div className="row align-items-stretch justify-content-center">
                            <div className="col-12 d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center service-title-wrap">
                                    {itineraryRow.type === ShiftBatRowType.service ?
                                        <div className={`RouteIcon`}><RouteNumber route={itineraryRow.route}/>
                                        </div> : <></>}
                                    {itineraryRow?.priority === ShiftBatRowNotePriority.high ?
                                        <Warning className="sb-row-warning"/> : ""}
                                    <h4 className="title">
                                        {itineraryRow.type !== ShiftBatRowType.service && itineraryRow.title}{itineraryRow.type === ShiftBatRowType.charter && itineraryRow.route ? ` (${toHrsMinsSecs(itineraryRow.route.duration, false, true, 1)}, ${toKmMs(itineraryRow.route.distance)})` : <></>}
                                        {itineraryRow.type === ShiftBatRowType.service ? itineraryRow.title : <></>}
                                    </h4>
                                </div>
                                {itineraryRow.type === ShiftBatRowType.service ?
                                    <div className="service-desc-wrap">{itineraryRow.route.routeDetails}</div> : <></>}
                                {itineraryRow.description &&
                                    <div className={`RouteDetails`}>{itineraryRow.description}</div>}
                            </div>
                            {/* <div className="col-7 d-flex align-items-center flex-wrap">{itineraryRow.description &&
                                <span className={`RouteDetails`}>{itineraryRow.description}</span>}</div> */}
                        </div>
                    </div>
                </div>
            </div>
            {itineraryRow.type === ShiftBatRowType.service && viewing[itineraryRow.id] &&
                // <ItineraryServiceRow key={'SBServR' + itineraryRow.id} itineraryRow={itineraryRow} editMode={editMode}
                //                     onSave={onUpdate} setSelectedStop={setSelectedStop} printMode={printMode}
                //                     selectedStop={selectedStop} setFocusStop={setFocusStop}/>

                <div className="col-lg-12 sb-row-routes">
                    {itineraryRow.getStopRows().map(itineraryStopRow => (<div key={'SBSR' + itineraryRow.id}>
                        <ItineraryStopRowPrintSm route={itineraryRow.route} itineraryStopRow={itineraryStopRow}
                                              setSelectedStop={setSelectedStop}
                                              setFocusStop={setFocusStop} selectedStop={selectedStop}
                                              printMode={printMode}
                                              editMode={editMode} onSave={(e) => onSave(e, itineraryRow)}/>
                        {/*{itineraryStopRow.transfersTo?.length ?*/}
                        {/*    <ItineraryTransferRowPrint itineraryStopRow={itineraryStopRow} transfers={itineraryStopRow.transfersTo}*/}
                        {/*                        pickup={false}/> : <></>*/}
                        {/*}*/}
                        {/*{itineraryStopRow.transfersFrom?.length ?*/}
                        {/*    <ItineraryTransferRowPrint itineraryStopRow={itineraryStopRow} transfers={itineraryStopRow.transfersFrom}*/}
                        {/*                        pickup={true}/> : <></>*/}
                        {/*}*/}
                        {/*{itineraryStopRow.steps?.length ?*/}
                        {/*    <ItineraryDirectionsRow steps={itineraryStopRow.steps}/> : <></>*/}
                        {/*}*/}
                    </div>))}
                </div>}
            {itineraryRow.type === ShiftBatRowType.service ?
                <div className="service-trip-bar" style={{backgroundColor: itineraryRow.route.colour}}></div> : ''}
        </div>
    );

}

export default React.memo(ItineraryRowPrintSm)
