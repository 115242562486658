import dayjs from "../dayjs";
import {Model} from "./busRoute";
import {capitalize, cloneDeep, flatten, keyBy, last, memoize} from "lodash";
import {ulid} from "ulid";
import log from "loglevel";
import {values} from "lodash/object";

const logger = log.getLogger('Schedule');

export const schedule_options = schedules => {
    return (Array.isArray(schedules) ? schedules : values(schedules)).map(schedule => ({
        label: (schedule.isFuture() ? 'Future: ' : '') + schedule.scheduleName,
        value: schedule.scheduleId
    }))
}

export const DATE_STRING = 'DD/MM/YYYY';
const checkDayjs = memoize((date) => {
    if ('string' === typeof date) {
        date = dayjs(date, DATE_STRING);
    }
    return date
})

export const OPERATING_DAY_DEFAULTS = {
    Everyday: null,
    Weekdays: [true, true, true, true, true, false, false],
    Weekends: [false, false, false, false, false, true, true]
}

export class OperatingDays {
    constructor(data) {
        this.monday = true;
        this.tuesday = true;
        this.wednesday = true;
        this.thursday = true;
        this.friday = true;
        this.saturday = true;
        this.sunday = true;
        if (Array.isArray(data)) {
            this.monday = !!data[0];
            this.tuesday = !!data[1];
            this.wednesday = !!data[2];
            this.thursday = !!data[3];
            this.friday = !!data[4];
            this.saturday = !!data[5];
            this.sunday = !!data[6];
        } else {
            Object.assign(this, data);
        }
    }

    isMonToFriOnly() {
        return this.isMonToFri() && !this.saturday && !this.sunday
    }

    isMonToFri() {
        return this.monday && this.tuesday && this.wednesday && this.thursday && this.friday
    }

    isRunningOn(date = dayjs()) {
        date = checkDayjs(date);
        return this.toArray()[date.isoWeekday() - 1];
    }

    toArray() {
        return [this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday, this.sunday];
    }

    toString() {
        if (this.monday && this.tuesday && this.wednesday && this.thursday && this.friday) {
            if (this.saturday && this.sunday) {
                return 'Everyday'
            }
            return 'Weekdays' + (this.saturday ? ' and Saturday' : this.sunday ? ' and Sunday' : '');
        } else if (!this.monday && !this.tuesday && !this.wednesday && !this.thursday && !this.friday && this.saturday && this.sunday) {
            return 'Weekends';
            // } else if (Object.keys(this).reduce((prev, val) => !!this[val] ? prev++ : prev, 0) === 1) {
            //     switch (this.count) {
            //         case 0:
            //             return 'Mondays only'
            //         case 1:
            //             return 'Tuesdays only'
            //         case 2:
            //             return 'Wednesdays only'
            //         case 3:
            //             return 'Thursdays only'
            //         case 4:
            //             return 'Fridays only'
            //         case 5:
            //             return 'Saturdays only'
            //         case 6:
            //             return 'Sundays only'
            //     }
        } else if (this.toArray().every(d => !d)) {
            return ''
        } else {
            return Object.keys(this).filter(d => !!this[d]).map((d, idx) => capitalize(d) + 's').join(', ') + ' only'
        }
    }
}

export class Period {

    constructor(data) {
        this.id = data?.id || ulid();
        this.start = null;
        this.end = null;
        this.type = 'Period';
        this.forceInclude = false;
        this.name = data?.name
        this.shortName = data?.shortName
        this.update(data);
    }

    isSingleDay() {
        return this.start?.diff(this.end, 'day') === 0
    }

    isValid() {
        return dayjs.isDayjs(this.start) && dayjs.isDayjs(this.end) && this.start.isValid() && this.end.isValid() && this.end.isSameOrAfter(this.start);
    }

    toString() {
        return !this.isValid() ? 'Invalid' : this.isSingleDay() ? this.start.format(DATE_STRING) : this.start.format(DATE_STRING) + "→" + this.end.format(DATE_STRING)
    }

    update(data) {
        this.start = null;
        this.end = null;
        if (data) {
            if (Array.isArray(data)) {
                this.start = dayjs.isDayjs(data[0]) ? data[0] : dayjs(data[0], DATE_STRING);
                this.end = dayjs.isDayjs(data[1]) ? data[1] : dayjs(data[1], DATE_STRING);
                if (data.length === 3 && data[2] === 'forceInclude') {
                    this.forceInclude = true;
                }
            } else if (data.start && data.end) {
                this.start = dayjs.isDayjs(data.start) ? data.start : dayjs(data.start, DATE_STRING);
                this.end = dayjs.isDayjs(data.end) ? data.end : dayjs(data.end, DATE_STRING);
                // } else {
                //     logger.debug('the data', data)
                //     throw new Error('Period is invalid: ', data);
            }
            if (!this.isValid()) {
                this.start = null
                this.end = null
            }
        }
    }

    contains(date = dayjs()) {
        date = checkDayjs(date);
        return date.isBetween(this.start, this.end, 'day', '[]');
    }

    toArray() {
        return [this.start ? this.start.toDate() : null, this.end ? this.end.toDate() : null];
    }

    toJson() {
        const array = [this.start ? this.start.format(DATE_STRING) : null, this.end ? this.end.format(DATE_STRING) : null];
        if (this.forceInclude) {
            array.push('forceInclude');
        }
        return array;
    }

    totalDays() {
        return this.forEachDate().length;
    }

    forEachDate() {
        const dates = [];
        for (let m = this.start.clone(); m.diff(this.end, 'days') <= 0; m = m.add(1, 'days')) {
            dates.push(m);
        }
        return dates
    }
}

// const SCHEDULE_INCLUSION_MAP = {ignore: 'Ignore', include: 'Include', exclude: 'Exclude'}

export class Schedule extends Model {
    constructor(data) {
        super()
        // this.startDate = null // The inclusive first day of the schedule, defaults to today
        // this.endDate = null // The exclusive last day of the schedule, null means no end date
        // this.occurrences = 0 // End after occurrences
        // this.startTime = 0 // The start time of a trip in seconds since midnight
        // this.endTime = 0 // The end time of a trip in seconds since midnight, defaults to route.lastStop.delta
        // this.recurring = null // daily, weekly, etc, or number indicating "every num days"
        // data?.excludedPeriods && logger.debug([...data.excludedPeriods])

        this.scheduleId = "_";
        this.scheduleName = null
        this.schedulePeriods = []; // [[Period,Period]]
        this.operatingDays = new OperatingDays([]);
        this.excludedPeriods = []; // [[Period,Period]]
        this.includedSchedules = []; // [Schedule]
        this.excludedSchedules = []; // [Schedule]
        // this.publicHolidays = HOLIDAY_MAP.ignore;
        // this.schoolYear = HOLIDAY_MAP.ignore;
        // this.schoolTerms = HOLIDAY_MAP.ignore;
        // this.schoolHolidays = HOLIDAY_MAP.ignore;

        Object.assign(this, data)
        if ("string" === typeof this.operatingDays) {
            switch (this.operatingDays) {
                case "WD":
                    this.operatingDays = new OperatingDays([true, true, true, true, true, false, false]);
                    break;
                case "SAT":
                    this.operatingDays = new OperatingDays([false, false, false, false, false, true, false]);
                    break;
                case "SUN":
                    this.operatingDays = new OperatingDays([false, false, false, false, false, false, true]);
                    break;
                default:
            }
        } else {
            this.operatingDays = new OperatingDays(this.operatingDays);
        }
        if (!this.schedulePeriods?.length && this.schedulePeriod?.length) {
            this.schedulePeriods = [this.schedulePeriod]
            delete this.schedulePeriod;
        }
        if (!this.excludedPeriods && this.excludedPeriod) {
            this.excludedPeriods = [this.excludedPeriod]
            delete this.excludedPeriod;
        }
        if (this.schedulePeriods.length && !Array.isArray(this.schedulePeriods[0]) && !(this.schedulePeriods[0] instanceof Period)) {
            this.schedulePeriods = [this.schedulePeriods]
        }
        if (this.excludedPeriods.length && !Array.isArray(this.excludedPeriods[0]) && !(this.excludedPeriods[0] instanceof Period)) {
            this.excludedPeriods = [this.excludedPeriods]
        }
        this.schedulePeriods = this.schedulePeriods.map(period => new Period(period)).filter(period => period && period.isValid());
        this.excludedPeriods = this.excludedPeriods.map(period => new Period(period)).filter(period => period && period.isValid());

        this.schedulePeriods.sort((d1, d2) => d1.start.unix() - d2.start.unix())

        if (this.scheduleId === this.scheduleName) {
            this.scheduleName = this.toString();
        }
    }

    static clone(data) {
        const schedule = new Schedule();
        Object.assign(schedule, cloneDeep(data));
        return schedule;
    }

    setSubSchedules(schedules) {
        const log = this.scheduleName === 'My School year'
        log && logger.debug('Setting subSchedules...', {...this})
        log && logger.debug(PREDEFINED_SCHEDULES)
        this.includedSchedules = this.includedSchedules.map(sId => typeof sId === 'string' ? (PREDEFINED_SCHEDULES[sId] || schedules[sId]) : sId).filter(s => s?.setSubSchedules)
        this.includedSchedules.forEach(s => s.setSubSchedules(schedules));
        this.excludedSchedules = this.excludedSchedules.map(sId => {
            logger.debug(PREDEFINED_SCHEDULES[sId], schedules[sId])
            return typeof sId === 'string' ? (PREDEFINED_SCHEDULES[sId] || schedules[sId]) : sId
        }).filter(s => s?.setSubSchedules)
        this.excludedSchedules.forEach(s => s.setSubSchedules(schedules));
        log && logger.debug(this.excludedSchedules)
    }

    containsSubSchedule(schedule) {
        return this.includesSubSchedule(schedule) || this.excludesSubSchedule(schedule);
    }

    includesSubSchedule(schedule) {
        return this.includedSchedules.some(includedSchedule => includedSchedule.scheduleId === schedule.scheduleId || includedSchedule.includedSchedules(schedule))
    }

    excludesSubSchedule(schedule) {
        return this.excludedSchedules.some(excludedSchedule => excludedSchedule.scheduleId === schedule.scheduleId || excludedSchedule.excludedSchedules(schedule))
    }

    totalDays(periods) {
        if (!periods?.length) return 0
        return periods.reduce((p, c) => c.totalDays() + p, 0);
    }

    getAllScheduledPeriods(sort) {
        let periods = (this.schedulePeriods || [])
            .concat(flatten(this.includedSchedules?.map(s => s?.getAllScheduledPeriods ? s.getAllScheduledPeriods() : []) || []))
            .concat(flatten(this.excludedSchedules?.map(s => s?.getAllExcludedPeriods ? s.getAllExcludedPeriods() : []) || []))
        sort && periods.sort((p1, p2) => p1.start.isBefore(p2.start) ? -1 : 1)
        return periods;
    }

    getAllExcludedPeriods() {
        return (this.excludedPeriods || [])
            .concat(flatten(this.excludedSchedules?.map(s => s?.getAllScheduledPeriods ? s.getAllScheduledPeriods() : []) || []))
            .concat(flatten(this.includedSchedules?.map(s => s?.getAllExcludedPeriods ? s.getAllExcludedPeriods() : []) || []))
    }

    hasExclusions() {
        return this.getAllExcludedPeriods().length;
    }

    exclusionsToString() {
        return this.getAllExcludedPeriods().map(p => p.toString()).join(', ');
    }

    // TODO: Do operatingDays change with included/excluded Schedules
    isOperatingDay(date = dayjs()) {
        return this.operatingDays.isRunningOn(date)
    }

    isScheduled(date = dayjs(), specificPeriod = true) {
        if (specificPeriod) {
            return this.getAllScheduledPeriods().some(schedulePeriod => schedulePeriod.contains(date));
        }
        return this.getFirstActiveDate().isSameOrBefore(date) && this.getLastActiveDate().isSameOrAfter(date);
    }

    isExcluded(date = dayjs()) {
        return this.getAllExcludedPeriods().some(excludedPeriod => excludedPeriod.contains(date))
    }

    isForceIncluded(date = dayjs()) {
        return this.getAllScheduledPeriods().some(period => period.forceInclude && period.contains(date))
    }

    isActive(date = dayjs(), checkOperatingDay = true) {
        const scheduled = checkOperatingDay ? this.isScheduled(date, true) : !this.isObsolete(date);
        const excluded = checkOperatingDay && this.isExcluded(date);
        const isOperatingToday = checkOperatingDay ? this.isOperatingDay(date) : this.isSchoolSchedule()
        const forceIncluded = checkOperatingDay && this.isForceIncluded(date);
        // logger.debug('%s Scheduled: %s, Excluded: %s, Operating Today: %s', this.scheduleName, scheduled, excluded, isOperatingToday)
        return scheduled && !excluded && (isOperatingToday || forceIncluded);
    }

    isActiveToday(date = dayjs()) {
        return this.isActive(date);
    }

    isMonToFriOnly() {
        return this.operatingDays.isMonToFriOnly()
    }

    isMonToFri() {
        return this.operatingDays.isMonToFri()
    }

    isSchoolSchedule() {
        return this.isMonToFri()
    }

    getFirstActiveDate() {
        if (!this.getAllScheduledPeriods()?.length) {
            return null;
        }
        return this.getAllScheduledPeriods(true)[0].start
    }

    getLastActiveDate() {
        if (!this.getAllScheduledPeriods()?.length) {
            return null;
        }
        return last(this.getAllScheduledPeriods(true)).end
    }

    includeSchoolHolidays() {
        this.includedPeriods = this.includedPeriods.concat(SCHOOL_HOLIDAYS)
    }

    includePublicHolidays() {
        this.includedPeriods = this.includedPeriods.concat(PUBLIC_HOLIDAY_PERIODS)
    }

    excludeSchoolHolidays() {
        // SCHOOL_HOLIDAYS.forEach(holidayPeriod => {
        // if (this.isScheduled(holidayPeriod[0]) && this.isScheduled(holidayPeriod[1])) {
        //     this.excludedPeriods = this.excludedPeriods.concat([holidayPeriod]);
        // }
        // })
        this.excludedPeriods = this.excludedPeriods.concat(SCHOOL_HOLIDAYS)
    }

    excludePublicHolidays() {
        // this.excludedPeriods = this.excludedPeriods.concat(Object.keys(PUBLIC_HOLIDAYS).filter(date => this.isScheduled(date)).map(date => [date, date]));
        this.excludedPeriods = this.excludedPeriods.concat(PUBLIC_HOLIDAY_PERIODS)
    }

    getNext7DepartureDates(date = dayjs()) {
        let next = this.getNextDepartureDate(date, 70)
        const result = []
        for (let i = 0; i < 70; i++) {
            if (next) {
                result.push(next);
                next = this.getNextDepartureDate(next.clone().add(1, 'day'));
            }
        }
        return result
    }

    getNextDepartureDate(date = dayjs()) {
        const log = false;//this.scheduleName.startsWith('Predefined')
        date = date.clone().startOf('d');
        let attempts = 0;
        log && logger.debug('checking date', date.format(DATE_STRING))
        while (attempts++ < 70 && !this.isActive(date)) {
            log && logger.debug('checking date again', date.format(DATE_STRING))
            date = date.add(1, 'day');
        }
        log && logger.debug(date.format(DATE_STRING))
        if (this.isActive(date)) {
            return date;
        }
    }

    toJson() {
        const schedulePeriodsJson = this.schedulePeriods.filter(p => p.isValid()).map(period => period.toJson())
        const excludedPeriodsJson = this.excludedPeriods.filter(p => p.isValid()).map(period => period.toJson())
        const operatingDaysJson = this.operatingDays.toArray();
        return {
            scheduleId: this.scheduleId,
            scheduleName: this.scheduleName,
            schedulePeriods: schedulePeriodsJson,
            excludedPeriods: excludedPeriodsJson,
            operatingDays: operatingDaysJson,
            includedSchedules: this.includedSchedules.map(s => s.scheduleId),
            excludedSchedules: this.excludedSchedules.map(s => s.scheduleId),
            userId: this.userId
        }
    }

    isFuture(date = dayjs()) {
        const firstActive = this.getFirstActiveDate()
        if (!firstActive) {
            return false;
        }
        return date.isBefore(firstActive);
    }

    isObsolete(date = dayjs()) {
        // This was commented out to "Fix obsolete schedules in transfers"
        // I'm adding it back so that we can determine if a schedule is obsolete to fix an issue where transfers are showing no schedule because school runs aren't scheduled for next year.
        const lastActive = this.getLastActiveDate()
        if (!lastActive) {
            return true;
        }
        return date.isAfter(lastActive, 'day');
    }

    toString() {
        if (this.scheduleId !== this.scheduleName) {
            return this.scheduleName
        }
        if (!this.getLastActiveDate() || !this.getFirstActiveDate()) {
            return "Not active."
        }
        if (this.isObsolete()) {
            return this.operatingDays.toString() + ". Obsolete since " + this.getLastActiveDate().format("MMM Do, YYYY");
        }
        if (dayjs().isBefore(this.getFirstActiveDate())) {
            // eslint-disable-next-line
            return this.operatingDays.toString() + ", " + " starting " + this.getFirstActiveDate().format("MMM Do, YYYY");
        }
        return this.operatingDays.toString();
    }

}


// NOTE: Ensure dates are in the format dd/mm/yyyy
const PUBLIC_HOLIDAYS = {
    2023: "01/01/2023,New Year's Day;02/01/2023,Additional Day;26/01/2023,Australia Day;07/04/2023,Good Friday;08/04/2023,Easter Saturday;09/04/2023,Easter Sunday;10/04/2023,Easter Monday;25/04/2023,Anzac Day;12/06/2023,Queen's Birthday;2/10/2023,Labour Day;25/12/2023,Christmas Day;26/12/2023,Boxing Day",
    2024: "01/01/2024, New Year's Day; 26/01/2024, Australia Day; 29/03/2024, Good Friday; 30/03/2024, Easter Saturday; 31/03/2024, Easter Sunday; 01/04/2024, Easter Monday; 25/04/2024, Anzac Day; 10/06/2024, King's Birthday; 07/10/2024, Labour Day; 25/12/2024, Christmas Day; 26/12/2024, Boxing Day",
    2025: "01/01/2025, New Year's Day; 27/01/2025, Australia Day; 18/04/2025, Good Friday; 19/04/2025, Easter Saturday; 20/04/2025, Easter Sunday; 21/04/2025, Easter Monday; 25/04/2025, Anzac Day; 09/06/2025, King's Birthday; 06/10/2025, Labour Day; 25/12/2025, Christmas Day; 26/12/2025, Boxing Day"
}

export const PUBLIC_HOLIDAY_PERIODS = {
    2023: [],
    2024: [],
    2025: []
}

Object.keys(PUBLIC_HOLIDAYS).forEach(year => {
    PUBLIC_HOLIDAYS[year].split(';').forEach(hol => {
        const holiday = hol.split(',')
        PUBLIC_HOLIDAY_PERIODS[year].push(new Period([holiday[0], holiday[0]]))
    })
})

const SCHOOL_TERMS_STR = {
    2023: "27/01/2023,06/04/2023;24/04/2023,30/06/2023;17/07/2023,22/09/2023;09/10/2023,19/12/2023",
    2024: "30/01/2024,12/04/2024;29/04/2024,05/07/2024;22/07/2024,27/09/2024;14/10/2024,20/12/2024",
    2025: "31/01/2025,11/04/2025;28/04/2025,04/07/2025;21/07/2025,26/09/2025;13/10/2025,19/12/2025"
}

export const SCHOOL_TERMS = {};
Object.keys(SCHOOL_TERMS_STR).forEach(year => {
    SCHOOL_TERMS[year] = SCHOOL_TERMS_STR[year].split(';').map(term => term.split(','))
})
export const SCHOOL_TERM_PERIODS = {}
export const ALL_FUTURE_TERMS = []
export const THIS_YEARS_FUTURE_TERMS = []
export const SCHOOL_YEARS = []
Object.keys(SCHOOL_TERMS).forEach(year => {
    if (year >= dayjs().year()) {
        SCHOOL_YEARS.push(new Period({
            id: `${year}_school_year`,
            name: `${year} School Year`,
            shortName: `${year}`,
            start: SCHOOL_TERMS[year][0][0],
            end: last(SCHOOL_TERMS[year])[1]
        }))
    }
    SCHOOL_TERMS[year].forEach((term, idx) => {
        SCHOOL_TERM_PERIODS[year] = SCHOOL_TERM_PERIODS[year] || []
        const period = new Period({start: checkDayjs(term[0]).clone(), end: checkDayjs(term[1]).clone()})
        SCHOOL_TERM_PERIODS[year].push(period);
        if (period.end.isSameOrAfter(dayjs(), 'day')) {
            ALL_FUTURE_TERMS.push(new Period({
                id: `term_${idx + 1}_${year}`,
                start: period.start,
                end: period.end,
                name: `Term ${idx + 1} ${year}`,
                shortName: `T${idx + 1} ${year}`
            }));
            if (parseInt(year) === dayjs().year()) {
                THIS_YEARS_FUTURE_TERMS.push(new Period({
                    id: `term_${idx + 1}`,
                    start: period.start,
                    end: period.end,
                    name: `End of T${idx + 1}`,
                    shortName: `T${idx + 1}`
                }));
            }
        }
    })
})

export const THIS_YEARS_FUTURE_TERMS_BY_ID = keyBy(THIS_YEARS_FUTURE_TERMS, 'id')

// export const SCHOOL_YEAR_PERIOD = new Period([SCHOOL_TERMS[0][0], last(SCHOOL_TERMS)[1]]);

export const SCHOOL_HOLIDAYS = {}
Object.keys(SCHOOL_TERMS).forEach(year => SCHOOL_TERMS[year].forEach((term, idx) => {
    SCHOOL_HOLIDAYS[year] = SCHOOL_HOLIDAYS[year] || []
    if (idx === 0) {
        SCHOOL_HOLIDAYS[year].push(new Period([checkDayjs(term[0]).clone().startOf('year'), checkDayjs(term[0]).clone().subtract(1, 'd')]));
    }
    if (idx < SCHOOL_TERMS[year].length - 1) {
        SCHOOL_HOLIDAYS[year].push(new Period([checkDayjs(term[1]).clone().add(1, 'd'), checkDayjs(SCHOOL_TERMS[year][idx + 1][0]).clone().subtract(1, 'd')]));
    }
    if (idx === SCHOOL_TERMS[year].length - 1) {
        SCHOOL_HOLIDAYS[year].push(new Period([checkDayjs(term[1]).clone().add(1, 'd'), checkDayjs(term[1]).clone().endOf('year')]));
    }
}))//.filter(hols => !!hols)

export const SCHOOL_HOLIDAY_SCHEDULES = {}
Object.keys(SCHOOL_HOLIDAYS).forEach(year => {
    SCHOOL_HOLIDAY_SCHEDULES[year] = new Schedule({
        shared: true,
        scheduleId: year + '_nsw_school_holidays',
        scheduleName: year + ' NSW School Holidays',
        schedulePeriods: SCHOOL_HOLIDAYS[year]
    })
});

export const PUBLIC_HOLIDAY_SCHEDULES = {}
Object.keys(PUBLIC_HOLIDAY_PERIODS).forEach(year => {
    PUBLIC_HOLIDAY_SCHEDULES[year] = new Schedule({
        shared: true,
        scheduleId: year + '_nsw_public_holidays',
        scheduleName: year + ' NSW Public Holidays',
        schedulePeriods: PUBLIC_HOLIDAY_PERIODS[year]
    })
});
export const SCHOOL_TERMS_SCHEDULES = {}
Object.keys(SCHOOL_TERM_PERIODS).forEach(year => {
    SCHOOL_TERMS_SCHEDULES[year] = new Schedule({
        shared: true,
        scheduleId: year + '_nsw_school_terms',
        scheduleName: year + ' NSW School Terms',
        schedulePeriods: SCHOOL_TERM_PERIODS[year]
    })
});
// export const SCHOOL_TERM_SCHEDULE = new Schedule({
//     shared: true,
//     scheduleId: 'nsw_school_term',
//     scheduleName: 'NSW School Term',
//     schedulePeriods: [SCHOOL_TERM_PERIODS.filter(p => p.contains())[0]]
// });
// export const SCHOOL_YEAR_SCHEDULE = new Schedule({
//     shared: true,
//     scheduleId: 'nsw_school_year',
//     scheduleName: 'NSW School Year',
//     schedulePeriods: [SCHOOL_YEAR_PERIOD]
// });
const PREDEFINED_SCHEDULES = {
    // nsw_school_year: new Schedule({shared: true, scheduleName: 'year'}),
    // nsw_school_terms: new Schedule({shared: true, scheduleName: 'terms'}),
    // nsw_school_term: new Schedule({shared: true, scheduleName: 'term'}),
    // nsw_school_holidays: new Schedule({shared: true, scheduleName: 'pub'}),
    // nsw_public_holidays: new Schedule({shared: true, scheduleName: 'hols'}),
}
Object.keys(SCHOOL_TERMS_SCHEDULES).forEach(year => PREDEFINED_SCHEDULES[year + '_nsw_school_terms'] = SCHOOL_TERMS_SCHEDULES[year])
Object.keys(SCHOOL_HOLIDAY_SCHEDULES).forEach(year => PREDEFINED_SCHEDULES[year + '_nsw_school_holidays'] = SCHOOL_HOLIDAY_SCHEDULES[year])
Object.keys(PUBLIC_HOLIDAY_SCHEDULES).forEach(year => PREDEFINED_SCHEDULES[year + '_nsw_public_holidays'] = PUBLIC_HOLIDAY_SCHEDULES[year])

export {PREDEFINED_SCHEDULES}

// SCHOOL_TERM_PERIODS.forEach((period, idx) => {
//     const termNumber = idx+1
//     const scheduleId = `nsw_school_term_${termNumber}`
//     PREDEFINED_SCHEDULE[scheduleId] = new Schedule({
//         scheduleId,
//         scheduleName: `NSW School Term ${termNumber}`,
//         schedulePeriods: [SCHOOL_TERM_PERIODS[idx]]
//     });
// })

