import React, { useEffect, useState } from "react";
import './Charters.css'
import LoadMessage from "../../components/LoadMessage";
import { useAppContext } from "../../libs/contextLib";
import useAllCharters from "./useAllCharters";
import { values } from "lodash";
import { filterFn, FilterSwitches } from "../../containers/FilterTag";
import { Button, Card, Col, Input, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import { debounce } from "lodash";
import { LinkContainer } from "react-router-bootstrap";
import CharterList from "./CharterList";

const { Search } = Input;

export const filterItems = {
    new: {
        name: "new",
        color: "processing",
        showFn: (r) => !r.status || r.status === "new",
    },
    completed: {
        name: "Completed",
        color: "success",
        showFn: (r) => !r.status || r.status === "completed",
    },
    allocated: {
        name: "Allocated",
        color: "success",
        showFn: (r) => !r.status || r.status === "allocated",
    },
    quoted: {
        name: "Quoted",
        color: "warning",
        showFn: (r) => !r.status || r.status === "quoted",
    },
    scheduled: {
        name: "Scheduled",
        color: "warning",
        showFn: (r) => !r.status || r.status === "scheduled",
    },
    cancelled: {
        name: "Cancelled",
        color: "success",
        showFn: (r) => !r.status || r.status === "cancelled",
    },
};

const nameSortFn = (a, b) => {
    if (!a.name?.length || !b.name?.length) {
        return 1
    }
    return a.name.localeCompare(b.name);
}

function Charters() {
    const { operator } = useAppContext();
    const { allCharters: charters } = useAllCharters();
    const [filteredCharters, setFilteredCharters] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [filter, setFilter] = useState("");
    const setFilterDebounced = debounce(setFilter, 250);

    useEffect(() => {
        if (!charters || !values(charters).length) {
            setFilteredCharters([]);
        }

        let charterList = values(charters).filter(charter => charter.type === 'charter#detail');
        if (filter) {
            const _filter = filter.toLowerCase();
            charterList = charterList.filter(
                (r) =>
                    (r.name && r.name.toLowerCase().indexOf(_filter) > -1) ||
                    (r.requested_by && r.requested_by.toLowerCase().indexOf(_filter) > -1)
            );
        }
        setFilteredCharters(filterFn(charterList, filterItems, filterBy, "charterId", nameSortFn));
    }, [charters, setFilteredCharters, filterBy, filter]);

    const getStatusCount = (status) => {
        return values(charters)?.filter((c) => c.status === status).length ?? 0;
    }

    return (
        <>
            {charters && filteredCharters ? (
                <div className="charters-list">
                    <Row gutter={[20, 20]}>
                        <Col xs={24} lg={6}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <Bus />
                                    </Col>
                                    <Col>
                                        <div>
                                            <h4>New Inquiries</h4>
                                        </div>
                                        <div className="text-lg">{getStatusCount('new')}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <Calendar />
                                    </Col>
                                    <Col>
                                        <div>
                                            <h4>Upcoming Events</h4>
                                        </div>
                                        <div className="text-lg">{getStatusCount('scheduled')}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <Bus />
                                    </Col>
                                    <Col>
                                        <div>
                                            <h4>Completed</h4>
                                        </div>
                                        <div className="text-lg">{getStatusCount('completed')}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <Bus />
                                    </Col>
                                    <Col>
                                        <div>
                                            <h4>Cancelled</h4>
                                        </div>
                                        <div className="text-lg">{getStatusCount('cancelled')}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-between filter-options-main mt-4">
                        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                            <LinkContainer to="/charters/_new_">
                                <Button type="primary" className="icon-button" icon={<PlusOutlined />}>
                                    Add Charter
                                </Button>
                            </LinkContainer>
                            {/* <ImportStudents operator={operator} messageApi={messageApi}
                                            existingStudents={keyBy(values(students), 'authorityId')}
                                            setImportedStudents={setImportedStudents}/> */}
                        </div>
                        <div className="d-flex align-items-center">
                            <Search
                                id="basic-addon1"
                                style={{
                                    width: 200,
                                }}
                                allowClear={true}
                                onChange={(e) => setFilterDebounced(e.target.value)}
                            />
                            <div className="filter-switches">
                                <FilterSwitches filterItems={filterItems} filterBy={filterBy} setFilterBy={setFilterBy} />
                            </div>
                        </div>
                    </div>
                    <CharterList charters={filteredCharters}/>
                </div>
            ) : (
                <LoadMessage message="Loading charters..." />
            )}
        </>
    );
}

export default React.memo(Charters);
