import React, {useCallback, useEffect, useState} from "react";
import {find} from "lodash/collection";
import {difference, uniq} from "lodash/array";
import RouteTitle from "../../components/RouteTitle";
import {flattenDeep} from "lodash/array";
import {toHrsMinsSecs, toTime} from "../../libs/formatLib";
import LoadMessage from "../../components/LoadMessage";
import {stopModelData, transferModelData} from "../../services/ModelService";
import {ulid} from "ulid";
import {RetweetOutlined, WarningFilled} from "@ant-design/icons";
import {Switch, Row, Col, Table, Tooltip} from "antd";
import {Stop} from "../../model/busRoute";
import {values} from "lodash/object";
import {uniqWith} from "lodash";

export const isSetdownTransfer = (transfer, t) => {
    return t.fromRouteId === transfer.fromRouteId && t.fromTripId === transfer.fromTripId &&
        t.fromStopId === transfer.fromStopId && t.fromStopTimeId === transfer.fromStopTimeId
}

export const isEqualTransfer = (transfer, t) => {
    return t.fromRouteId === transfer.fromRouteId && t.fromTripId === transfer.fromTripId &&
        t.fromStopId === transfer.fromStopId && t.fromStopTimeId === transfer.fromStopTimeId &&
        t.toRouteId === transfer.toRouteId && t.toTripId === transfer.toTripId &&
        t.toStopId === transfer.toStopId && t.toStopTimeId === transfer.toStopTimeId
}

const findTransfer = (transfers, transfer) => {
    return find(transfers, t => {
        return isEqualTransfer(transfer, t)
    })
}

const getSetdownDataForStop = (stop, transfers) => {
    let data = []
    transfers.filter(t => t.fromStopId === stop.stopId).forEach(transfer => {
        const existing = find(data, s => isSetdownTransfer(transfer, s.transfer))
        if (existing) {
            existing.count++
        } else {
            const fromRoute = find(stop.getAllRoutes(), ['routeId', transfer.fromRouteId])
            data.push(({
                key: `${transfer.fromRouteId}|${transfer.fromTripId}|${transfer.fromStopTimeId}`,
                service: <RouteTitle
                    route={fromRoute || {
                        routeId: transfer.fromRouteId, routeNumber: transfer.fromRouteNumber
                    }} link={true} meta={true}/>,
                arrive: toTime(transfer.arriveSecs, true),
                arriveSecs: transfer.arriveSecs,
                routeNumber: transfer.fromRouteNumber,
                transfer,
                count: 1
            }))
        }
    })

    data = data.concat(flattenDeep(stop.routes.filter(stopRoute => stopRoute.services).map(stopRoute => {
        return Object.keys(stopRoute.services).map(tripId => stopRoute.services[tripId].passingTimes.map(pt => {
            const transfer = {
                fromStopId: stop.stopId, fromRouteId: stopRoute.routeId, fromTripId: tripId,
                fromStopTimeId: pt.stopTimeId, fromColour: stopRoute.colour, fromRouteNumber: stopRoute.routeNumber
            }
            const existing = find(data, s => isSetdownTransfer(transfer, s.transfer))
            if (!existing) {
                return {
                    key: `${stopRoute.routeId}|${tripId}|${pt.stopTimeId}`,
                    service: <RouteTitle route={stopRoute || {
                        routeId: transfer.toRouteId, routeNumber: transfer.toRouteNumber
                    }} link={true} meta={true}/>,
                    arrive: toTime(pt.arriveSecs, true),
                    arriveSecs: pt.arriveSecs,
                    routeNumber: stopRoute.routeNumber,
                    transfer,
                    count: 0
                }
            }
            return null
        }))
    }))).filter(d => !!d)
    return data
}

const Transfers = ({
                       point,
                       // allStops,
                       // transfers,
                       routeId, tripId, stopTimeId
                   }) => {

    const [allStops, setAllStops] = useState(null);
    const [transfers, setTransfers] = useState(null);

    const [setdownPoint, setSetdownPoint] = useState(point);

    const [selectedSetdownServices, setSelectedSetdownServices] = useState([]); // "routeId|tripId|stopTimeId"
    const [selectedPickupServices, setSelectedPickupServices] = useState([]); //  "transferId"

    const [setdownServiceData, setSetdownServiceData] = useState([]);
    const [pickupServiceData, setPickupServiceData] = useState([]);

    const [saving, setSaving] = useState([])
    const [removing, setRemoving] = useState([])

    useEffect(() => {
        console.log("Transfers loaded effect")
        const time = Date.now();
        const stopsLoadedFn = stops => setAllStops(stops)
        const transfersLoadedFn = transfers => setTransfers(values(transfers))
        const listeners = {stops: null, transfers: null};
        // Load existing transfers
        const load = async () => {
            listeners.stops = stopModelData.addListener({loaded: stopsLoadedFn, setterFn: stopsLoadedFn})
            setAllStops(await stopModelData.getAll());
            listeners.transfers = transferModelData.addListener({
                loaded: transfersLoadedFn,
                setterFn: setTransfers
            })
            setTransfers(await transferModelData.getAll({asArray: true}));
        }

        load().then(() => console.log("Transfers/Stops loaded."))

        console.log("Transfers loaded effect time", Date.now() - time);
        return () => {
            stopModelData.removeListener(listeners.stops)
            transferModelData.removeListener(listeners.transfers)
        }

    }, [])

    useEffect(() => {
        if (!point || !allStops) {
            return
        }
        setSetdownPoint(allStops[point.stopId])
    }, [allStops, setSetdownPoint])

    const setdownTableColumns = useCallback(() => [
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
            sorter: (a, b) => a.routeNumber.localeCompare(b.routeNumber),
        },
        {
            title: 'Arrive',
            dataIndex: 'arrive',
            key: 'arrive',
            defaultSortOrder: routeId?.length && tripId?.length && stopTimeId?.length ? null : 'ascend',
            sorter: (a, b) => a.arriveSecs - b.arriveSecs,
        },
        {
            // title: 'Transfers',
            title: <RetweetOutlined/>,
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => a.count - b.count,
        }
    ], [routeId, tripId, stopTimeId])

    const pickupTableColumns = useCallback(() => {
        return [
            {
                title: 'Service',
                dataIndex: 'service',
                key: 'service',
                sorter: (a, b) => a.routeNumber.localeCompare(b.routeNumber),
            },
            {
                title: 'Depart',
                dataIndex: 'depart',
                key: 'depart',
                sorter: (a, b) => a.departSecs - b.departSecs,
            },
            {
                title: 'Wait',
                dataIndex: 'dwell',
                key: 'dwell',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.dwellSecs - b.dwellSecs,
                render: (_, record) => {
                    // return record.dwellSecs >= 0 ? record.dwell : <Tooltip title={record.transfer.invalid &&
                    // record.transfer.warnings?.length ? uniq(record.transfer.warnings).map(w => <p>{w}</p>) : null}>
                    //     <WarningFilled style={{color: '#dc3545'}}/></Tooltip>
                    return record.dwell
                }
            },
            {
                title: 'In-seat',
                dataIndex: 'inseat',
                key: 'inseat',
                sorter: (a, b) => (!!a?.transfer?.inSeat === !!b?.transfer?.inSeat) ? 0 : !!a?.transfer?.inSeat ? -1 : 1,
                render: (_, record) => {
                    return <Switch
                        disabled={!selectedPickupServices.includes(record.key) || saving.includes(record.key)}
                        checked={record.transfer.inSeat} onChange={(value, e) => {
                        e.stopPropagation()
                        record.transfer.inSeat = value
                        transferModelData.save(record.transfer).then(() => console.log("transfer saved"))
                    }}/>
                },
            },
            {
                title: <RetweetOutlined/>,
                dataIndex: 'count',
                key: 'count',
                sorter: (a, b) => selectedPickupServices.includes(a.key) && selectedPickupServices.includes(b.key) ? 0 :
                    selectedPickupServices.includes(a.key) && !selectedPickupServices.includes(b.key) ? -1 : 1,
                render: (_, record) => {
                    return record.transfer.warnings?.length ? <Tooltip title={record.transfer.invalid &&
                    record.transfer.warnings?.length ? uniq(record.transfer.warnings).map(w => <p>{w}</p>) : null}>
                        <WarningFilled style={{color: '#dc3545'}}/></Tooltip> : record.dwellSecs < 0 ? <Tooltip
                        title={<p>Invalid transfer
                            times. {record.transfer.toRouteNumber} departs {toHrsMinsSecs(Math.abs(record.dwellSecs), true, true)} before
                            setdown (REF-028)</p>}>
                        <WarningFilled style={{color: '#dc3545'}}/></Tooltip> : ''
                }
            }
        ]
    }, [selectedPickupServices, saving])

    useEffect(() => {
        if (!setdownPoint || !transfers) {
            setSetdownServiceData([])
            return
        }

        setdownPoint.setLinkedStops(allStops)

        const tableData = getSetdownDataForStop(setdownPoint, transfers);
        if (routeId?.length && tripId?.length && stopTimeId?.length) {
            const key = `${routeId}|${tripId}|${stopTimeId}`;
            const idx = tableData.findIndex(s => s.key === key);
            if (idx > -1) {
                const [service] = tableData.splice(idx, 1);
                setSelectedSetdownServices([key]);
                tableData.unshift(service);
            }
        }
        setSetdownServiceData(tableData);

    }, [setdownPoint, transfers, setSetdownServiceData, setSelectedSetdownServices,
        routeId, tripId, stopTimeId])


    const addPickupDataForStop = (data, stop, setdownService, transfers, selected) => {
        transfers.forEach(transfer => {
            if (transfer.toStopId === stop.stopId && isSetdownTransfer(transfer, setdownService.transfer)) {
                const toRoute = find(stop.getAllRoutes(), ['routeId', transfer.toRouteId])
                const dwellSecs = transfer.departSecs - transfer.arriveSecs

                data.push(({
                    // key: `${route.routeId}|${tripId}|${passingTime.stopTimeId}`,
                    key: transfer.transferId,
                    service: <RouteTitle
                        route={toRoute || {
                            routeId: transfer.toRouteId, routeNumber: transfer.toRouteNumber
                        }} link={true} meta={true}/>,
                    depart: toTime(transfer.departSecs, true),
                    dwellSecs: dwellSecs,
                    dwell: toHrsMinsSecs(dwellSecs),
                    departSecs: transfer.departSecs,
                    routeNumber: transfer.toRouteNumber,
                    transfer,
                    warnings: [],
                }))
                selected.push(transfer.transferId)
            }
        })
        const passingTimes = stop.getPassingTimes(r => r.routeId !== setdownService.transfer.fromRouteId, {
            time: parseInt(setdownService.arriveSecs) - 5 * 60,
            window: 3600 + 5 * 60,
            school: true
        })
        passingTimes.forEach(({route, tripId, passingTime}) => {
            const transfer = {
                ...setdownService.transfer,
                toRouteId: route.routeId,
                toRouteNumber: route.routeNumber,
                toColour: route.colour,
                toTripId: tripId,
                toStopId: stop.stopId,
                toStopTimeId: passingTime.stopTimeId,
                inSeat: false,
                transferId: ulid(),
                distance: stop.distance,
                waypoints: stop.waypoints,
                duration: stop.duration,
                warnings: [],
            }
            const existing = findTransfer(transfers, transfer)
            if (existing) {
                // transfer.transferId = existing.transferId
                // selected.push(existing.transferId)
                return
            }
            const dwellSecs = passingTime.departSecs - setdownService.arriveSecs
            data.push(({
                // key: `${route.routeId}|${tripId}|${passingTime.stopTimeId}`,
                key: existing?.transferId || ulid(),
                service: <RouteTitle route={route} link={true} meta={true}/>,
                depart: toTime(passingTime.departSecs, true),
                dwellSecs,
                dwell: toHrsMinsSecs(passingTime.departSecs - setdownService.arriveSecs),
                departSecs: passingTime.departSecs,
                routeNumber: route.routeNumber,
                transfer
            }))
        })
    }

    const populatePickupServices = useCallback((selectedSetdownServices) => {
        let pickupData = [], selectedPickups = []
        selectedSetdownServices.forEach(selectedSetdownService => {
            const service = find(setdownServiceData, ['key', selectedSetdownService])
            addPickupDataForStop(pickupData, setdownPoint, service, transfers, selectedPickups)
            setdownPoint.linkedStops && setdownPoint.linkedStops.forEach(ls => {
                const stop = new Stop({...allStops[ls.stopId], ...ls})
                stop.setLinkedStops(allStops)
                addPickupDataForStop(pickupData, stop, service, transfers, selectedPickups)
            })
        })
        setPickupServiceData(uniqWith(pickupData, (d1, d2) => isEqualTransfer(d1.transfer, d2.transfer)))
        setSelectedPickupServices(selectedPickups)
        return pickupData
    }, [setdownPoint, setdownServiceData, setPickupServiceData, allStops, transfers])

    useEffect(() => {
        populatePickupServices(selectedSetdownServices)
    }, [populatePickupServices, transfers, selectedSetdownServices])

    const selectedPickup = useCallback((selectedRowKeys, selectedRows) => {
        const removed = difference(selectedPickupServices, selectedRowKeys)//.map(key => find(pickupServiceData, ['key', key]))
        if (removed.length) {
            setRemoving(removing => uniq(removing.concat(removed)))
            transferModelData.delete(removed, true).then(() => {
                setRemoving(removing => removing.filter(id => !removed.includes(id)))
                console.log("Transfer deleted")
            })

        }
        const newTransfers = difference(selectedRowKeys, selectedPickupServices).map(key => {
            const selectedRow = find(pickupServiceData, ['key', key], 0)
            return selectedRow?.transfer
        })
        if (newTransfers.length) {
            const ids = newTransfers.map(t => t.transferId)
            setSaving(saving => uniq(saving.concat(ids)))
            console.log("Transfer saving....")
            transferModelData.save(newTransfers).then(() => {
                setSaving(saving => saving.filter(id => !ids.includes(id)))
                console.log("Transfer saved")
            })
        }
        setSelectedPickupServices(selectedRowKeys);
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }, [selectedPickupServices, setSelectedPickupServices, pickupServiceData, setRemoving, setSaving])

    return (
        <>
            {!setdownPoint || !allStops || !transfers ?
                <div><LoadMessage message={"Loading transfers..."}/></div> :
                <div className="transfers-modal">
                    <Row gutter={[20, 20]}>
                        <Col xs={24} md={12}>
                            <h3 className="title mt-3 mb-4">Setdown services</h3>
                            <div>
                                <Table className="DataTable" bordered
                                       rowSelection={{
                                           type: 'radio',
                                           columnWidth: 32,
                                           selectedRowKeys: selectedSetdownServices,
                                           onChange: (selectedRowKeys) => {
                                               setSelectedSetdownServices(selectedRowKeys)
                                               console.log(`selectedRowKeys: ${selectedRowKeys}`);
                                               // Populate pickup services based on setdownPoint, selectedSetdownService and transfers
                                               populatePickupServices(selectedRowKeys)
                                           }
                                       }}
                                       dataSource={setdownServiceData} columns={setdownTableColumns()}
                                       pagination={false}
                                       virtual
                                />
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <h3 className="title mt-3 mb-4">Pickup services</h3>
                            <div>
                                <Table className="DataTable" bordered
                                       rowSelection={{
                                           type: 'checkbox',
                                           columnWidth: 32,
                                           selectedRowKeys: selectedPickupServices,
                                           onChange: selectedPickup,
                                           getCheckboxProps: (record) => ({
                                               disabled: removing.includes(record.key) || saving.includes(record.key) || record.dwellSecs < 0,
                                           })
                                       }}
                                       dataSource={pickupServiceData} columns={pickupTableColumns()}
                                       pagination={false}
                                       virtual
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default React.memo(Transfers)
