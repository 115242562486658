import React, {useCallback, useState} from 'react';
import {Button, Collapse, Flex, Image, Input, List, Tooltip, Typography} from 'antd';
import useComments from "../hooks/useComments";
import dayjs from "../dayjs";
import {routeCommentModelData, stopCommentModelData} from "../services/ModelService";
import {useAppContext} from "../libs/contextLib";
import DisplayStop from "./DisplayStop";
import RouteTitle from "./RouteTitle";
import {CheckOutlined} from "@ant-design/icons";
import {uniq} from "lodash";

export const isUnread = (c, editor) => !c.readBy.includes(editor);

export const markAllAsRead = ({comments, chatContext, editor}) => {
    if (comments?.length) {
        const readComments = comments.filter(c => !c.readBy?.includes(editor)).map(c => {
            c.readBy = c.readBy || [];
            c.readBy.push(editor);
            return c;
        });
        if (readComments?.length) {
            if (chatContext.type === 'route') {
                routeCommentModelData.save(readComments).then(() => {
                    console.log('read comments saved')
                });
            } else if (chatContext.type === 'stop') {
                stopCommentModelData.save(readComments).then(() => {
                    console.log('read comments saved')
                });
            }
        }
    }
};

export const markAsRead = ({comment, editor}) => {
    if (comment) {
        comment.readBy = comment.readBy || [];
        comment.readBy.push(editor);
        comment.readBy = uniq(comment.readBy);
        if (comment.type === 'route') {
            routeCommentModelData.save(comment).then(() => {
                console.log('read comments saved')
            });
        } else if (comment.type === 'stop') {
            stopCommentModelData.save(comment).then(() => {
                console.log('read comments saved')
            });
        }
    }
};
export const Comment = ({comment, chatContext, editor}) => {
    return (
        <div className={"w-100"}>
            <Flex justify={"space-between"}><Flex>{comment.stop ?
                <DisplayStop stop={comment.stop}/> : comment.route && comment.subType === 'wp' ?
                    <Button type={"text"} onClick={() => {
                        chatContext.setSelectedRouteIds?.([comment.routeId]);
                        chatContext.setFocusPt?.(comment);
                    }}><Image preview={false} height={24}
                              src={'/location-pin.png'}/></Button> : <></>}<Typography.Paragraph
                copyable={true}>{comment.message}</Typography.Paragraph></Flex>{isUnread(comment, editor) &&
                <Tooltip title={"Mark as resolved"}><Button onClick={() => {
                    markAsRead({comment, editor})
                }} icon={<CheckOutlined/>}/></Tooltip>}</Flex>
            <Typography.Text><small>{`By: ${comment.author || 'Anonymous'} @ ${dayjs(comment.createdAt).format('lll')}`}</small></Typography.Text>
        </div>
    );
}

export const ChatHeader = ({chatContext, comments, editor}) => {
    return <Flex justify={"space-between"}>
        <div>{chatContext.route ?
            <RouteTitle route={chatContext.route}/> : chatContext.stop ?
                <DisplayStop stop={chatContext.stop}/> :
                'Unknown context'}</div>
        {comments.some(c => isUnread(c, editor)) && <div><Button onClick={() => {
            markAllAsRead({comments, chatContext: chatContext, editor});
        }} icon={<CheckOutlined/>}/></div>}
    </Flex>
}

export const ChatList = ({chatContext, editor, initialShowAll = false}) => {
    const [showAll, setShowAll] = useState(initialShowAll);
    const {comments} = useComments({chatContext});
    return (<List
        dataSource={comments}
        renderItem={item => (
            <List.Item
                style={{display: showAll || item.unRead || chatContext.showAllComments ? 'block' : 'none'}}>
                <Comment comment={item} chatContext={chatContext} editor={editor}/>
            </List.Item>
        )}>
        {!chatContext.showAllComments ? <List.Item>
            <Button onClick={() => setShowAll(showAll => !showAll)}>{showAll ? 'Hide' : 'Show'} resolved</Button>
        </List.Item> : <></>}
    </List>)
}

export const ChatInput = ({chatContext, onSave, onClose}) => {
    const {user, messageApi} = useAppContext();
    const {newComment, editor, setEditor} = useComments({chatContext});
    const [message, setMessage] = useState('');

    const handleSendMessage = useCallback(() => {
        if (!message.trim()) return;
        try {
            newComment(message);
            messageApi.info('Comment saved');
            onSave && onSave(message, chatContext);
        } catch (e) {
            messageApi.error('Error saving comment: ' + e.message, 30);
        }
    }, [message, newComment, messageApi, onSave, chatContext]);

    return <>
        {user ? <div>{editor}</div> :
            <Input
                value={editor}
                onChange={e => setEditor(e.target.value)}
                placeholder="Your name..."
                className={"mb-2"}
            />
        }
        <Input
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Your comment..."
            onPressEnter={handleSendMessage}
            className={"mb-2"}
        />
        <Flex justify={"space-between"}>
            <Button type="primary" onClick={handleSendMessage}>
                Save
            </Button>
            <Button type="default" onClick={onClose}>
                Close
            </Button>
        </Flex>
    </>
}

const ChatComponent = ({chatContext, hideInput}) => {
    const {editor} = useAppContext();
    const [message, setMessage] = useState('');
    const {comments, groupedComments, newComment} = useComments({chatContext});
    const [showAll, setShowAll] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);

    // useEffect(() => {
    //     if (groupedComments?.length) {
    //         setFilteredGroups(groupedComments);
    //     }
    //         let unreadGroups = groupedComments.map(group => group.filter(c => !c.readBy?.includes(editor)));
    //         setFilteredGroups(unreadGroups);
    //         return;
    //     setFilteredGroups(groupedComments);
    // }, [setFilteredGroups, groupedComments, unreadOnly, editor]);


    return (
        <div>
            {/*{chatContext?.type === 'all' ?*/}
            <Collapse
                accordion={true}
                items={groupedComments
                    .map(group => {
                            const key = `${group[0].type}_${group[0].id}`
                            return {
                                key,
                                label: <ChatHeader chatContext={chatContext} comments={group} editor={editor}/>,
                                children: <ChatList editor={editor} comments={group} chatContext={chatContext}/>
                            };
                        }
                    )}/>
            {/*:*/}
            {/*<List*/}
            {/*    dataSource={comments}*/}
            {/*    renderItem={item => (*/}
            {/*        <List.Item>*/}
            {/*            <Comment comment={item} chatContext={chatContext}/>*/}
            {/*        </List.Item>*/}
            {/*    )}*/}
            {/*/>*/}
            {/*}*/}
            {comments?.length === 0 && !hideInput && <>
                <ChatInput chatContext={chatContext}/>
            </>
            }
        </div>
    )
        ;
};

export default React.memo(ChatComponent);