import axios from "axios";
import config from "../config";

export const sendEmailViaTemplate = async({
    to,
    from = 'noreply@busable.app',
    subject,
    merge,
    template
}) => {
    await axios.post(
        "https://api.elasticemail.com/v4/emails/transactional",
        {
            Recipients: {
                To: to,
            },
            Content: {
                From: from,
                Subject: subject,
                Merge: merge,
                TemplateName: template,
            },
        },
        {
            headers: {
                "X-ElasticEmail-ApiKey": config.email.ELASTIC_EMAIL_KEY,
            },
        }
    );
}