import React from "react";
import {Modal} from "antd";
import ItineraryPrintView from "./ItineraryPrintView";

function ItineraryPrintModal({
                                apiKey,
                                itinerary,
                                allStops, allRoutes,
                                visible,
                                schedules,
                                setVisible
                            }) {
    return (
        <Modal
            width={1000}
            style={{ top: 50 }}
            open={visible}
            destroyOnClose
            okText={`Print`}
            onCancel={() => setVisible(null)}
            onOk={() => window.print()}
        >
            <ItineraryPrintView
                allRoutes={allRoutes}
                allStops={allStops}
                operatorId={apiKey}
                itinerary={itinerary}
                schedules={schedules}/>
        </Modal>
    );
}

export default React.memo(ItineraryPrintModal)
