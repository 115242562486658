import React, { useEffect, useState } from "react";
import { ShiftBatRowNotePriority, ShiftBatRowType } from "../../model/shiftBat";
import ItineraryServiceRowEdit from "./ItineraryServiceRowEdit";
import "./Itinerary.css";
import ItineraryStopRowEdit from "./ItineraryStopRowEdit";
import ItineraryDeadRowEdit from "./ItineraryDeadRowEdit";
import ItineraryNoteRowEdit from "./ItineraryNoteRowEdit";
import ItineraryBreakRowEdit from "./ItineraryBreakRowEdit";
import { CharterRowType } from "../../model/charter";
import ItineraryTemplateRowEdit from "./ItineraryTemplateRowEdit";

function ItineraryRowEdit({
    itineraryRow,
    schedules,
    allRoutes,
    allStops,
    onCancel,
    onUpdate,
    onCreate,
    setEditRouteRow,
    isLoading,
    updating,
    setUpdating,
}) {
    const [hideTime, setHideTime] = useState(itineraryRow.hideTime);
    const [time, setTime] = useState(itineraryRow.time);
    const [type, setType] = useState(itineraryRow.type);
    const [title, setTitle] = useState(itineraryRow.title);
    const [description, setDescription] = useState(itineraryRow.description);
    const [duration, setDuration] = useState((itineraryRow.duration / 60).toFixed(0));
    const [route, setRoute] = useState(itineraryRow.route);
    const [trip, setTrip] = useState(itineraryRow.trip);
    const [stop, setStop] = useState(itineraryRow.stop);
    const [priority, setPriority] = useState(itineraryRow.priority);
    const [template, setTemplate] = useState(itineraryRow.template)

    useEffect(() => {
        setHideTime(itineraryRow.hideTime);
        setTime(itineraryRow.time);
        setType(itineraryRow.type);
        setTitle(itineraryRow.title);
        setDescription(itineraryRow.description);
        setDuration((itineraryRow.duration / 60).toFixed(0));
        setRoute(itineraryRow.route);
        setTrip(itineraryRow.trip);
        setStop(itineraryRow.stop);
        setPriority(itineraryRow.priority);
        setTemplate(itineraryRow.template)
    }, [itineraryRow, setHideTime, setTime, setType, setTitle, setDescription, setDuration, setRoute, setTrip, setStop, setPriority, setTemplate]);

    // TODO: removed for BUS-454 as deemed unnecessary due to the stop rows being added in itinerary.js
    // useEffect(() => {
    //     if (route) {
    //         route.setBaseStops(allStops, route.stopTimes, !config?.operator?.opts?.shiftbat?.turnsOnAllStops);
    //     }
    // }, [allStops, route])

    const resetRow = (type) => {
        setHideTime(type === ShiftBatRowType.note);
        setType(type);
        setRoute(null);
        setTrip(null);
        setTitle(null);
        setDuration("0");
        setDescription(null);
        setPriority(ShiftBatRowNotePriority.normal);
        setTemplate(null)
    };

    // useEffect(() => {
    //
    //     if(itineraryRow) {
    //         setTime(itineraryRow.time)
    //     }
    //
    // }, [itineraryRow, setTime])

    // console.log(time);

    return (
        <div key={itineraryRow.id} className="row d-flex align-items-center sb-row current-edit">
            <div className="col-lg-12 sb-row-edit">
                {type === CharterRowType.noteTimed ? (
                    <ItineraryNoteRowEdit
                        itineraryRow={itineraryRow}
                        hideTime={hideTime}
                        time={time}
                        type={type}
                        title={title}
                        description={description}
                        priority={priority}
                        setTime={setTime}
                        setTitle={setTitle}
                        setDescription={setDescription}
                        updating={updating}
                        isLoading={isLoading}
                        setPriority={setPriority}
                        resetRow={resetRow}
                        onCancel={onCancel}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                    />
                ) : type === CharterRowType.note ? (
                    <ItineraryNoteRowEdit
                        itineraryRow={itineraryRow}
                        type={type}
                        title={title}
                        description={description}
                        hideTime={hideTime}
                        priority={priority}
                        setTitle={setTitle}
                        setDescription={setDescription}
                        updating={updating}
                        isLoading={isLoading}
                        setPriority={setPriority}
                        resetRow={resetRow}
                        onCancel={onCancel}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                    />
                ): type === CharterRowType.template ? (
                    <ItineraryTemplateRowEdit
                        itineraryRow={itineraryRow}
                        type={type}
                        title={title}
                        description={description}
                        hideTime={hideTime}
                        priority={priority}
                        setTitle={setTitle}
                        setDescription={setDescription}
                        template={template}
                        setTemplate={setTemplate}
                        updating={updating}
                        isLoading={isLoading}
                        setPriority={setPriority}
                        resetRow={resetRow}
                        onCancel={onCancel}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                    />
                )  : type === CharterRowType.stop ? (
                    <ItineraryStopRowEdit
                        itineraryRow={itineraryRow}
                        type={type}
                        setType={setType}
                        stop={stop}
                        setStop={setStop}
                        time={time}
                        setTime={setTime}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                        allStops={allStops}
                        resetRow={resetRow}
                        updating={updating}
                        onCancel={onCancel}
                        onUpdate={onUpdate}
                    />
                ) : type === CharterRowType.charter ? (
                    <ItineraryDeadRowEdit
                        itineraryRow={itineraryRow}
                        setEditRouteRow={setEditRouteRow}
                        route={route}
                        setRoute={setRoute}
                        time={time}
                        title={title}
                        setTitle={setTitle}
                        updating={updating}
                        isLoading={isLoading}
                        onCancel={onCancel}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                        description={description}
                        setDescription={setDescription}
                        duration={duration}
                        setDuration={setDuration}
                    />
                ) : type === CharterRowType.service ? (
                    <ItineraryServiceRowEdit
                        itineraryRow={itineraryRow}
                        allRoutes={allRoutes}
                        allStops={allStops}
                        type={type}
                        schedules={schedules}
                        setTime={setTime}
                        time={time}
                        title={title}
                        setTitle={setTitle}
                        route={route}
                        setRoute={setRoute}
                        trip={trip}
                        updating={updating}
                        setUpdating={setUpdating}
                        resetRow={resetRow}
                        description={description}
                        setTrip={setTrip}
                        isLoading={isLoading}
                        setDescription={setDescription}
                        onCancel={onCancel}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                    />
                ) : [CharterRowType.break, CharterRowType.breakMeal, CharterRowType.breakBroken].includes(type) ? (
                    <ItineraryBreakRowEdit
                        itineraryRow={itineraryRow}
                        type={type}
                        setTime={setTime}
                        time={time}
                        setTitle={setTitle}
                        title={title}
                        duration={duration}
                        setDuration={setDuration}
                        updating={updating}
                        resetRow={resetRow}
                        onCancel={onCancel}
                        onUpdate={onUpdate}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default React.memo(ItineraryRowEdit);
