import {CommentOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import useComments from "../hooks/useComments";
import useEditor from "../hooks/useEditor";
import {useAppContext} from "../libs/contextLib";
import {Badge} from "antd";
import useAllowComments from "../hooks/useAllowComments";

export default function ChatButton({chatContext, setChatContext, onClick, showComments}) {
    const {user} = useAppContext();
    const [editor] = useEditor({user});
    const [showBadge, setShowBadge] = useState(0);
    const {unreadComments} = useComments({chatContext});
    const [allowComments] = useAllowComments();

    useEffect(() => {
        setShowBadge(unreadComments.length);
    }, [unreadComments, setShowBadge, editor]);

    return (
        <>{allowComments && showComments ?
            <Badge dot={showBadge > 0} size={"small"}>
                <CommentOutlined style={{cursor: "pointer"}} onClick={() => {
                    setChatContext(chatContext)
                    onClick && onClick(true, chatContext);
                }}/></Badge> : <></>
        }</>)
}