import React from "react";
import ItineraryStopRow from "./ItineraryStopRow";
function ItineraryServiceRow({
                                itineraryRow, onUpdate,
                                editMode, printMode, onSave, selectedStop, setSelectedStop, setFocusStop
                            }) {

    const stopRows = itineraryRow.getStopRows();

    return (
        <div className="col-lg-12 sb-row-routes">
            {stopRows.map((itineraryStopRow, idx) => (
                <div key={'SBSR' + itineraryStopRow.id}>
                    <ItineraryStopRow itineraryStopRow={itineraryStopRow} setSelectedStop={setSelectedStop}
                                     itineraryServiceRow={itineraryRow}
                                     route={itineraryRow.route} trip={itineraryRow.trip}
                                     stopTimeId={itineraryStopRow.stopTimeId}
                                     route={itineraryRow.route} trip={itineraryRow.trip}
                                     stopTimeId={itineraryStopRow.stopTimeId}
                                     setFocusStop={setFocusStop} selectedStop={selectedStop} printMode={printMode}
                                     editMode={editMode} onSave={(e) => onSave(e, itineraryRow)}
                                     trimStart={idx > 0 ? e => {
                                         onUpdate(e, {
                                             ...itineraryRow,
                                             startStopIdx: itineraryRow.trip.stopTimes.findIndex(st => st.stopTimeId === itineraryStopRow.stopTimeId)
                                         })
                                     } : null}
                                     trimEnd={idx < stopRows.length-1 ? e => {
                                         onUpdate(e, {
                                             ...itineraryRow,
                                             endStopIdx: itineraryRow.trip.stopTimes.findIndex(st => st.stopTimeId === itineraryStopRow.stopTimeId)
                                         })
                                     } : null}
                    />
                    {/*{itineraryStopRow.transfersTo?.length ?*/}
                    {/*    <ItineraryTransferRow itineraryStopRow={itineraryStopRow} transfers={itineraryStopRow.transfersTo}*/}
                    {/*                         pickup={false}/> : <></>*/}
                    {/*}*/}
                    {/*{itineraryStopRow.transfersFrom?.length ?*/}
                    {/*    <ItineraryTransferRow itineraryStopRow={itineraryStopRow} transfers={itineraryStopRow.transfersFrom}*/}
                    {/*                         pickup={true}/> : <></>*/}
                    {/*}*/}
                    {/*{itineraryStopRow.steps?.length ?*/}
                    {/*    <ItineraryDirectionsRow steps={itineraryStopRow.steps}/> : <></>*/}
                    {/*}*/}
                </div>))}
        </div>
    );
}

export default React.memo(ItineraryServiceRow)
