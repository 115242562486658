import React from "react";
import { Button, Tooltip } from "antd";
import { ReactComponent as Trash } from "../../assets/icons/Trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import { ReactComponent as Break } from "../../assets/icons/Break.svg";
import { ReactComponent as Services } from "../../assets/icons/Services.svg";
import { ShiftBatRowNotePriority, ShiftBatRowType } from "../../model/shiftBat";
import { ReactComponent as DeadRunning } from "../../assets/icons/DeadRunning.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as Pin } from "../../assets/icons/Pin.svg";
import ItineraryServiceRow from "./ItineraryServiceRow";
import "./Itinerary.css";
import RouteNumber from "../../components/RouteNumber";
import { SyncOutlined } from "@ant-design/icons";
import { uniq } from "lodash/array";
import { useDroppable } from "@dnd-kit/core";
import { toHrsMinsSecs, toKmMs } from "../../libs/formatLib";
import { charterModelData } from "../../services/ModelService";

function ItineraryRow({
    allRoutes,
    allStops,
    itineraryRow,
    disableEdit,
    editMode,
    printMode,
    editRow,
    deleteRow,
    addRow,
    onUpdate,
    viewing,
    updating,
    setUpdating,
    setViewing,
    setSelectedStop,
    setHighlightedRouteIds,
    setFocusStop,
    selectedStop,
}) {
    const { isOver, setNodeRef } = useDroppable({
        id: itineraryRow.id,
    });
    const style = {
        color: isOver ? "green" : undefined,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            onMouseOver={() => {
                if (!editMode) {
                    return;
                }
                if (itineraryRow.route) {
                    setHighlightedRouteIds((highlightedRouteIds) => uniq(highlightedRouteIds.concat(itineraryRow.route?.routeId)));
                }
                if (itineraryRow.stop) {
                    setFocusStop(itineraryRow.stop);
                }
            }}
            onMouseLeave={() => {
                if (!editMode) {
                    return;
                }
                if (itineraryRow.route) {
                    setHighlightedRouteIds((highlightedRouteIds) =>
                        highlightedRouteIds.filter((rId) => viewing[itineraryRow.id] || rId !== itineraryRow.route?.routeId)
                    );
                }
                if (itineraryRow.stop) {
                    setFocusStop(null);
                }
            }}
            key={itineraryRow.id}
            className={`sb-row ${itineraryRow?.priority === ShiftBatRowNotePriority.high ? "sb-important" : ""} ${
                viewing[itineraryRow.id] ? "sb-highlight" : ""
            } ${itineraryRow.type === ShiftBatRowType.service ? "sb-row-service" : ""} ${
                !itineraryRow.isValid({
                    allStops,
                    allRoutes,
                })
                    ? "alert-danger"
                    : ""
            }`}
        >
            <div className={`row d-flex align-items-center ml-0 mr-0`}>
                <div className="col-1 sb-time">
                    {!itineraryRow.hideTime && itineraryRow.type !== ShiftBatRowType.charter && (
                        <span>{updating ? <SyncOutlined spin={true} /> : itineraryRow.toTimeString()}</span>
                    )}
                    {/*{editMode &&*/}
                    {/*    <Tooltip placement="bottom"*/}
                    {/*             title={itineraryRow.hideTime ? "Show time field" : "Hide time field"}>*/}
                    {/*        <div className="sb-controls">*/}
                    {/*        <Button className="sb-edit-row" style={{marginLeft: '5px'}}*/}
                    {/*                icon={updating ? <SyncOutlined spin={true}/> :*/}
                    {/*                    itineraryRow.hideTime ? <Time  /> : <NoTime />}*/}
                    {/*                disabled={updating}*/}
                    {/*                onClick={e => {*/}
                    {/*                    e.stopPropagation();*/}
                    {/*                    setUpdating(true)*/}
                    {/*                    itineraryRow.hideTime = !itineraryRow.hideTime*/}
                    {/*                    onUpdate(e, itineraryRow)*/}
                    {/*                }}/>*/}
                    {/*        </div>*/}
                    {/*    </Tooltip>*/}
                    {/*}*/}
                </div>
                <div className="col-2 sb-icon">
                    <div className={`RouteIcon`}>
                        {itineraryRow.type !== ShiftBatRowType.note &&
                        itineraryRow.type !== ShiftBatRowType.stop &&
                        [ShiftBatRowType.break, ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken].includes(itineraryRow.type) ? (
                            <Break />
                        ) : itineraryRow.type === ShiftBatRowType.charter ? (
                            <DeadRunning />
                        ) : itineraryRow.type === ShiftBatRowType.service ? (
                            <RouteNumber route={itineraryRow.route} />
                        ) : itineraryRow.type === ShiftBatRowType.stop ? (
                            <Pin />
                        ) : (
                            <></>
                        )}
                        {itineraryRow?.priority === ShiftBatRowNotePriority.high ? <Warning /> : ""}
                    </div>
                </div>
                <div
                    className={"col-9 d-flex RouteTitle align-items-center sb-info "}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (printMode) {
                            return;
                        }
                        if ([ShiftBatRowType.service, ShiftBatRowType.charter].includes(itineraryRow.type)) {
                            setViewing((viewing) => ({ ...viewing, [itineraryRow.id]: !viewing[itineraryRow.id] }));
                        }
                        setFocusStop(null);
                    }}
                >
                    <div className={`RouteInfo`}>
                        {!itineraryRow.isValid({ allStops, allRoutes }) ? (
                            <Tooltip
                                title={`${itineraryRow.invalidReason({ allStops, allRoutes })}. Please ${
                                    itineraryRow.type === ShiftBatRowType.charter
                                        ? " regenerate the deadrun"
                                        : [ShiftBatRowType.stop, ShiftBatRowType.service].includes(itineraryRow.type)
                                        ? " delete and add it again"
                                        : ""
                                }.`}
                            >
                                <span className="text-danger">
                                    Invalid row. {itineraryRow.invalidReason({ allStops, allRoutes })}. Please edit the shift bat
                                    {itineraryRow.type === ShiftBatRowType.charter
                                        ? " and regenerate the deadrun"
                                        : [ShiftBatRowType.stop, ShiftBatRowType.service].includes(itineraryRow.type)
                                        ? ` and delete this ${itineraryRow.title} ${itineraryRow.type} and add it again`
                                        : ""}
                                    .
                                </span>
                            </Tooltip>
                        ) : (
                            <>
                                <h4 className="title">
                                    {itineraryRow.title}
                                    {itineraryRow.type === ShiftBatRowType.charter && itineraryRow.route
                                        ? ` (${toHrsMinsSecs(itineraryRow.route.duration, false, true, 1) || "0s"}, ${toKmMs(
                                              itineraryRow.route.distance
                                          )})`
                                        : ""}
                                </h4>
                                {itineraryRow.description && <span className={`RouteDetails`}>{itineraryRow.description}</span>}
                            </>
                        )}
                        {/*    {editMode &&*/}
                        {/*    <div className={`RouteDetails ml-2`}>*/}
                        {/*        {itineraryRow.duration > 0 &&*/}
                        {/*            <div>{itineraryRow.duration > 0 && toHrsMinsSecs(itineraryRow.duration, false, true)}</div>*/}
                        {/*        }*/}
                        {/*        {itineraryRow.distance > 0 &&*/}
                        {/*            <div>{itineraryRow.distance > 0 && toKmMs(itineraryRow.distance, 0)}</div>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>

                    {editMode && (
                        <div className="sb-controls">
                            {itineraryRow.type === ShiftBatRowType.service && (
                                <Tooltip placement="bottom" title="Generate Left/Rights for stops">
                                    <Button
                                        className="sb-directions"
                                        icon={updating ? <SyncOutlined spin={true} /> : <Services />}
                                        disabled={updating}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setUpdating(true);
                                            itineraryRow.buildDirections(true).then(() => onUpdate(e, itineraryRow));
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {itineraryRow.type === ShiftBatRowType.charter && (
                                <Tooltip placement="bottom" title="Regenerate deadrun">
                                    <Button
                                        className="sb-directions"
                                        icon={updating ? <SyncOutlined spin={true} /> : <Services />}
                                        disabled={updating}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setUpdating(true);
                                            itineraryRow.reset({charterModelData}).then(() => {
                                                onUpdate(e, itineraryRow);
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {itineraryRow.isValid({ allStops, allRoutes }) ? (
                                <Button
                                    className={`sb-edit-row ${itineraryRow.type === ShiftBatRowType.charter ? "single" : ""}`}
                                    disabled={updating || disableEdit}
                                    icon={updating ? <SyncOutlined spin={true} /> : <Edit />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        editRow(e, itineraryRow);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {itineraryRow.type !== ShiftBatRowType.charter && (
                                <Button
                                    className="sb-delete-row"
                                    icon={<Trash />}
                                    disabled={updating || disableEdit}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteRow(e, itineraryRow);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {itineraryRow.type === ShiftBatRowType.service && viewing[itineraryRow.id] && (
                <ItineraryServiceRow
                    key={"SBServR" + itineraryRow.id}
                    itineraryRow={itineraryRow}
                    editMode={editMode}
                    onUpdate={onUpdate}
                    onSave={onUpdate}
                    setSelectedStop={setSelectedStop}
                    printMode={printMode}
                    selectedStop={selectedStop}
                    setFocusStop={setFocusStop}
                />
            )}
            {editMode && itineraryRow.type !== ShiftBatRowType.charter && (
                <Button
                    className="sb-add-row"
                    disabled={updating || disableEdit}
                    icon={<Plus />}
                    onClick={(e) => {
                        e.stopPropagation();
                        addRow();
                    }}
                />
            )}
        </div>
    );
}

export default React.memo(ItineraryRow);
