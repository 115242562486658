import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as Left } from "../../assets/icons/Left_a.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function EmployeeHeader() {
    const history = useHistory()

    const handleBack = () => {
        // update to handle dynamic back (based on previous page)
        history.push('/hrm')
    }
    return (
        <Button type="primary" onClick={handleBack} className="icon-button back-btn mb-3" icon={<Left />}>
            Staff List
        </Button>
    );
}

export default React.memo(EmployeeHeader);
