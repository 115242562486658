import { Button } from "react-bootstrap";
import { Col, Row, Card, Modal, Select, DatePicker } from "antd";
import { ulid } from "ulid";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useEffect, useState } from "react";

import { employeeModelData } from "../../services/ModelService";
import dayjs from "../../dayjs";
import EmployeeMenu from "./EmployeeMenu";
import useEmployeeData from "../../hooks/useEmployeeData";
import EmployeeHeader from "./EmployeeHeader";
import "./EmployeeHR.css";
import { Leave } from "../../model/hrm/leave";
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../libs/contextLib";

export const LEAVE_STATUS = {
    PENDING: "pending",
    APPROVED: "approved",
    REJECTED: "rejected",
};

const INITIAL_EVENT = {
    start: null,
    end: null,
    type: null,
    status: null,
};

export const LEAVE_TYPES = [
    { label: "Annual Leave", value: "Annual-Leave" },
    { label: "Sick Leave", value: "Sick-Leave" },
    { label: "Personal Leave", value: "Personal-Leave" },
    { label: "Unpaid Leave", value: "Unpaid-Leave" },
    { label: "Other Leave", value: "Other-Leave" },
];

function EmployeeCalendar() {
    const { id } = useParams();
    const history = useHistory();

    const [employee, setEmployee] = useEmployeeData();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [event, setEvent] = useState(INITIAL_EVENT);
    const [isLoading, setIsLoading] = useState(false);

    const { setBreadcrumb } = useAppContext();
    useEffect(() => {
        setBreadcrumb("Calendar");
    }, [employee, setBreadcrumb]);

    const handleOk = async () => {
        setIsLoading(true);
        const leaves = {
            employeeID: id,
            leaveTypeID: event.type,
            title: employee?.external?.leaveBalances
                ? employee?.external?.leaveBalances.find((balance) => balance.leaveTypeID === event.type)?.leaveName
                : LEAVE_TYPES.find((type) => type.value === event.type)?.label,
            startDate: event.start,
            endDate: event.end,
            status: event.status,
        };
        const res = await employeeModelData.createLeaves(id, leaves);
        if (employee?.external?.leaveBalances) {
            const leavesObjs = res.body.leaveApplications
                .map((l) => new Leave(l, employee.external))
                .map((l) => ({ ...l, sk: `#EMPLOYEE#${id}#LEAVE#${l.id}` }));
            employeeModelData.save(leavesObjs);
            setEmployee({ ...employee, leaves: [...employee.leaves, ...leavesObjs] });
        } else {
            setEmployee({ ...employee, leaves: [...(employee.leaves || []), res.body] });
        }
        setIsModalOpen(false);
        setIsLoading(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setEvent(INITIAL_EVENT);
    };

    if (id === "_new_") history.push("/hrm/_new_/details");

    const handleNewEvent = (event) => {
        const startDate = dayjs(event.startStr || event.date).format("YYYY-MM-DD");
        const endDate = dayjs(event.endStr || event.date).format("YYYY-MM-DD");
        setEvent({
            ...INITIAL_EVENT,
            start: startDate,
            end: endDate,
        });
        setIsModalOpen(true);
    };

    const handleExistingEvent = (event) => {
        const item = employee?.leaves.find((leave) => leave.leaveApplicationID === event.event.id || leave.id === event.event.id)
        setEvent(item);
        setIsModalOpen(true);
    };

    const renderEventContent = (eventInfo) => {
        const event = employee?.leaves.find((leave) => leave.id === eventInfo.event.id);
        return (
            <div>
                <span>
                    {event.type} - {event.status}
                </span>
            </div>
        );
    };

    const getLeaveOptions = () => {
        if (employee?.external?.leaveBalances) {
            return employee.external.leaveBalances.map((b) => ({
                label: b.leaveName,
                value: b.leaveTypeID,
            }));
        } else {
            return LEAVE_TYPES;
        }
    };

    return (
        <div className="employee-dashboard">
            <EmployeeHeader/>
            <Row gutter={[20, 20]}>
                <EmployeeMenu
                    user={{
                        avatarSrc: "",
                        name: "John Doe",
                        jobTitle: "Bus Driver",
                        employeeId: "1234",
                    }}
                    activeKey={"9"}
                />
                <Col xs={24} lg={19}>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            <Card title="Leave Calendar" bordered={false} className="card-main card-info">
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    headerToolbar={""}
                                    events={employee?.leaves?.map((l) => ({
                                        ...l,
                                        // display: 'background',
                                        // className: `leave-${l.type} leave-${l.status}`
                                    }))}
                                    dateClick={handleNewEvent}
                                    select={handleNewEvent}
                                    eventClick={handleExistingEvent}
                                    // eventContent={renderEventContent}
                                    selectable
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                title="Apply Leave"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={(_) => (
                    <>
                        <Button className="secondary mr-4" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <LoaderButton onClick={handleOk} isLoading={isLoading}>
                            Save
                        </LoaderButton>
                    </>
                )}
            >
                <Row xs={24} lg={24} className="justify-content-between mb-3">
                    <label>Starting Date</label>
                    <DatePicker
                        {...(event.start &&
                            event.start !== "" && {
                                value: dayjs(event.start),
                            })}
                        onChange={(date) => setEvent({ ...event, start: dayjs(date).format("DD-MM-YYYY") })}
                        format={{
                            format: 'DD-MM-YYYY',
                            type: 'mask',
                        }}
                    />
                </Row>
                <Row xs={24} lg={24} className="justify-content-between mb-3">
                    <label>Ending Date</label>
                    <DatePicker
                        {...(event.end && event.end !== "" && { value: dayjs(event.end) })}
                        onChange={(date) => setEvent({ ...event, end: dayjs(date).format("DD-MM-YYYY") })}
                        format={{
                            format: 'DD-MM-YYYY',
                            type: 'mask',
                        }}
                    />
                </Row>
                <Row xs={24} lg={24} className="justify-content-between mb-3">
                    <label>Type</label>
                    <Select
                        name="type"
                        value={event.type}
                        placeholder="Select Type"
                        options={getLeaveOptions()}
                        onChange={(value) => setEvent({ ...event, type: value })}
                    />
                </Row>
                <Row xs={24} lg={24} className="justify-content-between mb-3">
                    <label>Status</label>
                    <Select
                        name="status"
                        defaultValue={LEAVE_STATUS.PENDING}
                        value={event.status}
                        placeholder="Select Type"
                        options={[
                            { label: "Pending", value: "pending" },
                            { label: "Approved", value: "approved" },
                            { label: "Rejected", value: "rejected" },
                        ]}
                        onChange={(value) => setEvent({ ...event, status: value })}
                    />
                </Row>
            </Modal>
        </div>
    );
}

export default React.memo(EmployeeCalendar);
