import React, {useCallback, useEffect, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import {ReactComponent as Check} from "../../assets/icons/Check.svg";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import ItinerarySelectType from "./ItinerarySelectType";
import ItinerarySelectPriority from "./ItinerarySelectPriority";
import {Col, Row} from "react-bootstrap";
import ItineraryTimePicker from "./ItineraryTimePicker";

//import {Space} from "antd/lib";

function ItineraryNoteRowEdit({
                                 type, time, priority,
                                 description, title, onUpdate,
                                 updating, onCreate, hideTime,
                                 itineraryRow, setTitle, setTime, setDescription,
                                 setPriority, isLoading, onCancel, resetRow
                             }) {
    // eslint-disable-next-line
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!itineraryRow?.notes) {
            return;
        }
        // Set the service options when allRoutes change
        const opts = Object.keys(itineraryRow.notes).map(noteId => ({
            value: noteId,
            label: itineraryRow.notes[noteId].title || 'New note'
        }))
        setOptions(opts);
    }, [itineraryRow, setOptions])

    const isValid = useCallback(() => {
        return (hideTime || time > -1) && title?.length
    }, [hideTime, time, title])

    return (
        <>
            <div>
                <Row className="row-compact">
                    <Col md="auto">
                        {!hideTime && <ItineraryTimePicker time={time} setTime={setTime} />}
                    </Col>
                    <Col className="row-col-gap">
                        <ItinerarySelectType type={type} resetRow={resetRow}
                                            className={"inputselect-small"}/>
                        <ItinerarySelectPriority className="inputselect-small" priority={priority}
                                                setPriority={setPriority}/>
                        {/*<ItinerarySelectRowEdit*/}
                        {/*    suffixIcon={!itineraryRow?.loaded || isLoading ? <SyncOutlined spin={true}/> :*/}
                        {/*        <Down/>}*/}
                        {/*    // disabled={!itineraryRow?.notes || !Object.keys(itineraryRow.notes).length || isNew()}*/}
                        {/*    value={itineraryRow?.refId}*/}
                        {/*    placeholder={"Select note"}*/}
                        {/*    className={"inputselect-small w-100"}*/}
                        {/*    onChange={noteId => {*/}
                        {/*        const newNote = itineraryRow.notes[noteId]*/}
                        {/*        setTitle(newNote.title)*/}
                        {/*        setDescription(newNote.description)*/}
                        {/*        setPriority(newNote.priority)*/}
                        {/*    }}*/}
                        {/*    options={options}*/}
                        {/*    itemName="note"*/}
                        {/*    newPlaceholder="Note..."*/}
                        {/*    addNew={(e, title) => {*/}
                        {/*        if (title?.length) {*/}
                        {/*            setTitle(title)*/}
                        {/*            onCreate(e, {*/}
                        {/*                ...itineraryRow,*/}
                        {/*                type,*/}
                        {/*                time: parseInt(time),*/}
                        {/*                title,*/}
                        {/*                description,*/}
                        {/*                priority,*/}
                        {/*            }, true)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Input placeholder="Note details" className="inputtext-small input-desc"
                               value={title || ""}
                               onChange={e => setTitle(e.target.value)}/>
                        <Input placeholder="Driver instructions" className="inputtext-small input-desc"
                               value={description || ""}
                               onChange={e => setDescription(e.target.value)}/>
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button className="sb-done"
                                    icon={updating && itineraryRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Check/>}
                                    disabled={!isValid() || updating}
                                    onClick={e => onUpdate(e, {
                                        ...itineraryRow,
                                        hideTime,
                                        type,
                                        time: parseInt(time),
                                        title,
                                        description,
                                        priority,
                                    })}/>
                            <Button className="sb-close"
                                    icon={updating && itineraryRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Close/>}
                                    disabled={updating} onClick={e => onCancel(e, itineraryRow)}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ItineraryNoteRowEdit)
