import { Badge, Avatar, Card, Col, Menu } from "antd";
import { useHistory } from "react-router-dom";
import React, { useCallback, useMemo, useRef } from "react";
import useEmployeeData from "../../hooks/useEmployeeData";

import {
    BankingIcon,
    BusinessIcon,
    CalendarIcon,
    DashboardIcon,
    EntitlementsIcon,
    IncomeIcon,
    QualificationsIcon,
    TaxIcon,
    UserSIcon,
    UserWIcon,
} from "../../components/Icons";
import { useParams } from "react-router-dom";
import { s3Upload } from "../../libs/awsLib";
import { employeeModelData } from "../../services/ModelService";
import config from "../../config";

const FILE_PREFIX = `https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/`;

function getItem(label, key, icon, showInCreate = true, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
        showInCreate,
    };
}

const items = [
    // getItem("Dashboard", "1", <DashboardIcon />, false),
    getItem("Personal Details", "2", <UserSIcon />),
    getItem("Employment Details", "3", <BusinessIcon />, false),
    getItem("Entitlements", "4", <EntitlementsIcon />, false),
    getItem("Banking Details", "5", <BankingIcon />, false),
    getItem("Superannuation Details", "6", <IncomeIcon />, false),
    getItem("Tax Details", "7", <TaxIcon />, false),
    getItem("Qualifications and Training", "8", <QualificationsIcon />, false),
    getItem("Calendar", "9", <CalendarIcon />, false),
];

function EmployeeMenu({ activeKey }) {
    const { id } = useParams();
    const [employee, setEmployee] = useEmployeeData();
    const { details } = employee;
    const history = useHistory();
    const fileInputRef = useRef();

    const employeeId = id ? id : "-";
    const isNewEmployee = id === "_new_";
    // ||  employeeId === "-" ;

    const onMenuSelect = useCallback(
        ({ key }) => {
            const menu = items.find((item) => item.key === key).label;

            switch (menu) {
                case "Dashboard":
                    history.push(`/hrm/${employeeId}/dashboard`);
                    break;
                case "Personal Details":
                    history.push(`/hrm/${employeeId}/details`);
                    break;
                case "Employment Details":
                    history.push(`/hrm/${employeeId}/employment`);
                    break;
                case "Entitlements":
                    history.push(`/hrm/${employeeId}/entitlements`);
                    break;
                case "Banking Details":
                    history.push(`/hrm/${employeeId}/banking`);
                    break;
                case "Superannuation Details":
                    history.push(`/hrm/${employeeId}/superannuation`);
                    break;
                case "Tax Details":
                    history.push(`/hrm/${employeeId}/tax`);
                    break;
                case "Qualifications and Training":
                    history.push(`/hrm/${employeeId}/qualifications`);
                    break;
                case "Calendar":
                    history.push(`/hrm/${employeeId}/calendar`);
                    break;
                default:
                    break;
            }
        },
        [employeeId, history]
    );

    const enabledItems = useMemo(() => {
        return items.filter((item) => !isNewEmployee || item.showInCreate).map(({ showInCreate, ...item }) => item);
    }, [isNewEmployee]);

    const memoizedAvatarSrc = useMemo(() => {
        if (details?.avatar) {
            return `${FILE_PREFIX}${details?.avatar}`;
        }
        return "";
    }, [details?.avatar]);

    const formattedEmployeeId = useMemo(() => {
        return employee?.details?.driverId?.toString().padStart(4, "0");
    }, [employee?.details?.driverId]);

    const uploadNewAvatar = useCallback(() => {
        if(isNewEmployee) return
        fileInputRef.current.click();
    }, [isNewEmployee]);

    const handleFileChange = async (event) => {
        try {
            const file = event.target.files[0];
            const filename = `${id}-${file.name}`;
            await s3Upload(file, filename, {
                level: "public",
                cacheControl: "no-cache",
            });
            await employeeModelData.save({
                ...employee.details,
                avatar: filename,
            });
            setEmployee({ ...employee, details: { ...employee.details, avatar: filename } });
        } catch (e) {
            console.error(e?.message);
        }
    };

    return (
        <Col xs={24} lg={5}>
            <Card bordered={false} className="card-main body-pad-0 card-height-auto">
                <div className="profile-summary">
                    <Badge dot className={`success ${!isNewEmployee ? "upload" : null}`}>
                        <Avatar size={100} icon={<UserWIcon />} src={memoizedAvatarSrc} onClick={uploadNewAvatar} />
                        <input type="file" style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
                    </Badge>
                    <h2>
                        {employee?.external?.firstName} {employee?.external?.lastName}
                    </h2>
                    <div className="text-info text-uppercase">{employee?.external?.jobTitle || "-"}</div>
                    <div className="text-primary">#{formattedEmployeeId}</div>
                </div>
                <Menu
                    className="profile-menu"
                    defaultSelectedKeys={["1"]}
                    mode="inline"
                    theme="light"
                    selectedKeys={[activeKey]}
                    items={enabledItems}
                    onSelect={onMenuSelect}
                />
            </Card>
        </Col>
    );
}

export default React.memo(EmployeeMenu);
