import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Charters.css";
import { charterModelData } from "../../services/ModelService";
import { Charter } from "../../model/charter";
import useModel from "../../hooks/useModel";
import SaveMenu from "../../components/SaveMenu";
import LoadMessage from "../../components/LoadMessage";
import { Card, Col, DatePicker, Flex, Input, InputNumber, Row, Select, TimePicker } from "antd";
import CharterMenu from "./CharterMenu";
import { startCase } from "lodash";
import ItineraryView from "../Itinerary/ItineraryView";
import useItinerary from "./useItinerary";
import ItineraryTemplateManager from "../Itinerary/ItineraryTemplateManager";

const { TextArea } = Input;

function CharterItinerary() {
    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({ charterId: "_" }),
    });
    const {itinerary, setItinerary} = useItinerary()

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({ ...charter });
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    return (
        <div className="charter-details w-secondary-menu">
            {charter && itinerary? (
                <>
                    <SaveMenu
                        save={() => {
                            charterModelData.save(charter).then(() => {
                                console.log("Charter saved.");
                                setMode({ edit: false });
                            });
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({ edit })}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => charter.isValid()}
                        closeView={true}
                    />
                    <Row gutter={[20, 20]}>
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={"2"}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: false,
                                    quote: mode.edit,
                                }}
                                hide={{
                                    charter: charter.charterId === "_",
                                    itinerary: false,
                                    quote: charter.charterId === "_",
                                }}
                            />
                        )}
                        <Col xs={24} lg={19}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Itinerary</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <ItineraryView />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..." />
            )}
        </div>
    );
}

export default React.memo(CharterItinerary);
