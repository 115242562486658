import React, {useCallback, useEffect, useRef, useState} from "react";
import {FilterOutlined} from "@ant-design/icons";
import {Button, ButtonGroup, Overlay, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Switch} from "antd";
import Select from 'react-dropdown-select';
import RouteNumber from "./RouteNumber";
import RouteTitle from "./RouteTitle";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {getAvailableDirections} from "../containers/Timetables";
import {GetFullUrl, getKey} from "../libs/hooksLib";
import {onError} from "../libs/errorLib";

export const DIRECTIONS = [
    {id: '1', name: 'Inbound', service: 'regular'},
    {id: '2', name: 'Outbound', service: 'regular'},
    {id: '3', name: 'Loop', service: 'regular'},
    {id: '4', name: 'AM', service: 'school'},
    {id: '5', name: 'PM', service: 'school'},
]

export const rFilter = (s, query) => {
    return s.published && ((s.routeNumber && s.routeNumber.toLowerCase().indexOf(query) > -1) ||
        (s.routeName && s.routeName.toLowerCase().indexOf(query) > -1) ||
        (s.routeDetails && s.routeDetails.toLowerCase().indexOf(query) > -1))
}

function MapFilterToolbar({match, setFilter, filter, clearFn, routes, operatorId, ...props}) {

    const [key, setKey] = useState(null);
    const fullUrl = GetFullUrl();
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [routeFilter, setRouteFilter] = useState([]);
    const [directionFilter, setDirectionFilter] = useState([]);
    const [regularFilter, setRegularFilter] = useState(true);
    const [schoolFilter, setSchoolFilter] = useState(true);

    useEffect(() => {
        async function onLoad() {
            try {
                const _key = operatorId || await getKey();
                setKey(_key);
            } catch (e) {
                onError(e);
            }
        }

        onLoad().then(() => console.log('Key loaded.'));
    }, [operatorId, setKey]);

    useEffect(() => {
        let rf = routes && filter && filter.routeIds ? routes.filter(r => filter.routeIds.indexOf(r.routeId) > -1) : []; // filter.routeIds.map(rId => routes[routes.findIndex(r => r.routeId === rId)]) : []
        rf = rf.map(r => ({routeId: r.routeId, routeNumber: r.routeNumber, colour: r.colour}))
        setRouteFilter(rf);
        setDirectionFilter(filter && filter.directions ? filter.directions.map(dir => DIRECTIONS[DIRECTIONS.findIndex(d => d.name === dir)]) : []);
        setRegularFilter(!filter || filter.regular)
        setSchoolFilter(!filter || filter.school)
    }, [filter, routes])

    const filterOn = (filter) => {
        if (!filter) {
            return false;
        }
        return (filter.school !== filter.regular && (!filter.immutable || !filter.immutable.type)) || (filter.directions && filter.directions.length && (!filter.immutable || !filter.immutable.directions)) || (filter.routeIds && filter.routeIds.length);
    }

    const filterToQuery = ({routeFilter, directionFilter, regularFilter, schoolFilter}) => {
        let filters = [];
        const rs = routeFilter && routeFilter.length ? `rs=${routeFilter.map(r => r.routeId).join(',')}` : undefined;
        if (rs) {
            filters.push(rs);
        }
        const dirs = directionFilter && directionFilter.length ? `dirs=${directionFilter.map(dir => dir.name).join(',')}` : undefined;
        if (dirs) {
            filters.push(dirs);
        }
        const reg = regularFilter && !schoolFilter ? 'reg=true' : undefined;
        if (reg) {
            filters.push(reg);
        }
        const schl = schoolFilter && !regularFilter ? 'schl=true' : undefined;
        if (schl) {
            filters.push(schl);
        }
        return filters.join('&');
    }

    const executeFilter = useCallback(() => {
        if (clearFn) {
            clearFn();
        }
        setFilter(filter => {
            const _filter = {...filter};
            _filter.routeIds = routeFilter.map(r => r.routeId);
            _filter.directions = directionFilter.map(d => d.name);
            _filter.regular = regularFilter
            _filter.school = schoolFilter;
            return _filter
        })
        setShow(false)
    }, [routeFilter, directionFilter, regularFilter, schoolFilter, setFilter, clearFn, setShow]);

    // const getFilteredTypes = (regularFilter, schoolFilter) => {
    //     const types = [];
    //     // if (regularFilter !== schoolFilter) {
    //     if (regularFilter) {
    //         types.push("Regular");
    //     }
    //     if (schoolFilter) {
    //         types.push("School");
    //     }
    //     // }
    //     return types;
    // }

    // const overlay = useMemo(() => (
    const overlay = (target, show, setShow, setFilter, routeFilter, setRouteFilter, filter, directionFilter, setDirectionFilter, regularFilter, setRegularFilter, schoolFilter, setSchoolFilter) => (
        <Overlay target={target.current} show={show} rootClose={true} placement={props.placement || "right"}
                 onExit={executeFilter}
                 onHide={() => setShow(false)}
        >
            <div
                style={{
                    backgroundColor: "white",
                    marginLeft: '10px',
                    marginTop: '20px',
                    color: 'black',
                    borderRadius: 4,
                    border: '1px solid #ccc',
                    textAlign: "center",
                }}
            >
                {routes && routes.length ?
                    <div style={{width: '100%', padding: '10px'}}>
                        <Select
                            style={{width: 290}}
                            placeholder="All routes"
                            options={routes}
                            itemRenderer={({item, methods}) => (
                                <div style={{paddingLeft: '10px'}}
                                     onClick={() => methods.addItem(item)}>
                                    <RouteTitle route={item} size="sm"/>
                                </div>
                            )}
                            optionRenderer={({item, props, state, methods}) => (
                                <div style={{paddingRight: '10px'}}>
                                    <RouteNumber route={item} size="sm">
                                    <span
                                        onClick={(event) => methods.removeItem(event, item, props.closeOnSelect)}>&times;</span>
                                    </RouteNumber>
                                </div>
                            )}
                            labelField={'routeNumber'}
                            valueField={'routeId'}
                            multi={true}
                            values={routeFilter}
                            onChange={(value) => {
                                setRouteFilter(value);
                            }}
                            searchFn={({state}) => {
                                return routes.filter(r => rFilter(r, state.search.toLowerCase()));
                            }}
                        />
                    </div>
                    : <></>
                }
                {(!filter || !filter.immutable || !filter.immutable.type) &&
                    <div className='d-flex flex-row'>
                        <div className="p-2">
                            <Switch size='small' checked={regularFilter}
                                    onChange={(checked) => {
                                        // setFilter(filter => ({
                                        //     school: !checked ? true : filter.school,
                                        //     regular: checked,
                                        //     direction: undefined
                                        // }))
                                        setRegularFilter(checked);
                                    }}/> Regular
                        </div>

                        <div className="p-2">
                            <Switch style={{marginLeft: "15px"}} size='small'
                                    checked={schoolFilter} onChange={(checked) => {
                                // setFilter(filter => ({
                                //     regular: !checked ? true : filter.regular,
                                //     school: checked,
                                //     direction: undefined
                                // }))
                                setSchoolFilter(checked);
                            }}/> School
                        </div>
                    </div>
                }

                {/*<div style={{width: '100%', padding: '10px', textAlign: "center"}}>*/}
                {/*<ToggleButtonGroup type="checkbox" name="serviceTypes"*/}
                {/*                   value={getFilteredTypes(regularFilter, schoolFilter)}*/}
                {/*                   className="mb-2"*/}
                {/*                   size="sm"*/}
                {/*                   onChange={types => {*/}
                {/*                       setSchoolFilter(false);*/}
                {/*                       setRegularFilter(false);*/}
                {/*                       if (types.indexOf("School") > -1) {*/}
                {/*                           setSchoolFilter(true);*/}
                {/*                       }*/}
                {/*                       if (types.indexOf("Regular") > -1) {*/}
                {/*                           setRegularFilter(true);*/}
                {/*                       }*/}
                {/*                   }}>*/}

                {/*    <ToggleButton*/}
                {/*        key={`TypeSelectionBtn-Regular`}*/}
                {/*        variant="outline-secondary"*/}
                {/*        name="Regular"*/}
                {/*        value="Regular"*/}
                {/*        checked={regularFilter && !schoolFilter}*/}
                {/*    >Regular</ToggleButton>*/}

                {/*    <ToggleButton*/}
                {/*        key={`TypeSelectionBtn-School`}*/}
                {/*        variant="outline-secondary"*/}
                {/*        name="School"*/}
                {/*        value="School"*/}
                {/*        checked={schoolFilter && !regularFilter}*/}
                {/*    >School</ToggleButton>*/}

                {/*</ToggleButtonGroup>*/}
                {(!filter || !filter.immutable || !filter.immutable.directions) &&
                    <div style={{width: '100%', padding: '10px', textAlign: "center"}}>

                        <ToggleButtonGroup type="checkbox" name="directions" value={directionFilter}
                                           className="mb-2"
                                           size="sm"
                                           onChange={dirs => {
                                               setDirectionFilter(dirs)
                                           }}>
                            {getAvailableDirections(DIRECTIONS, {
                                school: schoolFilter,
                                regular: regularFilter
                            }).map((d, idx) => (

                                <ToggleButton
                                    key={`DirSelectionBtn-${idx}`}
                                    variant="outline-secondary"
                                    name={d.name}
                                    value={d}
                                    checked={directionFilter && directionFilter.indexOf(d.name) > -1}
                                >{d.name}</ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                }
                {/*<div style={{width: '100%', padding: '10px', textAlign: "center"}}>*/}
                {/*<Select*/}
                {/*        style={{width: 290}}*/}
                {/*        placeholder="All directions"*/}
                {/*        onChange={(value) => {*/}
                {/*            // setFilter(filter => ({...filter, directions: filter.directions.concat(value)}))*/}
                {/*            setDirectionFilter(value);*/}
                {/*        }}*/}
                {/*        options={DIRECTIONS.filter(d => (schoolFilter && d.service === 'school') || (regularFilter && d.service === 'regular'))}*/}
                {/*        // defaultValue={'all'}*/}
                {/*        values={directionFilter}*/}
                {/*        labelField='name'*/}
                {/*        valueField='name'*/}
                {/*        multi={true}*/}
                {/*    >*/}
                {/*        /!*<Option key={`RouteDirection-all`}*!/*/}
                {/*        /!*        value={'all'}>All directions</Option>*!/*/}
                {/*        /!*{filter.regular && DIRECTIONS.regular.map(direction => (*!/*/}
                {/*        /!*    <Option key={`RouteDirection-${direction}`}*!/*/}
                {/*        /!*            value={direction}>{direction}</Option>*!/*/}
                {/*        /!*))}*!/*/}

                {/*        /!*{filter.school && DIRECTIONS.school.map(direction => (*!/*/}
                {/*        /!*    <Option key={`RouteDirection-${direction}`}*!/*/}
                {/*        /!*            value={direction}>{direction}</Option>*!/*/}
                {/*        /!*))}*!/*/}
                {/*    </Select>*/}
                {/*</div>*/}
                <div className="row" style={{padding: "10px"}}>
                    <div className="col-lg-12" style={{textAlign: "right"}}>
                        {props.setShow &&
                            <CopyToClipboard
                                text={`${fullUrl}/timetables/${key}?${filterToQuery({
                                    routeFilter,
                                    directionFilter,
                                    regularFilter,
                                    schoolFilter
                                })}`}
                                onCopy={() => props.setShow('Filter copied to clipboard.')}>
                                <span style={{paddingRight: "20px"}}><Button variant={"primary"}
                                                                             size={"sm"}>Share</Button></span>
                            </CopyToClipboard>}
                        {/* </div>
                    <div className="col-md-6" style={{textAlign: "right"}}> */}
                        <ButtonGroup>
                            <Button variant={"warning"} size={"sm"}
                                    disabled={!filterOn(filter)}
                                    onClick={() => {
                                        setSchoolFilter(true);
                                        setRegularFilter(true);
                                        setDirectionFilter([]);
                                        setRouteFilter([]);
                                        executeFilter()
                                    }}>Clear</Button>
                            <Button variant={"success"} size={"sm"} onClick={executeFilter}>Filter</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </Overlay>
    );
// ), [target, show, setShow, setFilter, routeFilter, filter]);

    return (
        <>
            {/*<div style={{position: "absolute", top: 110, left: 10}}>*/}
            {/*    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">*/}
            <Button className="mapbox-ctrl-icon" variant={"light"}
                    style={{paddingBottom: "32px", backgroundColor: filterOn(filter) ? 'lightseagreen' : undefined}}
                    ref={target}
                    onClick={() => setShow(!show)}>
                <FilterOutlined/>
            </Button>
            {overlay(target, show, setShow, setFilter, routeFilter, setRouteFilter, filter, directionFilter, setDirectionFilter, regularFilter, setRegularFilter, schoolFilter, setSchoolFilter)}
            {/*</div>*/}
            {/*</div>*/}
        </>
    )
}

export default React.memo(MapFilterToolbar);

