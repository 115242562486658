import { useState, useRef, useCallback, useMemo } from 'react';
import {isFunction, isNumber} from "lodash/lang";

const getSplitIndex = (step, arr) => {
    let index = step > 0
        ? step - 1 // move forward
        : arr.length + step; // move backward
    if (index >= arr.length - 1) {
        index = arr.length - 1;
    }
    if (index < 0) {
        index = 0;
    }
    return index;
};
/**
 * Splits the target array at a history point into before, current and after sections.
 * Useful to navigate history.
 */
const split = (step, targetArr) => {
    const index = getSplitIndex(step, targetArr);
    return {
        _current: targetArr[index],
        _before: targetArr.slice(0, index),
        _after: targetArr.slice(index + 1),
    };
};
/**
 * useTimeTravelState
 * @description A hook that manages state which can undo and redo. A more powerful version of useUndoState hook.
 * @see {@link https://rooks.vercel.app/docs/useTimeTravelState}
 * @param initialValue The initial value of the state.
 * @returns {UseTimeTravelStateReturnValue}
 * @example
 * const [value, setValue, controls] = useTimeTravelState(0);
 * setValue(1);
 * setValue(2);
 * setValue(3);
 * controls.back(); // value === 2
 * controls.back(); // value === 1
 * controls.forward(); // value === 2
 * controls.forward(); // value === 3
 * controls.reset(); // value === 0
 * controls.reset(5); // value === 5
 * controls.back(2); // value === 3
 *
 * setValue(1);
 * setValue(2);
 * setValue(6, { overwriteLastEntry: true });
 * setValue(7, { overwriteLastEntry: true });
 *
 * controls.back(2); // value === 1
 *
 */
function useTimeTravelState(initialValue, {maxSize = 10}) {
    const [history, setHistory] = useState({
        present: initialValue,
        past: [],
        future: [],
    });
    const { present, past, future } = history;
    const initialValueRef = useRef(initialValue);
    /**
     * @description Updates the state with a new value.
     */
    const reset = useCallback((resetInitialValue) => {
        const newInitialValue = resetInitialValue !== null && resetInitialValue !== void 0 ? resetInitialValue : initialValueRef.current;
        initialValueRef.current = newInitialValue;
        setHistory({
            present: newInitialValue,
            future: [],
            past: [],
        });
    }, [initialValueRef, setHistory]);
    const updateValue = useCallback((val, options) => {
        setHistory((currentHistory) => {
            const { past, present } = currentHistory;
            const { overwriteLastEntry = false } = options || {};
            let newValue = undefined;
            if (isFunction(val)) {
                newValue = val(present);
            }
            else {
                newValue = val;
            }
            if (overwriteLastEntry) {
                return {
                    present: newValue,
                    past,
                    future: [],
                };
            }
            else {
                let newPast = [...past, present]
                return {
                    present: newValue,
                    past: newPast.length > maxSize ? newPast.slice(-maxSize) : newPast,
                    future: [],
                    // past: newPast,
                };
            }
        });
    }, [maxSize]);
    const goForwardInternal = useCallback((step = 1) => {
        setHistory((currentHistory) => {
            const { future, past, present } = currentHistory;
            if (future.length === 0) {
                return currentHistory;
            }
            const { _before, _current, _after } = split(step, future);
            return {
                past: [...past, present, ..._before],
                present: _current,
                future: _after,
            };
        });
    }, []);
    const goBackwardInternal = useCallback((step = -1) => {
        setHistory((currentHistory) => {
            const { future, past, present } = currentHistory;
            if (past.length === 0) {
                return currentHistory;
            }
            const { _before, _current, _after } = split(step, past);
            return {
                past: _before,
                present: _current,
                future: [..._after, present, ...future],
            };
        });
    }, []);
    const go = useCallback((step) => {
        const stepNum = isNumber(step) ? step : Number(step);
        if (stepNum === 0) {
            return;
        }
        if (stepNum > 0) {
            return goForwardInternal(stepNum);
        }
        goBackwardInternal(stepNum);
    }, [goBackwardInternal, goForwardInternal]);
    const back = useCallback(() => {
        go(-1);
    }, [go]);
    const forward = useCallback(() => {
        go(1);
    }, [go]);
    const canUndo = useMemo(() => {
        return past.length > 0;
    }, [past.length]);
    const canRedo = useMemo(() => {
        return future.length > 0;
    }, [future.length]);
    const controls = useMemo(() => {
        return {
            backLength: past.length,
            forwardLength: future.length,
            go,
            back,
            forward,
            reset,
            undo: back,
            redo: forward,
            canUndo,
            canRedo,
        };
    }, [back, canRedo, canUndo, forward, future.length, go, past.length, reset]);
    const returnValue = useMemo(() => {
        return [present, updateValue, controls];
    }, [controls, present, updateValue]);
    return returnValue;
}

export { useTimeTravelState };
