import React from "react";
import "./Itinerary.css"
import RouteNumber from "../../components/RouteNumber";
import {useAppContext} from "../../libs/contextLib";
import {find} from "lodash";

function ItineraryTransferRow({itineraryStopRow, transfers, pickup}) {

    const {operator} = useAppContext()

    return (<>
        <div className="row align-items-center align-items-stretch justify-content-center sb-row-transfers w-100">
            {/* <div className="col-3 sb-time"><span>{itineraryStopRow.toTimeString()}</span></div> */}
            <div className="col-12 d-flex RouteTitle flex-wrap align-items-center sb-info">
                <div className="sb-transfer-row-info align-items-center">
                    <div>{pickup ? 'Pickup' : 'Transfer'}</div>
                    {transfers.map(tx => <RouteNumber key={'TxRid' + tx.routeId} route={tx}
                                                      alias={!!operator.opts?.shiftbat?.transfersShowHeadsign ?
                                                          find(tx.services, {tripId: tx.txTripId})?.transferAlias : null}/>)}
                </div>
            </div>
        </div>
    </>);
}

export default React.memo(ItineraryTransferRow)
