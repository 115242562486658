import React from 'react';
import "../EmployeeHR/EmployeeHR.css";
import {Col, Row, Card, Menu, Avatar, Badge, Tag, Button, Input, DatePicker, Select, Space} from 'antd';
import PlotColumn from "../EmployeeHR/PlotColumn";
import PlotBullet from "../EmployeeHR/PlotBullet";
import LoaderButton from "../../components/LoaderButton";
import {ReactComponent as DashboardIcon} from '../../assets/icons/Dashboard.svg';
import {ReactComponent as UserS} from '../../assets/icons/User_s.svg';
import {ReactComponent as Business} from '../../assets/icons/Business.svg';
import {ReactComponent as Entitlements} from '../../assets/icons/Entitlements.svg';
import {ReactComponent as Banking} from '../../assets/icons/Banking.svg';
import {ReactComponent as Income} from '../../assets/icons/Income.svg';
import {ReactComponent as Tax} from '../../assets/icons/Tax.svg';
import {ReactComponent as Qualifications} from '../../assets/icons/Qualifications.svg';
import {ReactComponent as Calendar} from '../../assets/icons/Calendar.svg';
import {ReactComponent as UserW} from '../../assets/icons/User_w.svg';
import {ReactComponent as LgClock} from '../../assets/icons/Lg_clock.svg';
import {ReactComponent as LgDistance} from '../../assets/icons/Lg_distance.svg';
import {ReactComponent as LgAway} from '../../assets/icons/Lg_away.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';

function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
const items = [
    getItem('Dashboard', '1', <DashboardIcon />),
    getItem('Personal Details', '2', <UserS />),
    getItem('Employment Details', '3', <Business />),
    getItem('Entitlements', '4', <Entitlements />),
    getItem('Banking Details', '5', <Banking />),
    getItem('Superannuation Details', '6', <Income />),
    getItem('Tax Details', '7', <Tax />),
    getItem('Qualifications and Training', '8', <Qualifications />),
    getItem('Calendar', '9', <Calendar />),
];

function Elements() {
    return (
        <div className="employee-dashboard">
            <div className="d-flex align-items-center justify-content-between page-main-controls top-float">
                <Button type="primary" className="icon-button btn-filled" icon={<Edit />}>Edit</Button>
            </div>
            <Row gutter={[20, 20]}>
                <Col xs={24} lg={5}>
                    <Card bordered={false} className="card-main body-pad-0" style={{ height: 'auto' }}>
                        <div className="profile-summary">
                            <Badge dot className="success">
                                <Avatar size={100} icon={<UserW />} />
                                {/* <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" size={100} /> */}
                            </Badge>
                            <h2>John Smith</h2>
                            <div className="text-info text-uppercase">Bus Driver</div>
                            <div className="text-primary">#1234</div>
                        </div>
                        <Menu className="profile-menu"
                            defaultSelectedKeys={['1']}
                            mode="inline"
                            theme="light"
                            items={items}
                        />
                    </Card>
                </Col>
                <Col xs={24} lg={19}>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} lg={8}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center"><LgClock /></Col>
                                    <Col>
                                        <div><h4>Total Hours</h4></div>
                                        <div className="text-lg">88</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center"><LgDistance /></Col>
                                    <Col>
                                        <div><h4>Total Distance</h4></div>
                                        <div className="text-lg">456</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center"><LgAway /></Col>
                                    <Col>
                                        <div><h4>Time Off</h4></div>
                                        <div className="text-lg">06</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Card title="Total Hours" bordered={false} className="card-main">
                                <PlotColumn />
                            </Card>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Card title="Time Off" bordered={false} className="card-main">
                                <PlotBullet />
                            </Card>
                        </Col>
                        <Col xs={24}>
                            <Card bordered={false} className="card-main">
                                <Row className="card-row-header align-items-center">
                                    <Col xs={12} className='d-flex align-items-center'>
                                        <h3>Leave Requests</h3>
                                        <Button type="primary" className="icon-button btn-sm">View all</Button>
                                    </Col>
                                    <Col xs={3}><span>From</span></Col>
                                    <Col xs={3}><span>To</span></Col>
                                    <Col xs={3}><span>Status</span></Col>
                                    <Col xs={3}><span>Actions</span></Col>
                                </Row>
                                <Row className="card-row-content border-full align-items-center">
                                    <Col xs={12}>
                                        <strong>Casual Leave</strong>
                                        <span>Reason for leave</span>
                                    </Col>
                                    <Col xs={3}>04/10/2023</Col>
                                    <Col xs={3}>05/10/2023</Col>
                                    <Col xs={3} className="simple-tags"><Tag color="success">Approved</Tag></Col>
                                    <Col xs={3} className="row-col-actions">
                                        <LoaderButton className="btn-icon-control">
                                            <Edit style={{width: 14}} />
                                        </LoaderButton>
                                        <LoaderButton className="btn-icon-control btn-delete">
                                            <Trash/>
                                        </LoaderButton>
                                    </Col>
                                </Row>
                                <Row className="card-row-content border-full align-items-center">
                                    <Col xs={12}>
                                        <strong>Casual Leave</strong>
                                        <span>Reason for leave</span>
                                    </Col>
                                    <Col xs={3}>04/10/2023</Col>
                                    <Col xs={3}>05/10/2023</Col>
                                    <Col xs={3} className="simple-tags"><Tag color="success">Approved</Tag></Col>
                                    <Col xs={3} className="row-col-actions">
                                        <LoaderButton className="btn-icon-control">
                                            <Edit style={{width: 14}} />
                                        </LoaderButton>
                                        <LoaderButton className="btn-icon-control btn-delete">
                                            <Trash/>
                                        </LoaderButton>
                                    </Col>
                                </Row>
                                <Row className="card-row-content border-full align-items-center">
                                    <Col xs={12}>
                                        <strong>Casual Leave</strong>
                                        <span>Reason for leave</span>
                                    </Col>
                                    <Col xs={3}>04/10/2023</Col>
                                    <Col xs={3}>05/10/2023</Col>
                                    <Col xs={3} className="simple-tags"><Tag color="warning">Pending</Tag></Col>
                                    <Col xs={3} className="row-col-actions">
                                        <LoaderButton className="btn-icon-control">
                                            <Edit style={{width: 14}} />
                                        </LoaderButton>
                                        <LoaderButton className="btn-icon-control btn-delete">
                                            <Trash/>
                                        </LoaderButton>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24}>
                            <Card title="General Information" bordered={false} className="card-main card-info">
                                <Row gutter={[30, 30]}>
                                    <Col xs={24} lg={6}>
                                        <label>First Name</label>
                                        <strong>John</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Last Name</label>
                                        <strong>Smith</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Date of Birth</label>
                                        <strong>11/05/1981</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Age</label>
                                        <strong>42</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Gender</label>
                                        <strong>Male</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Blood Group</label>
                                        <strong>A+</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Contact No.</label>
                                        <strong>+61 2 5550 4321</strong>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Email</label>
                                        <strong>johnsmith@gmail.com</strong>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24}>
                            <Card title="General Information (Edit Mode)" bordered={false} className="card-main card-info">
                                <Row gutter={[30, 30]}>
                                    <Col xs={24} lg={6}>
                                        <label>First Name</label>
                                        <Input placeholder="Enter First Name" />
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Last Name</label>
                                        <Input placeholder="Enter Last Name" />
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Date of Birth</label>
                                        <DatePicker placeholder="Select Date of Birth" />
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Age</label>
                                        <Input placeholder="Enter Age" />
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Gender</label>
                                        <Select
                                            defaultValue="Select Gender"
                                            options={[
                                                { value: 'male', label: 'Male' },
                                                { value: 'female', label: 'Female' },
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Blood Group</label>
                                        <Input placeholder="Enter Blood Group" status="error" />
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Contact No.</label>
                                        <Space.Compact>
                                        <Input style={{ width: '30%' }} defaultValue="+61 2" />
                                        <Input style={{ width: '70%' }} defaultValue="9876 5432" />
                                        </Space.Compact>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label>Email</label>
                                        <Input placeholder="Enter Email Address" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24}>
                            <Card bordered={false} className="card-main card-col">
                                <Row className="card-row-header align-items-center">
                                    <Col xs={12} className='d-flex align-items-center'><h3>Entitlements</h3></Col>
                                    <Col xs={12} className='d-flex justify-content-end'><Button type="primary" className="icon-button btn-sm">View Rates Table</Button></Col>
                                </Row>
                                <Row gutter={[80, 50]}>
                                    <Col xs={12}>
                                        <label>Permanent</label>
                                        <Row className="card-row-content align-items-center">
                                            <Col xs={16}><strong>38 hour week</strong></Col>
                                            <Col xs={8} className='d-flex justify-content-end'>$893.60</Col>
                                        </Row>
                                        <Row className="card-row-content align-items-center">
                                            <Col xs={16}><strong>38 hour week</strong></Col>
                                            <Col xs={8} className='d-flex justify-content-end'>$893.60</Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <label>Casuals</label>
                                        <Row className="card-row-content align-items-center">
                                            <Col xs={16}><strong>38 hour week</strong></Col>
                                            <Col xs={8} className='d-flex justify-content-end'>$893.60</Col>
                                        </Row>
                                        <Row className="card-row-content align-items-center">
                                            <Col xs={16}><strong>38 hour week</strong></Col>
                                            <Col xs={8} className='d-flex justify-content-end'>$893.60</Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <label>Allowances</label>
                                        <Row className="card-row-content align-items-center">
                                            <Col xs={16}><strong>38 hour week</strong></Col>
                                            <Col xs={8} className='d-flex justify-content-end'>$893.60</Col>
                                        </Row>
                                        <Row className="card-row-content align-items-center">
                                            <Col xs={16}><strong>38 hour week</strong></Col>
                                            <Col xs={8} className='d-flex justify-content-end'>$893.60</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default React.memo(Elements)