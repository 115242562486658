import React, { useCallback, useEffect, useState } from "react";
import { Select, Tag, Button } from "antd";
import ItineraryRowsPrint from "./ItineraryRowsPrint";
import { Col, Image, Row } from "react-bootstrap";
import config from "../../config";
import { ShiftBatRowType } from "../../model/shiftBat";
import RouteTitle from "../../components/RouteTitle";
import { to12HrTime, toHrsMinsSecs, toKmMs } from "../../libs/formatLib";
import { last } from "lodash/array";
import dayjs from "../../dayjs";
import { QRCode } from "react-qrcode-logo";
import { API } from "aws-amplify";
import html2pdf from "html2pdf.js";
import LoadMessage from "../../components/LoadMessage";
import ItineraryRows from "./ItineraryRows";
import { ReactComponent as Print } from "../../assets/icons/Print.svg";
import { ReactComponent as Reset } from "../../assets/icons/Reset.svg";
import { getOperator } from "../../services/routeService";
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../libs/contextLib";

function ItineraryPrintView({ isPublic = false, allStops, allRoutes, operatorId, itinerary, schedules }) {
    const {operator} = useAppContext();
    const [encryptedUrl, setEncryptedUrl] = useState(null);
    const [viewing, setViewing] = useState({});
    const [printLayout, setPrintLayout] = useState("lone"); // lone, ltwo
    const [printMode, setPrintMode] = useState("lg"); // lg, md, sm
    const [syncing, setSyncing] = useState(false);

    useEffect(() => {
        if (itinerary) {
            const viewing = {};
            itinerary.rows.filter((row) => row.type === ShiftBatRowType.service).forEach((serviceRow) => (viewing[serviceRow.id] = true));
            setViewing(viewing);
            API.endpoint("routes").then((path) => {
                setEncryptedUrl(path + `/checkItinerary/${operatorId}/${itinerary.itineraryId}?_q=${Date.now()}`);
            });
        }
    }, [itinerary, setViewing, setEncryptedUrl, operatorId, isPublic]);


    // Generates PDF file and syncs with staff portal
    const sync = useCallback(async () => {
        setSyncing(true);
        const element = document.getElementById("shiftbat-view");

        const generatePDF = async () => {
            const opt = {
                margin: 1,
                filename: "modal-content.pdf",
                image: { type: "jpeg", quality: 1 },
                // html2canvas: { scale: 2, useCors: true },
                html2canvas: {
                    scale: 4, // Increase the scale factor for better quality
                    useCORS: true, // Ensure CORS is used
                    logging: true, // Enable logging to debug issues
                },
                jsPDF: { unit: "px", format: "a4", orientation: "portrait", hotfixes: ["px_scaling"] },
            };

            return new Promise((resolve, reject) => {
                html2pdf().from(element).set(opt).outputPdf("blob").then(resolve).catch(reject);
            });
        };

        if (element && itinerary) {
            try {
                const pdfBlob = await generatePDF(element);

                if(!operator) {
                    throw new Error(`Operator not found: ${operatorId}`);
                }

                // Uncomment to verify the file contents
                // const pdfUrl = URL.createObjectURL(pdfBlob);
                // window.open(pdfUrl, "_blank");
                // return

                const form = new FormData();
                form.append("files", pdfBlob, `${itinerary.itineraryId}.pdf`);

                const response = await fetch(`${config.cms.URL}/api/upload`, {
                    method: "POST",
                    body: form,
                    headers: {
                        Authorization: `Bearer ${config.cms.APIKEY}`,
                    }
                })
                const uploadedFile = await response.json();
                const linkResponse = await fetch(`${config.cms.URL}/busable-inject-functions/busable/shiftbat/sync`, {
                    method: "POST",
                    body: JSON.stringify({
                        companyId: operator.companyId,
                        shiftbatId: itinerary.itineraryId,
                        shiftbatName: itinerary.itineraryName,
                        file: uploadedFile[0],
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                if (!linkResponse.ok) {
                    throw new Error(`Error uploading file: ${linkResponse.statusText}`);
                }

                const data = await linkResponse.json();
                console.log("File uploaded successfully", data);
                setSyncing(false);
            } catch (error) {
                console.error("Error uploading file", error);
                setSyncing(false);
            }
        }
    }, [operator, operatorId, itinerary]);

    return (
        <>
            <div class="ant-modal-header d-flex justify-content-between hide-print" style={{ paddingRight: "28px", marginBottom: "25px" }}>
                <div class="ant-modal-title">Shift Bat Print</div>
                <div className="d-flex" style={{ gap: "20px" }}>
                    <Select
                        onSelect={setPrintLayout}
                        value={printLayout}
                        options={[
                            { value: "lone", label: "Layout 1" },
                            { value: "ltwo", label: "Layout 2" },
                        ]}
                    />
                    <Select
                        onSelect={setPrintMode}
                        value={printMode}
                        options={[
                            { value: "lg", label: "Comfortable" },
                            { value: "md", label: "Standard" },
                            { value: "sm", label: "Compact" },
                        ]}
                    />
                    <Button key="submit" type="primary" className="btn-primary icon-btn" icon={<Print />} onClick={() => window.print()}>
                        Print
                    </Button>
                    {(operator.features.beta || operator.features.all) 
                    && <LoaderButton
                        key="submit"
                        type="primary"
                        className="btn-primary icon-btn"
                        icon={<Reset />}
                        isLoading={syncing}
                        onClick={sync}
                    ></LoaderButton>}
                </div>
            </div>
            <div id="shiftbat-view" className={`Itinerary PrintView PrintView-${printMode} PrintView-${printLayout}`}>
                <Row className="header-row align-items-center">
                    <Col className="d-flex customer-info-text">
                        <RouteTitle
                            route={{
                                routeNumber: itinerary.itineraryNumber,
                                routeName: itinerary.itineraryName,
                                routeDetails: itinerary.itineraryDetails,
                                routeLogo: itinerary.itineraryLogo,
                                colour: itinerary.itineraryColour,
                            }}
                        />
                        <div className="RouteInfo">
                            <h1>{itinerary.itineraryName}</h1>
                            <div className="RouteDetails">{itinerary.itineraryDetails}</div>
                            <div className="d-flex flex-row simple-tags">
                                {itinerary.scheduleIds.map((sId) => (
                                    <Tag key={sId}>{schedules[sId]?.scheduleName}</Tag>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xs="auto" className="customer-info-logo">
                        <Image
                            style={{ maxHeight: "120px", minWidth: "120px", maxWidth: "160px" }}
                            src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${operatorId}/logo.png`}
                            onError={(e) => {}}
                        />
                        <div className="QRCode">
                            {" "}
                            {encryptedUrl ? (
                                <QRCode value={`${encryptedUrl}`} ecLevel="L" size={120} fgColor={"#000000"} qrStyle={"dots"} />
                            ) : (
                                <LoadMessage />
                            )}
                        </div>
                    </Col>
                </Row>
                <div className="sb-header-info">
                    <div>
                        Start Shift: <strong>{to12HrTime(itinerary?.getStartTime() || 0)}</strong>
                    </div>
                    {itinerary.getFirstStopRow()?.stop?.stopType === "depot" && (
                        <div>
                            Depart Depot: <strong>{to12HrTime(itinerary.getFirstStopRow().time || 0)}</strong>
                        </div>
                    )}
                    {itinerary.getLastStopRow()?.stop?.stopType === "depot" && (
                        <div>
                            Arrive Depot: <strong>{to12HrTime(itinerary.getLastStopRow().time || 0)}</strong>
                        </div>
                    )}
                    <div>
                        Finish Shift: <strong>{to12HrTime(itinerary?.getEndTime() || 0)}</strong>
                    </div>
                    <div>
                        Shift hours: <strong>{toHrsMinsSecs(itinerary.getShiftTime(), false, true)}</strong>
                    </div>
                    <div>
                        {/* Shift distance: <strong>{toKmMs(itinerary.getShiftDistance(), 1)}</strong> */}
                    </div>
                    <div>
                        Current From:{" "}
                        <strong>{itinerary?.effectiveDate ? itinerary?.effectiveDate.format("DD/MM/YYYY") : "--/--/----"}</strong>
                    </div>
                    <div>
                        Printed: <strong>{dayjs().format("DD/MM/YYYY")}</strong>
                    </div>
                </div>
                <Row className="mx-0">
                    <Col xs={12} className="px-0">
                        <ItineraryRows
                            disableEditForRow={() => false}
                            itinerary={itinerary}
                            editMode={false}
                            allStops={allStops}
                            allRoutes={allRoutes}
                            printLayout={printLayout}
                            printMode={printMode}
                            viewing={viewing}
                            setViewing={setViewing}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ItineraryPrintView);
