import React, {useCallback, useEffect, useState} from "react";
import "./Export.css";
import {Button, Checkbox, Flex, Modal, Radio, Tag, Typography} from "antd";
import {SyncOutlined} from '@ant-design/icons';
import {getRoutes} from "../services/routeService";
import {onError} from "../libs/errorLib";
import fileDownload from 'js-file-download';
import csvStringify from 'csv-stringify';
import dayjs from "../dayjs";

import {buildGtfsZip, buildHRMtoZip, getRoutesAsFlatArray, publishTfnsw,} from "../libs/exportLib";
import {useParams} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import {
    deadrunModelData,
    driverShiftModelData,
    employeeModelData,
    publishTfNSWModelData,
    routeModelData,
    scheduleModelData,
    shiftBatModelData,
    stopModelData,
    vehicleShiftModelData
} from "../services/ModelService";
import {Collapse} from "antd/lib";
import {last} from "lodash/array";
import {values} from "lodash/object"
import {Download} from "react-bootstrap-icons";
import config from "../config";
import {FEATURE} from "../model/features";
import {checkFeatureAuth} from "../App";
import {find, keyBy} from "lodash/collection";
import {BusRoute} from "../model/busRoute";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const {Text, Paragraph} = Typography;

const fileTypeOptions = [
    // {label: 'CSV', value: 'CSV', disabled: false, button: 'Download'},
    {label: 'GTFS', value: 'GTFS', disabled: false, button: 'Download'},
    {label: 'TODIS', value: 'TODIS', disabled: false, button: 'Export to TfNSW'},
    {label: 'HRM', value: 'HRM', disabled: false, button: 'Download'},
    // {label: 'TransXChange', value: 'TransXChange', disabled: true, button: 'Download'},
]

// const actionOptions = [
//     {label: 'Download', value: 'Download', disabled: false, types: ['CSV', 'GTFS']},
//     {label: 'Publish', value: 'Publish', disabled: false, types: ['TfNSW']},
// ]

const MAX_TODIS_HISTORY_ITEMS = 5;

export default function ExportModal({schedules, operator, setFaultState, isAdmin = false, messageApi}) {
    const {key} = useParams();
    // const {schedules} = useAppContext();
    const [visible, setVisible] = useState(false);
    const [fileType, setFileType] = useState('GTFS');
    const [action, setAction] = useState('Download');
    const [isLoading, setIsLoading] = useState(false);
    // const [currentPublishStatus, setCurrentPublishStatus] = useState(null);
    const [isValidating, setIsValidating] = useState(false);
    const [publishEvents, setPublishEvents] = useState(null);
    const [publishHistoryItems, setPublishHistoryItems] = useState(null);
    const [tfnsw, setTfnsw] = useState(false)
    const [excludeInvalidGtfs, setExcludeInvalidGtfs] = useState(false)
    const [excludeInvalidTodis, setExcludeInvalidTodis] = useState(false)

    // const getActions = useCallback(() => {
    //     const opts = [...actionOptions]
    //     opts.forEach(opt => {
    //         opt.disabled = !opt.types.includes(fileType)
    //     })
    //     return opts
    // }, [fileType])

    const loadPublishEvents = useCallback(async () => {
        let events = await publishTfNSWModelData.fetchAll({asArray: false, companyId: operator.companyId});
        setPublishEvents(events);
    }, [operator.companyId, setPublishEvents]);

    useEffect(() => {
        // const load = async () => {
        //     let events = await publishTfNSWModelData.fetchAll({asArray: false, companyId: operator.companyId});
        //     setPublishEvents(events);
        // }
        if (action === 'Export to TfNSW' && fileType === 'TODIS') {
            loadPublishEvents().then(() => console.log('TODIS publish logs loaded'))
            const timer = setInterval(() => {
                loadPublishEvents().then(() => console.log('TODIS publish logs loaded'))
            }, 5000);
            return () => clearInterval(timer);
        }
    }, [loadPublishEvents, action, fileType, operator])

    useEffect(() => {
        if (!publishEvents) {
            return
        }
        let publishHistoryByKey = {};
        Object.keys(publishEvents).forEach(key => {
            const keyItems = key.split("#")
            if (last(keyItems) === 'current') {
                return
            }
            const eventKey = keyItems.slice(0, -1).join("#");
            publishHistoryByKey[eventKey] = publishHistoryByKey[eventKey] || []
            publishHistoryByKey[eventKey].push(publishEvents[key])
        })

        const sortedKeys = Object.keys(publishHistoryByKey).sort((a, b) => publishHistoryByKey[b][0].createdAt - publishHistoryByKey[a][0].createdAt).slice(0, MAX_TODIS_HISTORY_ITEMS);

        // Create a new object and insert the keys in the sorted order
        const sortedObj = {};
        for (const key of sortedKeys) {
            sortedObj[key] = publishHistoryByKey[key];
        }
        setPublishHistoryItems(Object.keys(sortedObj).map(key => {
            const publishedEvents = sortedObj[key]
            const lastPublishedEvent = last(publishedEvents)
            //const style = publishedEvents.some(e => e.status === 'Failed') ? {color: '#CB2727FF'} : publishedEvents.some(e => e.status === 'Published') ? {color: '#04A123FF'} : {}
            return {
                key,
                label:
                    <div style={{fontWeight: 500}}>{dayjs(lastPublishedEvent.createdAt).format("lll")}: <Tag
                        color={lastPublishedEvent.status === 'Failed' ? 'error' : 'success'}>{['Failed', 'Validating'].includes(lastPublishedEvent.status) ? lastPublishedEvent.status : 'Submitted'}</Tag>
                    </div>,
                children: <div key={'ffe' + key}>{publishedEvents.filter(e => e === lastPublishedEvent).map(event =>
                    <div key={'slju' + event.createdAt} className="w-100">
                        <div className="row">
                            <div className="col-auto" style={{color: '#777'}}>{dayjs(event.createdAt).format("lll")}:
                            </div>
                            <div className="col-auto d-flex flex-column"
                                 style={{fontWeight: 400}}><Paragraph key={'sd  _'}
                                                                      type={"danger"}>{['Failed', 'Validated'].includes(lastPublishedEvent.status) ? lastPublishedEvent.status : 'Submitted'}</Paragraph>
                                {event.error ?
                                    <div>
                                        <div>
                                            <span>{event.error.split('\n').filter(s => s?.length).map((sentence, i) => {
                                                if (i === 0) {
                                                    return <Paragraph key={'s_' + i}
                                                                      type={"danger"}>{sentence}</Paragraph>
                                                }
                                                return <Paragraph type={"danger"} key={'s_' + i} copyable={true}
                                                                  ellipsis={{
                                                                      rows: 1,
                                                                      tooltip: sentence
                                                                  }}>{sentence}</Paragraph>
                                            })}</span>
                                        </div>
                                        <div>
                                            <CopyToClipboard
                                                text={event.error}>
                                                <span className=''><Button
                                                    onClick={() => messageApi.info('Error copied to clipboard', 10)}>Copy error</Button></span>
                                            </CopyToClipboard>
                                        </div>
                                    </div> : (event.key || event.url) ?
                                        <Button size="small" className="btn-secondary mt-1 mb-2"
                                                onClick={async () => {
                                                    event.key && fileDownload(`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com${event.key}`, `Busable_GTFS_${dayjs().format("YYYY-MM-DD_HH:mm")}.zip`, 'application/zip');
                                                    event.url && fileDownload(event.url, `Busable_TODIS_${dayjs().format("YYYY-MM-DD_HH:mm")}.zip`, 'application/zip');
                                                }}><Download className="mr-2"/> {event.key ? 'GTFS' : 'TODIS'}
                                        </Button> :
                                        ''}
                            </div>
                        </div>
                    </div>)}
                </div>
            }
        }))
    }, [publishEvents, setPublishHistoryItems, action, fileType, operator])

    function validateForm() {
        return action && fileType;
    }

    async function toCsv(rows) {
        return new Promise((resolve, reject) => {
            csvStringify(rows, {
                header: true,
                delimiter: ','
            }, (err, output) => {
                if (err) {
                    console.log(`Error CSVing rows.`, err)
                    reject(err)
                }
                resolve(output)
            })
        })
    }

    async function handleSubmit(event, validateOnly) {
        event.preventDefault();
        if (validateOnly) {
            setIsValidating(true);
        } else {
            setIsLoading(true);
        }

        try {
            // const operator = await getOperator(key);

            if (fileType === 'CSV') {

                const routes = await getRoutes(false, true, true, true, false, false, key);
                routes.forEach(route => {
                    if (schedules && route.scheduleId) {
                        route.schedule = schedules[route.scheduleId]
                    }
                })

                const rows = getRoutesAsFlatArray(routes)
                let output = await toCsv(rows)
                fileDownload(output, `Busable_CSV_${dayjs().format("YYYY-MM-DD_HH:mm")}.csv`);
                setIsLoading(false);
                setVisible(false)

            } else if (fileType === 'GTFS') {
                const userId = operator.operatorKey;
                const schedules = values(await scheduleModelData.getAll());
                console.log('Found %d schedules', schedules.length)
                const stops = values(await stopModelData.getAll(userId)).filter(s => (!s.stopType || s.stopType === 'bus') && s.verified === 1);

                console.log('Found %d stops', stops.length)
                const stopsById = keyBy(stops, 'stopId');
                const routes = values(await routeModelData.getAll()).filter(r => r.published === 1).map(r => new BusRoute(r));
                console.log('Found %d routes', routes.length)

                const shiftBats = await shiftBatModelData.getAll({asArray: true});
                console.log('Building zip.')
                const zip = await buildGtfsZip(operator, routes, schedules, stopsById,
                    await driverShiftModelData.getAll(), await vehicleShiftModelData.getAll(),
                    shiftBats, await deadrunModelData.getAll(), {exts: {tfnsw: tfnsw}, excludeInvalid: excludeInvalidGtfs});
                console.log(zip);

                fileDownload(zip.toBuffer(), `Busable_GTFS-S_${dayjs().format("YYYY-MM-DD_HH:mm")}.zip`, 'application/zip');
                setIsLoading(false);
                setVisible(false)
            } else if (fileType === 'TODIS') {
                if (!await checkFeatureAuth(setFaultState, operator, FEATURE.tfnsw)) {
                    setFaultState({type: 'subscription', feature: FEATURE.tfnsw})
                    setIsLoading(false);
                    return
                }
                let opts = {force: true, excludeInvalid: excludeInvalidTodis}
                if (validateOnly) {
                    opts = {force: false, validateOnly: true, excludeInvalid: excludeInvalidTodis}
                }
                const events = await publishTfnsw({companyId: operator.companyId, opts})
                // setPublishEvents(existingEvents => ({...existingEvents, ...events}));
                loadPublishEvents().then(() => console.log('TODIS publish logs loaded'))
                if (validateOnly) {
                    setIsValidating(false);
                } else {
                    setIsLoading(false);
                }
            } else if (fileType === 'HRM') {
                const employees = values(await employeeModelData.getAll());
                const zip = await buildHRMtoZip(operator, employees);
                fileDownload(zip.toBuffer(), `Busable_HRM_${dayjs().format("YYYY-MM-DD_HH:mm")}.zip`, 'application/zip');
                setIsLoading(false);
                setVisible(false)
            }

        } catch (e) {
            setIsLoading(false);
            console.log(e, e)
            onError(e);
        }

        // if (action === 'Publish') {
        //     setStatus("submitted")
        //     setTimeout(() => {
        //         setStatus("validating")
        //         setTimeout(() => {
        //             setIsLoading(false);
        //         }, 10000)
        //     }, 10000)
        //
        // }
    }

    return (
        <>
            {
                !isLoading ?
                    <Nav.Link onClick={() => setVisible(true)}>Export</Nav.Link>
                    :
                    <Nav.Link onClick={() => setVisible(true)}>
                        <SyncOutlined spin/> Export
                    </Nav.Link>
            }
            <Modal
                open={visible}
                width={800}
                title={`Export Data`}
                onCancel={() => {
                    setVisible(false)
                    setAction("Download");
                    setFileType("GTFS")
                }}
                destroyOnClose={true}
                footer={[
                    <Button key="back" className="btn-secondary" onClick={() => {
                        setVisible(false)
                        setAction("Download");
                        setFileType("GTFS")
                    }}>
                        Done
                    </Button>,
                    fileType === 'TODIS' && <Button key="validate" className="btn-secondary"
                                                    loading={isValidating}
                                                    onClick={e => {
                                                        handleSubmit(e, true).then(() => console.log('validated'))
                                                    }}>
                        Validate
                    </Button>,
                    <Button key="submit" type="primary" className="btn-primary"
                            feature={fileType === 'TODIS' ? FEATURE.tfnsw : null} loading={isLoading}
                            onClick={handleSubmit}
                            disabled={!validateForm()}>
                        {action}
                    </Button>,
                ]}
            >
                <div className="Export">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <h6>File type {fileType}</h6> */}
                            <p>Export operational service information.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Radio.Group
                                options={fileTypeOptions}
                                onChange={({target: {value}}) => {
                                    setFileType(value)
                                    const opt = find(fileTypeOptions, opt => opt.label === value)
                                    setAction(opt.button)
                                }}
                                value={fileType}
                                optionType="button"
                                buttonStyle="solid"
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    {/*<div className="row" style={{marginTop: "15px"}}>*/}
                    {/*    <div className="col-lg-12">*/}
                    {/*        <h6>Action</h6>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-lg-12">*/}
                    {/*        <Radio.Group*/}
                    {/*            options={getActions()}*/}
                    {/*            onChange={({target: {value}}) => setAction(value)}*/}
                    {/*            value={action}*/}
                    {/*            disabled={isLoading}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {
                        <div className="row" style={{margin: "20px 0"}}>
                            {/*<div className="col-lg-12"><h6>Status: {currentPublishStatus.status} on {dayjs(parseInt(currentPublishStatus.logId.split("#")[4])).format('lll')}</h6> {currentPublishStatus.status === 'Success' && currentPublishStatus.url && <a href={currentPublishStatus.url}>Download</a>}</div>*/}
                            {fileType === 'TODIS' && publishHistoryItems ?
                                <div className={"w-100"}>
                                    <div className={"mb-2"}>
                                        <div><h6 className="mt-3">Exclude invalid stop times</h6></div>
                                        <div><Checkbox checked={excludeInvalidTodis} onChange={({target: {checked}}) => {
                                            setExcludeInvalidTodis(checked)
                                        }}>Exclude</Checkbox></div>
                                    </div>
                                    <Collapse className="w-100" items={publishHistoryItems}/>
                                </div> :
                                fileType === 'GTFS' ?
                                    <Flex justify={"flex-start"}>
                                        <div className={"mr-4"}>
                                            <div><h6 className="mt-3">GTFS Extensions</h6></div>
                                            <div><Checkbox checked={tfnsw} onChange={({target: {checked}}) => {
                                                setTfnsw(checked)
                                            }}>TfNSW</Checkbox></div>
                                        </div>
                                        <div>
                                            <div><h6 className="mt-3">Exclude invalid stop times</h6></div>
                                            <div><Checkbox checked={excludeInvalidGtfs} onChange={({target: {checked}}) => {
                                                setExcludeInvalidGtfs(checked)
                                            }}>Exclude</Checkbox></div>
                                        </div>
                                    </Flex> :
                                    <></>
                            }
                        </div>
                        // publishLogs.map(log => <div>{log}</div>)
                    }
                    {/*{*/}
                    {/*    action === 'Publish' && isLoading &&*/}
                    {/*    <div className="row" style={{marginTop: "15px"}}>*/}
                    {/*        <div className="col-lg-12">*/}
                    {/*            <h6>Status: <span className="text-muted">{status}</span></h6>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </Modal>
        </>
    );
}
