import {Button, Popover} from "antd";
import React, {useState} from "react";
import {Modal} from "antd/lib";
import Transfers from "./Transfers";
import {ReactComponent as TransfersIcon} from '../../assets/icons/Transfers.svg';
import DisplayStop from "../../components/DisplayStop";
import {useAppContext} from "../../libs/contextLib";
import {noop} from "lodash";

const TransfersModal = ({
                            iconOnly,
                            point,
                            routeId,
                            tripId,
                            stopTimeId,
                            width,
                            popPlacement,
                            disabled,
                            setTransfersModalOpen = noop
                        }) => {
    const {isAuthenticated} = useAppContext()
    const [visible, setVisible] = useState(false);

    return (
        <>
            {isAuthenticated &&
                <Popover placement={popPlacement}
                         title={<span>Service transfers</span>}
                         content={<div><p>Edit service transfers at the selected point</p></div>}
                         trigger="hover">
                    <Button disabled={!point || disabled}
                            onClick={() => {
                                setTransfersModalOpen(true);
                                setVisible(true)
                            }}
                            className="transfer-icon icon-button btn-filled btn-fade"><TransfersIcon width={16} height={16}/>{!iconOnly && ' Transfers'}</Button>
                </Popover>
            }
            <Modal
                open={visible}
                width={'94%'} style={{top: 60}}
                title={<div className={"transfers-header d-flex flex-row"}>
                    <div className={""}>{point &&
                        <DisplayStop key={`DisplayStop-${point.stopId}`} stop={point} noRoutes={true}
                                     noInterchange={true} noWarning={true}/>}</div>
                </div>}
                onCancel={() => {
                    setTransfersModalOpen(false);
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={[
                    <Button key="back" type="primary" className="btn-primary" onClick={() => {
                        setTransfersModalOpen(false);
                        setVisible(false)
                    }}>
                        Done
                    </Button>,
                ]}
            >
                <Transfers point={point} //transfers={values(allTransfers)} allStops={allStops}
                           routeId={routeId} tripId={tripId} stopTimeId={stopTimeId}/>
            </Modal>
        </>
    );
}

export default React.memo(TransfersModal)
