import RouteTitle from "./RouteTitle";
import {Radio} from "antd";
import TripsTimetable from "./TripsTimetable";
import {values} from "lodash";
import React from "react";
import LoadMessage from "./LoadMessage";

export default function TimetableRouteList({
                                               filteredRoutes,
                                               setHighlightedRouteIds,
                                               highlightedRouteIds,
                                               setZoom,
                                               zoom,
                                               selectedSchedule,
                                               setSelectedSchedule,
                                               fetching,
                                               selectedStop,
                                               setSelectedStop,
                                               setFocusStop,
                                               focusStop,
                                               schedules,
                                               getScheduleOptions,
                                               allStops,
                                               operator,
                                               focusTransfers,
                                               setFocusTransfers,
                                               showComments,
                                           }) {
    return (
        <div className="map-items RouteListContainer" style={{width: '35%'}}>
            {fetching &&
                <div style={{position: 'absolute', top: 20, right: 40}}>
                    <LoadMessage title={'Loading source system points...'} height={32}/>
                </div>
            }
            <div className="row">
                <div className="col-lg-12" style={{padding: "0 15px", overflow: "hidden"}}>
                    {filteredRoutes?.length ? filteredRoutes.map(route => (
                        <div className="timetable-row"
                             key={`TimetableRouteListContainer-${route.routeId}`}>
                            <div key={`TimetableRouteList-${route.routeId}`}
                                 onMouseOver={() => {
                                     setHighlightedRouteIds(highlightedRouteIds => highlightedRouteIds.concat(route.routeId));
                                 }}
                                 onClick={() => {
                                     setSelectedStop(null);
                                     setFocusStop(null);
                                     setFocusTransfers({to: [], from: []});
                                     if (zoom.includes(route.routeId)) {
                                         setZoom(zoom.filter(rId => rId !== route.routeId));
                                     } else {
                                         setZoom(zoom => zoom.concat(route.routeId));
                                     }

                                 }}
                                 onMouseLeave={() => {
                                     const _highlightedRouteIds = highlightedRouteIds.filter(rId => rId !== route.routeId)
                                     setHighlightedRouteIds(_highlightedRouteIds);
                                 }}
                                 style={{cursor: "pointer"}}>
                                <RouteTitle route={route} style={{margin: "0px"}} showUnreadBadge={true}/>
                            </div>
                            {zoom.indexOf(route.routeId) > -1 && selectedSchedule[route.routeId] && (
                                <>
                                    <div className="timetable-schedule">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='Schedule'>
                                                    {
                                                        getScheduleOptions(route).length === 1 ?
                                                            <h2 className="Days">{getScheduleOptions(route)[0].label}</h2> :
                                                            <Radio.Group
                                                                options={getScheduleOptions(route)}
                                                                onChange={({target: {value}}) => setSelectedSchedule(selectedSchedule => {
                                                                    selectedSchedule[route.routeId] = value
                                                                    return {...selectedSchedule}
                                                                })}
                                                                value={selectedSchedule[route.routeId]}
                                                                disabled={fetching}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                                className="mt-2 mb-2"
                                                            />
                                                    }
                                                    {/*<h2 className="Days">{selectedSchedule[route.routeId] ? selectedSchedule[route.routeId].scheduleName : ''}</h2>*/}
                                                    <div
                                                        className="ServiceNotes">
                                                        <strong>{route.stops[0]?.stopName}</strong> to <strong>{route.stops[route.stops.length - 1]?.stopName}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-lg-12">*/}
                                            {/*    <table>*/}
                                            {/*        <thead>*/}
                                            {/*        <tr>*/}
                                            {/*            <th>Stop Name</th>*/}
                                            {/*            <th className="Times">Departure</th>*/}
                                            {/*        </tr>*/}
                                            {/*        </thead>*/}
                                            {/*        <tbody>*/}
                                            {/*        {*/}
                                            {/*            route.getPublicStops().map((stop, idx) => (*/}
                                            {/*                    <tr className={selectedStop && selectedStop.stopId === stop.stopId ? 'selected' : stop.timingPoint ? 'TimingPoint' : ''}*/}
                                            {/*                        onClick={() => {*/}
                                            {/*                            if (selectedStop && selectedStop.stopId === stop.stopId) {*/}
                                            {/*                                setSelectedStop(null)*/}
                                            {/*                            } else {*/}
                                            {/*                                setSelectedStop(stop)*/}
                                            {/*                            }*/}
                                            {/*                        }}*/}
                                            {/*                        ref={selectedStop && selectedStop.stopId === stop.stopId ? scrollRef : undefined}*/}
                                            {/*                        onMouseOver={(e) => {*/}
                                            {/*                            e.preventDefault()*/}
                                            {/*                            e.stopPropagation();*/}
                                            {/*                            setFocusStop(stop)*/}
                                            {/*                        }}*/}
                                            {/*                        onMouseLeave={() => setFocusStop(null)}*/}
                                            {/*                        // className={`${selectedStop && stop.stopId === selectedStop.stopId ? 'Selected' : ''}`}*/}
                                            {/*                    >*/}
                                            {/*                        <td>{stop.stopName}&nbsp;{stop.type && '*'}</td>*/}
                                            {/*                        /!*<td>{toTime(getStartTimeAsSecondsSinceMidnight(route) + stop.delta + stop.adjustedDelta)}</td>*!/*/}
                                            {/*                        <td className="Times">{stop.timingPoint ? getDepartureTime(route, stop, idx) || '-:-' : ''}</td>*/}
                                            {/*                    </tr>*/}
                                            {/*                )*/}
                                            {/*            )*/}
                                            {/*        }*/}
                                            {/*        </tbody>*/}
                                            {/*    </table>*/}
                                            {/*</div>*/}
                                            {/* <Trips immutable={true}
                                                       route={route}
                                                       operator={operator}
                                                       activeOnly
                                                       allStops={allStops}
                                                       schedules={values(schedules).filter(schedule => !schedules[schedule.scheduleId].isObsolete() && route.includesSchedule(schedule.scheduleId))}
                                                       allSchedules={schedules}
                                                       setFocusStop={setFocusStop}
                                                       focusStp={focusStop}
                                                       setSelectedStop={setSelectedStop}
                                                       selectedStop={selectedStop}
                                                       selectedScheduleId={selectedSchedule[route.routeId]}
                                                    // onScheduleChange={(scheduleId) => {
                                                    //     setSelectedSchedule(selectedSchedule => {
                                                    //         selectedSchedule[route.routeId] = schedules[scheduleId]
                                                    //         return {...selectedSchedule};
                                                    //     });
                                                    // }}
                                                /> */}
                                            <TripsTimetable immutable={true}
                                                            route={route}
                                                            operator={operator}
                                                            activeOnly
                                                            allStops={allStops}
                                                            schedules={values(schedules).filter(schedule => !schedules[schedule.scheduleId].isObsolete() && route.includesSchedule(schedule.scheduleId))}
                                                            allSchedules={schedules}
                                                            setFocusStop={setFocusStop}
                                                            focusStp={focusStop}
                                                            setSelectedStop={setSelectedStop}
                                                            selectedStop={selectedStop}
                                                            selectedScheduleId={selectedSchedule[route.routeId]}
                                                            focusTransfers={focusTransfers}
                                                            setFocusTransfers={setFocusTransfers}
                                                // onScheduleChange={(scheduleId) => {
                                                //     setSelectedSchedule(selectedSchedule => {
                                                //         selectedSchedule[route.routeId] = schedules[scheduleId]
                                                //         return {...selectedSchedule};
                                                //     });
                                                // }}
                                            />
                                        </div>
                                        {schedules[selectedSchedule[route.routeId]] && schedules[selectedSchedule[route.routeId]].hasExclusions() ? (
                                            <div className="row" style={{marginTop: 0}}>
                                                <div className="col-lg-12">
                                                    <div className='Exclusions mt-2'>
                                                        Excluding
                                                        days: {schedules[selectedSchedule[route.routeId]].exclusionsToString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : <></>}
                                    </div>
                                </>
                            )}
                        </div>
                    )) : <span>Loading routes...</span>}
                    {/*{fetching ? (*/}
                    {/*    <LoadMessage message={"loading timetable..."} size={"lg"}/>*/}
                    {/*) : <>No routes to display</>}*/}
                </div>
            </div>
        </div>
    )
}