import { cloneDeep } from "lodash";
import moment from "moment/moment";
import { Employment } from "./employment";

export const GENDERS = {
    male: 'Male',
    female: 'Female',
    nonBinary: 'Non-binary',
}

export class Employee {
  constructor(data) {
    // this.employeeID = null;
    // this.firstName = null;
    // this.lastName = null;
    // this.dateOfBirth = null;
    // this.gender = null;
    // this.bloodGroup = null;
    // this.phone = null;
    // this.countryCode = "+61";
    // this.email = null;

    // this.medicalConditions = []
    // this.emergencyContact = {}


    Object.assign(this, data)
  }

  name() {
    return `${this.firstName} ${this.lastName}`
  }

  clone(employee) {
    return new Employee(cloneDeep(employee))
  }

}
