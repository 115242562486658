import React, {Suspense, lazy} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import LoadMessage from "./components/LoadMessage";
import ShiftBatViewTemplate from "./features/ShiftBat/ShiftBatViewTemplate";
import AdminRoute from "./components/AdminRoute";
import SMS from "./features/Marketplace/SMS";
import Elements from "./features/Charters/Elements";
import Charters from "./features/Charters/Charters";
import CharterDetails from "./features/Charters/CharterDetails";
import CharterItinerary from "./features/Charters/CharterItinerary";
import CharterQuote from "./features/Charters/CharterQuote";
import Dispatch from "./features/Charters/Dispatch";
import Customers from "./features/Customers/Customers";
import CustomerDetails from "./features/Customers/CustomerDetails";

const Home = lazy(() => import("./containers/Home"));
const Login = lazy(() => import("./containers/Login"));
const Signup = lazy(() => import("./containers/Signup"));
const BusRouteDetails = lazy(() => import("./containers/BusRouteDetails"));
const NotFound = lazy(() => import("./containers/NotFound"));
const AuthenticatedRoute = lazy(() => import("./components/AuthenticatedRoute"));
const UnauthenticatedRoute = lazy(() => import("./components/UnauthenticatedRoute"));
const Schedules = lazy(() => import("./editableList/schedules/Schedules"));
const Overview = lazy(() => import("./containers/Overview"));
const Settings = lazy(() => import("./containers/Settings"));
const ChangePassword = lazy(() => import("./containers/ChangePassword"));
const ChangeEmail = lazy(() => import("./containers/ChangeEmail"));
const Timetable = lazy(() => import("./containers/Timetable"));
const Timetables = lazy(() => import("./containers/Timetables"));
const ResetPassword = lazy(() => import("./containers/ResetPassword"));
const StopManager = lazy(() => import("./containers/StopManager"));
const TripPlanner = lazy(() => import("./containers/TripPlanner"));
const Transfers = lazy(() => import("./containers/Transfers"));
const NewOperator = lazy(() => import("./components/NewOperator"));
const Students = lazy(() => import("./features/Students/Students"));
const StudentDetails = lazy(() => import("./features/Students/StudentDetails"));
const StudentPasses = lazy(() => import("./features/Students/StudentPasses"));
const StudentPass = lazy(() => import("./features/Students/StudentPass"));
const StudentMisbehaviours = lazy(() => import("./features/Students/StudentMisbehaviours"));
const StudentMisbehaviour = lazy(() => import("./features/Students/StudentMisbehaviour"));
const Operators = lazy(() => import("./editableList/operators/Operators"));
const StopTimetablesList = lazy(() => import("./containers/StopTimetablesList"));
const Fleet = lazy(() => import("./editableList/vehicles/Vehicles"));
const Drivers = lazy(() => import("./editableList/drivers/Drivers"));
const Shifts = lazy(() => import("./editableList/shifts/Shifts"));
const ShiftBats = lazy(() => import("./features/ShiftBat/ShiftBats"));
const ShiftBatView = lazy(() => import("./features/ShiftBat/ShiftBatView"));
const ShiftBatPrivatePrintView = lazy(() => import("./features/ShiftBat/ShiftBatPrivatePrintView"));
const EmployeeHR = lazy(() => import("./features/EmployeeHR/Elements"));
const Refresh = lazy(() => import("./components/Refresh"));
const Logout = lazy(() => import("./components/Logout"));
const SwitchRegion = lazy(() => import("./components/SwitchRegion"));
const EmployeeManagementDashboard = lazy(() => import("./features/EmployeeHR/EmployeeManagementDashboard"));
const Employees = lazy(() => import("./features/EmployeeHR/Employees"));
const EmployeeDashboard = lazy(() => import("./features/EmployeeHR/EmployeeDashboard"));
const EmployeeDetails = lazy(() => import("./features/EmployeeHR/EmployeeDetails"));
const EmployeeEmployment = lazy(() => import("./features/EmployeeHR/EmployeeEmployment"));
const EmployeeEntitlements = lazy(() => import("./features/EmployeeHR/EmployeeEntitlements"));
const EmployeeBanking = lazy(() => import("./features/EmployeeHR/EmployeeBanking"));
const EmployeeSuperannuation = lazy(() => import("./features/EmployeeHR/EmployeeSuperannuation"));
const EmployeeTax = lazy(() => import("./features/EmployeeHR/EmployeeTax"));
const EmployeeQualificationsAndTraining = lazy(() => import("./features/EmployeeHR/EmployeeQualificationsAndTraining"));
const EmployeeCalendar = lazy(() => import("./features/EmployeeHR/EmployeeCalendar"));
const Marketplace = lazy(() => import("./features/Marketplace/Marketplace"));
const MarketplaceDetails = lazy(() => import("./features/Marketplace/MarketplaceDetails"));

function RoutesComponent({isAuthenticating, setChatContext, setChatOpen}) {

    return (
        <>
            {isAuthenticating ? <LoadMessage message='Loading Busable...' size={"lg"}/> :
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect exact from="/" to="/services"/>
                        </Route>
                        <UnauthenticatedRoute exact path="/login/:id?">
                            <Login/>
                        </UnauthenticatedRoute>
                        <UnauthenticatedRoute exact path="/reset">
                            <ResetPassword/>
                        </UnauthenticatedRoute>
                        {/*<UnauthenticatedRoute exact path="/timetable">*/}
                        {/*    <TimetableOverview />*/}
                        {/*</UnauthenticatedRoute>*/}
                        <Route exact path="/timetables/:key?/timetable/:id">
                            <Timetable/>
                        </Route>
                        <Route exact path="/timetables/:key?">
                            <Timetables setChatContext={setChatContext} setChatOpen={setChatOpen}/>
                        </Route>
                        <Route exact path="/trip_planner/:key?">
                            <TripPlanner/>
                        </Route>
                        <Route exact path="/school_list/:key?">
                            <StopTimetablesList stopType={'school'}/>
                        </Route>
                        <Route exact path="/venue_list/:key?">
                            <StopTimetablesList stopType={'venue'}/>
                        </Route>
                        <Route exact path="/p_sb/:key/:id">
                            <ShiftBatPrivatePrintView/>
                        </Route>
                        <AuthenticatedRoute exact path="/marketplace">
                            <Marketplace/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/marketplace/:id">
                            <MarketplaceDetails/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/sms">
                            <SMS/>
                        </AuthenticatedRoute>
                        <UnauthenticatedRoute exact path="/trial_14d">
                            <Signup/>
                        </UnauthenticatedRoute>
                        <AuthenticatedRoute exact path="/refresh">
                            <Refresh/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/settings">
                            <Settings/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/logout">
                            <Logout/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/switch/:companyId/:operatorId">
                            <SwitchRegion/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/settings/password">
                            <ChangePassword/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/settings/email">
                            <ChangeEmail/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/points/:stopId?">
                            <StopManager/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/services">
                            <Home/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/transfers">
                            <Transfers/>
                        </AuthenticatedRoute>
                        {/*<AuthenticatedRoute exact path="/services/#new">*/}
                        {/*    <NewBusRoute/>*/}
                        {/*</AuthenticatedRoute>*/}
                        <AuthenticatedRoute exact path="/services/:id">
                            <BusRouteDetails/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/calendars">
                            <Schedules/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/students">
                            <Students/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/students/:studentId">
                            <StudentDetails/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/students/:studentId/passes">
                            <StudentPasses/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/students/:studentId/passes/:passId">
                            <StudentPass/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/students/:studentId/misbehaviours">
                            <StudentMisbehaviours/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/students/:studentId/misbehaviours/:incidentId">
                            <StudentMisbehaviour/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/overview">
                            <Overview/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/new_operator">
                            <NewOperator/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/fleet">
                            <Fleet/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/drivers">
                            <Drivers/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/shifts">
                            <Shifts/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/shiftbats">
                            <ShiftBats/>
                        </AuthenticatedRoute>
                        <AdminRoute exact path="/operators">
                            <Operators/>
                        </AdminRoute>
                        <AuthenticatedRoute exact path="/shiftbatsTemplate">
                            <ShiftBatViewTemplate/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/shiftbats/:id">
                            <ShiftBatView/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm">
                            <EmployeeManagementDashboard/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/all">
                            <Employees/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/dashboard">
                            <EmployeeDashboard/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/details">
                            <EmployeeDetails/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/employment">
                            <EmployeeEmployment/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/entitlements">
                            <EmployeeEntitlements/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/banking">
                            <EmployeeBanking/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/superannuation">
                            <EmployeeSuperannuation/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/tax">
                            <EmployeeTax/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/qualifications">
                            <EmployeeQualificationsAndTraining/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/:id/calendar">
                            <EmployeeCalendar/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/hrm/elements">
                            <EmployeeHR/>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/charters">
                            <Charters />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/charters/:charterId">
                            <CharterDetails />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/charters/:charterId/itinerary">
                            <CharterItinerary />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/charters/:charterId/quote">
                            <CharterQuote />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/customers">
                            <Customers />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/customers/:customerId">
                            <CustomerDetails />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/dispatch">
                            <Dispatch />
                        </AuthenticatedRoute>
                        {/* Finally, catch all unmatched routes */}
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </Suspense>
            }
        </>
    );
}

export default React.memo(RoutesComponent)
