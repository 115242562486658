import React from "react";
import {Modal} from "antd";
import EditStop from "./EditStop";
import {noop} from "lodash/util";

function EditStopModal({modelRef, visible, setVisible, ...props}) {

    return (
        <>
            <Modal
                open={visible}
                ref={modelRef}
                title={"Create stop"}
                onCancel={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setVisible(false)
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                destroyOnClose
                footer={[]}
            >
                <EditStop key={`EditStop-${props.stop.stopId}`} setUpdated={noop}
                          {...props} />
            </Modal>
        </>
    );
}

export default React.memo(EditStopModal);
