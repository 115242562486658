import React, {useState} from "react";
import {toTime} from "../../libs/formatLib";
import Pin from "../../components/Pin";
import "./Itinerary.css"
import {ulid} from "ulid";
import ItineraryStopNoteEdit from "./ItineraryStopNoteEdit";
import ItineraryNoteRow from "./ItineraryNoteRow";
import ItineraryTransferRowPrint from "./ItineraryTransferRowPrint";

function ItineraryStopRowPrint({route, itineraryStopRow, onSave, editMode, printMode, setSelectedStop, selectedStop, setFocusStop}) {

    const [editing, setEditing] = useState({})

    return (<>
        <div className={`row align-items-center align-items-stretch justify-content-center ${selectedStop && itineraryStopRow?.stop.stopTimeId === selectedStop.stopTimeId ? 'Selected' : ''}`}
             style={{cursor: 'pointer'}}
             onClick={e => {
                 if (printMode) {
                     return
                 }
                 e.stopPropagation();
                 setSelectedStop(itineraryStopRow.stop)
             }}
             onMouseOver={() => {
                 if (printMode) {
                     return
                 }
                 setFocusStop(itineraryStopRow.stop);
             }}>
            <div className="col-1 sb-time">{itineraryStopRow.time > -1 &&
                <span>{toTime(itineraryStopRow.time)}</span>}</div>
            <div className="col-2 sb-step-no">{itineraryStopRow.stop &&
                <Pin size={20} type={itineraryStopRow.stop.type}
                     sequence={itineraryStopRow.sequence}/>}</div>
            <div className="col-9 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                <div className={`RouteInfo`}>
                    <div className="row align-items-stretch justify-content-center">
                        <div className="col-5 d-flex align-items-center flex-wrap">
                            <h4 className="title">{itineraryStopRow.title}</h4>
                        </div>
                        <div className="col-7 d-flex align-items-center flex-wrap">
                            {itineraryStopRow.transfersTo?.length ?
                                <ItineraryTransferRowPrint itineraryStopRow={itineraryStopRow} transfers={itineraryStopRow.transfersTo}
                                                          pickup={false}/> : <></>
                            }
                            {itineraryStopRow.transfersFrom?.length ?
                                <ItineraryTransferRowPrint itineraryStopRow={itineraryStopRow} transfers={itineraryStopRow.transfersFrom}
                                                          pickup={true}/> : <></>
                            }
                            {itineraryStopRow.noteRows && itineraryStopRow.noteRows.map((itineraryNote, idx) => (
                                editing[itineraryNote.id] ?
                                    <ItineraryStopNoteEdit key={'ESTR-' + itineraryNote.id} itineraryNote={itineraryNote}
                                                        onCancel={(e, note) => {
                                                            if (!note.title?.length) {
                                                                itineraryStopRow.removeNote(note)
                                                                return onSave(e);
                                                            }
                                                            setEditing(editing => ({...editing, [itineraryNote.id]: false}))
                                                        }}
                                                        onSave={(e, note) => {
                                                            if (!note.title?.length) {
                                                                itineraryStopRow.removeNote(note)
                                                                return onSave(e);
                                                            }
                                                            itineraryStopRow.replaceNote(note);
                                                            setEditing(editing => ({...editing, [itineraryNote.id]: false}))
                                                            onSave(e)
                                                        }}/>
                                    :
                                    <ItineraryNoteRow key={'STNR-' + itineraryNote.id}
                                                    itineraryNoteRow={itineraryNote} editMode={editMode}
                                                    onEdit={e => setEditing(editing => ({...editing, [itineraryNote.id]: true}))}
                                                    onAdd={e => {
                                                        const id = ulid();
                                                        itineraryStopRow.addNote({id}, idx + 1)
                                                        setEditing(editing => ({...editing, [id]: true}))
                                                    }}
                                                    onDelete={e => {
                                                        itineraryStopRow.removeNote(itineraryNote)
                                                        onSave(e);
                                                    }}
                                    />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default React.memo(ItineraryStopRowPrint)
