import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Itinerary.css";
import { Button, message, Select, Tag } from "antd";
import { Image } from "react-bootstrap";
import { ReactComponent as Print } from "../../assets/icons/Print.svg";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Timer } from "../../assets/icons/Timer.svg";
import { ReactComponent as Services } from "../../assets/icons/Services.svg";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import { ReactComponent as Undo } from "../../assets/icons/Undo.svg";
import { ReactComponent as Reset } from "../../assets/icons/Reset.svg";
import { ReactComponent as Redo } from "../../assets/icons/Redo.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import RouteTitleEdit from "../../components/RouteTitleEdit";
import { ShiftBatRowType } from "../../model/shiftBat";
import { Prompt, useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { toHrsMinsSecs } from "../../libs/formatLib";
import {
    charterModelData,
    deadrunModelData,
    noteRefModelData,
    routeModelData,
    stopModelData,
} from "../../services/ModelService";
import RouteMap from "../../components/RouteMap";
import LoadMessage from "../../components/LoadMessage";
import ItineraryRows from "./ItineraryRows";
import ItineraryPrintModal from "./ItineraryPrintModal";
import { keyBy } from "lodash/collection";
import { schedule_options } from "../../model/schedule";
import { values } from "lodash/object";
import { debounce } from "lodash/function";
import { Col, Row } from "react-bootstrap";
import config from "../../config";
import util from "util";
import { s3Upload } from "../../libs/awsLib";
import { FEATURE } from "../../model/features";
import { checkFeatureAuth } from "../../App";
import { Popconfirm } from "antd/lib";
import { useTimeTravelState } from "../../libs/useTimeTravelState";
import LoaderButton from "../../components/LoaderButton";
import Form from "react-bootstrap/Form";
import { BusRoute, ROUTE_STATUS, ROUTE_STATUSES } from "../../model/busRoute";
import useItinerary from "../Charters/useItinerary";
import RouteMapHybrid from "../../components/RouteMapHybrid";
import { CharterRowTypeLabel, ItineraryShift } from "../../model/charter";
import { CharterRouteRun } from "../../model/deadrun";
import ItineraryTemplateManager from "./ItineraryTemplateManager";

function ItineraryView() {
    // const { id } = useParams();
    const id = null;
    const { isAuthenticated, apiKey, schedules, setBreadcrumb, operator, setFaultState, email, givenName, familyName } = useAppContext();
    const history = useHistory();
    const templatesRef = useRef();
    const { itinerary: itineraryObj, setItinerary: setItineraryObj } = useItinerary();

    const [fetching, setFetching] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editingRoute, setEditingRoute] = useState(false);
    const [editing, setEditing] = useState({});
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);
    const [zoomStop, setZoomStop] = useState([]);
    const [zoom, setZoom] = useState([]);
    const [tolerance, setTolerance] = useState(0);
    const [viewing, setViewing] = useState({});
    const [itinerary, setItineraryHistory, controls] = useTimeTravelState(new ItineraryShift({ shiftBatId: "_" }, charterModelData), { maxSize: 10 });
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);

    const [showAllStops, setShowAllStops] = useState(true);
    const [allStops, setAllStops] = useState({});
    const [allCharterStops, setAllCharterStops] = useState({});
    const [allRoutes, setAllRoutes] = useState({});
    const [allTransfers, setAllTransfers] = useState({});

    // const [route, setRoute] = useState(null);

    const [editRouteRow, setEditRouteRow] = useState(null);

    const [printItinerary, setPrintItinerary] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    // eslint-disable-next-line
    const initialShiftbatRef = useRef(null);
    const [isCancelling, setIsCancelling] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [itineraryNumber, setItineraryNumber] = useState("");
    const [itineraryName, setItineraryName] = useState();
    const [itineraryDetails, setItineraryDetails] = useState();
    const [itineraryLogo, setItineraryLogo] = useState();
    const [actRoute, setActRoute] = useState(null);

    // useEffect(() => {
    //     if (itineraryObj) {
    //         controls.reset();
    //     }
    // }, [controls, itinerary, itineraryObj]);

    // const [itineraryColour, setItineraryColour] = useState()

    const getEditor = useCallback(
        () => (givenName?.length && familyName?.length ? `${givenName} ${familyName}` : email),
        [email, givenName, familyName]
    );

    // // eslint-disable-next-line
    // const setItinerary = useCallback((sb, opts = null) => {
    //     setItineraryHistory(sb, opts)
    //     if (!opts?.overwriteLastEntry) {
    //         setUpdated(true);
    //     }
    // }, [setItineraryHistory, setUpdated])

    const setItineraryNow = useCallback(
        (sb, opts = null) => {
            setItineraryHistory(sb, opts);
            if (!opts?.overwriteLastEntry) {
                setUpdated(true);
            }
        },
        [setItineraryHistory, setUpdated]
    );

    const setItinerary = debounce(setItineraryNow, 500);

    const disableEditForRow = useCallback(() => values(editing).some((id) => !!id), [editing]);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        checkFeatureAuth(setFaultState, operator, FEATURE.shfts, () => history.push("/")).then(() => console.log("Auth checked."));
    }, [setFaultState, operator, history]);

    useEffect(() => {
        const beforeunload = (e) => {
            if (updated) {
                e.returnValue = "unloading";
                return "unloading";
            }
        };
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [updated]);

    // const updateRoute = useCallback((updatedRoute) => {
    //     const routeRow = itinerary.rows[itinerary.rows.findIndex(row => row.route && row.route.routeId === updatedRoute.routeId)]
    //     if (routeRow) {
    //         routeRow.hasDirections = false
    //         setUpdated(itinerary);
    //     }
    // }, [itinerary, setUpdated])

    const notifyUpdate = useCallback(
        (routes) => {
            if (!Array.isArray(routes)) {
                routes = [routes];
            }
            setAllRoutes((_routes) => {
                routes.forEach((route) => {
                    if (route.published === -1) {
                        delete _routes[route.routeId];
                    } else {
                        _routes[route.routeId] = route;
                        const routeRow =
                            itinerary.rows[itinerary.rows.findIndex((row) => row.route && row.route.routeId === route.routeId)];
                        if (routeRow) {
                            // routeRow.hasDirections = false
                            // routeRow.loaded = false;
                            routeRow.route = route;
                        }
                    }
                });
                // updateRoute(route)
                return { ..._routes };
            });
        },
        [itinerary]
    );

    const notifyDelete = useCallback((id) => {
        setAllRoutes((_routes) => {
            delete _routes[id];
            return { ..._routes };
        });
    }, []);

    useEffect(() => {
        const load = async () => {
            console.log("Loading shift bat...");

            try {
                if (!isAuthenticated) {
                    console.log("NOT AUTHENTICATED");
                    history.push("/");
                }

                stopModelData.addListener({
                    setterFn: setAllStops,
                });
                const allStops = await stopModelData.getAll();
                setAllStops(allStops);
                stopModelData.addListener({
                    loaded: (stops) => setAllStops(stops),
                    refresh: debounce(() => {
                        stopModelData.getAll().then((stopsById) => setAllStops(stopsById));
                    }, 500),
                });

                // Preload notes
                await noteRefModelData.getAll();

                // transferModelData.setFetchOption("allStops", allStops).setFetchOption("allSchedules", schedules);
                // transferModelData.addListener({
                //     setterFn: setAllTransfers,
                // });
                // const allTransfers = await transferModelData.getAll();
                // console.log("All transfers finished loading.");
                // setAllTransfers(allTransfers);

                routeModelData
                    .setFetchOption("includeStopTimes", true)
                    .setFetchOption("scheduledOnly", true)
                    .setFetchOption("allStops", allStops);
                routeModelData.addListener({ notifyUpdate, notifyDelete });
                await routeModelData
                    .getAll(apiKey)
                    .then((routes) => {
                        setAllRoutes(routes);
                        return routes;
                    })
                    .then((allRoutes) => {
                        // load shift bat
                        if (itineraryObj) {
                            const sb = new ItineraryShift(itineraryObj, charterModelData);
                            setItineraryNumber(sb.shiftBatNumber);
                            setItineraryName(sb.shiftBatName);
                            setItineraryDetails(sb.shiftBatDetails);
                            // setItineraryColour(sb.shiftBatColour)
                            Promise.all(
                                sb.rows
                                    .filter((row) => row.updateRow)
                                    .map(async (row) => {
                                        await row.updateRow({
                                            apiKey,
                                            allStops,
                                            allTransfers,
                                            allRoutes,
                                            charterModelData,
                                            deadrunModelData,
                                        });
                                    })
                            ).then(() => {
                                if (sb?.shiftBatLogo?.length) {
                                    console.log("SB logo: ", sb.shiftBatLogo);
                                    setFileList([
                                        {
                                            url: `https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${sb.shiftBatLogo}`,
                                            status: "done",
                                        },
                                    ]);
                                } else {
                                    setFileList([
                                        {
                                            url: "https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg",
                                            status: "done",
                                        },
                                    ]);
                                }
                                controls.reset(sb.clone());
                                setFetching(false);
                                setBreadcrumb(sb.shiftBatNumber);
                            });
                        }
                        // if (id === "_new_") {
                        //     setEditMode(true);
                        //     setFetching(false);
                        //     setBreadcrumb("NEW");
                        //     setFileList([
                        //         {
                        //             url: "https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg",
                        //             status: "done",
                        //         },
                        //     ]);
                        // } else {
                        //     shiftBatModelData.get(apiKey, id).then((sb) => {
                        //         setItineraryNumber(sb.itineraryNumber);
                        //         setItineraryName(sb.itineraryName);
                        //         setItineraryDetails(sb.itineraryDetails);
                        //         // setItineraryColour(sb.itineraryColour)
                        //         Promise.all(
                        //             sb.rows
                        //                 .filter((row) => row.updateRow)
                        //                 .map(async (row) => {
                        //                     await row.updateRow({ apiKey, allStops, allTransfers, allRoutes, charterModelData });
                        //                 })
                        //         ).then(() => {
                        //             if (sb?.itineraryLogo?.length) {
                        //                 console.log("SB logo: ", sb.itineraryLogo);
                        //                 setFileList([
                        //                     {
                        //                         url: `https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${sb.itineraryLogo}`,
                        //                         status: "done",
                        //                     },
                        //                 ]);
                        //             } else {
                        //                 setFileList([
                        //                     {
                        //                         url: "https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg",
                        //                         status: "done",
                        //                     },
                        //                 ]);
                        //             }
                        //             controls.reset(sb.clone());
                        //             setFetching(false);
                        //             setBreadcrumb(sb.itineraryNumber);
                        //         });
                        //     });
                        // }
                    });

                console.log("All routes finished loading.");
            } catch (e) {
                console.log(e);
            }
        };

        if (!initialShiftbatRef.current) {
            load().then(() => {
                console.log("ShiftBat loaded.");
            });
        }
        // eslint-disable-next-line
    }, [apiKey, id, schedules, isAuthenticated, history, setEditMode, setAllStops, setAllTransfers, initialShiftbatRef, itineraryObj]);

    useEffect(() => {
        const stopKeys = Object.keys(allStops);
        const _filteredStops = {};
        stopKeys.forEach((k) => {
            if (allStops[k].type === "charter") _filteredStops[k] = allStops[k];
        });
        setAllCharterStops(_filteredStops);
    }, [allStops]);

    // eslint-disable-next-line
    const updateItineraryAndSave = useCallback(
        debounce((itinerary) => {
            setUpdating(true);
            itinerary.updateRows({ apiKey, allStops, allTransfers, allRoutes, charterModelData, deadrunModelData }).then(() => {
                // debounceSave(itinerary);
                setUpdating(false);
            });
            // eslint-disable-next-line
        }, 1000),
        [setUpdating, apiKey, allStops, allTransfers, allRoutes]
    );

    // useEffect(() => {
    //     if (itinerary?.scheduleId) {
    //         setAllRoutes(allRoutes => pickBy(allRoutes, route => route.includesSchedule(itinerary.scheduleId)))
    //     }
    // }, [setAllRoutes, itinerary])

    const save = useCallback(
        async (itinerary, editMode = true) => {
            if (!itinerary || !operator.features.access(FEATURE.shfts)) {
                return;
            }

            try {
                const file = fileList[0];

                if (file?.type && file?.originFileObj) {
                    console.log("Saving file: ", util.inspect(file));
                    if (file.type.indexOf("/") === -1) {
                        console.log("Could not save file. Tpe does not look right.");
                    } else {
                        let fileExt = file.type.toLowerCase().split("/")[1];
                        console.log("File ext", fileExt);
                        const filename = `${operator.operatorId}/shiftbats/${itinerary.shiftBatId}/logo/${Date.now()}.${fileExt}`;
                        itinerary.shiftBatLogo = await s3Upload(file.originFileObj, filename, { level: "public" });
                    }
                }

                itinerary.shiftBatNumber = itineraryNumber;
                itinerary.shiftBatName = itineraryName;
                itinerary.shiftBatDetails = itineraryDetails;
                // itinerary.shiftBatColour = itineraryColour
                await charterModelData.save(itinerary);
                // await Promise.all(itinerary.rows.filter(row => row.type === ShiftBatRowType.charter && (row.routeEdited || row.title !== row.route.routeName || row.description !== row.route.routeDetails)).map(async deadRow => {
                //     if(deadRow.route.routeName !== deadRow.title) {
                //         deadRow.route.routeId = "_";
                //     }
                //     deadRow.route.routeName = deadRow.title
                //     deadRow.route.routeDetails = deadRow.description
                //     await charterModelData.save(new CharterRouteRun({...deadRow.route}))
                // }));
                // controls.reset(itinerary)
                setEditMode(editMode);
                setHighlightedRouteIds([]);

                controls.reset(itinerary);
                setUpdated(false);
                console.log("Itinerary saved...");
                messageApi.info("Itinerary saved...");
            } catch (e) {
                console.log(e, e);
            }
        },
        [
            setEditMode,
            controls,
            setHighlightedRouteIds,
            messageApi,
            fileList,
            operator,
            setUpdated,
            itineraryNumber,
            itineraryName,
            itineraryDetails,
        ]
    );

    // const debounceSave = debounce((itinerary) => save(itinerary), 30000)

    const onUpdate = useCallback(
        (event, row, idx, keepEditing) => {
            const update = async () => {
                if (!keepEditing) {
                    if (row.type === ShiftBatRowType.charter && row.id === editRouteRow?.id) {
                        setEditRouteRow(null);
                        editRouteRow.title = row.title;
                        editRouteRow.description = row.description;
                        if (row.title?.length && editRouteRow.route.routeName !== row.title) {
                            editRouteRow.route.routeId = "_"; // Creates a new Deadrun in the DB
                        }
                        editRouteRow.route.calculateDistances();
                        // editRouteRow.route.routeDetails = row.description
                        const deadrun = new CharterRouteRun(editRouteRow.route);
                        deadrun.routeName = row.title;
                        deadrun.routeDetails = row.description;
                        deadrun.duration = row.duration;
                        const savedDeadrun = await charterModelData.save(deadrun.clone());
                        deadrun.routeId = savedDeadrun.routeId;
                        row.route = deadrun.clone();
                        row.routeId = deadrun.routeId;
                        row.routes = row.routes || {};
                        row.routes[deadrun.routeId] = deadrun;
                        row.distance = deadrun.distance;
                        // } else if (row.type === ShiftBatRowType.note) {
                        //     const savedRow = await noteRefModelData.save(clone(row));
                        //     row.refId = savedRow.refId;
                    }
                }

                setItinerary((itinerary) => {
                    const _itinerary = new ItineraryShift({ ...itinerary }, charterModelData);
                    setUpdating(true);
                    _itinerary.replaceRow(row, idx);
                    return _itinerary;
                });
                setEditing((editing) => {
                    return { ...editing, [row.id]: !!keepEditing };
                });
                setHighlightedRouteIds([]);
            };

            // setUpdating(true)
            update().then(() => {
                console.log("Row updated.");
            });
        },
        [setItinerary, setEditing, editRouteRow, setEditRouteRow, setHighlightedRouteIds]
    );

    const onCreate = useCallback(
        (e, row, idx, keepEditing) => {
            if (row.type === ShiftBatRowType.note) {
                row.refId = "_";
            }
            onUpdate(e, row, idx, keepEditing);
        },
        [onUpdate]
    );

    // const addRow = useCallback(({idx}) => {
    //     const id = ulid();
    //     console.log('New row...', id)
    //     setItinerary(sb => {
    //         const itinerary = sb.clone();
    //         itinerary.addRow({id, idx})
    //         return itinerary
    //     })
    //     setEditing(editing => {
    //         return ({...editing, [id]: true})
    //     })
    // }, [setItinerary, setEditing])

    const deleteRow = useCallback(
        (event, row) => {
            setItineraryNow((sb) => {
                const itinerary = sb.clone();
                itinerary.removeRow(row);
                return itinerary;
            });
            setEditing((editing) => ({ ...editing, [row.id]: false }));
            setHighlightedRouteIds([]);
        },
        [setItineraryNow, setEditing, setHighlightedRouteIds]
    );

    const cancel = useCallback(
        (event, row) => {
            if (row.id === editRouteRow?.id) {
                setEditRouteRow(null);
            }
            if (row.isDefault()) {
                deleteRow(event, row);
            }

            setEditing((editing) => ({ ...editing, [row.id]: false }));
            setHighlightedRouteIds([]);
        },
        [setEditing, editRouteRow, setEditRouteRow, deleteRow, setHighlightedRouteIds]
    );
    //
    //
    // const cut = useCallback((startWpIdx, endWpIdx) => {
    //     if (startWpIdx >= endWpIdx) {
    //         return
    //     }
    //
    //     if (!window.confirm(`Are you sure you want to cut the route between the selected waypoints?`)) {
    //         return;
    //     }
    //
    //     setRoute(route => {
    //         const _route = route.clone();
    //         console.log('wps before cut:', route.waypoints.length)
    //         _route.cut(startWpIdx, endWpIdx);
    //         console.log('wps after cut:', route.waypoints.length)
    //
    //         console.log("Finish cut")
    //         const newRoute = new BusRoute(_route);
    //         console.log('wps after after cut:', newRoute.waypoints.length)
    //         // setStops([...route.stops]);
    //         return newRoute
    //     })
    // }, [setRoute]);

    useEffect(() => {
        const viewingRoutIds = Object.keys(viewing)
            .filter((rowId) => viewing[rowId])
            .map((rowId) => itinerary.rows[itinerary.rows.findIndex((row) => row.id === rowId)]?.route?.routeId)
            .filter((rId) => !!rId);
        console.log(viewingRoutIds);
        setHighlightedRouteIds(viewingRoutIds);
    }, [viewing, setZoom, setHighlightedRouteIds, itinerary]);

    // console.log('Updating view', itinerary?.rows?.filter(row => row.mappedRoute).map(row=>row.mappedRoute.waypoints[0]))

    const handleNewDeadrun = useCallback(
        (row) => {
            setEditRouteRow((editRouteRow) => {
                const newDeadrun = editRouteRow.route.clone();
                newDeadrun.routeName = "New deadrun";
                newDeadrun.routeId = "_";
                editRouteRow.routes["_"] = newDeadrun;
                return { ...editRouteRow, route: newDeadrun };
            });
        },
        [setEditRouteRow]
    );

    // function handleDragEnd(event) {
    //     if (event.over && event.over.id === 'droppable') {
    //         setIsDropped(true);
    //     }
    // }

    // useEffect(() => {
    //     console.log('Updated: ', readyToSave)
    //     console.log('Maybe saving shiftbat...')
    //     if (readyToSave && editMode) {
    //         setReadyToSave(false);
    //         console.log('Yep, saving shiftbat...')
    //         save(editMode).then(() => {
    //             console.log('shiftbat saved')
    //             messageApi.info('Shift bat saved.');
    //         })
    //     }
    // }, [readyToSave, setReadyToSave, save, editMode])

    useEffect(() => {
        setUpdating(true);
        itinerary.updateRows({ apiKey, allStops, allTransfers, allRoutes, charterModelData, deadrunModelData }).then(() => {
            setUpdating(false);
        });
    }, [itinerary, allRoutes, allStops, allTransfers, apiKey, setUpdating]);

    const createNewStop = useCallback(
        async (stop) => {
            // charters are verified when created
            stop.type = "charter";
            stop.verified = 1;
            stop = await stopModelData.save(stop);
            setAllStops((allStops) => {
                allStops[stop.stopId] = stop;
                return { ...allStops };
            });
            console.log('>>>> stop HERE', stop)
            setSelectedStop(stop);
        },
        [setSelectedStop, setAllStops]
    );

    useEffect(() => {
        const load = () => {
            const getAllDeadrunroutes = () => {
                const deadruns = [];
                const stops = [];
                itinerary.rows.forEach((row) => {
                    if (row.type === CharterRowTypeLabel.charter && row?.route && row?.route?.waypoints) {
                        deadruns.push(row.route);
                    }
                    if (row.type === "Point") {
                        stops.push(row.stop);
                    }
                });
                return [deadruns, stops];
            };
            // if(itineraryObj?.route) setActRoute(itineraryObj.route.clone())
            const [deadruns, stops] = getAllDeadrunroutes();
            if (deadruns.length === 0) {
                setActRoute(new BusRoute({}));
                return;
            }
            const _route = deadruns[0];
            for (let i = 1; i < deadruns.length; i++) {
                const _r = deadruns[i];
                _route.waypoints.push(..._r.waypoints);
                _route.stopTimes.push(..._r.stopTimes);
                _route.stops.push(..._r.stops);
                _route.endStopIdx = _r.endStopIdx;
                _route.endWpIdx = _r.endWpIdx;
                _route.distance += _r.distance;
                _route.duration += _r.duration;
            }
            _route.colour = "#ffac15";
            _route.mainStops = stops;
            setActRoute(_route);
        };

        load();
    }, [itinerary]);

    return (
        <>
            <div className="ItineraryView">
                {contextHolder}

                <Prompt when={controls.backLength > 0 || updated} message="You have unsaved changes. Are you sure you want to leave?" />

                {printItinerary ? (
                    <ItineraryPrintModal
                        allStops={allCharterStops}
                        allRoutes={allRoutes}
                        apiKey={apiKey}
                        itinerary={itinerary}
                        visible={printItinerary}
                        schedules={schedules}
                        setVisible={setPrintItinerary}
                    />
                ) : (
                    <></>
                )}
                {editMode ? (
                    <div className="d-flex align-items-center justify-content-between page-main-controls mb-0">
                        <div></div>
                        {/* <a href={'https://learn.busable.app/driver-shift-bats'} target="_blank"
                           rel="noreferrer noopener"><Button type="primary" className="icon-button btn-filled"
                                                             icon={<Info/>}>Help</Button></a> */}
                        <div className="page-main-controls top-float">
                            <div className="d-flex page-sec-controls">
                                <Button
                                    type="primary"
                                    className="icon-button btn-filled"
                                    onClick={(e) => templatesRef?.current?.openModal()}
                                >
                                    Manage Templates
                                </Button>
                                {controls.backLength > 0 || updated ? (
                                    <Popconfirm
                                        title={"You have unsaved changes. Are you sure you want to leave?"}
                                        onConfirm={(e) => {
                                            controls.go(-controls.backLength);
                                            setEditMode(false);
                                        }}
                                    >
                                        <Button type="primary" className="icon-button btn-filled btn-light icon-10" icon={<Close />}>
                                            {" "}
                                            Close
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    <Button
                                        type="primary"
                                        className="icon-button btn-filled btn-light icon-10"
                                        icon={<Close />}
                                        onClick={() => setEditMode(false)}
                                    >
                                        {" "}
                                        Close
                                    </Button>
                                )}
                                <Button
                                    type="primary"
                                    className="icon-button btn-filled"
                                    icon={<Undo />}
                                    disabled={!controls.canUndo || disableEditForRow()}
                                    onClick={() => {
                                        controls.undo();
                                    }}
                                >
                                    Undo ({controls.backLength})
                                </Button>
                                <Popconfirm
                                    title="Reset all changes"
                                    description="Reset all changes since loading this shift bat?"
                                    onConfirm={() => {
                                        setIsCancelling(true);
                                        // setUpdated(false)
                                        // if (id === "_new_") {
                                        //     shiftBatModelData
                                        //         .delete(itinerary.itineraryId, true)
                                        //         .then(() => {
                                        //             console.log("Deleted new sb.");
                                        //         })
                                        //         .catch((e) => {
                                        //             console.log(e);
                                        //         })
                                        //         .finally(() => {
                                        //             setIsCancelling(false);
                                        //             setEditMode(false);
                                        //             history.push("/shiftbats");
                                        //         });
                                        // } else if (initialShiftbatRef.current) {
                                        //     save(initialShiftbatRef.current)
                                        //         .then(() => {
                                        //             console.log("Saved original sb.");
                                        //             controls.reset(initialShiftbatRef.current);
                                        //         })
                                        //         .catch((e) => {
                                        //             console.log(e);
                                        //         })
                                        //         .finally(() => {
                                        //             setIsCancelling(false);
                                        //             setEditMode(false);
                                        //         });
                                        // } else {
                                        //     setIsCancelling(false);
                                        //     setEditMode(false);
                                        // }
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <LoaderButton
                                        isLoading={isCancelling}
                                        type="primary"
                                        icon={<Reset />}
                                        disabled={controls.backLength === 0}
                                        className="icon-button btn-filled btn-error"
                                    >
                                        Reset
                                    </LoaderButton>
                                </Popconfirm>
                                <Button
                                    type="primary"
                                    className="icon-button btn-filled"
                                    icon={<Redo />}
                                    disabled={!controls.canRedo || disableEditForRow()}
                                    onClick={() => {
                                        controls.redo();
                                    }}
                                >
                                    Redo ({controls.forwardLength})
                                </Button>
                                <Button
                                    type="primary"
                                    className="icon-button btn-filled btn-success"
                                    icon={<Check />}
                                    disabled={disableEditForRow() || controls.backLength === 0}
                                    onClick={async (e) => await save(itinerary, true)}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex align-items-center justify-content-between page-main-controls top-float">
                        <div className="d-flex page-sec-controls">
                            <Button
                                type="primary"
                                className="icon-button btn-filled"
                                onClick={(e) => templatesRef?.current?.openModal()}
                            >
                                Manage Templates
                            </Button>
                            <Button
                                type="primary"
                                className="icon-button btn-filled"
                                icon={<Print />}
                                disabled={!itinerary?.isValid({ allStops, allRoutes })}
                                onClick={async (event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setPrintItinerary(true);
                                }}
                            >
                                Print
                            </Button>
                            {/* <a href={'https://learn.busable.app/driver-shift-bats'} target="_blank"
                               rel="noreferrer noopener"><Button type="primary" className="icon-button btn-filled"
                                                                 icon={<Info/>}>Help</Button></a> */}
                            <Button
                                type="primary"
                                className="icon-button btn-filled"
                                onClick={(e) => {
                                    initialShiftbatRef.current = itinerary.clone();
                                    controls.reset(itinerary.clone());
                                    setEditMode(true);
                                }}
                                icon={<Edit />}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                )}
                {itinerary && !fetching && schedules ? (
                    <>
                        <div className="card-main shift-bat-map no-pad d-flex">
                            <div className="map-holder" style={{ width: "65%", position: "relative" }}>
                                <div className="MapPlaceholder">
                                    {
                                        editRouteRow ? (
                                            <RouteMap
                                                route={editRouteRow.route.clone()}
                                                editingRoute={true}
                                                routeOnly={true}
                                                allStops={keyBy([editRouteRow.route.startStop, editRouteRow.route.endStop], "stopId")}
                                                updateRouteFn={(route) => {
                                                    console.log("Updated route from ROuteMap");
                                                    setEditRouteRow((editRouteRow) => {
                                                        editRouteRow.route.waypoints = route.waypoints;
                                                        editRouteRow.routeEdited = true;
                                                        return { ...editRouteRow };
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <>
                                                {itinerary && (
                                                    <RouteMapHybrid
                                                        {...(actRoute ? { route: actRoute.clone(), color: actRoute.colour } : {})}
                                                        editMode={editMode}
                                                        setEditMode={setEditMode}
                                                        editingRoute={editingRoute}
                                                        setEditingRoute={setEditingRoute}
                                                        routeOnly={false}
                                                        tolerance={tolerance}
                                                        setTolerance={setTolerance}
                                                        allStops={allCharterStops}
                                                        setAllStops={setAllCharterStops}
                                                        createNewStop={createNewStop}
                                                        showAllStops={showAllStops}
                                                        setShowAllStops={setShowAllStops}
                                                        selectedStop={selectedStop}
                                                        setSelectedStop={setSelectedStop}
                                                        // zoomStop={zoomStop}
                                                        // setZoomStop={setZoomStop}
                                                        focusStop={focusStop}
                                                        setFocusStop={setFocusStop}
                                                        updateRouteFn={(route) => {
                                                            setItineraryObj({ ...itineraryObj, route });
                                                            console.log("Updated route from ROuteMap");
                                                            //   setEditRouteRow(editRouteRow => {
                                                            //       editRouteRow.route.waypoints = route.waypoints
                                                            //       editRouteRow.routeEdited = true
                                                            //       return {...editRouteRow}
                                                            //   })
                                                        }}
                                                        type="charter"
                                                    />
                                                )}
                                            </>
                                        )
                                        // <RouteMapViewer charterBuilder={true} noToolbar={true} showWpSelector={false}
                                        //                 routes={itinerary.rows.filter(row => row.route).map(row => row.route).concat(itinerary.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                        //                 trips={itinerary.rows.filter(row => row.trip).map(row => row.trip)}
                                        //                 allStops={allCharterStops}
                                        //     // locations={itinerary.rows.filter(row => [ShiftBatRowType.location, ShiftBatRowType.stop, ShiftBatRowType.service].includes(row.type) && row.getStartLocation()).map(row => row.getStartLocation())}
                                        //                 verifiedOnly={true}
                                        //                 highlightedRouteIds={highlightedRouteIds}
                                        //                 selectedStop={selectedStop}
                                        //                 focusStop={focusStop}
                                        //                 zoom={zoom}
                                        //                 setFocusStop={setFocusStop}
                                        //                 handleStopClick={(stop) => setSelectedStop(stop)}
                                        //                 filteredRoutes={itinerary.rows.filter(row => row.route).map(row => {
                                        //                     return row.route
                                        //                 }).concat(itinerary.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                        //     // handleSelectedRoute={({routeId}) => {
                                        //     //     if (routeId) {
                                        //     //         const route = allRoutes[routeId]
                                        //     //         const id = ulid();
                                        //     //         console.log('New row...', route.routeId)
                                        //     //         itinerary.addRow({id,
                                        //     //             type: ShiftBatRowType.service,
                                        //     //             title: `Depart ${route.stops[0].stopName}`,
                                        //     //             route}, itinerary.rows.length)
                                        //     //         setItinerary(itinerary.clone())
                                        //     //         setEditing(editing => ({...editing, [id]: true}))
                                        //     //
                                        //     //     }
                                        //     // }}
                                        //     //             handleSelectedStop={(stop) => {
                                        //     //                 console.log('Selected stop: ', stop)
                                        //     //                 const id = ulid();
                                        //     //                 console.log('New row...', id)
                                        //     //                 itinerary.addRow({
                                        //     //                     id,
                                        //     //                     type: ShiftBatRowType.stop,
                                        //     //                     title: stop.stopName,
                                        //     //                     stop
                                        //     //                 }, itinerary.rows.length)
                                        //     //                 setItinerary(itinerary.clone())
                                        //     //                 setEditing(editing => ({...editing, [id]: true}))
                                        //     //                 setUpdated(true)
                                        //     //             }}
                                        // />
                                    }
                                </div>
                            </div>

                            <div className="map-shiftbats" style={{ width: "35%" }}>
                                <div className="sb-info-wrap">
                                    {editMode ? (
                                        <div>
                                            <RouteTitleEdit
                                                route={{
                                                    routeNumber: itineraryNumber,
                                                    routeName: itineraryName,
                                                    routeDetails: itineraryDetails,
                                                    routeLogo: itineraryLogo,
                                                    colour: itinerary.itineraryColour,
                                                }}
                                                fileList={fileList}
                                                setFileList={setFileList}
                                                handleLogoRemove={() => {
                                                    if (disableEditForRow()) {
                                                        return;
                                                    }
                                                    setItineraryLogo("");
                                                }}
                                                editRouteNumber={(val) => {
                                                    if (disableEditForRow()) {
                                                        return;
                                                    }
                                                    setItineraryNumber(val);
                                                }}
                                                onBlurRouteNumber={() => {
                                                    setItineraryNow(
                                                        (sb) =>
                                                            new ItineraryShift({
                                                                ...sb,
                                                                itineraryNumber,
                                                            })
                                                    );
                                                }}
                                                editRouteName={(val) => {
                                                    if (disableEditForRow()) {
                                                        return;
                                                    }
                                                    setItineraryName(val);
                                                }}
                                                onBlurRouteName={() => {
                                                    setItineraryNow(
                                                        (sb) =>
                                                            new ItineraryShift({
                                                                ...sb,
                                                                itineraryName,
                                                            })
                                                    );
                                                }}
                                                editRouteDetails={(val) => {
                                                    if (disableEditForRow()) {
                                                        return;
                                                    }
                                                    setItineraryDetails(val);
                                                }}
                                                onBlurRouteDetails={() => {
                                                    setItineraryNow(
                                                        (sb) =>
                                                            new ItineraryShift({
                                                                ...sb,
                                                                itineraryDetails,
                                                            })
                                                    );
                                                }}
                                            />
                                            <Select
                                                className="w-100 mb-2"
                                                placeholder="Operating calendars"
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                }
                                                disabled={disableEditForRow()}
                                                onChange={(scheduleIds) => {
                                                    const effectiveDate = scheduleIds
                                                        .map((sId) => schedules[sId])
                                                        .filter((s) => !!s)
                                                        .reduce((p, c) => {
                                                            if (p === null) {
                                                                return c.getFirstActiveDate();
                                                            }
                                                            if (p.isBefore(c.getFirstActiveDate())) {
                                                                return p;
                                                            }
                                                            return c.getFirstActiveDate();
                                                        }, null);
                                                    const _itinerary = new ItineraryShift({
                                                        ...itinerary,
                                                        scheduleIds,
                                                        schedules,
                                                        effectiveDate,
                                                    });
                                                    setItineraryNow(_itinerary);
                                                }}
                                                value={itinerary.scheduleIds}
                                                mode="multiple"
                                                allowClear
                                                style={{
                                                    width: "100%",
                                                }}
                                                options={schedule_options(schedules)}
                                            />
                                        </div>
                                    ) : (
                                        <Row className={`RouteTitle flex-wrap align-items-center w-100 row-compact`}>
                                            <Col md="auto d-flex align-items-center">
                                                <div className={`RouteLogo`} style={{ borderColor: itinerary.itineraryColour }}>
                                                    {itinerary?.itineraryLogo?.length ? (
                                                        <Image
                                                            src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${itinerary.itineraryLogo}`}
                                                        />
                                                    ) : (
                                                        <Image
                                                            src={
                                                                "https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg"
                                                            }
                                                            className="BusIcon"
                                                        />
                                                    )}
                                                </div>
                                                <div className={`RouteNumber`} style={{ backgroundColor: itinerary.itineraryColour }}>
                                                    {itinerary.itineraryNumber}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className={`RouteInfo align-items-center pl-2`}>
                                                    <h1 style={{ color: "#000" }}>{itinerary.itineraryName}</h1>
                                                    <div className={`RouteDetails`}>{itinerary.itineraryDetails}</div>
                                                </div>
                                            </Col>

                                            <div className="d-flex flex-row simple-tags w-100" style={{ marginTop: "10px", gap: "8px" }}>
                                                {itinerary.scheduleIds.map((sId) => (
                                                    <Tag key={`sid-${sId}`}>{schedules[sId]?.scheduleName || "MISSING CALENDAR"}</Tag>
                                                ))}
                                            </div>
                                        </Row>
                                    )}

                                    <div className="d-flex info-text-wrap w-label align-items-center justify-content-between">
                                        <div className="d-flex" style={{ gap: "8px" }}>
                                            <div className="icon-info-text">
                                                <Timer />
                                                <div>
                                                    <span>Duration</span>
                                                    {toHrsMinsSecs(itinerary.getShiftTime(), false, true)}
                                                </div>
                                            </div>
                                            <div className="icon-info-text">
                                                <Services />
                                                <div>
                                                    <span>Distance</span>
                                                    {/* {toKmMs(itinerary?.getShiftDistance(), 0)} */}
                                                </div>
                                            </div>
                                            <div className="icon-info-text">
                                                <Calendar />
                                                <div>
                                                    <span>Effective Date</span>
                                                    {itinerary?.effectiveDate
                                                        ? itinerary?.effectiveDate.format("DD/MM/YYYY")
                                                        : "--/--/----"}
                                                </div>
                                            </div>

                                            {!editMode && (
                                                <div className="icon-info-text">
                                                    <div>
                                                        <span>{ROUTE_STATUS[itinerary?.published]}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {editMode ? (
                                            <div>
                                                <Select
                                                    options={ROUTE_STATUSES}
                                                    defaultValue={-1}
                                                    onChange={(v) => {
                                                        setItineraryNow(
                                                            (sb) =>
                                                                new ItineraryShift({
                                                                    ...sb,
                                                                    published: v,
                                                                    approvedAt: v === 0 ? Date.now() : sb.approvedAt,
                                                                    approvedBy: v === 0 ? getEditor() : sb.approvedBy,
                                                                    publishedAt: v === 1 ? Date.now() : sb.publishedAt,
                                                                    publishedBy: v === 1 ? getEditor() : sb.publishedBy,
                                                                })
                                                        );
                                                    }}
                                                    value={itinerary.published}
                                                />
                                                <Form.Control
                                                    className="color-picker"
                                                    style={{ width: "80px" }}
                                                    type="color"
                                                    list="presets"
                                                    value={itinerary.itineraryColour}
                                                    title="Choose shift colour"
                                                    onChange={(event) => {
                                                        const sb = new ItineraryShift({
                                                            ...itinerary,
                                                            itineraryColour: event.target.value,
                                                        });
                                                        setItinerary(sb);
                                                    }}
                                                />
                                                <datalist id="presets">
                                                    <option value="#007bff"></option>
                                                    <option value="#1b65ac"></option>
                                                    <option value="#00aeef"></option>
                                                    <option value="#5567a9"></option>
                                                    <option value="#812990"></option>
                                                    <option value="#cb0b0b"></option>
                                                    <option value="#a02125"></option>
                                                    <option value="#c90b86"></option>
                                                    <option value="#e6b00e"></option>
                                                    <option value="#f57d20"></option>
                                                    <option value="#008a5d"></option>
                                                    <option value="#00b26a"></option>
                                                    <option value="#005a23"></option>
                                                    <option value="#76a33a"></option>
                                                    <option value="#515553"></option>

                                                    <option value="#"></option>
                                                    <option value="#"></option>
                                                </datalist>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>

                                <ItineraryRows
                                    itinerary={itinerary}
                                    setEditing={setEditing}
                                    schedules={schedules}
                                    editing={editing}
                                    allRoutes={allRoutes}
                                    updating={updating}
                                    setUpdating={setUpdating}
                                    allTransfers={allTransfers}
                                    allStops={allCharterStops}
                                    onUpdate={onUpdate}
                                    onCancel={cancel}
                                    disableEditForRow={disableEditForRow}
                                    selectedStop={selectedStop}
                                    onCreate={onCreate}
                                    deleteRow={deleteRow}
                                    setSelectedStop={setSelectedStop}
                                    setFocusStop={setFocusStop}
                                    handleNewDeadrun={handleNewDeadrun}
                                    fetching={fetching}
                                    setEditRouteRow={setEditRouteRow}
                                    editMode={editMode}
                                    viewing={viewing}
                                    setViewing={setViewing}
                                    setHighlightedRouteIds={setHighlightedRouteIds}
                                    editRouteRow={editRouteRow}
                                />
                            </div>
                        </div>
                        <ItineraryTemplateManager ref={templatesRef} />
                    </>
                ) : (
                    <LoadMessage message={"Loading Shift bat"} />
                )}
            </div>
        </>
    );
}

export default React.memo(ItineraryView);
