import React, { useCallback, useEffect, useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import ItinerarySelectType from "./ItinerarySelectType";
import { Col, Row } from "react-bootstrap";
import ItineraryTimePicker from "./ItineraryTimePicker";
import { ReactComponent as Down } from "../../assets/icons/Down.svg";
import { useAppContext } from "../../libs/contextLib";
import { charterModelData } from "../../services/ModelService";

function ItineraryTemplateRowEdit({
    type,
    time,
    priority,
    description,
    title,
    onUpdate,
    updating,
    onCreate,
    hideTime,
    itineraryRow,
    setTitle,
    setTime,
    setDescription,
    setPriority,
    isLoading,
    onCancel,
    resetRow,
    template,
    setTemplate,
}) {
    const { operator } = useAppContext();
    const [records, setRecords] = useState([]);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        const load = async () => {
            charterModelData.addListener({
                setterFn: setRecords,
                loaded: (charters) => setRecords(charters),
            });
        };
        if (operator) {
            load().then(() => {
                console.log("Data loaded.");
            });
        }

        return () => {
            charterModelData.removeListener();
        };
    }, [operator]);

    useEffect(() => {
        if (!records) return;
        const recordsArray = Object.values(records);

        const templateRecords = recordsArray.filter((record) => record.type === "charter#template");
        setTemplates(templateRecords);
    }, [records]);

    const isValid = useCallback(() => {
        return !!template;
    }, [template]);

    return (
        <>
            <div>
                <Row className="row-compact">
                    <Col md="auto">{!hideTime && <ItineraryTimePicker time={time} setTime={setTime} />}</Col>
                    <Col className="row-col-gap">
                        <ItinerarySelectType type={type} resetRow={resetRow} className={"inputselect-small"} />
                        <Select
                            size="small"
                            className="inputselect-small"
                            placeholder="Select Template"
                            suffixIcon={<Down />}
                            value={template}
                            onChange={(t) => {
                                const temp = templates.find((e) => e.charterId === t);
                                setTitle(temp.message);
                                setTemplate(t);
                            }}
                        >
                            {templates.map((t) => (
                                <Select.Option key={t.id} value={t.charterId}>
                                    {t.message}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button
                                className="sb-done"
                                icon={updating && itineraryRow.needsUpdate() ? <SyncOutlined spin={true} /> : <Check />}
                                disabled={!isValid() || updating}
                                onClick={(e) =>
                                    onUpdate(e, {
                                        ...itineraryRow,
                                        type,
                                        title,
                                        description,
                                        template,
                                    })
                                }
                            />
                            <Button
                                className="sb-close"
                                icon={updating && itineraryRow.needsUpdate() ? <SyncOutlined spin={true} /> : <Close />}
                                disabled={updating}
                                onClick={(e) => onCancel(e, itineraryRow)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ItineraryTemplateRowEdit);
