import React, {useState} from "react";
import {Button, Input, Select} from "antd";
import {ReactComponent as Down} from "../../assets/icons/Down.svg";
import {ReactComponent as Check} from "../../assets/icons/Check.svg";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import {ShiftBatRowNotePriority} from "../../model/shiftBat";
import {Col, Row} from "react-bootstrap";
import "./Itinerary.css";

function ItineraryStopNoteEdit({itineraryNote, onCancel, onSave}) {

    const [title, setTitle] = useState(itineraryNote.title);
    const [priority, setPriority] = useState(itineraryNote.priority);

    return (
        <div key={itineraryNote.id} className="row d-flex align-items-center sb-row">
            <div className="col-lg-12 sb-row-edit">
                <Row className="row-compact">
                    <Col>
                        <Input placeholder="Stop note" className="inputtext-small"
                            onClick={e => e.stopPropagation()}
                            value={title || ""}
                            onChange={e => setTitle(e.target.value)}/>
                    </Col>
                    <Col md="auto">
                        <Select defaultValue="Normal" className="inputselect-small"
                                onClick={e => e.stopPropagation()}
                                suffixIcon={<Down/>} value={priority}
                                onChange={setPriority}>
                            {Object.keys(ShiftBatRowNotePriority).map(type => (
                                <Select.Option key={'SBMP-' + type}
                                            value={ShiftBatRowNotePriority[type]}>{ShiftBatRowNotePriority[type]}</Select.Option>)
                            )}
                        </Select>
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls dir-row">
                            <Button className="sb-done" icon={<Check/>}
                                    onClick={e => {
                                        e.stopPropagation()
                                        onSave(e, {...itineraryNote, title, priority})
                                    }}/>
                            <Button className="sb-close" icon={<Close/>} onClick={e => {
                                e.stopPropagation();
                                onCancel(e, itineraryNote)
                            }}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );

}

export default React.memo(ItineraryStopNoteEdit)
