import React, {useCallback, useRef, useState} from "react";
import {Select} from "antd";
import {ReactComponent as Down} from "../../assets/icons/Down.svg";
import {PlusOutlined, SyncOutlined} from "@ant-design/icons";
import {Button, Divider, Tooltip} from "antd/lib";
import {Space} from "antd/es";


function ItinerarySelectRowEdit({
                                   isLoading,
                                   addNew,
                                   newPlaceholder = 'Name...',
                                   itemName = 'item',
                                   ttPlacement = 'left',
                                   ttTitle = `Select a previously created ${itemName}, or create a new one`,
                                   filterSearchName = 'label',
                                   ...props
                               }) {
    const [title, setTitle] = useState('');
    const selectRef = useRef();

    const filterOption = useCallback((input, option) => (option[filterSearchName] ?? '').toLowerCase().includes(input.toLowerCase()), [filterSearchName])

    return (
        <Tooltip title={ttTitle} placement={ttPlacement}>
            <Select ref={selectRef} size="small" className="inputselect-small"
                    showSearch={true}
                    optionFilterProp="children"
                    filterOption={filterOption}
                    filterSort={(optionA, optionB) => {
                        return (optionA[filterSearchName] ?? '').toLowerCase().localeCompare((optionB[filterSearchName] ?? '').toLowerCase())
                    }}
                    onDisplayValuesChange={(values) => {
                        console.log(values)
                    }}
                    suffixIcon={isLoading ? <SyncOutlined spin={true}/> : <Down/>}
                    onSearch={setTitle}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            {addNew && title && !props.options.filter(opt => filterOption(title, opt)).length ? <>
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                    <div style={{
                                        width: "100%",
                                        padding: "4px 10px",
                                        border: "1px solid #D9D9D9",
                                        boxShadow: "0 0 3px 0 rgba(195,186,252,0.8)",
                                        borderColor: "#C3BAFC",
                                        borderRadius: "6px"
                                    }}
                                    >{title}</div>

                                    <Button type="text" icon={<PlusOutlined/>} disabled={!title?.length}
                                            onClick={e => addNew(e, title)}>
                                        New {itemName}
                                    </Button>
                                </Space>
                            </> : <></>}
                        </>
                    )}
                    {...props}
            />
        </Tooltip>
    );
}

export default React.memo(ItinerarySelectRowEdit)
