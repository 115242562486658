import { Column } from '@ant-design/plots';
import { Tag } from 'antd';
import React from 'react';

const data = [
    {
        day: 'Mon',
        hours: 180,
        type: 'Worked'
    },
    {
        day: 'Mon',
        hours: 60,
        type: 'Balance'
    },
    {
        day: 'Tue',
        hours: 300,
        type: 'Worked'
    },
    {
        day: 'Wed',
        hours: 260,
        type: 'Worked'
    },
    {
        day: 'Wed',
        hours: 160,
        type: 'Balance'
    },
    {
        day: 'Thu',
        hours: 160,
        type: 'Worked'
    },
    {
        day: 'Fri',
        hours: 400,
        type: 'Worked'
    },
    {
        day: 'Sat',
        hours: 200,
        type: 'Worked'
    },
    {
        day: 'Sat',
        hours: 280,
        type: 'Balance'
    },
    {
        day: 'Sun',
        hours: 220,
        type: 'Worked'
    }
];
const config = {
    data,
    isStack: true,
    xField: 'day',
    yField: 'hours',
    seriesField: 'type',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    meta: {
      day: {
        alias: 'Days',
      },
      hours: {
        alias: 'Hours',
      },
    },
};

function PlotColumn() {
    return (
        <div className="graph-wrap">
            <div className="graph-info d-flex align-items-center">
                <strong>456</strong>
                <Tag color="#D9F3D2">+ 4.0%</Tag>
            </div>
            <Column {...config} />
        </div>
    );
}

export default React.memo(PlotColumn)