import React, { useCallback, useMemo } from "react";
import "./Charters.css";
import { charterModelData } from "../../services/ModelService";
import { Charter } from "../../model/charter";
import useModel from "../../hooks/useModel";
import SaveMenu from "../../components/SaveMenu";
import LoadMessage from "../../components/LoadMessage";
import { Button, Card, Col, DatePicker, Flex, Input, InputNumber, Row, Select, TimePicker } from "antd";
import CharterMenu from "./CharterMenu";
import { startCase } from "lodash";
import dayjs from "../../dayjs";
import LoaderButton from "../../components/LoaderButton";
import { EditIcon, TrashIcon } from "../../components/Icons";
import { ReactComponent as Copy } from "../../assets/icons/Copy.svg";
import { isEmpty } from "lodash";

const { TextArea } = Input;

function CharterQuote() {
    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({ charterId: "_" }),
    });

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({ ...charter });
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    const addLine = () => {
        setCharterWith("quoteLineItems", [
            ...(charter.quoteLineItems || []),
            {
                name: null,
                description: null,
                unitPrice: null,
                quantity: null,
                discount: null,
                amount: null,
            },
        ]);
    };

    const cloneLine = (index) => {
        const clone = { ...charter.quoteLineItems[index] };
        setCharterWith("quoteLineItems", [...charter.quoteLineItems, clone]);
    };

    const deleteLine = (index) => {
        setCharterWith(
            "quoteLineItems",
            charter.quoteLineItems.filter((e, i) => i !== index)
        );
    };

    const updateLine = (index, key, value) => {
        setCharterWith(
            "quoteLineItems",
            charter.quoteLineItems.map((e, i) => {
                if (i === index) {
                    return {
                        ...e,
                        [key]: value,
                    };
                }
                return e;
            })
        );
    };

    const calculateAmount = useCallback((line) => {
        const discount = line.discount || 0;
        if (line.unitPrice && line.quantity) {
            const total = line.unitPrice * line.quantity;
            const discountedAmount = total * (discount / 100);
            const finalAmount = total - discountedAmount;
            if (finalAmount < 0) return 0;
            return finalAmount;
        }
        return null;
    }, []);

    // Memoize the calculateSubTotal function
    const calculateSubTotal = useMemo(() => {
        return (
            charter.quoteLineItems.reduce((acc, current) => {
                return acc + parseFloat(calculateAmount(current));
            }, 0) || 0
        );
    }, [charter.quoteLineItems, calculateAmount]);

    // Memoize the calculateGST function
    const calculateGST = useMemo(() => {
        const subTotal = calculateSubTotal;
        // TODO: fix with actual GST
        return subTotal * 0.1 || 0;
    }, [calculateSubTotal]);

    // Memoize the calculateTotal function
    const calculateTotal = useMemo(() => {
        const subTotal = calculateSubTotal || 0;
        const gst = calculateGST || 0;
        return (subTotal - gst).toFixed(2);
    }, [calculateSubTotal, calculateGST]);

    return (
        <div className="charter-details w-secondary-menu">
            {charter ? (
                <>
                    <SaveMenu
                        save={() => {
                            charterModelData.save(charter).then(() => {
                                console.log("Charter saved.");
                                setMode({ edit: false });
                            });
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({ edit })}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => charter.isValid()}
                        closeView={true}
                    />
                    <Row gutter={[20, 20]}>
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={"3"}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: mode.edit,
                                    quote: false,
                                }}
                                hide={{
                                    charter: false,
                                    itinerary: charter.charterId === "_",
                                    quote: charter.charterId === "_",
                                }}
                            />
                        )}
                        <Col xs={24} lg={19}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Summary</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={24} lg={6}>
                                                <label>To (Address)</label>
                                                {/* <strong>Picton High School</strong>
                                                <small>480 Argyle St, Picton NSW 2571</small>
                                                <small>(02) 4677 1242</small> */}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Line 1"
                                                        status={!charter.address1?.length ? "error" : ""}
                                                        value={charter.address1}
                                                        onChange={({ target: { value } }) => setCharterWith("address1", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.address1)}</strong>
                                                )}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Line 2"
                                                        status={!charter.address2?.length ? "error" : ""}
                                                        value={charter.address2}
                                                        onChange={({ target: { value } }) => setCharterWith("address2", value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.address2)}</small>
                                                )}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Contact"
                                                        status={!charter.addressContact?.length ? "error" : ""}
                                                        value={charter.addressContact}
                                                        onChange={({ target: { value } }) => setCharterWith("addressContact", value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.addressContact)}</small>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Quote Description</label>
                                                {/* <strong>Picton HS Swimming Carnival</strong> */}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address 1"
                                                        status={!charter.quoteDescription?.length ? "error" : ""}
                                                        value={charter.quoteDescription}
                                                        onChange={({ target: { value } }) => setCharterWith("quoteDescription", value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.quoteDescription)}</small>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={6} className="d-flex align-items-end">
                                                <img width={150} src="https://pictonbuslines.com.au/wp-content/uploads/2021/09/sm.png" />
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>Quote Date</label>
                                                {/* <strong>27/06/2024</strong> */}
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Quote Date"
                                                        {...(!isEmpty(charter.quoteDate) ? { value: dayjs(charter.quoteDate) } : {})}
                                                        status={!charter.quoteDate ? "error" : ""}
                                                        format={{
                                                            format: "DD-MM-YYYY",
                                                            type: "mask",
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith("quoteDate", value?.format("YYYY-MM-DD"));
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.quoteDate ? dayjs(charter.quoteDate).format("DD/MM/YYYY") : "TBC"}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>Payment Due</label>
                                                {/* <strong>27/08/2024</strong> */}
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Payment Due"
                                                        {...(!isEmpty(charter.paymentDue) ? { value: dayjs(charter.paymentDue) } : {})}
                                                        status={!charter.paymentDue ? "error" : ""}
                                                        format={{
                                                            format: "DD-MM-YYYY",
                                                            type: "mask",
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith("paymentDue", value?.format("YYYY-MM-DD"));
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.paymentDue ? dayjs(charter.paymentDue).format("DD/MM/YYYY") : "TBC"}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>Start Date & Time</label>
                                                <strong>
                                                    {charter.startDate} - {charter.startTime || "TBC"}
                                                </strong>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>End Date & Time</label>
                                                <strong>
                                                    {charter.endDate} - {charter.endTime || "TBC"}
                                                </strong>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>Distance (kms)</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter Distance"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                        value={charter.distance}
                                                        onChange={(value) => setCharterWith("distance", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.distance)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>Duration (hours)</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter Duration"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                        value={charter.duration}
                                                        onChange={(value) => setCharterWith("duration", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.duration)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label># Passengers</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter # Passengers"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                        value={charter.passengers}
                                                        onChange={(value) => setCharterWith("passengers", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.passengers)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <label>Ref</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Ref"
                                                        status={!charter.ref?.length ? "error" : ""}
                                                        value={charter.ref}
                                                        onChange={({ target: { value } }) => setCharterWith("ref", value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.ref)}</small>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col xs={24} lg={24}>
                                    <Card bordered={false} className="card-main">
                                        <Row className="card-row-header align-items-center">
                                            <Col xs={6} className="d-flex align-items-center">
                                                <h3>Charter Quote</h3>
                                                {mode.edit && (
                                                    <Button className="icon-button btn-sm" type="primary" onClick={addLine}>
                                                        Add
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col xs={5} className="text-left">
                                                <span>Description</span>
                                            </Col>
                                            <Col xs={3}>
                                                <span>Unit price</span>
                                            </Col>
                                            <Col xs={2}>
                                                <span>Qty</span>
                                            </Col>
                                            <Col xs={2}>
                                                <span>Disc(%)</span>
                                            </Col>
                                            <Col xs={3} className="text-right">
                                                <span>Amount</span>
                                            </Col>
                                            {mode.edit && <Col xs={3}>
                                                <span>Actions</span>
                                            </Col>}
                                        </Row>
                                        {charter.quoteLineItems?.map((line, index) => (
                                            <Row className="card-row-content border-full align-items-center" style={{ cursor: "pointer" }}>
                                                <Col xs={6}>
                                                    {mode.edit ? (
                                                        <Input
                                                            placeholder="Name"
                                                            status={!line.name?.length ? "error" : ""}
                                                            value={line.name}
                                                            onChange={({ target: { value } }) => updateLine(index, "name", value)}
                                                        />
                                                    ) : (
                                                        <strong>{startCase(line.name)}</strong>
                                                    )}
                                                </Col>
                                                <Col xs={5} className="text-left">
                                                    {mode.edit ? (
                                                        <Input
                                                            placeholder="Description"
                                                            value={line.description}
                                                            onChange={({ target: { value } }) => updateLine(index, "description", value)}
                                                        />
                                                    ) : (
                                                        <span>{startCase(line.description)}</span>
                                                    )}
                                                </Col>
                                                <Col xs={3}>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Unit Price"
                                                            min={0}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                            value={line.unitPrice}
                                                            onChange={(value) => updateLine(index, "unitPrice", value)}
                                                        />
                                                    ) : (
                                                        <span>${line.unitPrice}</span>
                                                    )}
                                                </Col>
                                                <Col xs={2}>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Quantity"
                                                            min={0}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                            value={line.quantity}
                                                            onChange={(value) => updateLine(index, "quantity", value)}
                                                        />
                                                    ) : (
                                                        <span>{line.quantity}</span>
                                                    )}
                                                </Col>
                                                <Col xs={2}>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Discount"
                                                            max={100}
                                                            min={0}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                            value={line.discount}
                                                            onChange={(value) => updateLine(index, "discount", value)}
                                                        />
                                                    ) : (
                                                        <span>{line.discount}</span>
                                                    )}
                                                </Col>
                                                <Col xs={3} className="text-right">
                                                    ${calculateAmount(line)}
                                                </Col>
                                                {mode.edit && (
                                                    <Col xs={3} className="row-col-actions">
                                                        <LoaderButton className="btn-icon-control" onClick={() => cloneLine(index)}>
                                                            <Copy style={{ width: 14 }} />
                                                        </LoaderButton>
                                                        <LoaderButton
                                                            className="btn-icon-control btn-delete"
                                                            onClick={() => deleteLine(index)}
                                                        >
                                                            <TrashIcon />
                                                        </LoaderButton>
                                                    </Col>
                                                )}
                                            </Row>
                                        ))}
                                        <Row className="card-row-content border-full align-items-center">
                                            <Col xs={18}>
                                                <strong>Subtotal</strong>
                                            </Col>
                                            <Col xs={3} className="text-right">
                                                <strong style={{ fontSize: "15px" }}>${calculateSubTotal}</strong>
                                            </Col>
                                            <Col xs={3}></Col>
                                        </Row>
                                        <Row className="card-row-content border-full align-items-center no-border">
                                            <Col xs={18}>
                                                <strong>Plus GST</strong>
                                            </Col>
                                            <Col xs={3} className="text-right">
                                                <strong style={{ fontSize: "15px" }}>${calculateGST}</strong>
                                            </Col>
                                            <Col xs={3}></Col>
                                        </Row>
                                        <Row className="card-row-content border-full align-items-center">
                                            <Col xs={18}>
                                                <strong style={{ fontSize: "18px" }}>Total</strong>
                                            </Col>
                                            <Col xs={3} className="text-right">
                                                <strong style={{ fontSize: "18px" }}>${calculateTotal}</strong>
                                            </Col>
                                            <Col xs={3}></Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..." />
            )}
        </div>
    );
}

export default React.memo(CharterQuote);
