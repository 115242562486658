import React, { useCallback, useEffect, useState } from "react";
import "./Charters.css";
import { charterModelData, customerModelData } from "../../services/ModelService";
import { Charter, Itinerary, ItineraryShift } from "../../model/charter";
import useModel from "../../hooks/useModel";
import SaveMenu from "../../components/SaveMenu";
import LoadMessage from "../../components/LoadMessage";
import { Card, Col, DatePicker, Flex, Input, InputNumber, Row, Select } from "antd";
import CharterMenu from "./CharterMenu";
import { startCase } from "lodash";
import dayjs from "../../dayjs";
import { isEmpty } from "lodash";
import { dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs } from "../../model/timeFilter";
import TimeField from "react-simple-timefield";
import { useAppContext } from "../../libs/contextLib";
import { values } from "lodash";
import { FilterTags } from "../../containers/FilterTag";
import { filterItems } from "./Charters";

const { TextArea } = Input;

function CharterDetails() {
    const { operator } = useAppContext();
    const [customers, setCustomers] = useState([]);

    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({ charterId: "_" }),
    });

    useEffect(() => {
        let customerListener;
        const load = async () => {
            customerListener = await customerModelData.addListener({
                setterFn: (allEmployees) => setCustomers(values(allEmployees)),
                loaded: (allEmployees) => setCustomers(values(allEmployees)),
            });
        };

        if (operator) {
            load().then(() => {
                console.log("Charter details loaded.");
            });

            return () => {
                customerModelData.removeListener(customerListener);
            };
        }
    }, [operator]);

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({ ...charter });
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    const saveCharter = useCallback(() => {
        let savingObject = [charter];

        const isNewCharter = charter.charterId === "_";
        if (isNewCharter) {
            const itinerary = new ItineraryShift();
            charter.itineraryId = itinerary.charterId;
            savingObject = [charter, itinerary];
        }

        charterModelData.save(savingObject).then(async (res) => {
            console.log("Charter saved.");
            setMode({ edit: false });
        });
    }, [charter, setMode]);

    useEffect(() => {
        const calculateDriverHours = () => {

            // Create Date objects from the date strings
            const start = new Date(charter.startDate);
            const end = new Date(charter.endDate);

            // Add the seconds since midnight to the start and end times
            start.setSeconds(charter.startTime);
            end.setSeconds(charter.endTime);

            // Calculate the difference in milliseconds
            const diffInMilliseconds = end - start;

            // Convert the difference to hours
            const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

            setCharterWith("driverHours", diffInHours.toFixed(2))
        }
        if(charter.startDate && charter.startTime && charter.endDate && charter.endTime) {
            calculateDriverHours()
        }
    }, [charter.startDate, charter.startTime, charter.endDate, charter.endTime, setCharterWith])

    return (
        <div className="charter-details w-secondary-menu">
            {charter ? (
                <>
                    <SaveMenu
                        save={saveCharter}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({ edit })}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => charter.isValid()}
                        closeView={true}
                    />
                    <Row gutter={[20, 20]}>
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={"1"}
                                setCharter={setCharter}
                                disabled={{
                                    charter: false,
                                    itinerary: mode.edit,
                                    quote: mode.edit,
                                }}
                                hide={{
                                    charter: false,
                                    itinerary: charter.charterId === "_",
                                    quote: charter.charterId === "_",
                                }}
                            />
                        )}
                        <Col xs={24} lg={19}>
                            <Row gutter={[20, 20]}>
                                <Col xs={12}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Details</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={24} lg={24}>
                                                <label>Charter Name</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Charter Name"
                                                        status={!charter.name?.length ? "error" : ""}
                                                        value={charter.name}
                                                        onChange={({ target: { value } }) => setCharterWith("name", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.name)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Customer</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.customer}
                                                        // status={!charter.customer ? "error" : ""}
                                                        options={customers.map((customer) => ({
                                                            label: customer.name,
                                                            value: customer.customerId,
                                                        }))}
                                                        onChange={(value) => setCharterWith("customer", value)}
                                                        placeholder="Select Customer"
                                                    />
                                                ) : (
                                                    <strong>{customers.find((c) => c.customerId === charter.customer)?.name}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Charter Type</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.charterType}
                                                        // status={!charter.charterType ? "error" : ""}
                                                        options={[{ value: "School" }, { value: "Business" }, { value: "Private" }]}
                                                        defaultValue={'Private'}
                                                        onChange={(value) => setCharterWith("charterType", value)}
                                                    />
                                                ) : (
                                                    <strong>{charter.charterType}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Pay Type</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.payType}
                                                        // status={!charter.payType ? "error" : ""}
                                                        options={[
                                                            { value: "Rate of the day" },
                                                            { value: "Monthly retainer" },
                                                            { value: "Fixed cost" },
                                                        ]}
                                                        onChange={(value) => setCharterWith("payType", value)}
                                                    />
                                                ) : (
                                                    <strong>{charter.payType}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Vehicle Type</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.vehicleType}
                                                        // status={!charter.vehicleType ? "error" : ""}
                                                        options={[{ value: "Rosa - Bus" }, { value: "Coach - Bus" }, { value: "Van" }]}
                                                        onChange={(value) => setCharterWith("vehicleType", value)}
                                                    />
                                                ) : (
                                                    <strong>{charter.vehicleType}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={24}>
                                                <label>Additional Information</label>
                                                {mode.edit ? (
                                                    <TextArea
                                                        placeholder="Enter Additional Information"
                                                        value={charter.note}
                                                        onChange={({ target: { value } }) => setCharterWith("note", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.note)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Status</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.status}
                                                        status={!charter.status ? "error" : ""}
                                                        options={[
                                                            { label: "New", value: "new" },
                                                            { label: "Allocated", value: "allocated" },
                                                            { label: "Scheduled", value: "scheduled" },
                                                            { label: "Quoted", value: "quoted" },
                                                            { label: "Completed", value: "completed" },
                                                            { label: "Cancelled", value: "cancelled" },
                                                        ]}
                                                        onChange={(value) => setCharterWith("status", value)}
                                                        defaultValue={'new'}
                                                    />
                                                ) : (
                                                    <strong>
                                                        <FilterTags filterItems={filterItems} model={charter} />
                                                    </strong>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={12}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Date & Time</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={24} lg={12}>
                                                <label>Start Date</label>
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Start Date"
                                                        {...(!isEmpty(charter.startDate) ? { value: dayjs(charter.startDate) } : {})}
                                                        status={!charter.startDate ? "error" : ""}
                                                        format={{
                                                            format: "DD-MM-YYYY",
                                                            type: "mask",
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith("startDate", value?.format("YYYY-MM-DD"));
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.startDate ? dayjs(charter.startDate).format("DD/MM/YYYY") : "TBC"}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Start time</label>
                                                {mode.edit ? (
                                                    <TimeField
                                                        className="inputtext-small sb-time-edit"
                                                        value={
                                                            Number.isFinite(charter.startTime) && charter.startTime > 0
                                                                ? secsSinceMidnightToDayjs(charter.startTime).format("HH:mm")
                                                                : ""
                                                        }
                                                        status={!charter.startTime ? "error" : ""}
                                                        onChange={(e, value) => {
                                                            value = dayjs(value, "HH:mm");
                                                            setCharterWith("startTime", dayjsToSecsSinceMidnight(value));
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.startTime
                                                            ? secsSinceMidnightToDayjs(charter.startTime).format("HH:mm")
                                                            : "TBC"}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>End Date</label>
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="End Date"
                                                        {...(!isEmpty(charter.endDate) ? { value: dayjs(charter.endDate) } : {})}
                                                        {...(!isEmpty(charter.startDate) ? { minDate: dayjs(charter.startDate) } : {})}
                                                        status={!charter.endDate ? "error" : ""}
                                                        format={{
                                                            format: "DD-MM-YYYY",
                                                            type: "mask",
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith("endDate", value?.format("YYYY-MM-DD"));
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>{charter.endDate ? dayjs(charter.endDate).format("DD/MM/YYYY") : "TBC"}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>End time</label>
                                                {mode.edit ? (
                                                    <TimeField
                                                        className="inputtext-small sb-time-edit"
                                                        value={
                                                            Number.isFinite(charter.endTime) && charter.endTime > 0
                                                                ? secsSinceMidnightToDayjs(charter.endTime).format("HH:mm")
                                                                : ""
                                                        }
                                                        status={!charter.endTime ? "error" : ""}
                                                        onChange={(e, value) => {
                                                            value = dayjs(value, "HH:mm");
                                                            setCharterWith("endTime", dayjsToSecsSinceMidnight(value));
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.endTime
                                                            ? secsSinceMidnightToDayjs(charter.endTime).format("HH:mm")
                                                            : "TBC"}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Recurring</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.recurring}
                                                        // status={!charter.recurring ? "error" : ""}
                                                        options={[{ value: "Yes" }, { value: "No" }]}
                                                        onChange={(value) => setCharterWith("recurring", value)}
                                                        defaultValue="No"
                                                    />
                                                ) : (
                                                    <strong>{charter.recurring}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Driver Hours</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter Driver hours"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                                        // status={!charter.driverHours ? "error" : ""}
                                                        value={charter.driverHours}
                                                        onChange={(value) => setCharterWith("driverHours", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.driverHours)}</strong>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..." />
            )}
        </div>
    );
}

export default React.memo(CharterDetails);
