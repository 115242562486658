import {ulid} from "ulid";
import ItineraryRowPrint from "./ItineraryRowPrint";
import {ShiftBatRowType} from "../../model/shiftBat";
import React, {useEffect, useState} from "react";
import {charterModelData} from "../../services/ModelService";

function ItineraryRowsPrint({
                          itinerary,
                          setEditing,
                          editing,
                          printMode,
                          updating, setUpdating,
                          onUpdate,
                          deleteRow,
                          setHighlightedRouteIds,
                          editMode, viewing, setViewing,
                          setEditRouteRow, disableEditForRow,
                          selectedStop,
                          setSelectedStop, setFocusStop
                      }) {

    const [draftItinerary, setDraftItinerary] = useState(null)
    useEffect(() => {
        if (!itinerary) {
            return
        }
        setDraftItinerary(itinerary);
        // setDraftItinerary(itinerary.clone()); //Removed clone as it was causing Deadruns to not show until another update
    }, [itinerary, setDraftItinerary])

    return (
        <>
            <div className={`sb-items-wrap`}>
                {draftItinerary && draftItinerary.rows.map((row, idx) => {
                    return editMode && editing[row.id] ?
                        <></> :
                        <ItineraryRowPrint key={'SBR-' + row.id} itineraryRow={row} editMode={editMode} printMode={printMode}
                                     updating={updating} setUpdating={setUpdating} setSelectedStop={setSelectedStop}
                                     selectedStop={selectedStop} editing={editing} disableEdit={disableEditForRow()}
                                     viewing={viewing} setViewing={setViewing} setEditRouteRow={setEditRouteRow}
                                     setFocusStop={setFocusStop} setHighlightedRouteIds={setHighlightedRouteIds}
                                     editRow={(e, row) => {
                                         if (row.type === ShiftBatRowType.charter) {
                                             setEditRouteRow(row);
                                         }
                                         setEditing(editing => ({
                                             ...editing,
                                             [row.id]: true
                                         }));
                                     }}
                                     deleteRow={deleteRow}
                                     addRow={async () => {
                                         const id = ulid();
                                         console.log('New row...', id)
                                         const newRow = draftItinerary.addRow({id}, idx + 1)
                                         if (newRow.updateRow) {
                                             await newRow.updateRow({charterModelData});
                                         }
                                         // setDraftItinerary(draftItinerary.clone());
                                         setEditing(editing => ({...editing, [id]: true}))
                                     }} onUpdate={(e, row) => onUpdate(e, row, idx)
                        }/>

                })}
            </div>
        </>
    )

}

export default React.memo(ItineraryRowsPrint)
