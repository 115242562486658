import { Button } from "antd";
import { ulid } from "ulid";
import ItineraryRowEdit from "./ItineraryRowEdit";
import ItineraryRow from "./ItineraryRow";
import { ShiftBatRowType } from "../../model/shiftBat";
import React, { useCallback, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import ItineraryRowPrint from "./ItineraryRowPrint";
import ItineraryRowPrintSm from "./ItineraryRowPrintSm";
import { charterModelData } from "../../services/ModelService";

function ItineraryRows({
    itinerary,
    setEditing,
    editing,
    allRoutes,
    schedules,
    printLayout,
    printMode,
    updating,
    setUpdating,
    allStops,
    onUpdate,
    onCreate,
    onCancel,
    deleteRow,
    handleNewDeadrun,
    fetching,
    setHighlightedRouteIds,
    editMode,
    viewing,
    setViewing,
    setEditRouteRow,
    disableEditForRow,
    selectedStop,
    setSelectedStop,
    setFocusStop,
}) {
    const [draftItinerary, setDraftItinerary] = useState(null);
    useEffect(() => {
        if (!itinerary) {
            return;
        }
        setDraftItinerary(itinerary);
        // setDraftItinerary(itinerary.clone()); //Removed clone as it was causing Deadruns to not show until another update
    }, [itinerary, setDraftItinerary]);

    const addRow = useCallback(
        async (draftItinerary, idx = null, row = { time: -1, type: ShiftBatRowType.noteTimed }) => {
            const id = ulid();
            const newRow = draftItinerary.addRow({ id, ...row }, idx);
            if (newRow.updateRow) {
                await newRow.updateRow({ charterModelData });
            }
            setDraftItinerary({ ...draftItinerary });
            setEditing((editing) => ({ ...editing, [id]: true }));
        },
        [setEditing, setDraftItinerary]
    );

    return (
        <>
            {editMode && !itinerary?.rows?.length && (
                <div className="sb-first-row">
                    <Button
                        className="icon-button sb-add-btn"
                        disabled={disableEditForRow()}
                        onClick={async () => await addRow(draftItinerary)}
                    >
                        <PlusOutlined /> New row
                    </Button>
                </div>
            )}
            <div className={`sb-items-wrap ${editMode ? "edit-mode" : ""}`}>
                {editMode && (
                    <div className="add-initial-row">
                        <Button className="sb-add-row" icon={<Plus />} onClick={async () => await addRow(draftItinerary)} />
                    </div>
                )}
                {draftItinerary &&
                    draftItinerary.rows.map((row, idx) => {
                        return editMode && editing[row.id] ? (
                            <ItineraryRowEdit
                                key={"SBRE-" + row.id}
                                itineraryRow={row}
                                allRoutes={allRoutes}
                                editing={editing}
                                schedules={schedules}
                                updating={updating}
                                setUpdating={setUpdating}
                                setEditRouteRow={setEditRouteRow}
                                allStops={allStops}
                                onUpdate={(e, row, keepEditing) => onUpdate(e, row, idx, keepEditing)}
                                onCreate={(e, row, keepEditing) => onCreate(e, row, idx, keepEditing)}
                                onCancel={onCancel}
                                isLoading={fetching}
                                handleNewDeadrun={handleNewDeadrun}
                            />
                        ) : printLayout === "ltwo" ? (
                            <ItineraryRowPrintSm
                                key={"SBR-" + row.id}
                                itineraryRow={row}
                                editMode={false}
                                viewing={viewing}
                                printMode={printMode}
                            />
                        ) : printMode?.length ? (
                            <ItineraryRowPrint
                                key={"SBR-" + row.id}
                                itineraryRow={row}
                                editMode={false}
                                viewing={viewing}
                                printMode={printMode}
                            />
                        ) : (
                            <ItineraryRow
                                key={"SBR-" + row.id}
                                allStops={allStops}
                                allRoutes={allRoutes}
                                itineraryRow={row}
                                editMode={editMode}
                                printMode={printMode}
                                updating={updating}
                                setUpdating={setUpdating}
                                setSelectedStop={setSelectedStop}
                                selectedStop={selectedStop}
                                editing={editing}
                                disableEdit={disableEditForRow()}
                                viewing={viewing}
                                setViewing={setViewing}
                                setEditRouteRow={setEditRouteRow}
                                setFocusStop={setFocusStop}
                                setHighlightedRouteIds={setHighlightedRouteIds}
                                editRow={(e, row) => {
                                    if (row.type === ShiftBatRowType.charter) {
                                        setEditRouteRow(row);
                                    }
                                    setEditing((editing) => ({
                                        ...editing,
                                        [row.id]: true,
                                    }));
                                }}
                                deleteRow={deleteRow}
                                addRow={async () => addRow(draftItinerary, idx + 1)}
                                onUpdate={(e, row) => onUpdate(e, row, idx)}
                            />
                        );
                    })}
            </div>
        </>
    );
}

export default React.memo(ItineraryRows);
