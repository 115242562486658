import {
    getArrivalTimeAsSecondsSinceMidnight,
    getDepartureTime,
    getDepartureTimeAsDayjs,
    getDepartureTimeAsSecondsSinceMidnight,
    getDistanceInMetres,
    getNearestSegment,
    hasLogo,
    publicStopFilter,
    SECONDS_IN_DAY,
    timingPtFilter,
    updateRouteDeltas
} from "../libs/routes-lib";
import {toHrsMinsSecs, toKmMs} from "../libs/formatLib";
import {ensureWpAtStop} from "../libs/mapLib";
// eslint-disable-next-line
import util from "util";
import {cloneDeep, find, findIndex, findLastIndex, last, pullAllBy} from "lodash";
import dayjs from "../dayjs"
import {keyBy} from "lodash/collection";
import {ulid} from "ulid";
import {getCachedPath} from "../libs/pathLib";
import {flatten, uniqBy} from "lodash/array";

import log from 'loglevel';
import {values} from "lodash/object";
import {TimeFilterType} from "./timeFilter";
import simplify from "simplify-js";

const logger = log.getLogger('BusRoute');


const _addStopTimeFn = (stop, secs) => {
    stop.arriveSecs += secs
    stop.departSecs += secs
}

export const STOP_TYPES = {
    depot: 'Depot',
    nonpub: 'Non public',
    school: 'School',
    bus: 'Bus stop'
}

export const ROUTE_STATUSES = [
    {label: 'Draft', value: -1},
    {label: 'Approved', value: 0},
    {label: 'Published', value: 1}
]

export const ROUTE_STATUS = {[-1]: 'Draft', 0: 'Approved', 1: 'Published'}

export class Model {
    update(data) {
        if (data) {
            Object.assign(this, data)
        }
    }

    clone() {
        return {...this}
    }
}

const DWELL_TIME = 30;

const METRES_PER_SEC = 120 / 3.6;

const WP_OPTIMISE_RATE = 0.00001;

export class BusRoute extends Model {
    constructor(data) {
        super()
        this.routeId = ulid()
        this.scheduledAt = null // Epoch. Will be set when the schedule is updated
        this.publishedAt = null // Epoch. Will be set when the route is published to TfNSW
        this.author = null
        this.routeNumber = null
        this.routeName = null
        this.routeType = null // Regular, School
        this.driverShift = null
        this.vehicleShift = null
        this.waypoints = []
        this.stops = [] // List of stops this route services
        this.stopTimes = [] // Base list of stopTimes for the route
        this.schedule = null //
        // this.trips = {}; // {scheduleId: [Trip]}
        this.services = []; // {tripId, scheduleIds: []}
        this.startWpIdx = 0;
        this.endWpIdx = 0;
        this.startStopIdx = -1;
        this.endStopIdx = -1;
        this.colour = null;
        this.published = -1;
        Object.assign(this, data)
        // if (data.stops && data.stops.length) {
        //     // logger.log(data.stops.map(stop => stop))
        //     this.stops = data.stops.map(stop => new Stop(stop))
        //     // this.getPublicStops()[0].timingPoint = true
        //     this.stops[0].departHour = this.stops[0].departHour || 0
        //     this.stops[0].departMin = this.stops[0].departMin || 0
        //     this.stops.forEach(setDelta);
        //     // this.stops = stopTimeSorter(this.stops);
        // }

        this.services = this.services?.map(trip => new Trip(trip)) || [];

        // if (this.stopTimes.some((st, idx) => idx > 0 && st.delta === 0)) {
        //     const anyTrip = this.getAnyService();
        //     this.stopTimes = anyTrip?.stopTimes ? anyTrip.stopTimes.map(st => ({...st})) : [];
        // }

        if (this.stopTimes && this.stopTimes.length) {
            // logger.debug(data.stopTimes.map(stop => stop))
            // this.stopTimes.forEach(setDelta);
            // this.stopTimes = stopTimeSorter(this.stopTimes);
        } else if (this.stops) {
            this.stopTimes = cloneDeep(this.stops);
        }

        this.tripCount = this.services.reduce((prev, trip) => {
            return prev + (trip.scheduleIds?.length || 1);
        }, 0)

        if (data.waypoints) {
            this.waypoints = data.waypoints.map(wp => new WayPoint(wp)).filter(wp => wp.isValid());
            this.endWpIdx = data.waypoints.length - 1;
        }
        // this.calculateStartEnd({});
        // this.updateStopPoints();
        // this.calculateDistances();

        // if (!data.originalWaypoints) {
        //     this.originalWaypoints = data.waypoints;
        // }
        // if (!data.originalStops) {
        //     this.originalStops = data.stops;
        // }
        if (data.scheduleId && !data.schedule) {
            delete data.scheduleId;
        }

        this.noTrips = !this.hasServices()

        // BUS-607, bad data... seconds since epoch was saved, instead of ms
        if (dayjs(this.createdAt).year() < 2000) {
            this.createdAt *= 1000;
        }
        if (dayjs(this.updatedAt).year() < 2000) {
            this.updatedAt *= 1000;
        }
    }

    getEditor() {
        return this.lastEditor?.length ? this.lastEditor : this.author?.length ? this.author : 'Unknown'
    }

    setSchedules(allSchedules) {
        this.services.forEach(trip => {
            trip.schedules = trip.scheduleIds.map(sId => allSchedules[sId]).filter(s => !!s);
        })
    }

    clone(field, value) {
        const newRoute = new BusRoute(cloneDeep(this))
        if (field?.length) {
            newRoute[field] = value
        }
        return newRoute;
    }

    static create({routeId = "_", routeType = 'Regular', author = ''}) {
        return new BusRoute({
            routeId,
            routeNumber: '',
            routeName: '',
            routeDetails: '',
            waypoints: [],
            stopTimes: [],
            colour: '#007bff',
            routeType,
            author,
            createdAt: Date.now(),
            direction: 'Inbound',
            services: []
        });
    }

    addWayPoint(wp, idx) {
        let previousWP = this.waypoints[idx - 1]
        wp.distance = (previousWP?.distance || 0) + wp.distance
        wp.delta = (previousWP?.delta || 0) + wp.time
        this.waypoints.forEach((wp, i) => {
            if (i >= idx) {
                this.waypoints[i].delta += wp.delta
                this.waypoints[i].distance += wp.distance
            }
        })
        const waypoint = new WayPoint(wp)
        this.waypoints.splice(idx, 0, waypoint);
        return waypoint;
    }

    addPath(path, idx = this.waypoints.length - 1) {
        let previousWP = this.waypoints[idx]
        logger.debug(idx)
        logger.debug(this.waypoints.map(wp => wp.delta));
        logger.debug('PREVWP: ', previousWP)
        this.waypoints.forEach((wp, i) => {
            if (i > idx) {
                this.waypoints[i].delta += path.duration
                this.waypoints[i].distance += path.distance
            }
        })
        // const waypoint = new WayPoint(wp)
        path.waypoints.map(wp => {
            const waypoint = new WayPoint(wp)
            if (previousWP) {
                waypoint.distance += previousWP.distance
                waypoint.delta += previousWP.delta
            }
            return waypoint
        }).forEach((wp, i) => this.waypoints.splice(idx + 1 + i, 0, wp));
    }

    getAvgSpeed(idx = this.waypoints.length - 1) {
        const lastWp = this.waypoints[idx]
        return lastWp?.delta > 0 ? (lastWp.distance / lastWp.delta) : 14; // default 50 km/hr
    }

    addFirstStop(stop) {
        const baseStop = {
            ...stop,
            stopTimeId: ulid(),
            arriveSecs: 0,
            departSecs: 0
        }
        // if (stop.master) {
        //     delete baseStop.master
        //     baseStop.stopId = stop.stopId+'-m'
        //     baseStop.verified = 1
        // }
        this.addStopAtIndex(baseStop, 0);
        this.waypoints = [{lat: stop.lat, lon: stop.lon, delta: 0, distance: 0}]
        // this.calculateStartEnd({stops: this.stops})
        return true;
    }

    addFirstPt(pt) {
        this.waypoints = [{...pt, delta: 0, distance: 0}]
        return true;
    }

    async insertWaypointsBetweenStops(stop1, stop2) {
        logger.debug('Looking up waypoints between %s and %s', stop1.stopName, stop2.stopName)
        const path = await getCachedPath('drive', stop1, stop2)
        return path.waypoints.map(this.addWayPoint.bind(this));
    }

    async appendPath(stop, startIdx = null, endIdx = null) {
        logger.debug('Appending path')

        // If no stops yet, add this as first stop
        if (!this.waypoints?.length && !this.stops?.length) {
            return this.addFirstPt(stop);
        }
        logger.debug('wps before append:', this.waypoints.length)
        logger.debug('WP deltas', this.waypoints.map(wp => wp.delta))

        // if (Number.isFinite(startIdx) && Number.isFinite(endIdx) && endIdx > startIdx) {
        //     this.cut(startIdx, endIdx)
        // }

        startIdx = startIdx > -1 ? startIdx : this.waypoints?.length ? this.waypoints.length - 1 : last(this.stops).wpIdx

        let path, startPt = this.waypoints[startIdx]
        try {
            // If second stop, there will be no waypoints yet.
            path = await getCachedPath('bus', startPt, stop)
        } catch (e) {
            console.log(e, e);
        }

        if (this.waypoints.length) {
            // We can remove the first as it is the same as the last waypoint.
            path.waypoints.splice(0, 1);
        }
        logger.debug(path);

        // Add waypoints to the end
        this.addPath(path, startIdx)
        this.calculateDistances()
        return true;
    }

    async prependPath(stop) {
        logger.debug('Prepending path')

        // If no stops yet, add this as first stop
        if (!this.waypoints?.length && !this.stops?.length) {
            return this.addFirstPt(stop);
        }
        logger.debug('wps before append:', this.waypoints.length)
        logger.debug('WP deltas', this.waypoints.map(wp => wp.delta))

        let path, endPt = this.waypoints?.length ? this.waypoints[0] : this.stops[0];
        try {
            // If second stop, there will be no waypoints yet.
            path = await getCachedPath('bus', stop, endPt)
        } catch (e) {
            console.log(e, e);
        }

        if (this.waypoints.length) {
            // We can remove the first as it is the same as the last waypoint.
            path.waypoints.splice(-1, 1);
        }
        logger.debug(path);

        // Add waypoints to the end
        this.addPath(path, -1)
        this.calculateDistances()
        return true;
    }

    async insertPath(point, dragIdx, startWpIdx, endWpIdx = -1) {
        try {
            // If no stops yet, add this as first stop
            if (!this.waypoints?.length && !this.stops?.length) {
                return this.addFirstPt(point);
            }
            logger.debug('wps before append:', this.waypoints.length)
            logger.debug('WP deltas', this.waypoints.map(wp => wp.delta))

            // If second stop, there will be no waypoints yet.
            const startPath = await getCachedPath('bus', this.waypoints[startWpIdx], point)
            // We can remove the first as it is the same as the last waypoint.
            // startPath.waypoints.splice(0, 1);
            logger.debug(startPath);

            // Add waypoints to the start
            if (endWpIdx > -1) {
                // If second stop, there will be no waypoints yet.
                const endPath = await getCachedPath('bus', point, this.waypoints[endWpIdx])
                // We can remove the first as it is the same as the last waypoint.
                // endPath.waypoints.splice(0, 1);
                logger.debug(endPath);

                this.cut(startWpIdx + 1, endWpIdx - 1);
                this.addPath(startPath, startWpIdx)
// this.cut(startWpIdx + startPath.waypoints.length, endWpIdx);

                // Add waypoints to the end
                this.addPath(endPath, startWpIdx + startPath.waypoints.length);
            }
            this.calculateDistances()
            return true;
        } catch (err) {
            logger.debug(err);
        }
    }

    async replacePath(path, startWpIdx, endWpIdx = -1) {
        try {
            if (!this.waypoints?.length || !this.stops?.length) {
                return
            }

            logger.debug('wps before replace:', this.waypoints.length)

            if (endWpIdx > startWpIdx) {
                this.cut(startWpIdx + 1, endWpIdx, false);
            }
            this.addPath(path, startWpIdx)
            logger.debug('wps after replace:', this.waypoints.length)

            // Add waypoints to the end
            this.calculateDistances()
            return true;
        } catch (err) {
            logger.debug(err);
        }
    }

    async appendStop(stop, startIdx) {
        logger.debug('Appending stop')

        // If no stops yet, add this as first stop
        if (!this.waypoints?.length && !this.stops?.length) {
            return this.addFirstStop(stop);
        }

        if (this.stops?.length && stop.stopId === last(this.stops).stopId) {
            logger.warn('Trying to append to the same stop. Ignoring.')
            return true
        }

        logger.debug('wps before append:', this.waypoints.length);
        logger.debug('WP deltas', this.waypoints.map(wp => wp.delta))

        let startPt = startIdx ? this.waypoints[startIdx] : this.waypoints.length ? last(this.waypoints) : last(this.stops)
        // If second stop, there will be no waypoints yet.
        let path = await getCachedPath('bus', startPt, stop)

        if (this.waypoints.length) {
            // We can remove the first as it is the same as the last waypoint.
            path.waypoints.splice(0, 1);
        }
        logger.debug(path);

        // Add waypoints to the end
        this.addPath(path)

        logger.debug('wps after append:', this.waypoints.length)

        logger.debug('WP deltas', this.waypoints.map(wp => wp.delta))

        const seconds = last(this.stops).departSecs + DWELL_TIME + path.duration // Add 30 seconds for stopping time
        const baseStop = {
            ...stop,
            stopTimeId: ulid(),
            arriveSecs: seconds,
            departSecs: seconds
        }

        // if (stop.master) {
        //     delete baseStop.master
        //     baseStop.stopId = stop.stopId+'-m'
        //     baseStop.verified = 1
        // }

        this.stops.push(baseStop);
        const {stopTimeId, stopId, arriveSecs, departSecs} = baseStop;
        const stopTime = {stopTimeId, stopId, arriveSecs, departSecs};
        this.stopTimes.push(stopTime);

        this.services.forEach(trip => {
            trip.stopTimes.push({...stopTime})
        })

        // Ensure wp at stop
        this.calculateStartEnd({lastStop: baseStop, stops: this.stops, ensureStop: false})
        this.calculateDistances()
        return true
    }

    async prependStop(stop) {
        logger.debug('Prepending stop')

        // If no stops yet, add this as first stop
        if (!this.waypoints?.length && !this.stops?.length) {
            return this.addFirstStop(stop);
        }

        if (stop.stopId === this.stops[0].stopId) {
            logger.debug('Trying to prepend from the same stop. Ignoring.')
            return true
        }

        // Get the waypoints between lastStop and stop amd insert
        const path = await getCachedPath('bus', stop, this.waypoints[0])
        path.waypoints.splice(-1, 1);

        // Add waypoints from ths start
        this.addPath(path, -1);
        // path.waypoints.forEach((wp, idx) => this.addWayPoint(wp, idx))

        // const insertedWaypoints = await this.insertWaypointsBetweenStops(stop, nextStop);
        let pathDuration = Math.round(path.duration);

        const baseStop = {
            ...stop,
            stopTimeId: ulid(),
            arriveSecs: this.stops[0].arriveSecs - pathDuration,
            departSecs: this.stops[0].departSecs - pathDuration
        }

        // if (stop.master) {
        //     delete baseStop.master
        //     baseStop.stopId = stop.stopId+'-m'
        //     baseStop.verified = 1
        // }

        this.stops.unshift(baseStop)
        const {stopTimeId, stopId} = baseStop;
        const stopTime = {
            stopTimeId, stopId, arriveSecs: this.stopTimes[0].arriveSecs - pathDuration,
            departSecs: this.stopTimes[0].departSecs - pathDuration
        };
        this.stopTimes.unshift(stopTime)

        this.services.forEach(trip => {
            const newSt = {...stopTime};
            newSt.arriveSecs = trip.stopTimes[0].arriveSecs - pathDuration
            newSt.departSecs = trip.stopTimes[0].departSecs - pathDuration
            trip.stopTimes.unshift(newSt)
        })
        // this.addStopAtIndex(baseStop, 0)

        // Ensure wp at stop
        this.calculateStartEnd({firstStop: baseStop, stops: this.stops, ensureStop: false})
        this.calculateDistances()
        return true;
    }

    async insertStopBetweenPoints(stop, startWpIdx, endWpIdx) {
        logger.debug('Inserting stop between points, ', startWpIdx, endWpIdx)
        this.calculateStartEnd({stops: this.stops})

        // Get the waypoints between prevStop and stop amd insert
        logger.debug('Looking up waypoints between wpIdx: %d and %s', startWpIdx, stop.stopName)
        let startPath = await getCachedPath('drive', this.waypoints[startWpIdx], stop)

        startPath.waypoints.splice(0, 1);
        // let newStartDelta = Math.round(startPath.duration / 60) * 60;

        logger.debug('Looking up waypoints between %s and wpIdx: %d', stop.stopName, endWpIdx)
        let endPath = await getCachedPath('drive', stop, this.waypoints[endWpIdx])

        endPath.waypoints.splice(-1, 1);
        // let newEndDelta = Math.round(startPath.duration / 60) * 60;
        //
        // let newDelta = newStartDelta + newEndDelta;
        //
        // newDelta = Math.round(newDelta.duration / 60) * 60;
        //
        // const additionalDelta = newDelta - (this.waypoints[startWpIdx-1].delta - this.waypoints[endWpIdx-1].delta)

        this.cut(startWpIdx, endWpIdx)

        // Add waypoints from ths start
        this.addPath(startPath, startWpIdx)
        // Add waypoints from ths start
        this.addPath(endPath, endWpIdx + startPath.waypoints?.length);

        updateRouteDeltas(0, this);

        await this.addStop(stop, 'add')

        // Object.keys(this.trips).forEach(scheduleId => this.trips[scheduleId].forEach(trip => {
        //     trip.stopTimes.forEach(setDelta)
        // }));
        // this.stops.forEach(setDelta)
        // this.stopTimes.forEach(setDelta)
        //
        // logger.debug(this.waypoints.map(wp => wp.delta));
        // const stopDelta = this.waypoints[startWpIdx].delta + startPath.duration + 30 // Add 30 seconds for stopping time
        // logger.debug('wps: %d, prevStop.wpIdx: %d, prevWp delta: %d, start duration: %d, Stop Delta: ', this.waypoints.length, startWpIdx ,this.waypoints[startWpIdx].delta, startPath.duration, stopDelta)
        // const deltaDayjs = dayjs().startOf('d').add(stopDelta, 's')
        // const baseStop = {
        //     ...stop,
        //     stopTimeId: ulid(),
        //     stopId: stop.stopId,
        //     delta: stopDelta,
        //     departHour: deltaDayjs.hour(),
        //     departMin: deltaDayjs.minute()
        // }
        //
        // const stopIdx = this.stops.findIndex(s => s.stopTimeId === nextStop.stopTimeId)
        // this.stops.splice(stopIdx, 0, baseStop)
        // const {stopTimeId, stopId, delta, departHour, departMin} = baseStop;
        // const stopTime = {stopTimeId, stopId, delta, departHour, departMin};
        // this.stopTimes.splice(stopIdx, 0, stopTime)
        //
        // Object.keys(this.trips).forEach(scheduleId => this.trips[scheduleId].forEach(trip => {
        //     const newSt = {...stopTime};
        //     delete newSt.departHour
        //     delete newSt.departMin
        //     trip.stopTimes.splice(stopIdx, 0, newSt)
        //     logger.debug('New stop delta: ', newSt.delta)
        //     const departAsDayjs = getDepartureTimeAsDayjs(trip, newSt)
        //     newSt.departHour = departAsDayjs.hour();
        //     newSt.departMin = departAsDayjs.minute();
        // }))

        // Ensure wp at stop
        this.calculateStartEnd({stops: this.stops, ensureStop: false})
        this.calculateDistances()
        return true;
    }

    async insertStopBetweenStops(stop, prevStop, nextStop) {
        logger.debug('Inserting stop')
        this.waypoints.splice(prevStop.wpIdx + 1, nextStop.wpIdx - prevStop.wpIdx)
        this.calculateStartEnd({stops: this.stops})

        const prevStopIdx = this.stops.findIndex(s => s.stopTimeId === prevStop.stopTimeId)

        // Get the waypoints between prevStop and stop amd insert
        logger.debug('Looking up waypoints between %s and %s', prevStop.stopName, stop.stopName)
        let startPath = await getCachedPath('drive', prevStop, stop)

        startPath.waypoints.splice(0, 1);
        let newDelta = Math.round(startPath.duration);
        // Add waypoints from ths start
        this.addPath(startPath, prevStop.wpIdx + 1)

        logger.debug('Looking up waypoints between %s and %s', stop.stopName, nextStop.stopName)
        let endPath = await getCachedPath('drive', stop, nextStop)

        endPath.waypoints.splice(-1, 1);

        newDelta += Math.round(endPath.duration);

        const additionalSecs = newDelta - (nextStop.arriveSecs - prevStop.departSecs)

        // Add waypoints from ths start
        this.addPath(endPath, prevStop.wpIdx + 1 + startPath.waypoints.length);

        this.services.forEach(trip => {
            trip.stopTimes.forEach((st, idx) => idx > prevStopIdx && _addStopTimeFn(st, additionalSecs))
        });
        this.stops.forEach((s, idx) => idx > prevStopIdx && _addStopTimeFn(s, additionalSecs))
        this.stopTimes.forEach((s, idx) => idx > prevStopIdx && _addStopTimeFn(s, additionalSecs))

        logger.debug(this.waypoints.map(wp => wp.delta));
        const seconds = this.waypoints[prevStop.wpIdx - 1].delta + startPath.duration + DWELL_TIME // Add 30 seconds for stopping time
        logger.debug('wps: %d, prevStop.wpIdx: %d, prevWp delta: %d, start duration: %d, Stop Delta: ', this.waypoints.length, prevStop.wpIdx, this.waypoints[prevStop.wpIdx].delta, startPath.duration, seconds)
        const baseStop = {
            ...stop,
            stopTimeId: ulid(),
            arriveSecs: seconds,
            departSecs: seconds,
        }

        // if (stop.master) {
        //     delete baseStop.master
        //     baseStop.stopId = stop.stopId+'-m'
        //     baseStop.verified = 1
        // }

        const stopIdx = this.stops.findIndex(s => s.stopTimeId === nextStop.stopTimeId)
        this.stops.splice(stopIdx, 0, baseStop)
        const {stopTimeId, stopId, arriveSecs, departSecs} = baseStop;
        const stopTime = {stopTimeId, stopId, arriveSecs, departSecs};
        this.stopTimes.splice(stopIdx, 0, stopTime)

        this.services.forEach(trip => {
            trip.stopTimes.splice(stopIdx, 0, {...stopTime})
        })

        // Ensure wp at stop
        this.calculateStartEnd({stops: this.stops, ensureStop: false})
        this.calculateDistances()
        return true;
    }

    async insertStop(stop) {
        logger.debug('Inserting stop...')
        this.calculateStartEnd({stops: this.stops})

        logger.debug('Getting nearest segment...')
        const segment = getNearestSegment(stop, this.waypoints, {maxDistFrom: 2000})?.segment;
        logger.debug('Segment: ', segment);
        if (segment) {
            let prevStop = this.getStopBeforeWpIndex(segment.startIdx, this.stops);
            let nextStop = this.getStopAfterWpIndex(segment.startIdx, this.stops);
            if (prevStop && nextStop) {
                return await this.insertStopBetweenStops(stop, prevStop, nextStop);
            }
        }
    }

    async addStop(stop, method) {
        logger.debug('Adding stop...', method)

        // If no stops yet, add this as first stop
        if (!this.waypoints?.length && !this.stops?.length) {
            return this.addFirstStop(stop);
        }

        this.calculateStartEnd({stops: this.stops})

        if (method === 'append') {
            return await this.appendStop(stop);
        }
        if (method === 'prepend') {
            return await this.prependStop(stop);
        }

        logger.debug('Getting nearest segment...')
        const segment = getNearestSegment(stop, this.waypoints, {maxDistFrom: 2000})?.segment;
        logger.debug('Segment: ', segment);
        if (segment) {
            let prevStop = this.getStopBeforeWpIndex(segment.startIdx, this.stops);
            let nextStop = this.getStopAfterWpIndex(segment.startIdx, this.stops);

            if (!method || method === 'add' || segment.dist < 100) {

                let timeToCoverDistance = 0
                if (!prevStop) {
                    // New start of route...
                    stop.arriveSecs = 0;
                    stop.departSecs = 0;
                    stop.distance = 0;
                } else {

                    let speed = 14;
                    if (this.stops?.length > 1) {
                        speed = this.getAvgSpdBetweenStops(this.getStartStop(this.stops), this.getEndStop(this.stops))
                    }
                    if (prevStop && nextStop) {
                        speed = this.getAvgSpdBetweenStops(prevStop, nextStop) || speed;
                    }
                    let distance = this.getDistanceBetweenWpIdxs(prevStop.wpIdx, segment.startIdx) + segment.distToStart;
                    timeToCoverDistance = Math.round(distance / speed)
                    stop.distance = prevStop.distance + distance
                }
                const stopTimeId = ulid();

                const stopTime = {
                    stopTimeId,
                    stopId: stop.stopId,
                }
                this.stops = this.stops || [];
                let idx = 0;
                if (this.stops.length) {
                    idx = this.findStopIndex(stop.distance);
                }
                if (idx > -1) {
                    this.addStopAtIndex({...stop, ...stopTime}, idx, timeToCoverDistance);
                } else {
                    this.stops.push({
                        ...stop, arriveSecs: prevStop.departSecs + timeToCoverDistance,
                        departSecs: prevStop.departSecs + timeToCoverDistance, ...stopTime
                    });
                    this.stopTimes.push({
                        ...stopTime, arriveSecs: prevStop.departSecs + timeToCoverDistance,
                        departSecs: prevStop.departSecs + timeToCoverDistance
                    });
                    this.services.forEach(trip => trip.stopTimes.push({
                        ...stopTime, arriveSecs: last(trip.stopTimes).departSecs + timeToCoverDistance,
                        departSecs: last(trip.stopTimes).departSecs + timeToCoverDistance
                    }))
                }
                if (prevStop) {
                    ensureWpAtStop(stop, this, prevStop.wpIdx);
                }
                this.calculateDistances()
                return true;
            }

            logger.debug('Distance to route is more than 20m... Going to calculate new route...')
            if (prevStop && nextStop && method === 'insert') {
                return await this.insertStopBetweenStops(stop, prevStop, nextStop);
            }
        } else {
            if (!this.stops?.length) {
                logger.debug('No segment can be found. Assuming first stop.')
                return this.addFirstStop(stop);
            }
            // return await this.appendStop(stop);
        }
    }

    removeStop(stop) {

        logger.debug('Removing stop:', stop.stopName);
        pullAllBy(this.stops, [stop], 'stopTimeId');
        pullAllBy(this.stopTimes, [stop], 'stopTimeId');
        this.services.forEach(trip => pullAllBy(trip.stopTimes, [stop], 'stopTimeId'));
        logger.debug('Removed stop. Remaining: ', this.stops)
    }

    addStopAtIndex(stop, idx, timeToCoverDistance = 0) {
        logger.debug('Adding stop:', stop.stopName, 'at', idx);
        const {stopTimeId, stopId} = stop;
        const stopTime = {stopTimeId, stopId};
        this.stops = this.stops || []
        this.stopTimes = this.stopTimes || []
        if (idx === 0) {
            this.stops.splice(idx, 0, {...stop, arriveSecs: 0, departSecs: 0});
            this.stopTimes.splice(idx, 0, {...stopTime, arriveSecs: 0, departSecs: 0});
            this.services = this.services || []
            this.services.forEach(trip => trip.stopTimes.splice(idx, 0, {...stopTime, arriveSecs: 0, departSecs: 0}))
            return
        }
        this.stops.splice(idx, 0, {
            ...stop, arriveSecs: this.stops[idx - 1].departSecs + timeToCoverDistance,
            departSecs: this.stops[idx - 1].departSecs + timeToCoverDistance
        });
        this.stopTimes.splice(idx, 0, {
            ...stopTime, arriveSecs: this.stopTimes[idx - 1].departSecs + timeToCoverDistance,
            departSecs: this.stopTimes[idx - 1].departSecs + timeToCoverDistance
        });
        this.services = this.services || []
        this.services.forEach(trip => trip.stopTimes.splice(idx, 0, {
            ...stopTime, arriveSecs: trip.stopTimes[idx - 1].departSecs + timeToCoverDistance,
            departSecs: trip.stopTimes[idx - 1].departSecs + timeToCoverDistance
        }))
    }

    setBaseStops(stopsById, stopTimes = this.stopTimes, opts = {
        timingPointsOnly: false,
        excludeLinkedStops: false,
        excludeCalculateStartEnd: false,
        // excludeTransfers: false,
        removeStops: true,
        operator: null
    }) {

        // logger.debug('Setting base stops for route %s: ', this.routeNumber, stopTimes.map(st => `${st.delta}`));
        this.stopTimes = stopTimes;
        const _stops = []
        stopTimes.forEach((stopTime, idx) => {
            const stop = stopsById[stopTime.stopId];
            if (stop) {
                const baseStop = new BaseStop({...stop, ...stopTime, routeId: this.routeId});
                if (baseStop.master) {
                    baseStop.verified = 1
                    baseStop.stopId = ulid();
                    delete baseStop.master
                }
                if (!opts.excludeLinkedStops) {
                    baseStop.setLinkedStops(stopsById, true);
                }
                _stops.push(baseStop);
            } else {
                // Removing the following code as it could cause destructive changes to the routes in an event like a network issue.
                // if (opts.removeStops) {
                //     this.services.forEach(trip => trip.stopTimes.splice(idx, 1));
                // }
                console.log('No stop with ID: %s @ idx: %d for route: %s', stopTime.stopId, idx, this.routeNumber)
                // Object.keys(this.trips).forEach(scheduleId => this.trips[scheduleId] = this.trips[scheduleId].stopTimes.splice(idx, 1));
            }
        });
        this.stops = _stops;
        // if (this.stops.length) {
        //     this.stops[0].arriveSecs = this.stops[0].arriveSecs || 0
        //     this.stops[0].departSecs = this.stops[0].departSecs || 0
        //     this.stops.forEach(stop => {
        //         stop.departTime = getDepartureTimeAsDayjs(this, stop)
        //         stop.arriveSecs = stop.departTime.hour();
        //         stop.departMin = stop.departTime.minute();
        //         stop.departSec = stop.departTime.second();
        //     })
        //
        // }

        if (this.services.length) {
            this.services.forEach(trip => {
                const _stops = []
                trip.stopTimes.forEach(stopTime => {
                    const stop = stopsById[stopTime.stopId];
                    if (stop) {
                        const baseStop = new BaseStop({...stop, ...stopTime});
                        if (baseStop.master) {
                            baseStop.verified = 1
                            baseStop.stopId = ulid()
                            delete baseStop.master
                        }
                        if (!opts.excludeLinkedStops) {
                            baseStop.setLinkedStops(stopsById, true);
                        }
                        (!opts.timingPointsOnly || baseStop.timingPoint) && _stops.push(baseStop);
                        // if (!opts.excludeTransfers) {
                        //     const txFromRoute = find(stop.routes, r=> r.routeId === this.routeId)
                        //     const txFromTrip = txFromRoute ? txFromRoute.passingTimes[trip.tripId] : null
                        //     const txs = find(txFromTrip.pts, pt => pt.stopTimeId === stopTime.stopTimeId)
                        //     if(txs?.length) {
                        //         stopTime.transfersTo = txs.map(tx => ({
                        //             routeId: tx.toRouteId,
                        //             routeNumber: tx.toRouteNumber,
                        //             colour: tx.toRouteColour
                        //         }))
                        //     }
                        // }
                    }
                })
                trip.stops = _stops
            })
        }
        if (!opts.excludeCalculateStartEnd) {
            this.calculateStartEnd({firstStop: _stops[0], lastStop: last(_stops)});
        }
        this.calculateDistances();
        // logger.debug('Base stops: ', _stops.map(st => `${st.departHour}:${st.departMin}`));
        return _stops;
    }

    setTransfers(transfers, routes = {}, validOnly) {
        transfers = (Array.isArray(transfers) ? transfers : values(transfers)).filter(t => !validOnly || !t.invalid);
        this.services.forEach(trip => {
            trip.transfersTo = transfers.filter(tx => tx.fromTripId === trip.tripId).filter(tx => !!tx)
            trip.transfersFrom = transfers.filter(tx => tx.toTripId === trip.tripId).filter(tx => !!tx)
            trip.stopTimes.forEach(st => {
                st.transfersTo = trip.transfersTo
                    .filter(tx => tx.fromStopId === st.stopId && tx.fromStopTimeId === st.stopTimeId)
                    .map(tx => routes?.[tx.toRouteId] ? {
                        routeId: routes[tx.toRouteId].routeId,
                        routeNumber: routes[tx.toRouteId].routeNumber,
                        colour: routes[tx.toRouteId].colour
                    } : {
                        routeId: tx.toRouteId,
                        routeNumber: tx.toRouteNumber,
                        colour: tx.toRouteColour,
                        ...tx
                    })
                    .filter(tx => !!tx)
                st.transfersFrom = trip.transfersFrom
                    .filter(tx => tx.toStopId === st.stopId && tx.toStopTimeId === st.stopTimeId)
                    .map(tx => routes?.[tx.fromRouteId] ? {
                        routeId: routes[tx.fromRouteId].routeId,
                        routeNumber: routes[tx.fromRouteId].routeNumber,
                        colour: routes[tx.fromRouteId].colour
                    } : {
                        routeId: tx.fromRouteId,
                        routeNumber: tx.fromRouteNumber,
                        colour: tx.fromRouteColour,
                        ...tx
                    })
                    .filter(tx => !!tx)
            })
        })
    }

    /**
     *
     * @param stop Complete stop expected ({...stop, ...stopTime})
     * @param sequence index of stop using base 1
     */
    setStopSequence(stop, sequence) {
        const idx = sequence - 1;
        this.removeStop(stop);
        this.addStopAtIndex(stop, idx);
    }

    addTrip() {
        this.services = this.services || [];
        let trip;
        if (this.services.length) {
            const copyOfLastTrip = cloneDeep(last(this.services));
            delete copyOfLastTrip.transfersFrom;
            delete copyOfLastTrip.transfersTo;
            trip = new Trip({
                ...copyOfLastTrip,
                tripId: null,
            })
            if (trip.stopTimes?.length) {
                trip.stopTimes.forEach(st => {
                    delete st.transfersFrom;
                    delete st.transfersTo;
                })
                trip.setStartTime(trip.stopTimes[0].arriveSecs + 3600)
            }
        } else {
            trip = new Trip({stopTimes: cloneDeep(this.stopTimes), routeId: this.routeId});
        }
        this.services.push(trip);
        return trip;
    }

    removeTrip(tripId) {
        const tripIdx = this.services.findIndex(trip => trip.tripId === tripId)
        const trip = this.services[tripIdx]
        if (!trip) {
            return;
        }
        // trip.scheduleIds.splice(trip.scheduleIds.findIndex(sId => sId === scheduleId), 1)
        // if (!trip.scheduleIds.length) {
        this.services.splice(tripIdx, 1);
        // }
    }

    includesSchedule(scheduleId) {
        return this.getTripCountForSchedule(scheduleId) > 0
    }

    getTripCountForSchedule(scheduleId) {
        return this.getTripsForSchedule(scheduleId).length
    }

    getTripsForSchedule(scheduleId) {
        return this.services.reduce((prev, trip) => {
            if (trip.scheduleIds?.includes(scheduleId)) {
                prev.push(trip);
            }
            return prev
        }, [])
    }

    hasServices() {
        return this.services && !!this.getAnyService();
    }

    getAnyService() {
        return this.services[0]
    }

    isObsolete(schedulesById, date = dayjs()) {
        return !this.hasServices() || this.services.every(trip => trip.scheduleIds.every(sId => !schedulesById[sId] || schedulesById[sId].isObsolete(date)))
    }

    isFuture(schedulesById, date = dayjs()) {
        return this.hasServices() && this.services.every(trip => trip.scheduleIds.every(sId => schedulesById[sId]?.isFuture(date)))
    }

    someObsolete(schedulesById, date = dayjs()) {
        return !this.hasServices() || this.services.some(trip => trip.hasObsoleteCalendar(schedulesById))
    }

    findStopIndex(distance) {
        if (this.stops && this.stops.length) {
            return this.stops.findIndex(stop => {
                return stop.distance > distance
            });
        }
        return -1;
    }

    calculateDistances() {
        this.waypoints.forEach((wp, idx) => {
            if (idx === 0) {
                wp.distance = 0;
                wp.delta = 0;
                return;
            }
            const prevWp = this.waypoints[idx - 1];
            const dist = getDistanceInMetres(prevWp, wp)
            wp.distance = prevWp.distance + dist;
        });
        if (this.stops) {
            this.stops.forEach((stop, idx) => {
                if (stop.nearestSegment && stop.nearestSegment.startIdx > -1) {
                    const wp = this.waypoints[stop.nearestSegment.startIdx];
                    stop.distance = wp?.distance + stop.nearestSegment.distToStart;
                }
            });
        }

        updateRouteDeltas(0, this);

        const lastWp = last(this.waypoints);
        this.distance = lastWp?.distance;
        this.duration = lastWp?.delta

    }

    calculateStartEnd({
                          firstStop = this.firstStop,
                          lastStop = this.lastStop,
                          checkDirection = false,
                          shortestRoute = false,
                          ensureStop = true,
                          stops = this.getPublicStops()
                      }) {
        const log = false//this.routeNumber === '572'
        log && logger.debug('Stop count for %s calculating start/end: %d ', this.routeNumber, stops.length)
        log && logger.debug('WP count for %s calculating start/end: %d ', this.routeNumber, this.waypoints.length)
        if (!this.stops?.length) {
            log && logger.debug(' we have no stops.')
            return
        }


        if (!firstStop || !lastStop) {
            log && logger.debug('Using first/last public stops.')
            firstStop = firstStop || stops[0];
            lastStop = lastStop || stops[stops.length - 1];
            log && logger.debug('1st stop', firstStop.stopName, 'last stop', lastStop.stopName);
        }

        this.firstStop = firstStop
        this.lastStop = lastStop
        let time = Date.now()

        if (firstStop && lastStop) {
            log && logger.debug(this.routeNumber, 'checking start/stop idx', firstStop.stopName, 'to', lastStop.stopName, 'shortest route', shortestRoute)

            let startStopIdx = firstStop.sequence && firstStop.stopTimeId ? stops.findIndex(s => s.stopTimeId === firstStop.stopTimeId) : -1,
                endStopIdx = lastStop.sequence && lastStop.stopTimeId ? stops.findIndex(s => s.stopTimeId === lastStop.stopTimeId) : -1;
            if (startStopIdx < 0 || endStopIdx < 0) {
                let startStopIdxs = stops.reduce((prev, stop, idx) => {
                    prev = prev || [];
                    if (stop.stopId === firstStop.stopId) {
                        prev.push(idx)
                    }
                    return prev
                }, [])
                let endStopIdxs = stops.reduce((prev, stop, idx) => {
                    prev = prev || [];
                    if (stop.stopId === lastStop.stopId) {
                        prev.push(idx)
                    }
                    return prev
                }, [])

                log && logger.debug('startStopIdxs', startStopIdxs)
                log && logger.debug('endStopIdxs', endStopIdxs)

                if (shortestRoute) {
                    for (let i = startStopIdxs.length - 1; i >= 0; i--) {
                        for (let j = 0; j < endStopIdxs.length; j++) {
                            if (startStopIdxs[i] < endStopIdxs[j]) {
                                startStopIdx = startStopIdxs[i];
                                endStopIdx = endStopIdxs[j];
                                break;
                            }
                        }
                        if (startStopIdx > -1 && endStopIdx > -1) {
                            break;
                        }
                    }
                } else {
                    for (let j = 0; j < startStopIdxs.length; j++) {
                        for (let i = endStopIdxs.length - 1; i >= 0; i--) {
                            if (startStopIdxs[j] < endStopIdxs[i]) {
                                startStopIdx = startStopIdxs[j];
                                endStopIdx = endStopIdxs[i];
                                break;
                            }
                        }
                        if (startStopIdx > -1 && endStopIdx > -1) {
                            break;
                        }
                    }
                }

            }
            log && logger.debug('start stop idx', firstStop.stopName, ":", startStopIdx);
            log && logger.debug('end stop idx', lastStop.stopName, ':', endStopIdx)
            if (startStopIdx > -1 && endStopIdx > -1) {
                this.startStopIdx = startStopIdx;
                this.endStopIdx = endStopIdx;
            }
        }

        if (this.stopTimes.length > 50) {
            console.log(`Took ${Date.now() - time}ms to start/stopIdx for route ${this.routeNumber}`)
        }

        if (ensureStop) {
            const time = Date.now()
            // logger.debug(this.routeNumber, 'waypoints length before', this.waypoints.length);
            log && logger.debug('wp count before', this.waypoints.length)
            let prevWpIdxForStop = 0
            stops.forEach((stop, idx) => {
                const nearestSeg = ensureWpAtStop(stop, this, checkDirection, prevWpIdxForStop);
                if (nearestSeg) {
                    prevWpIdxForStop = nearestSeg.segment.nearestWpIdx;
                }
            })
            log && logger.debug('wp count after', this.waypoints.length)

            if (this.stopTimes.length > 50) {
                console.log(`Took ${Date.now() - time}ms to ensureWp for route ${this.routeNumber}`)
            }
        }
        // logger.debug(this.routeNumber, 'waypoints length after', this.waypoints.length);
        let prevWpIdxForStop = 0
        time = Date.now()
        stops.forEach((stop, idx) => {
            const stopLog = log && false//stop.stopName.indexOf('Spears Dr at Bren') === 0 || stop.stopName.indexOf('Dubbo Square') === 0

            // if(stop.stopName.indexOf('45 Mit') === -1) return
            log && stopLog && logger.debug('Checking stop: ', stop.stopName, 'after wpIdx', prevWpIdxForStop);
            const nearestSeg = getNearestSegment(stop, this.waypoints, {
                fromWpIdx: prevWpIdxForStop,
                log: stopLog,
                furthestSeg: idx === (stops.length - 1)
            });
            if (!nearestSeg) {
                return;
            }

            let wpIdxForStop = nearestSeg.segment.nearestWpIdx;
            stop.wpIdx = wpIdxForStop;
            stop.nearestSegment = nearestSeg.segment
            log && stopLog && logger.debug('Stop WP IDX: ', stop.stopName, stop.stopTimeId, stop.wpIdx, nearestSeg.segment)
            prevWpIdxForStop = wpIdxForStop;
        })
        if (this.stopTimes.length > 50) {
            console.log(`Took ${Date.now() - time}ms to nearest segments in stops for route ${this.routeNumber}`)
        }
        let firstPublicStop = stops[this.startStopIdx];
        let lastPublicStop = stops[this.endStopIdx];
        if (firstPublicStop && lastPublicStop) {
            // const nearestStartSeg = ensureWpAtStop(firstPublicStop, this, checkDirection);
            log && logger.debug(firstPublicStop)
            // this.startWpIdx = nearestStartSeg.segment.endIdx;
            this.startWpIdx = firstPublicStop.wpIdx;

            // const nearestLastSeg = ensureWpAtStop(lastPublicStop, this, checkDirection, this.startWpIdx);
            log && logger.debug(lastPublicStop)
            // this.endWpIdx = nearestLastSeg.segment.endIdx;
            this.endWpIdx = lastPublicStop.wpIdx;
        }

        this.services.forEach(trip => {
            trip.startStopIdx = this.startStopIdx
            trip.endStopIdx = this.endStopIdx
        })

        log && logger.debug('startIdx:', this.startWpIdx, 'ednIdx:', this.endWpIdx, 'startStopIdx:', this.startStopIdx, 'endStopIdx:', this.endStopIdx, ' waypoint count: ', this.waypoints.length)

    }

    getStartEnd(firstStop, lastStop, checkDirection, fromWp) {
        if (firstStop && lastStop) {
            const nearestStartSeg = ensureWpAtStop(firstStop, this, checkDirection, fromWp);
            let startWpIdx = nearestStartSeg.segment.endIdx;
            let nearestLastSeg = ensureWpAtStop(lastStop, this, checkDirection, fromWp);
            let endWpIdx = nearestLastSeg.segment.endIdx;
            while (startWpIdx > endWpIdx) {
                logger.debug('Start wp after end! Trying again from idx: ', startWpIdx, endWpIdx);
                nearestLastSeg = ensureWpAtStop(lastStop, this, checkDirection, startWpIdx);
                endWpIdx = nearestLastSeg.segment.endIdx;
            }
            // Loop
            if (startWpIdx === endWpIdx) {
                startWpIdx = 0;
                endWpIdx = this.waypoints.length - 1;
            }
            return {startWpIdx, endWpIdx};
        }

        return {startWpIdx: 0, endWpIdx: this.waypoints.length}

    }

    getPublicStop(potentialStop, fromIndex = 0, reverse = false) {
        if (potentialStop.stopId) {
            return this.getPublicStops()[this.getPublicStopIndex(potentialStop, fromIndex, reverse)];
        }
    }

    getActiveWaypoints() {
        let startIdx = this.startWpIdx >= 0 ? this.startWpIdx : 0;
        let endIdx = this.endWpIdx ? this.endWpIdx + 1 : this.waypoints.length;
        return this.waypoints.slice(startIdx, endIdx);
    }

    getStartStopSequence() {
        return this.startStopIdx + 1;
    }

    getEndStopSequence() {
        return this.endStopIdx + 1;
    }

    getStartStop(stops = null) {
        stops = stops || this.getPublicStops()
        return stops[this.startStopIdx > -1 ? this.startStopIdx : 0];
    }

    getEndStop(stops = null) {
        stops = stops || this.getPublicStops()
        return stops[this.endStopIdx > -1 && this.endStopIdx < stops.length ? this.endStopIdx : stops.length - 1];
    }

    getStopsBetween(trip) {
        const stopsById = keyBy(this.stops, "stopId");
        return trip.stopTimes.filter(st => !!stopsById[st.stopId]).map(st => ({...stopsById[st.stopId], ...st}))
            .filter(publicStopFilter)
            .slice(this.startStopIdx + 1, this.endStopIdx)
            .map((stop, idx) => {
                stop.sequence = this.getStartStopSequence() + 1 + idx
                return stop
            })
    }


    getStartDepartureTime() {
        return getDepartureTime(this, this.getStartStop());
    }

    getEndArrivalTime() {
        return getDepartureTime(this, this.getEndStop());
    }

    getStartDepartureTimeAsSecondsSinceMidnight() {
        return getDepartureTimeAsSecondsSinceMidnight(this, this.getStartStop())
    }

    getSecondsTilNextDeparture() {
        const secondsSinceMidnight = dayjs().startOf('day').diff(dayjs(), 's');
        const departureSecondsSinceMidnight = this.getStartDepartureTimeAsSecondsSinceMidnight();
        if (secondsSinceMidnight < departureSecondsSinceMidnight) {
            return departureSecondsSinceMidnight - secondsSinceMidnight
        }
        return departureSecondsSinceMidnight + SECONDS_IN_DAY - secondsSinceMidnight
    }

    getNextTripBeforeDate(date = dayjs(), school, schedules) {
        return find(this.services.reverse(), trip => {
            if (trip.scheduleIds.some(sId => schedules[sId].isActive(date, !school))) {
                const arrivalTime = getDepartureTimeAsDayjs(trip, trip.getStopTime(this.getEndStop()), null, date);
                return arrivalTime.isSameOrBefore(date, 'd');
            }
            return false
        })
    }

    getNextTripAfterDate(date = dayjs(), school, schedules) {
        return find(this.services, trip => {
            if (trip.scheduleIds.some(sId => schedules[sId].isActive(date, !school))) {
                const departureTime = getDepartureTimeAsDayjs(trip, trip.getStopTime(this.getStartStop()), null, date)
                return departureTime.isSameOrAfter(date, 'd');
            }
            return false
        })
    }

    getStopCount() {
        return this.endStopIdx - this.startStopIdx;
    }

    getDuration() {
        // This has a flaw in that it relies on the stopTimes field being up to date with deltas... so may not be accurate.
        return getDepartureTimeAsDayjs(this, this.getEndStop()).diff(getDepartureTimeAsDayjs(this, this.getStartStop()), 's')
    }

    getDistance() {
        if (!this.waypoints?.length) {
            return 0;
        }
        return this.getDistanceBetweenWpIdxs(this.startWpIdx > -1 ? this.startWpIdx : 0, this.endWpIdx > 0 ? this.endWpIdx : this.waypoints.length - 1);
    }

    getTravelDistance() {
        return toKmMs(this.getDistance());
    }

    getTravelTime() {
        return toHrsMinsSecs(this.getDuration(), false, true)
    }

    getPublicStops() {
        return this.stops.filter(publicStopFilter);
    }

    getPublicStopTimes() {
        return this.stopTimes.filter(publicStopFilter);
    }

    isLoopRoute() {
        const publicStops = this.getPublicStops();
        return publicStops[0].stopId === last(publicStops).stopId;
    }

    getPublicStopIndex(stop, fromIndex, reverse = false) {
        if (reverse) {
            if (fromIndex === 0) {
                fromIndex = this.getPublicStops().length - 1;
            }
            return findLastIndex(this.getPublicStops(), routeStop => routeStop.stopId === stop.stopId || (routeStop.linkedStops || []).some(ls => ls.stopId === stop.stopId), fromIndex);
        }
        return findIndex(this.getPublicStops(), routeStop => {
            return routeStop.stopId === stop.stopId || (routeStop.linkedStops || []).some(ls => ls.stopId === stop.stopId)
        }, fromIndex);
    }

    getVisiblePublicStops() {
        if (this.startStopIdx > -1 && this.endStopIdx > this.startStopIdx) {
            return this.getPublicStops().slice(this.startStopIdx, this.endStopIdx + 1);
        }
        return [];
    }

    isStopVisible({stop, stopIdx}) {
        stopIdx = Number.isFinite(stopIdx) ? stopIdx : stop ? this.getPublicStopIndex(stop) : -1;
        return this.startStopIdx <= stopIdx && stopIdx <= this.endStopIdx;
    }

    // getStartTimes() {
    //     if (this.hasServices()) {
    //         const tripList = {}
    //         Object.keys(this.trips).forEach(sId => tripList[sId] = this.trips[sId].map(trip => trip.getStartTime()));
    //         return tripList
    //     }
    //     return getDepartureTimeAsDayjs(this, this.getPublicStops()[0]).format('HH:mm');
    // }

    // toGeoJsonLayers({lineWidth = 2, focus = false, unfocus = false, pickable = true}) {
    //     lineWidth = focus ? lineWidth * 2 + 1 : lineWidth;
    //     let path = toPublicGeoJson(this)
    //     const hexColour = hexToRgb(this.colour)
    //     if (unfocus) {
    //         hexColour.push(150);
    //     }
    //     return [new GeoJsonLayer({
    //         id: `route-layer-bg-${this.routeId}`,
    //         data: path,
    //         lineWidthMinPixels: lineWidth * 2,
    //         getLineColor: () => {
    //             return [255, 255, 255]
    //         },
    //         focus: focus,
    //         bg: true
    //     }), new GeoJsonLayer({
    //         id: `route-layer-${this.routeId}`,
    //         data: path,
    //         lineWidthMinPixels: lineWidth,
    //         getLineColor: () => {
    //             return hexColour
    //         },
    //         pickable: pickable,
    //         focus: focus
    //     })];
    // }

    trim(selectedStop, truncate, checkDirection) {
        console.log('Trimming!!', util.inspect(this.services[0].stopTimes[3]))
        logger.debug('WP deltas before trim', this.waypoints.map(wp => wp.delta))
        if (!selectedStop || !this.stops || !this.stops.length || !this.waypoints || this.waypoints.length < 2) {
            logger.debug("Can't trim!")
            return
        }
        // Find closest waypoint
        const segment = ensureWpAtStop(selectedStop, this, checkDirection, -1, truncate)?.segment;
        if (!segment) {
            logger.debug("Can't trim as there is no route close enough.")
            return
        }
        //nearestWp, origin, distance, nearestIdx
        let nearestStopTimeIdx = -1;
        if (selectedStop.stopTimeId) {
            nearestStopTimeIdx = this.stops.findIndex(st => st.stopTimeId === selectedStop.stopTimeId);
        }
        // logger.debug(this.waypoints.length)
        if (truncate) {
            if (nearestStopTimeIdx > -1) {
                this.stops.splice(nearestStopTimeIdx + 1)
                this.stopTimes.splice(nearestStopTimeIdx + 1)
                this.services.forEach(trip => trip.stopTimes.splice(nearestStopTimeIdx + 1))
            }
            logger.debug('Trimming from ', segment.nearestWpIdx + 1, ' to ', this.waypoints.length);
            this.waypoints.splice(segment.nearestWpIdx + 1, this.waypoints.length)
        } else {
            if (nearestStopTimeIdx > -1) {
                this.stops.splice(0, nearestStopTimeIdx)
                this.stopTimes.splice(0, nearestStopTimeIdx);
                this.services.forEach(trip => {
                    trip.stopTimes.splice(0, nearestStopTimeIdx)
                })
            }
            logger.debug('Trimming from 0 to ', segment);
            this.waypoints.splice(0, segment.nearestWpIdx)
            updateRouteDeltas(0, this);
        }
        // logger.debug(this.waypoints.length)
        // this.stops = this.stops.filter(stop => getNearestSegment(stop, this.waypoints).distance < 50);
        this.stops.forEach(stop => delete stop.wpIdx);
        logger.debug("Finish trim")
        logger.debug('WP deltas after trim', this.waypoints.map(wp => wp.delta))

        return this;
    }

    cut(startWpIdx, endWpIdx, removeRoutes) {
        if (endWpIdx <= startWpIdx) {
            logger.debug('Invalid indexes. End must be after start')
            return
        }
        logger.debug('Cutting!!');
        logger.debug('WP deltas before trim', this.waypoints.map(wp => wp.delta))
        if (this.waypoints?.length <= startWpIdx) {
            logger.debug("Can't cut! Length: %d, StartIdx: %d, EndIdx: %d", this.waypoints?.length, startWpIdx, endWpIdx)
            return
        }

        endWpIdx = endWpIdx > this.waypoints?.length - 1 ? this.waypoints.length - 1 : endWpIdx

        // Find any stops between idx's
        this.calculateStartEnd({stops: this.stops})
        if (removeRoutes) {
            const stopIdxsToRemove = this.stops.reduce((a, e, i) => {
                if (e.wpIdx >= startWpIdx && e.wpIdx <= endWpIdx) {
                    a.push(i);
                }
                return a;
            }, []);
            this.stops.splice(stopIdxsToRemove[0], stopIdxsToRemove.length);
            this.stopTimes.splice(stopIdxsToRemove[0], stopIdxsToRemove.length);
            this.services.forEach(trip => {
                trip.stopTimes.splice(stopIdxsToRemove[0], stopIdxsToRemove.length);
            })
        }
        logger.debug('Cutting Wps from ', startWpIdx, ' to ', endWpIdx);
        this.waypoints.splice(startWpIdx, endWpIdx - startWpIdx + 1);
        if (!this.waypoints.length) {
            // if all waypoints deleted then we shouldn't have any stops, so remove any stray ones.
            this.stops.length = 0;
            this.stopTimes.length = 0;
            this.services.forEach(trip => {
                trip.stopTimes.length = 0;
            })
        }

        updateRouteDeltas(0, this);
        this.stops.forEach(stop => delete stop.wpIdx);

        logger.debug("Finish cut")
        logger.debug('WP deltas after cut', this.waypoints.map(wp => wp.delta))
        return this;
    }

    updateStopPoints() {
        if (this.stops && this.stops.length && this.waypoints && this.waypoints.length) {
            logger.debug(`Looking for missing stop points...`)
            this.stops.forEach((stop, idx) => {
                const {segment, distance} = getNearestSegment(stop, this.waypoints, false, -1, 0, 0);
                logger.debug(`${distance} < ${segment.distanceToNearestWp}`);
                if (distance < segment.distanceToNearestWp) {
                    logger.debug(`Adding new waypoints at stop ${idx + 1} @ ${segment.startIdx}: ${util.inspect(segment.calculatedClosestWp)}`)
                    this.waypoints.splice(segment.startIdx + 1, 0, segment.calculatedClosestWp);
                }
            })
        }
    }

    getStopBeforeWpIndex(wpIdx, stops = this.getPublicStops()) {
        logger.debug('looking for stop before', wpIdx)
        // assumes calculateStartEnd has been run on all stops
        const publicStops = stops;
        if (!publicStops.length || wpIdx < publicStops[0].wpIdx) {
            return;
        }
        for (let i = 1; i < publicStops.length; i++) {
            const stop = publicStops[i];
            if (stop.wpIdx > wpIdx) {
                return publicStops[i - 1]
            }
        }
        return last(publicStops);
    }

    getStopAfterWpIndex(wpIdx, stops = this.getPublicStops()) {
        // assumes calculateStartEnd has been run on all stops
        const publicStops = stops;
        for (let i = 0; i < publicStops.length; i++) {
            const stop = publicStops[i];
            if (stop.wpIdx > wpIdx) {
                return stop
            }
        }
    }

    getDistanceBetweenWpIdxs(idx1, idx2) {
        idx2 = this.waypoints.length < idx2 ? this.waypoints.length - 1 : idx2
        if (idx1 < 0 || idx1 > idx2) {
            throw new Error(`invalid indexes, idx1: ${idx1}, idx2: ${idx2}, this.waypoints.length: ${this.waypoints.length}`);
        }
        let prevWp, dist = 0;
        for (let i = idx1 + 1; i <= idx2; i++) {
            prevWp = this.waypoints[i - 1];
            const wp = this.waypoints[i];
            dist += getDistanceInMetres(prevWp, wp);
        }
        return dist;
    }

    getAvgSpdBetweenStops(stop1, stop2) {
        logger.debug('Getting distance between %s and %s. WpIdxs: %d, %d, Deltas: %d, %d', stop1.stopName, stop2.stopName, stop1.wpIdx, stop2.wpIdx, stop1.departSecs, stop2.arriveSecs)
        const dist = this.getDistanceBetweenWpIdxs(stop1.wpIdx, stop2.wpIdx);
        logger.debug('Distance between %s to %s: %d', stop1.stopName, stop2.stopName, dist);
        const time = stop2.arriveSecs - stop1.departSecs;
        return time > 0 ? dist / time : 0;
    }

    getTripCount() {
        return this.tripCount
    }

    hasLogo() {
        return hasLogo(this);
    }

    stopsAtStopInWindow(stop, timeFilter, end) {
        if (!find(this.stops, {stopId: stop.stopId})) {
            return false;
        }
        if (end === (timeFilter.type === TimeFilterType.ARRIVING)) {
            return this.services.filter(trip => trip.schedules.filter(schedule => schedule.isActive(timeFilter.startTime, !timeFilter.anyDate)).length)
                .some(trip => {
                    return trip.stopTimes.filter(st => stop.stopId === st.stopId).some(st => {
                        const departureTime = getDepartureTimeAsSecondsSinceMidnight(trip, st);
                        if (timeFilter.anyDate) {
                            if (timeFilter.type === TimeFilterType.ARRIVING) {
                                if (departureTime < (stop.startBell - stop.startBellWindow) || departureTime > stop.startBell) {
                                    return false
                                }
                            } else {
                                if (departureTime < stop.endBell || departureTime > (stop.endBell + stop.endBellWindow)) {
                                    return false
                                }
                            }
                        }
                        return departureTime >= timeFilter.getStartTime() && departureTime <= timeFilter.getEndTime()
                    })
                })
        }
        return true;
    }

    stopToStopTimesInWindow(stop, tripId, timeFilter, end) {
        if (!find(this.stops, {stopId: stop.stopId})) {
            return [];
        }

        const _this = this;
        const trip = find(this.services, {'tripId': tripId});
        return trip.stopTimes.filter(publicStopFilter).filter(st => {
            if (stop.stopId !== st.stopId) {
                return false;
            }
            // This will check the stoptime is within the time filter
            if (timeFilter) {
                const windowStart = timeFilter.getStartTimeAsDayJs()
                const windowEnd = timeFilter.getEndTimeAsDayJs()
                const timeToCheck = timeFilter.checkArrivalTime(_this.direction) ? getArrivalTimeAsSecondsSinceMidnight(trip, st) : getDepartureTimeAsSecondsSinceMidnight(trip, st);
                if (stop.startBell && stop.startBellWindow && timeFilter.anyDate && timeFilter.checkArrivalTime(_this.direction)) {
                    if (timeToCheck < (stop.startBell - stop.startBellWindow) || timeToCheck > stop.startBell) {
                        return false
                    }
                } else if (stop.endBell && stop.endBellWindow && timeFilter.anyDate && timeFilter.checkDepartureTime(_this.direction)) {
                    if (timeToCheck < stop.endBell || timeToCheck > (stop.endBell + stop.endBellWindow)) {
                        return false
                    }
                }

                if (timeFilter.anyDate) {
                    return timeToCheck >= timeFilter.getStartTime() && timeToCheck <= timeFilter.getEndTime()
                }
                // const startTime = dayjs().startOf('d').add(timeFilter.getStartTime(), 's')
                // const endTime = dayjs().startOf('d').add(timeFilter.getEndTime(), 's')
                let time = timeFilter.startTime.clone().startOf('d').add(timeToCheck, 's')
                if (time.isBefore(timeFilter.startTime)) {
                    time = time.add(1, 'd')
                }

                return time.isBetween(windowStart, windowEnd, 's', "[]");
            }
            return true
        }).map(st => new BaseStop({...stop, ...st}))
    }

    getWpPassingTimesForTrip(trip) {
        let currentTime = trip.getStartTimeAsSecondsSinceMidnight();
        let wpPassingTimes = []
        trip.stopTimes.forEach((st, i, sts) => {
            if (i === 0) {
                return
            }
            const prevSt = sts[i - 1]
            const stop = this.stops ? this.stops[i] : trip.stops ? trip.stops[i] : null
            const prevStop = this.stops ? this.stops[i - 1] : trip.stops ? trip.stops[i - 1] : null

            const start = prevSt.departSecs;
            const end = st.arriveSecs;
            const time = end - start
            const dist = (stop?.distance || 0) - (prevStop?.distance || 0)
            const avgSpd = dist / time
            wpPassingTimes = wpPassingTimes.concat(this.waypoints.slice(prevStop?.wpIdx || 0, stop?.wpIdx || this.waypoints.length - 1).map((wp, i, wps) => {
                if (i === 0) {
                    return currentTime;
                }
                const prevWp = wps[i - 1]
                const dist = getDistanceInMetres(wp, prevWp);
                currentTime += dist / avgSpd
                return currentTime
            }))
        })
        return wpPassingTimes;
    }

    validate() {
        this.calculateStartEnd({ensureStop: false});
        this.calculateDistances()
        const messages = {global: [], stops: [], isValid: true};
        if (!this.routeNumber?.length) {
            messages.global.push({msg: '⛔️ Route number cannot be empty (REF-019)'});
            messages.routeNumber = true
        }
        if (!this.routeName?.length) {
            messages.global.push({type: 'routeName', msg: '⛔️ Route name cannot be empty (REF-018)'});
            messages.routeName = true
        }
        messages.stops = this.stopTimes.map(() => [])
        if (this.stops) {
            this.stops.forEach((stop, idx) => {
                if (idx > 0) {
                    if (!stop.wpIdx || stop.wpIdx <= this.stops[idx - 1].wpIdx) {
                        messages.stops[idx]?.push({msg: `⛔️ Stop point is out of sequence (REF-023)`});
                    }
                }
                if (!stop.nearestSegment || stop.nearestSegment.dist > 20) {
                    // messages.stops[idx]?.push({msg: `Stop '${stop.stopName}' at sequence ${idx + 1} can't be mapped to the route.`});
                    messages.stops[idx]?.push({msg: `⛔️ Stop point is more than 20m from ${idx === this.stops.length - 1 ? 'the end of the' : idx === 0 ? 'the start of the' : ''} route path (REF-001)`});
                }
                if (stop.stopType && stop.stopType !== 'bus') {
                    messages.stops[idx]?.push({msg: `⛔️ Point type ${stop.stopType} is not allowed. Use bus stop point type only (REF-002)`});
                }
            })
        }
        if (this?.services) {
            this.services.forEach(trip => {

                if (trip.stopTimes?.length < 2) {
                    messages.global.push({msg: '⛔️ Published routes must have at least two bus stop points (REF-012)'});
                    return
                }

                // messages.services[trip.tripId] = []
                // messages.services[trip.tripId].length = trip.stopTimes.length
                let prevDepartureTime = 0, nextArrivalTime = -1;
                for (const [idx, stopTime] of trip.stopTimes.entries()) {
                    if (idx === trip.stopTimes.length - 1) {
                        nextArrivalTime = -1
                    } else {
                        nextArrivalTime = trip.stopTimes[idx + 1].arriveSecs;
                    }
                    // let departureTime = getDepartureTimeAsSecondsSinceMidnight(trip, stop);
                    if (prevDepartureTime > 0 && (stopTime?.arriveSecs || 0) < prevDepartureTime) {
                        messages.stops[idx]?.push({
                            tripId: trip.tripId,
                            arrive: true,
                            msg: (`⛔️ Arrival time is before the departure time of the previous stop (REF-003)`)// on the ${trip.getStartTime()} service${trip.stops ? `at stop ${idx + 1} ${trip.stops[idx]?.stopName}` : ''}`)
                        });
                    }
                    if (nextArrivalTime > -1 && (stopTime?.departSecs || 0) > nextArrivalTime) {
                        messages.stops[idx]?.push({
                            tripId: trip.tripId,
                            depart: true,
                            msg: (`⛔️ Departure time is after the arrival time of the next stop (REF-004)`)// on the ${trip.getStartTime()} service${trip.stops ? ` at stop ${idx + 1} ${trip.stops[idx]?.stopName}` : ''}`)
                        });
                    }
                    if (stopTime.departSecs < stopTime.arriveSecs) {
                        messages.stops[idx]?.push({
                            tripId: trip.tripId,
                            depart: true,
                            msg: (`⛔️ Departure time is before the arrival time (REF-005)`)// on the ${trip.getStartTime()} service${trip.stops ? ` at stop ${idx + 1} ${trip.stops[idx]?.stopName}` : ''}`)
                        });
                    }
                    if (this.stops?.length && idx > 0) {
                        const time = (trip.stopTimes[idx]?.arriveSecs || 0) - (trip.stopTimes[idx - 1]?.departSecs || 0)
                        const dist = (this.stops[idx]?.distance || 0) - (this.stops[idx - 1]?.distance || 0)
                        const speed = dist / (time || 1)
                        if (speed > METRES_PER_SEC) {
                            messages.stops[idx]?.push({
                                tripId: trip.tripId,
                                arrive: true,
                                warning: true,
                                // msg: (`Speed violation greater than 120km/hr departing from previous stop point. Distance between stops: ${toKmMs(dist, 1, 2000)}. Minimum travel time: ${toHrsMinsSecs(dist/METRES_PER_SEC)}.`)
                                msg: (`⚠️ Speed exceeds 120km/hr departing from previous stop. Minimum travel time of ${toHrsMinsSecs(dist / METRES_PER_SEC)} required to travel ${toKmMs(dist, 1)} (REF-006)`)
                            });
                        }
                    }
                    if (stopTime?.arriveSecs === prevDepartureTime) {
                        messages.stops[idx]?.push({
                            tripId: trip.tripId,
                            arrive: true,
                            msg: (`⛔️ Arrival time is the same as previous stop's departure time`)
                        });
                    }
                    prevDepartureTime = stopTime.departSecs;
                }

                if (!trip.stopTimes[0].timingPoint || !last(trip.stopTimes).timingPoint) {
                    messages.global.push({
                        msg: '⛔️ The first and last stop of a published route must be a timing point (REF-015)'
                    });
                }
                if (!trip.stopTimes[0].timingPoint) {
                    messages.stops[0]?.push({
                        tripId: trip.tripId,
                        msg: '⛔️ First stop point must be a timing point (REF-016)',
                        stop: true
                    })
                }

                if (!last(trip.stopTimes).timingPoint) {
                    messages.stops[messages.stops.length - 1]?.push({
                        tripId: trip.tripId,
                        msg: '⛔️ Last stop point must be a timing point (REF-017)',
                        stop: true
                    })
                }

                if (!trip.scheduleIds?.length) {
                    messages.global.push({
                        tripId: trip.tripId,
                        type: 'schedule',
                        msg: '⛔️ This service has missing or expired operating calenders (REF-020)'
                    });
                    messages.schedule = true
                }
            })
            if (this.stops?.length) {
                // eslint-disable-next-line
                this.stops.forEach((stop, idx) => {
                    if (!stop.verified) {
                        messages.stops[idx]?.push({msg: `⛔️ Point '${stop.stopName}' has not been verified (REF-007)`});
                    }
                    if (idx > 0 && this.stops[idx - 1].stopId === stop.stopId) {
                        messages.stops[idx]?.push({msg: `⛔️ Point '${stop.stopName}' is a duplicate (REF-008)`});
                    }
                })

                if (this.stops[0].wpIdx !== 0) {
                    messages.stops[0]?.push({
                        warning: true,
                        msg: '⛔️ Route must start with no leading waypoints (REF-009)'
                    });
                }
                if (last(this.stops).wpIdx !== this.waypoints.length - 1) {
                    messages.stops[this.stops.length - 1]?.push({
                        warning: true,
                        msg: '⛔️ Route must end with no trailing waypoints (REF-010)'
                    });
                }
            }
            if (!this.services?.length) {
                messages.global.push({msg: '⛔️ Published routes must have at least one service timetable (REF-011)'});
            }
            if (this.stops?.length && this.stops.filter(publicStopFilter).length < 2) {
                messages.global.push({msg: '⛔️ Published routes must have at least two bus stop points (REF-012)'});
            }
            if (this.stops?.length && this.stops?.filter(publicStopFilter).length !== this.stops.length) {
                messages.global.push({msg: '⛔️ Published routes must only contain bus stop types (REF-013)'});
            }

            if (this.stopTimes?.length < 2) {
                messages.global.push({msg: '⛔️ Published routes must have at least 2 stop times (REF-014)'});
            }
        }

        messages.isValid = !messages.global?.length && !messages.stops.some(s => s?.length) && !messages.schedule && !messages.routeNumber && !messages.routeName
        messages.isWarning = messages.global.some(m => m.warning) || messages.stops.some(s => s.some(m => m.warning))
        messages.isTripInvalid = tripId => {
            return messages.global?.length ||
                messages.stops.some(s => s.some(st => !st.tripId || st.tripId === tripId))
        }
        return messages
    }

    validateAsList() {
        let messages = this.validate();
        if (messages.isValid && !messages.isWarning) {
            return []
        }
        const result = messages.global || []
        const stopValidationMessges = flatten(messages.stops).filter(m => !!m).length
        const error = flatten(messages.stops).some(m => !m.warning)
        if (stopValidationMessges) {
            result.push({
                msg: (error ? "⛔ " : "⚠️ ") + stopValidationMessges + " stop time validation " +
                    (error ? "errors" : "warnings") + " (REF-022)"
            });
        }
        return result.map(m => m.msg);
    }

    optimiseWaypointResult() {
        const _route = this.clone();
        _route.waypoints.forEach(wp => {
            wp.x = wp.lon
            wp.y = wp.lat
        })
        return this.waypoints.length - simplify(_route.waypoints, WP_OPTIMISE_RATE).length
    }

    optimiseWaypoints() {
        const _route = this.clone();
        _route.waypoints.forEach(wp => {
            wp.x = wp.lon
            wp.y = wp.lat
        })
        console.log(`WPs before optimisation: ${_route.waypoints.length}`)
        this.waypoints = simplify(_route.waypoints, WP_OPTIMISE_RATE)
        console.log(`WPs after optimisation: ${this.waypoints.length}`)
        _route.waypoints.forEach(wp => {
            delete wp.x;
            delete wp.y;
        })
        this.calculateStartEnd({})
    }

}

export class Transfer extends Model {
    constructor(data) {
        super(data);
        this.startRouteId = null;
        this.startStopSequence = 0;
        this.endRouteId = null;
        this.endStopSequence = 0;
        this.walkingTime = null;
        this.walkingDist = null;
        Object.assign(this, data)
    }
}

export class WayPoint extends Model {
    constructor(data) {
        super()
        this.lat = 0.0
        this.lon = 0.0
        this.delta = 0 // Time in seconds since the start of the route
        this.distance = 0 // Distance in metres from the start of the route
        Object.assign(this, data)
        this.delta = Math.round(this.delta)
        // this.delta = Math.ceil(this.delta / 60.0) * 60;
    }

    isValid() {
        return Number.isFinite(this.lat) && Number.isFinite(this.lon) && this.lat >= -90 && this.lat <= 90 && this.lon >= -180 && this.lon <= 180
    }

    distanceToWayPoint(wp) {
        return getDistanceInMetres(this, wp)
    }

}

export const StopTypes = {
    bus: {type: 'bus', name: 'Bus', shortName: 'B', title: 'Bus stop'},
    school: {type: 'school', name: 'School', shortName: 'S', title: 'School stop'},
    depot: {type: 'depot', name: 'Depot', shortName: 'D', title: 'Depot stop'},
    busInterchange: {type: 'bus_interchange', name: 'Bus Interchange', shortName: 'BI', title: 'Bus interchange'},
    schoolInterchange: {
        type: 'school_interchange',
        name: 'School Interchange',
        shortName: 'SI',
        title: 'School interchange'
    }
}

export class Stop extends WayPoint {

    static simplifyStopName(stopName) {
        return stopName.toLowerCase().replace(/[^a-z]/g, '')
    }

    static sort(a, b) {
        if (a.master && b.master) {
            return a.stopName.localeCompare(b.stopName);
        } else if (a.master) {
            return -1;
        } else if (b.master) {
            return 1;
        } else {
            if (a.authorityId && b.authorityId) {
                return a.stopName.localeCompare(b.stopName);
            } else if (a.authorityId) {
                return -1;
            } else if (b.authorityId) {
                return 1;
            } else {
                if (a.stopCode && b.stopCode) {
                    return a.stopName.localeCompare(b.stopName);
                } else if (a.stopCode) {
                    return -1;
                } else if (b.stopCode) {
                    return 1;
                } else {
                    if (a.verified !== b.verified) {
                        return b.verified - a.verified
                    } else {
                        return b.updatedAt - a.updatedAt
                    }
                }
            }
        }
    }

    constructor(data) {
        super(data)
        this.stopId = null;
        this.stopName = null;
        this.stopDesc = null;
        this.stopType = null; // StopType
        this.stopCode = null;
        this.linkedStops = null; // [{LinkedStop}] from DB
        this.imported = false; // Imported stop from a CSV file
        this.authorityId = null; // Downloaded stop from the master stop list, or imported stop that had a stopCode (australia#tfnsw)
        this.master = false; // master stop from the cloud
        this.outOfSync = null // Stop with stopCode that is out of sync with the master stop of the same stop code with the same authorityId
        this.routes = [];
        this.startBell = 0;
        this.startBellWindow = 0;
        this.endBell = 0;
        this.endBellWindow = 0;
        Object.assign(this, data);
        if (typeof this.outOfSync === 'string') {
            this.outOfSync = [this.outOfSync];
        }
        this.center = [this.lon, this.lat]
    }

    getAllRoutes() {
        let routes = this.routes
        if (this.hasLinkedStops()) {
            this.linkedStops.forEach(ls => routes = routes.concat(ls.stop.routes))
        }
        return routes
    }

    isLinkedStop(stop) {
        return this.linkedStops?.findIndex(ls => ls.stopId === stop?.stopId) > -1;
    }

    hasLinkedStops() {
        return !!this.linkedStops?.length;
    }

    hasTransfers() {
        return !!this.routes?.some(r => r.transfers?.length);
    }

    unsetLinkedStops() {
        this.linkedStops?.forEach(ls => delete ls.stop);
    }

    setLinkedStops(stopsById, includeStopModel = true) {
        if (Array.isArray(stopsById)) {
            stopsById = keyBy(stopsById, 'stopId');
        }
        this.linkedStops = this.linkedStops?.filter(linkedStop => stopsById[linkedStop.stopId]).map(linkedStop => {
            const stop = stopsById[linkedStop.stopId]
            if (includeStopModel) {
                linkedStop.stop = cloneDeep(stop);
                delete linkedStop.stop.linkedStops
            }
            // this.routes = (this.routes || []).concat(stop?.routes || [])
            return new LinkedStop(linkedStop);
        }).filter(ls => !!ls);
        this.routes = uniqBy(this.routes, "routeId");
        this.routes?.forEach(r => {
            r.transfers?.forEach(t => {
                t.fromStop = stopsById[t.fromStopId]
                t.toStop = stopsById[t.toStopId]
            })
        })
        return this.linkedStops;
    }

    getPassingTime(routeId, tripId, stopTimeId) {
        const r = find(this.routes, r => r.routeId === routeId)
        if (!r) return
        const t = r.services[tripId]
        if (!t) return
        return find(t.passingTimes, pt => pt.stopTimeId === stopTimeId)
    }

    getPassingTimes(routeFilter, {date = dayjs(), school, time, window: windowSecs = 0}, allSchedules) {

        const today = dayjs().year(date.year()).month(date.month()).date(date.date())
        const windowStart = date.clone().startOf('d').add(time, 's')
        const windowEnd = windowStart.clone().add(windowSecs, 's')

        const passingTimes = [];
        (this.routes || []).filter(routeFilter).forEach(r => {
            logger.debug('Checking route: %s', r.routeNumber, r.services)
            Object.keys(r.services || {}).forEach(tripId => {
                logger.debug('Checking route: %s, trip: %s', r.routeNumber, tripId, r.services[tripId]?.passingTimes)
                try {
                    if (r.services[tripId]?.passingTimes?.length) {
                        const ptIdx = r.services[tripId].passingTimes.findIndex(pt => {
                            let arrivalTime = dayjs(date).startOf('d').add(pt.arriveSecs, 's')
                            if (arrivalTime.isBefore(today)) {
                                arrivalTime = arrivalTime.add(1, 'd')
                            }
                            let departureTime = dayjs(date).startOf('d').add(pt.departSecs, 's');
                            if (departureTime.isBefore(today)) {
                                departureTime = departureTime.add(1, 'd')
                            }
                            if (school) {
                                const windowEnd = time + windowSecs;
                                logger.debug('Checking arriveSecs %d < %d < %d,  departSecs %d < %d < %d', time, pt.arriveSecs, windowEnd, time, pt.departSecs, windowEnd)
                                return (time <= pt.arriveSecs && pt.arriveSecs <= windowEnd) || (time <= pt.departSecs && pt.departSecs <= windowEnd)
                            }
                            logger.debug('Looking for %s between %s and %s on %s, school: %s Schedules: %s', arrivalTime.format('lll'), windowStart.format('lll'), windowEnd.format('lll'), date?.format('DD/M/YYYY'), school, allSchedules)
                            return arrivalTime.isBetween(windowStart, windowEnd, 's', "[]") || departureTime.isBetween(windowStart, windowEnd, 's', "[]");
                        })
                        if (ptIdx > -1) {
                            logger.debug('We have a pt. Check schedule: %s', (allSchedules && date))
                            let scheduleIds = r.services[tripId].scheduleIds
                            if (allSchedules && date) {
                                logger.debug('Checking schedule...')
                                const days = Math.abs(windowStart.clone().startOf('d').diff(windowEnd.clone().startOf('d'), 'd'))
                                scheduleIds = r.services[tripId].scheduleIds
                                    .map(sId => allSchedules[sId])
                                    .filter(schedule => {
                                        if (!schedule || schedule.isObsolete(date)) return false
                                        for (let i = 0; i <= days; i++) {
                                            if (schedule.isActive(windowStart.clone().add(i, 'd'), !school)) {
                                                return true
                                            }
                                        }
                                        return false
                                    })
                                    .map(schedule => schedule.scheduleId)
                                logger.debug('Filtered schedules', scheduleIds.map(sId => allSchedules[sId].scheduleName))
                            }
                            if (scheduleIds?.length) {
                                logger.debug('We have %d schedules', scheduleIds.length)
                                passingTimes.push({
                                    tripId,
                                    passingTime: r.services[tripId].passingTimes[ptIdx],
                                    routeId: r.routeId,
                                    route: r,
                                    scheduleIds
                                })
                            }
                        }
                    }
                } catch (e) {
                    logger.warn('Passing times for stop: %s on route %s: trip: %s', this.stopName, r.routeNumber, tripId, r.services[tripId])
                    logger.warn(e)
                }
            })
        })
        return passingTimes
    }

}

export class BaseStop extends Stop {
    constructor(data) {
        super(data);
        this.timingPoint = false
        this.arriveSecs = 0
        this.departSecs = 0
        this.createdAt = 0
        this.updatedAt = 0;
        Object.assign(this, data);
    }

    getDepartSecondsSinceMidnight() {
        return this.departSecs;
    }

    isInWindow(direction) {
        logger.debug('Checking %s in window for direction %s', this.stopName, direction)
        return direction === 'AM' ? this.isInStartWindow() : direction === 'PM' ? this.isInEndWindow() : true;
    }

    isInStartWindow() {
        logger.debug('Checking %s in start window', this.stopName, this.startBell, this.startBellWindow)
        if (!this.startBell || !this.startBellWindow) {
            return true
        }
        const windowStart = this.startBell - this.startBellWindow;
        const windowEnd = this.startBell;
        const departTime = this.getDepartSecondsSinceMidnight();
        logger.debug('%d < %d > %d', windowStart, departTime, windowEnd)

        return departTime >= windowStart && departTime <= windowEnd;
    }

    isInEndWindow() {
        logger.debug('Checking %s in end window', this.stopName, this.endBell, this.endBellWindow)
        if (!this.endBell || !this.endBellWindow) {
            return true
        }
        const windowStart = this.endBell;
        const windowEnd = this.endBell + this.endBellWindow;
        const departTime = this.getDepartSecondsSinceMidnight();
        logger.debug('%d < %d > %d', windowStart, departTime, windowEnd)

        return departTime >= windowStart && departTime <= windowEnd;
    }

    static isEqual(s1, s2) {
        return s1.stopId === s2.stopId && s1.stopTimeId === s2.stopTimeId
    }
}

export class LinkedStop {
    constructor(data) {
        this.stopId = null;
        this.stop = null;
        this.distance = -1;
        this.duration = -1;
        this.waypoints = null;
        Object.assign(this, data);
    }

    static SERIALISED_KEYS() {
        return ['stopId', 'distance', 'duration', 'waypoints']
    }
}

export class StopTime {
    constructor(data) {
        this.stop = null
        this.timingPoint = false
        this.arriveSecs = 0
        this.departSecs = 0
        this.createdAt = 0
        this.updatedAt = 0;
        this.distance = 0 // Distance in metres from the start of the route

        Object.assign(this, data)
    }
}


export class Interchange extends Stop {
    constructor(data) {
        super(data)
        this.stops = [];
        Object.assign(this, data)
        this.interchange = true;
    }
}

export class Trip extends Model {
    constructor(data) {
        super();
        this.tripId = null
        this.routeId = null;
        this.route = null;
        this.startStopIdx = -1;
        this.endStopIdx = -1;
        this.stopTimes = [];
        this.scheduleIds = [];
        this.driverId = null;
        this.vehicleId = null;
        this.shifts = [];
        this.headsign = null;
        this.transferAlias = null;
        Object.assign(this, data)
        this.tripId = this.tripId || ulid();
        // this.stopTimes.forEach(st => {
        //     st.stopTimeId = st.stopTimeId || ulid()
        // });
        //TODO: Ensure the first stop is a public stop
        this.startTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(this, this.stopTimes[0]);
    }

    hasObsoleteCalendar(schedulesById, date = dayjs()) {
        return this.scheduleIds.some(sId => !schedulesById[sId] || schedulesById[sId].isObsolete(date))
    }

    getStopSequence(stopTime, allStops) {
        return this.getPublicStopTimes(allStops).findIndex(st => st.stopTimeId === stopTime.stopTimeId) + 1;
    }

    getTimingPointStops() {
        return this.stops.filter(timingPtFilter);
    }

    getPublicStopTimes(allStops) {
        return this.stopTimes.filter(st => publicStopFilter(allStops[st.stopId]));
    }

    getDuration({startStopIdx = 0, endStopIdx = this.stopTimes.length - 1, stops = []}) {
        const publicStopTimes = this.getPublicStopTimes(keyBy(stops, 'stopId'))
        return getDepartureTimeAsDayjs(this, publicStopTimes[endStopIdx]).diff(getDepartureTimeAsDayjs(this, publicStopTimes[startStopIdx]), 's')
    }


    getVisiblePublicStopTimes(allStops) {
        if (this.startStopIdx > -1 && this.endStopIdx > this.startStopIdx) {
            return this.getPublicStopTimes(allStops).slice(this.startStopIdx, this.endStopIdx + 1);
        }
        return [];
    }

    setStartTime(newStartSecsSinceMidnight) {
        if (this.stopTimes?.length) {
            const diff = newStartSecsSinceMidnight - this.stopTimes[0].departSecs
            this.stopTimes?.forEach(st => {
                st.arriveSecs += diff;
                st.departSecs += diff;
            })
        }
    }

    getStartTime(showSeconds = false) {
        //TODO: Ensure the first stop is a public stop
        return this.stopTimes?.length ? getDepartureTimeAsDayjs(this, this.stopTimes[0]).format(showSeconds ? 'HH:mm:ss' : 'HH:mm') : showSeconds ? '--:--:--' : '--:--';
    }

    getStartTimeAsSecondsSinceMidnight(startStopIdx = 0) {
        return getDepartureTimeAsSecondsSinceMidnight(this, this.stopTimes[startStopIdx]);
    }

    getEndTimeAsSecondsSinceMidnight(endStopIdx = this.stopTimes.length - 1) {
        if (endStopIdx >= this.stopTimes.length) {
            endStopIdx = this.stopTimes.length - 1
        }
        return getArrivalTimeAsSecondsSinceMidnight(this, this.stopTimes[endStopIdx]);
    }

    getStopTime(stop) {
        return this.stopTimes.filter(st => st.stopTimeId === stop.stopTimeId)[0] || null;
    }

    isStopTimeInWindow(stopTime, time, window) {
        const departureTimeAtStopTime = getDepartureTimeAsSecondsSinceMidnight(this, stopTime)
        return departureTimeAtStopTime >= time && departureTimeAtStopTime <= time + window
    }

    getAllStopTimes(stop) {
        if (!stop) {
            return [];
        }
        return this.stopTimes.reduce((result, st, i) => {
            if (st.stopId === stop.stopId)
                result.push(st);
            return result;
        }, []);
    }

    clone() {
        return new Trip({...this, stopTimes: [...this.stopTimes]})
    }

    addSchedule(scheduleId) {
        this.scheduleIds.push(scheduleId);
    }

    removeSchedule(scheduleId) {
        this.scheduleIds = this.scheduleIds.filter(sId => sId !== scheduleId);
    }
}


export class TripShift extends Model {
    constructor(data) {
        super();
        this.tripShiftId = null
        this.tripId = null
        this.vehicleIds = []
        this.driverIds = []
        Object.assign(this, data);
        this.tripShiftId = this.tripShiftId || ulid();
    }
}
