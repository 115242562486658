import { cloneDeep } from "lodash";
import { ulid } from "ulid";
import { BusRoute } from "./busRoute";
import { ShiftBat } from "./shiftBat";
import dayjs from "../dayjs";
import { getDistanceInMetres } from "../libs/routes-lib";

export class Charter {
    constructor(props) {
        this.charterId = "_";
        this.status = "new";
        this.type = "charter#detail";
        this.itineraryId = "_";
        this.quoteLineItems = []

        Object.assign(this, props);
    }

    isValid() {
        return true;
    }

    clone() {
        return new Charter(cloneDeep(this));
    }
}

export class Itinerary {
    constructor(props) {
        this.charterId = ulid();
        this.type = "charter#itinerary";

        Object.assign(this, props);

        this.route = new BusRoute(this.route ?? {});
        this.shift = new ShiftBat();
    }

    isValid() {
        return true;
    }

    clone() {
        return new Itinerary(cloneDeep(this));
    }
}

export class ItineraryShift extends ShiftBat {
    constructor(props, modelData = null) {
        super(props);
        this.charterId = ulid();
        this.type = "charter#itinerary";
        Object.assign(this, props);

        if (this.rows.length) {
            this.rows = this.rows.map((row) => this.createRow(row, modelData));
        }
        if (typeof this.createdAt === "string") {
            this.createdAt = dayjs(this.createdAt, "DD/MM/YYYY");
        }
        if (typeof this.updatedAt === "string") {
            this.updatedAt = dayjs(this.updatedAt, "DD/MM/YYYY");
        }
    }

    clone(field, value) {
        const newShiftBat = new ItineraryShift({
            ...this,
            rows: this.rows.map((r) => r?.clone()),
        });
        if (field?.length) {
            newShiftBat[field] = value;
        }
        return newShiftBat;
    }

    checkPrevLoc(row, idx) {
        let addedDeadRow = false;
        if (idx > 0 && row.type !== CharterRowType.charter) {
            let prevIdx = idx - 1;
            let prevRow = this.rows[prevIdx--];

            if (prevRow.type === CharterRowType.charter) {
                return false;
            }

            // Add dead running if necessary
            if (row.getStartLocation) {
                const toLoc = row.getStartLocation();
                if (toLoc) {
                    let prevLocRow = prevRow,
                        prevTimeRow = Number.isFinite(prevRow.time) && prevRow.time > -1 ? prevRow : null;
                    while (prevIdx >= 0 && !prevLocRow.getEndLocation) {
                        prevLocRow = this.rows[prevIdx--];
                        if (!prevTimeRow && Number.isFinite(prevLocRow.time) && prevLocRow.time > -1) {
                            prevTimeRow = prevLocRow;
                        }
                    }

                    if (prevLocRow.getEndLocation) {
                        const fromLoc = prevLocRow.getEndLocation();
                        if (fromLoc && toLoc.geohash !== fromLoc.geohash) {
                            const distance = getDistanceInMetres(prevLocRow.getEndLocation(), row.getStartLocation());
                            const duration = distance / 14;

                            const deadRow = this.createRow({
                                type: CharterRowType.charter,
                                time: prevTimeRow ? prevTimeRow.time + (prevTimeRow.duration || 0) : -1,
                                start: prevLocRow.getEndLocation(),
                                startStopId: prevLocRow.getEndLocation().stopId,
                                end: row.getStartLocation(),
                                endStopId: row.getStartLocation().stopId,
                                distance,
                                duration,
                                modelData: this.modelData,
                            });
                            this.rows.splice(idx, 0, deadRow);
                            addedDeadRow = true;
                            prevRow = deadRow;
                        }
                    }
                }
            }

            // Update the new rows time
            // if (row.time === -1 && ![ShiftBatRowType.service].includes(row.type)) {
            //     row.time = prevRow.time + (prevRow.duration || 0)
            // }
            row.prevRow = prevRow;
        }
        return addedDeadRow;
    }

    async updateRows({ apiKey, allStops, allTransfers, allRoutes, charterModelData, deadrunModelData }) {
        console.log("Updating rows...");

        // eslint-disable-next-line
        let prevRow, nextRow;
        for (let i = 0; i < this.rows.length; i++) {
            if (i > 0) {
                // eslint-disable-next-line
                prevRow = this.rows[i - 1];
            }
            let row = this.rows[i];
            if (i < this.rows.length - 1) {
                // eslint-disable-next-line
                nextRow = this.rows[i + 1];
            }

            if (i > 0 && [CharterRowType.service, CharterRowType.stop].includes(row.type)) {
                if (this.checkPrevLoc(row, i)) {
                    i++;
                }
            }
        }

        await Promise.all(
            this.rows
                .filter((row) => row.updateRow)
                .map(async (row) =>
                    row.updateRow({
                        apiKey,
                        allStops,
                        allTransfers,
                        allRoutes,
                        charterModelData,
                        deadrunModelData,
                    })
                )
        );

        return this;
    }

    deleteNextDeadRow(idx) {
        for (let i = idx + 1; i < this.rows.length; i++) {
            const row = this.rows[i];
            if ([CharterRowType.service, CharterRowType.stop].includes(row.type)) {
                return;
            }
            if (row.type === CharterRowType.charter) {
                this.rows.splice(i, 1);
                return;
            }
        }
    }

    deletePrevDeadRow(idx) {
        for (let i = idx - 1; i >= 0; i--) {
            const row = this.rows[i];
            if ([CharterRowType.service, CharterRowType.stop].includes(row.type)) {
                return;
            }
            if (row.type === CharterRowType.charter) {
                this.rows.splice(i, 1);
                return;
            }
        }
    }

    replaceRow(row, idx = 0) {
        let replace = false;
        let existingIdx = this.rows.findIndex((r) => row.id === r.id);
        if (existingIdx > -1) {
            replace = true;
            idx = existingIdx;
        }
        // const currentRow = this.rows[idx]
        row = this.putRow(idx, row, replace);
        // Check for dead runs on either side. Delete so they will be rebuilt.
        if (row && [CharterRowType.service, CharterRowType.stop, CharterRowType.noteTimed].includes(row.type)) {
            this.deleteNextDeadRow(idx);
            this.deletePrevDeadRow(idx);
        }
        return row;
    }
}

export class CharterTemplate {
    constructor(props) {
        this.charterId = ulid();
        this.type = "charter#template";

        Object.assign(this, props);
    }

    isValid() {
        return true;
    }

    clone() {
        return new CharterTemplate(cloneDeep(this));
    }
}

export const CharterRowType = {
    noteTimed: "Note timed",
    note: "Note",
    service: "Service",
    break: "Break",
    breakMeal: "Meal break",
    breakBroken: "Broken shift break",
    location: "Add location",
    stop: "Point",
    stopNote: "Stop Note",
    dead: "Dead running",
    charter: "Charter route",
    template: "Template",
};
export const CharterRowTypeReverseMap = {
    "Note timed": "noteTimed",
    Note: "note",
    Service: "service",
    Break: "break",
    "Meal break": "breakMeal",
    "Broken shift break": "breakBroken",
    "Add location": "location",
    Point: "stop",
    "Stop Note": "stopNote",
    "Dead running": "dead",
    "Charter route": "charter",
    Template: "template",
};

export const CharterRowTypeLabel = {
    service: "Service",
    stop: "Point",
    noteTimed: "Note - Timed",
    note: "Note - Untimed",
    break: "Break - Crib",
    breakMeal: "Break - Meal",
    breakBroken: "Break - Split Shift",
    location: "Add location",
    stopNote: "Stop Note",
    dead: "Dead running",
    charter: "Charter route",
    template: "Template",
};

export const SelectableCharterRowTypes = ["template", "service", "stop", "note", "break", "breakMeal", "breakBroken"];
export const CharterRowNotePriority = { normal: "Normal", high: "High" };
export const CharterRowNotePriorityLabel = { normal: "Normal", high: "Important" };
