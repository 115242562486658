import React, {useEffect, useState} from "react";
import {Button, Flex, Input, Tag} from "antd";
import {ReactComponent as Down} from "../../assets/icons/Down.svg";
import {values} from "lodash/object";
import {intersection} from "lodash/array";
import ItinerarySelectRowEdit from "./ItinerarySelectRowEdit";
import {SyncOutlined} from "@ant-design/icons";
import {ReactComponent as Check} from "../../assets/icons/Check.svg";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import ItinerarySelectType from "./ItinerarySelectType";
import {find} from "lodash/collection";
import {toTime} from "../../libs/formatLib";
import {Col, Row} from "react-bootstrap";
import {Space} from "antd/es";
import schedules from "../../editableList/schedules/Schedules";

const getKey = (route, trip) => `${route.routeNumber}|${route.routeId}|${trip.tripId}|${trip.getStartTimeAsSecondsSinceMidnight()}|${route.stops[0]?.stopName}`

function ItineraryServiceRowEdit({
                                    itineraryRow, schedules,
                                    setDescription, setUpdating,
                                    allRoutes, updating, onUpdate, onCancel,
                                    route, setRoute, isLoading,
                                    trip, setTrip,
                                    resetRow, type, time, setTitle,
                                    title,
                                    description
                                }) {
    const [options, setOptions] = useState([])
    const [routeTime, setRouteTime] = useState(0);

    useEffect(() => {
        console.log('Setting ROUTE & TRIP')
        let options = [];

        const routeFn = route => {
            [...route.services].sort((t1, t2) => t1.getStartTimeAsSecondsSinceMidnight() - t2.getStartTimeAsSecondsSinceMidnight()).forEach(trip => {
                if ((time > -1 && trip.getStartTimeAsSecondsSinceMidnight() < time)) {
                    return
                }
                // const label = `${trip.getStartTime()}: ${route.routeNumber} ${route.routeName}`
                // labelOpts[label] = labelOpts[label] || {}
                // labelOpts[label].options = labelOpts[label].options || []
                const key = getKey(route, trip)
                options.push({
                    searchText: `${trip.scheduleIds.map(sId => schedules[sId].scheduleName).join('')}${trip.getStartTime()}${route.routeNumber}${route.routeName}${route.routeLabel || ''}${route.routeDetails || route.routeHeadsign || route.routeName}`,
                    value: key,
                    label: <>{trip.getStartTime()}: <strong>{route.routeNumber}</strong> {route.routeName} {route.routeDetails || route.routeHeadsign || route.routeName}</>,
                    route,
                    trip
                });
            });
        }
        values(allRoutes).filter(r => r.published >= 0).forEach(routeFn);

        setRoute(itineraryRow.route || null)
        setTrip(itineraryRow.trip || null);
        // Set the service options when allRoutes change
        // setOptions(Object.keys(labelOpts).map(label => ({label, ...labelOpts[label]})))
        setOptions(options)
    }, [allRoutes, setOptions, itineraryRow, setRoute, setTrip, time])

    return (
        <>
            <div key={itineraryRow.id}>
                <Row className="row-compact">
                    <Col md="auto">
                        <span className={"sb-time"}>{time === -1 ? '--:--' : toTime(time)}</span>
                    </Col>
                    <Col className="row-col-gap" style={{maxWidth: 'calc(100% - 77px)'}}>
                        <ItinerarySelectType type={type} resetRow={resetRow} className={"inputselect-small"}/>
                        <Input className="inputtext-small" placeholder="Driver instructions"
                               value={description || ""} onChange={e => setDescription(e.target.value)}/>
                        <ItinerarySelectRowEdit className={"inputselect-small w-100"}
                                               ttTitle="Select a service"
                                               filterSearchName={"searchText"}
                                               suffixIcon={Object.keys(allRoutes)?.length || isLoading ? <Down/> :
                                                   <SyncOutlined spin={true}/>}
                                               value={route && trip ? getKey(route, trip) : ""}
                                               placeholder={"Select service"}
                                               onChange={async value => {
                                                   // eslint-disable-next-line
                                                   const [routeNumber, routeId, tripId, time] = value.split('|')
                                                   const route = allRoutes[routeId]
                                                   const trip = find(route.services, trip => trip.tripId === tripId);
                                                   setRoute(route);
                                                   setTrip(trip);
                                                   setRouteTime(time);
                                               }}
                                               optionRender={(option) => (
                                                   <div>
                                                       <Flex gap={'small'}>{option.label}</Flex>
                                                       <Flex gap={'small'} align={'end'}>{option.data.trip.scheduleIds.map(sId =>
                                                           <Tag style={{fontSize: '8px', lineHeight: '12px'}}>{schedules[sId]?.scheduleName || 'Unknown'}</Tag>)}</Flex>
                                                   </div>
                                               )}
                                               options={options}
                        />
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button className="sb-done"
                                    icon={<Check/>}
                                    disabled={updating}
                                    onClick={e => onUpdate(e, {
                                        ...itineraryRow,
                                        type,
                                        time: parseInt(routeTime),
                                        title,
                                        description,
                                        route,
                                        trip
                                    })}/>
                            <Button className="sb-close"
                                    icon={updating && itineraryRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Close/>}
                                    disabled={updating} onClick={e => onCancel(e, itineraryRow)}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
        ;
}

export default React.memo(ItineraryServiceRowEdit)
