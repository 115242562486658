import React, {useEffect, useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import {LinkContainer} from "react-router-bootstrap";
import {useAppContext} from "../../libs/contextLib";
import {onError} from "../../libs/errorLib";
import "./ShiftBat.css";
import LoaderButton from "../../components/LoaderButton";
import RouteTitle from "../../components/RouteTitle";
import LoadMessage from "../../components/LoadMessage";
import {FormControl, InputGroup} from "react-bootstrap";
import {PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons";
import {debounce, find, pickBy, uniq, values} from "lodash";
import {deadrunModelData, routeModelData, shiftBatModelData, stopModelData,} from "../../services/ModelService";
import {FEATURE} from "../../model/features";
import {filterFn, FilterSwitches, FilterTags} from "../../containers/FilterTag";
import {ReactComponent as Copy} from '../../assets/icons/Copy.svg';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';
import {ShiftBat, ShiftBatRowType} from "../../model/shiftBat";
import util from "util";
import {Link, useHistory} from "react-router-dom";
import {checkFeatureAuth} from "../../App";
import {Button, Tag} from "antd";
import {keyBy} from "lodash/collection";
import {toHrsMinsSecs, toKmMs, toTime} from "../../libs/formatLib";

const filterItems = {
    Active: {showFn: (r) => !r.future && !r.expired},
    Draft: {name: 'Draft', color: '#656d88', showFn: r => r.published === -1},
    Approved: {name: 'Approved', color: '#3f8a35', showFn: r => r.published === 0},
    Published: {name: 'Published', color: '#8848d3', showFn: r => r.published === 1},
    NoTrips: {showFn: (r) => r.noTrips},
    Warning: {showFn: (r) => r.warning?.length}
};

function ShiftBats() {
    const {
        operator,
        setFaultState
    } = useAppContext();
    const history = useHistory()
    const {isAuthenticating, isAuthenticated, isAdmin, apiKey, schedules} = useAppContext();
    const [shiftBats, setShiftBats] = useState([]);
    const [routeSummary, setRouteSummary] = useState(null);
    const [allStops, setAllStops] = useState(null);
    const [validatedShiftBats, setValidatedShiftBats] = useState([]);
    const [sortedShiftBats, setSortedShiftBats] = useState([]);
    const [filter, setFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDuplicating] = useState([]);
    const [isDuplicating, setIsDeleting] = useState([]);
    const [fetching, setFetching] = useState(true);

    const [filterBy, setFilterBy] = useState([])

    const setFilterDebounced = debounce(setFilter, 250);
    const [loaded, setLoaded] = useState({routes: false, stops: false, shiftBats: false, deadruns: false});

    useEffect(() => {
        if (operator) {
            checkFeatureAuth(setFaultState, operator, FEATURE.shfts, () => history.push("/")).then(() => console.log('Auth checked.'));
        }
    }, [setFaultState, operator, history])

    useEffect(() => {

        if (isAuthenticating || !isAuthenticated) {
            return;
        }

        const setRoutes = routes => {
            routes = Array.isArray(routes) ? keyBy(routes, 'routeId') : routes
            setRouteSummary(existing => {
                return pickBy({...existing, ...routes}, value => {
                    return value.published > -1
                })
            })
        }

        const routeListener = routeModelData.addListener({
            loaded: routes => {
                setRoutes(routes)
                setLoaded(l => ({...l, routes: true}))
            },
            notifyUpdate: setRoutes,
            notifyDelete: id => setRouteSummary(existing => pickBy(existing, (value, key) => key !== id))
        })
        const stopListener = stopModelData.addListener({
            summary: true, loaded: stops => {
                setAllStops(stops)
                setLoaded(l => ({...l, stops: true}))
            },
            refresh: debounce(() => {
                stopModelData.getAll().then(stopsById => setAllStops(stopsById))
            }, 500)
        })
        const shiftBatListener = shiftBatModelData.addListener({
            loaded: shiftBats => {
                setIsLoading(false)
                setFetching(false)
                setShiftBats(values(shiftBats))
                setLoaded(l => ({...l, shiftBats: true}))
            }
        })

        const deadRunListener = deadrunModelData.addListener({
            loaded: () => {
                setLoaded(l => ({...l, deadruns: true}))
            }
        })

        return () => {
            routeModelData.removeListener(routeListener);
            stopModelData.removeListener(stopListener);
            shiftBatModelData.removeListener(shiftBatListener);
            deadrunModelData.removeListener(deadRunListener)
        }

    }, [apiKey, isAuthenticated, isAuthenticating, setShiftBats, setRouteSummary, setAllStops, setIsLoading, setFetching, history, setFaultState, setLoaded]);


    useEffect(() => {

        if (!loaded.routes || !loaded.stops || !loaded.shiftBats || !loaded.deadruns || !shiftBats?.length) {
            return
        }

        const time = Date.now();
        shiftBats.forEach(sb => {
            const warnings = []
            let invalidServiceRows = sb.rows?.filter(r => r.type === ShiftBatRowType.service)?.filter(r => {
                    return !routeSummary[r.routeId] ||
                        !find(routeSummary[r.routeId].services, {tripId: r.tripId})
                }
            )
            if (invalidServiceRows?.length) {
                warnings.push(invalidServiceRows.map((mr, i) => <p key={mr.title + i}>Service row {mr.title} is invalid
                    (REF-024)</p>))
            }
            const invalidPointRows = sb.rows?.filter(r => r.type === ShiftBatRowType.stop)?.filter(s => !allStops[s.stopId])
            if (invalidPointRows?.length) {
                warnings.push(invalidPointRows.map((mr, i) => <p key={mr.title + i}>Point row {mr.title} is invalid
                    (REF-025)</p>))
            }
            const invalidDeadRows = sb.rows?.filter(r => r.type === ShiftBatRowType.dead)?.filter(s => !allStops[s.startStopId] || !allStops[s.endStopId])
            if (invalidDeadRows?.length) {
                warnings.push(invalidDeadRows.map((mr, i) => <p key={mr.title + i}>Deadrun row {mr.title} is invalid
                    because the
                    original {!allStops[mr.startStopId] ? 'start' : 'end'} stop has been deleted (REF-026)</p>))
            }
            sb.warnings = warnings;
        })
        shiftBats.forEach(r => {
            r.warning = uniq(r.warnings).map((w, i) => <p key={w + i}>{w}</p>)
            r.rows.filter(row => row.type === ShiftBatRowType.dead).forEach(row => {
                row.updateRow({deadrunModelData})
            });
        })
        console.log('ShiftBat verification done. Elapsed time: %dms', Date.now() - time)
        setValidatedShiftBats([...shiftBats])

    }, [shiftBats, routeSummary, allStops, setValidatedShiftBats, schedules, loaded])

    const nameSortFn = (a, b) => {
        if (!a.shiftBatNumber?.length || !b.shiftBatNumber?.length) {
            return 1
        }
        return a.shiftBatNumber.localeCompare(b.shiftBatNumber);
    }

    useEffect(() => {

        if (!filter?.length) {
            return setSortedShiftBats(filterFn(validatedShiftBats, filterItems, filterBy, 'shiftBatId', nameSortFn))
        }
        const _filter = filter.toLowerCase()
        setSortedShiftBats(filterFn(validatedShiftBats.filter(r => (r.shiftBatNumber && r.shiftBatNumber.toLowerCase().indexOf(_filter) > -1) ||
            (r.shiftBatName && r.shiftBatName.toLowerCase().indexOf(_filter) > -1) ||
            (r.shiftBatDetails && r.shiftBatDetails.toLowerCase().indexOf(_filter) > -1) ||
            (r.author && r.author.toLowerCase().indexOf(_filter) > -1)), filterItems, filterBy, 'shiftBatId', nameSortFn));
    }, [validatedShiftBats, setSortedShiftBats, filterBy, filter])

    async function handleDuplicate(event, shiftBat) {
        event.preventDefault();
        event.stopPropagation();

        const confirmed = window.confirm(
            `Are you sure you want to copy ${shiftBat.shiftBatNumber}?`
        );

        if (!confirmed) {
            return;
        }

        setIsDuplicating(isDup => isDup.concat(shiftBat.shiftBatId));

        try {
            const dup = await shiftBatModelData.duplicate(shiftBat.shiftBatId, apiKey);
            console.log(`Duplicated shift bat: ${util.inspect(shiftBat)}`)
            history.push("/shiftbats/" + dup.shiftBatId)
        } catch (e) {
            onError(e);
        }
        setIsDuplicating(isDup => isDup.filter(id => id !== shiftBat.shiftBatId));
    }

    async function handleDelete(event, shiftBat) {
        event.preventDefault();

        const confirmed = window.confirm(
            `Are you sure you want to delete the shift bat ${shiftBat.shiftBatNumber}?`
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(isDel => isDel.concat(shiftBat.shiftBatId));

        try {
            await shiftBatModelData.delete(shiftBat.shiftBatId, true)
            setShiftBats(shiftBats => shiftBats.filter(sb => sb.shiftBatId !== shiftBat.shiftBatId));
        } catch (e) {
            onError(e);
        }
        setIsDeleting(isDel => isDel.filter(id => id !== shiftBat.shiftBatId));
    }

    function renderShiftBatList() {
        return (
            <>
                {isLoading ? <SyncOutlined spin/> :
                    <>
                        <div className="row list-group-header">
                            <div className="col-lg-4">Driver Duties</div>
                            <div className="col-lg-1 text-center">Sign On</div>
                            <div className="col-lg-1 text-center">Sign Off</div>
                            <div className="col-lg-1 text-center">Duration</div>
                            <div className="col-lg-1 text-center">Distance</div>
                            <div className="col-lg-2 text-center">Calendars</div>
                            <div className="col-lg-1 text-center">Tags</div>
                            <div className="col-lg-1 text-center">Actions</div>
                        </div>
                        {fetching ? (
                            <ListGroup.Item>
                                <SyncOutlined spin/>
                            </ListGroup.Item>
                        ) : <></>}
                        {sortedShiftBats.map(shiftBat => {
                            shiftBat = new ShiftBat(shiftBat);
                            return (
                                <Link key={shiftBat.shiftBatId} to={`/shiftbats/${shiftBat.shiftBatId}`}
                                      style={{cursor: 'pointer'}}>
                                    <ListGroup.Item>
                                        <div className="row"
                                            // onClick={e => {
                                            //     e.preventDefault();
                                            //     e.stopPropagation();
                                            //     if (e.metaKey || e.ctrlKey) {
                                            //         window.open(`/shiftbat/${shiftBat.shiftBatId}`, "_blank");
                                            //     } else {
                                            //         history.push(`/shiftbat/${shiftBat.shiftBatId}`)
                                            //     }
                                            // }}
                                        >

                                            <div className="col-lg-4">
                                                <RouteTitle route={{
                                                    routeNumber: shiftBat.shiftBatNumber,
                                                    routeName: shiftBat.shiftBatName,
                                                    routeDetails: shiftBat.shiftBatDetails,
                                                    colour: shiftBat.shiftBatColour,
                                                    routeLogo: shiftBat.shiftBatLogo
                                                }}/>
                                            </div>
                                            <div className="col-lg-1 text-center">
                                                {toTime(shiftBat.getStartTime())}
                                            </div>
                                            <div className="col-lg-1 text-center">
                                                {toTime(shiftBat.getEndTime())}
                                            </div>
                                            <div className="col-lg-1 text-center">
                                                {toHrsMinsSecs(shiftBat.getShiftTime(), false, true)}
                                            </div>
                                            <div className="col-lg-1 text-center">
                                                {toKmMs(shiftBat.getShiftDistance(), 0)}
                                            </div>
                                            <div className={'col-lg-2 text-center calendar-tags'}>
                                                {shiftBat.scheduleIds.map((sId, i) =>
                                                    <Tag key={sId + i}>{schedules[sId]?.scheduleName}</Tag>)}
                                            </div>
                                            {/* <div className="text-muted text-left col-lg-1">
                                                {route?.driver?.shiftName || ''}
                                            </div>
                                            <div className="text-muted text-left col-lg-2">
                                                <TripTimesPopover route={route} schedules={schedules}/>
                                            </div>
                                            <div className="text-muted text-left col-lg-1">
                                                {routeType}
                                            </div>
                                            <div className="text-muted text-left col-lg-1">
                                                {direction}
                                            </div>
                                            <div className="col-lg-1">
                                                {operator.features.routes() &&
                                                    <Switch size="small"
                                                            disabled={!validatePublish(routeName, routeNumber, route.trips)}
                                                            loading={isPublishing.indexOf(routeId) > -1}
                                                            onChange={(v, e) => handlePublishChange(e, published, routeId, routeNumber, routeName)}
                                                            checked={published}
                                                    />}
                                            </div> */}
                                            <div className="col-lg-1 d-flex justify-content-around simple-tags">
                                                <FilterTags filterItems={filterItems}
                                                            model={{
                                                                expired: shiftBat.expired,
                                                                noTrips: shiftBat.noTrips,
                                                                warning: shiftBat.warning,
                                                                published: shiftBat.published
                                                            }}/>
                                            </div>
                                            <div className="col-lg-1 d-flex justify-content-around sb-controls">
                                                <LoaderButton className="btn-icon-control"
                                                              size="sm"
                                                              isLoading={isDuplicating.indexOf(shiftBat.shiftBatId) > -1}
                                                              feature={FEATURE.shfts}
                                                              onClick={e => {
                                                                  handleDuplicate(e, shiftBat).then(() => console.log('ShiftBat deleted.'))
                                                              }}
                                                >
                                                    <Copy/>
                                                </LoaderButton>
                                                <LoaderButton className="btn-icon-control btn-delete"
                                                              size="sm"
                                                              isLoading={isDeleting.indexOf(shiftBat.shiftBatId) > -1}
                                                              feature={FEATURE.shfts}
                                                              onClick={e => {
                                                                  handleDelete(e, shiftBat).then(() => console.log('ShiftBat deleted.'))
                                                              }}
                                                >
                                                    <Trash/>
                                                </LoaderButton>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </Link>
                            )
                        })}
                    </>
                }
            </>
        );
    }

    function renderShiftBats() {
        return (
            <>
                {!isLoading && !fetching ?
                    <div className="routes">
                        <div className="d-flex align-items-center justify-content-between filter-options-main">
                            <LinkContainer to="/shiftbats/_new_">
                                <Button type="primary" className="icon-button"
                                        icon={<PlusOutlined/>}>Shift
                                    Bat</Button>
                            </LinkContainer>
                            <div className="d-flex align-items-center">
                                <InputGroup className="search-filter">
                                    <FormControl
                                        type="search"
                                        placeholder="Filter"
                                        className=""
                                        size="sm"
                                        aria-label="Filter"
                                        onChange={(e) => setFilterDebounced(e.target.value || "")}
                                    />
                                    <InputGroup.Text id="basic-addon1"><SearchOutlined/></InputGroup.Text>
                                </InputGroup>
                                <div className="filter-switches">
                                    <FilterSwitches filterItems={filterItems} filterBy={filterBy}
                                                    setFilterBy={setFilterBy}/>
                                </div>
                                {isAdmin &&
                                    <span
                                        className={"mr-2"}>ShiftBat count: {shiftBats?.length}</span>}
                            </div>
                        </div>
                        <ListGroup>{renderShiftBatList(sortedShiftBats)}</ListGroup>
                    </div>
                    :
                    <LoadMessage message={"loading driver duties..."} size={"lg"}/>
                }
            </>
        );
    }

    return (
        <div className="ShiftBat">
            {!isAuthenticating && isAuthenticated && renderShiftBats()}
        </div>
    );
}

export default React.memo(ShiftBats)
