import React from "react";
import GridLayout from "react-grid-layout";
import {Col, Row, Card, Menu, Avatar, Badge, Tag, Button, Input, DatePicker, Select, Space} from 'antd';
import 'react-grid-layout/css/styles.css'
import './GridLayout.css'

export default class ExampleLayout extends React.Component {
  render() {
    const gridWidth = 3000;
    const layout = [
      { i: "a", x: 0, y: 0, w: 3, h: 1, maxH: 1, static: true },
      { i: "b", x: 5, y: 0, w: 3, h: 1, maxH: 1, static: true },
      { i: "c", x: 10, y: 0, w: 6, h: 1, maxH: 1, static: true },
      { i: "d", x: 2, y: 1, w: 5, h: 1, maxH: 1, static: true },
      { i: "e", x: 0, y: 2, w: 4, h: 1, maxH: 1, static: true },
      { i: "f", x: 4, y: 2, w: 10, h: 1, maxH: 1, static: true },
      { i: "g", x: 0, y: 4, w: 3, h: 1, maxH: 1, minW: 2 },
    ];

    return (
      <div className="GridLayout">
        <div className="GridLabel">
          <strong>Dispatch</strong>
          <div>Bus 01</div>
          <div>Bus 02</div>
          <div>Bus 03</div>
          <div>Bus 04</div>
        </div>
        <div className="GridWrap">
          <div className="GridItems" style={{width: gridWidth}}>
            <GridLayout
              layout={layout}
              cols={24}
              rowHeight={80}
              width={gridWidth}
            >
              <div key="a">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="success">Completed</Tag></div>
              </div>
              <div key="b">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="blue">In Progress</Tag></div>
              </div>
              <div key="c">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="blue">In Progress</Tag></div>
              </div>
              <div key="d">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="blue">In Progress</Tag></div>
              </div>
              <div key="e">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="error">Cancelled</Tag></div>
              </div>
              <div key="f">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="blue">In Progress</Tag></div>
              </div>
              <div key="g">
                <Avatar size={40} style={{ backgroundColor: '#C1C5CE' }}>JS</Avatar>
                <div>
                  <strong>CHA 086</strong>
                  <span>07:00 - 09:00</span>
                </div>
                <div className="simple-tags"><Tag color="success">Completed</Tag></div>
              </div>
            </GridLayout>
          </div>
          <div className="GridLines" style={{width: gridWidth}}>
            {[...Array(24)].map((e, i) => <div key={i}>{i}:00</div>)}
          </div>
        </div>
      </div>
    );
  }
}