import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, List, Modal, Tooltip } from "antd";
import { Col, Row } from "react-bootstrap";
import TextArea from "antd/lib/input/TextArea";
import LoaderButton from "../../components/LoaderButton";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { ReactComponent as Trash } from "../../assets/icons/Trash.svg";
import { useAppContext } from "../../libs/contextLib";
import { charterModelData } from "../../services/ModelService";
import { CharterTemplate } from "../../model/charter";

import { PlusOutlined } from "@ant-design/icons";

const ItineraryTemplateManager = forwardRef((props, ref) => {
    const { operator } = useAppContext();
    const [records, setRecords] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [openTemplates, setOpenTemplates] = useState(false);
    const [addTemplate, setAddTemplate] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useImperativeHandle(ref, () => ({
        openModal: () => setOpenTemplates(true),
        closeModal: () => setOpenTemplates(false),
    }));

    useEffect(() => {
        const load = async () => {
            charterModelData.addListener({
                setterFn: setRecords,
                loaded: (charters) => setRecords(charters),
            });
        };
        if (operator) {
            load().then(() => {
                console.log("Data loaded.");
            });
        }

        return () => {
            charterModelData.removeListener();
        };
    }, [operator]);

    useEffect(() => {
        if (!records) return;
        const recordsArray = Object.values(records);

        const templateRecords = recordsArray.filter((record) => record.type === "charter#template");
        setTemplates(templateRecords);
    }, [records]);

    const createTemplate = async (message) => {
        const templateObj = new CharterTemplate({
            companyId: operator?.companyId,
            message: selectedTemplate.message,
            author: operator.id,
        });
        console.log("Creating template", templateObj);
        await charterModelData.save(templateObj);
        setTemplates([...templates, templateObj]);
        setSelectedTemplate(null);
        setAddTemplate(false);
    };

    const deleteTemplate = async (id) => {
        const templateObj = templates.find((t) => t.id === id);
        await charterModelData.delete(templateObj.charterId, true);
        setTemplates(templates.filter((t) => t.id !== id));
        setSelectedTemplate(null);
    };

    const updateTemplate = async () => {
        const charterId = selectedTemplate.charterId;
        const templateObj = templates.find((t) => t.charterId === charterId);
        const updatedTemplate = {
            ...templateObj,
            message: selectedTemplate.message,
        };
        console.log("Updating template", updatedTemplate);
        await charterModelData.save(updatedTemplate);
        setTemplates(templates.map((t) => (t.sk === charterId ? updatedTemplate : t)));
        setSelectedTemplate(null);
        setAddTemplate(false);
    };

    const editTemplate = (item) => {
        setAddTemplate(true);
        setSelectedTemplate(item);
    };

    return (
        <>
            <div>
                <Modal
                    open={openTemplates}
                    onCancel={() => {
                        setOpenTemplates(false);
                        setAddTemplate(false);
                    }}
                    width={700}
                    destroyOnClose
                    footer={[
                        <Button
                            className="btn-secondary"
                            onClick={() => {
                                setOpenTemplates(false);
                                setAddTemplate(false);
                            }}
                        >
                            Close
                        </Button>,
                    ]}
                >
                    <div class="ant-modal-header d-flex" style={{ gap: "20px" }}>
                        <div class="ant-modal-title">Charter Reusable Templates</div>
                        <Button onClick={() => setAddTemplate(true)} type="primary" className="icon-button" icon={<PlusOutlined />}>
                            Add Template
                        </Button>
                    </div>
                    <div class="modal-info pt-3 pb-2">
                        {addTemplate ? (
                            <Row gutter={[30, 30]} className="mb-5">
                                <Col xs={24} lg={24}>
                                    <label>Row Templates</label>
                                    <div className="">
                                        <TextArea
                                            size="large"
                                            showCount
                                            // maxLength={160}
                                            placeholder="Type your Message"
                                            style={{
                                                height: 120,
                                                resize: "none",
                                            }}
                                            value={selectedTemplate?.message}
                                            onChange={(template) => {
                                                setSelectedTemplate({
                                                    ...selectedTemplate,
                                                    message: template.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} lg={24} className="d-flex flex-row">
                                    {selectedTemplate?.charterId ? (
                                        <Button type="primary" className="btn-primary" onClick={updateTemplate}>
                                            Update Template
                                        </Button>
                                    ) : (
                                        <Button type="primary" className="btn-primary" onClick={createTemplate}>
                                            Create Template
                                        </Button>
                                    )}
                                    <Button
                                        className="btn-secondary"
                                        onClick={() => {
                                            setAddTemplate(false);
                                            setSelectedTemplate(null);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}

                        <List
                            bordered
                            dataSource={templates}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                    onClick={() => editTemplate(item)}
                                    actions={[
                                        <Tooltip placement="bottom" title="Edit">
                                            <LoaderButton className="btn-icon-control" onClick={() => editTemplate(item)}>
                                                <Edit style={{ width: 14 }} />
                                            </LoaderButton>
                                        </Tooltip>,
                                        <Tooltip placement="bottom" title="Delete">
                                            <LoaderButton className="btn-icon-control btn-delete" onClick={() => deleteTemplate(item.id)}>
                                                <Trash style={{ width: 12 }} />
                                            </LoaderButton>
                                        </Tooltip>,
                                    ]}
                                >
                                    <div style={{ cursor: "pointer" }}>{item.message}</div>
                                </List.Item>
                            )}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
});

export default React.memo(ItineraryTemplateManager);
