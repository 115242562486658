import {useCallback, useEffect, useState} from "react";
import {groupBy, map, values} from "lodash";
import {routeCommentModelData, stopCommentModelData} from "../services/ModelService";
import {useAppContext} from "../libs/contextLib";
import {roundToPrecision} from "../libs/formatLib";
import {LATLON_PRECISION} from "../libs/mapLib";

const newestFirst = (a, b) => b.createdAt - a.createdAt
const unreadFirst = (a, b) => {
    let aUnread = a.unRead;
    let bUnread = b.unRead;
    if (Array.isArray(a)) {
        aUnread = a.some(a => a.unRead)
        bUnread = b.some(b => b.unRead)
    }
    return aUnread === bUnread ? 0 : (aUnread ? -1 : 1);
}
const isUnread = (c, editor) => !c.readBy.includes(editor);

export default function useComments({chatContext}) {
    const {allRouteComments, allStopComments, editor, setEditor} = useAppContext();//{allRouteComments: routeComments, allStopComments: stopComments, editor: defaultEditor});
    const [comments, setComments] = useState([]);
    const [unreadComments, setUnreadComments] = useState([]);
    const [groupedComments, setGroupedComments] = useState([]);
    const [unreadGroupedComments, setUnreadGroupedComments] = useState([]);

    useEffect(() => {
        if (!chatContext || (chatContext.type === 'route' && !allRouteComments) || (chatContext.type === 'stop' && !allStopComments) || (chatContext.type !== 'all' && !chatContext.id)) {
            setComments([]);
            return
        }
        // console.log('loading comments...', chatContext);
        let filteredComments = chatContext.type === 'route' ? values(allRouteComments) : chatContext.type === 'stop' ? values(allStopComments) : values(allRouteComments).concat(values(allStopComments));
        if (chatContext.type !== 'all') {
            filteredComments = filteredComments.filter(comment => {
                return comment.type === chatContext.type && comment.id === chatContext.id &&
                    (!chatContext.subType || comment.subType === chatContext.subType) &&
                    (!chatContext.routeId || comment.routeId === chatContext.routeId) &&
                    (!chatContext.tripId || comment.tripId === chatContext.tripId) &&
                    ((!chatContext.lat && !chatContext.lon) || (comment.lat === chatContext.lat && comment.lon === chatContext.lon)) &&
                    (!chatContext.stopTimeId || comment.stopTimeId === chatContext.stopTimeId) &&
                    (!chatContext.stopId || comment.stopId === chatContext.stopId);
            })
        }
        filteredComments.forEach(c => c.unRead = isUnread(c, editor));
        filteredComments = filteredComments.sort(unreadFirst)
        // console.log('filteredComments', filteredComments);
        setComments(filteredComments);
        const unread = filteredComments.filter(comment => !comment.readBy?.includes(editor))
        setUnreadComments(unread);
        // group the list based on type and subType
        // const grouped = groupBy(filteredComments, comment => `${comment.type}_${comment.id}`)
        // setGroupedComments(map(grouped, group => group));
        //
        // const unreadGrouped = groupBy(unread, comment => `${comment.type}_${comment.id}`)
        // setUnreadGroupedComments(map(unreadGrouped, group => group));
        // if (chatContext !== 'all') {
        //     const readComments = filteredComments.filter(c => !c.readBy.includes(editor)).map(c => {
        //         c.readBy = c.readBy || [];
        //         c.readBy.push(editor);
        //         return c;
        //     })
        //     if (chatContext.type === 'route') {
        //         routeCommentModelData.save(readComments).then(() => {
        //             console.log('read comments saved')
        //         });
        //     } else if (chatContext.type === 'stop') {
        //         stopCommentModelData.save(readComments).then(() => {
        //             console.log('read comments saved')
        //         });
        //     }
        // }
    }, [chatContext, allRouteComments, allStopComments, setComments, setUnreadComments, setGroupedComments, setUnreadGroupedComments, editor])

    const newComment = useCallback((message) => {
        const comment = {
            message: message.trim(), author: editor, readBy: [editor], ...chatContext
        }
        if (comment.lat) {
            comment.lat = roundToPrecision(comment.lat, LATLON_PRECISION);
        }
        if (comment.lon) {
            comment.lon = roundToPrecision(comment.lon, LATLON_PRECISION);
        }
        if (chatContext.type === 'route') {
            routeCommentModelData.save(comment).then(() => {
                console.log('comment saved')
            });
        } else if (chatContext.type === 'stop') {
            stopCommentModelData.save(comment).then(() => {
                console.log('comment saved')
            });
        }

    }, [editor, chatContext]);
    return {comments, groupedComments, newComment, unreadComments, unreadGroupedComments, editor, setEditor};
}