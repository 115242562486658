import { Bullet } from '@ant-design/plots';
import { Button } from 'antd';
import React from 'react';


function PlotBullet({leaveBalances = []}) {
    const measures = leaveBalances.map(l => parseInt(l.numberOfUnits));

    const totalLeaveCount = measures.reduce((a, b) => a + b, 0);

    const data = [
        {
            title: '',
            ranges: [0],
            measures: measures,
            target: totalLeaveCount,
        }
    ];
    
    const config = {
        data,
        measureField: 'measures',
        rangeField: 'ranges',
        targetField: 'target',
        xField: 'title',
        height: 100,
        color: {
          measure: ['#7FDAFF', '#FFC857', '#A2D9CE'],
          target: '#ffffff',
        },
        size: {
          measure: 36,
        },
        label: {
          measure: {
            position: 'middle',
            style: {
              fill: '#fff',
              opacity: 0,
            },
          },
        },
        xAxis: {
          line: null,
        },
        yAxis: false,
        tooltip: {
          showMarkers: false,
          shared: false,
        }
    };

    return (
        <div className="graph-wrap">
            <div className="graph-info-lg d-flex flex-column">
                <strong>{totalLeaveCount}</strong>
                <div className="text-sm-info">Hours</div>
            </div>
            <Bullet {...config} maxBulletHeight={36} />
            <div className="graph-legend d-flex">
                {leaveBalances.map(l => {
                    return (
                        <div className="d-flex flex-column" style={{ width: '30%' }}>
                            <label className="text-sm-info">{l.leaveName}</label>
                            <strong>{parseInt(l.numberOfUnits)}</strong>
                        </div>
                    )
                })}
            </div>
            <div className="w-100 text-center mt-5">
              <Button type="primary" className="btn-secondary">View Calendar</Button>
            </div>
        </div>
    );
}

export default React.memo(PlotBullet)