import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { ReactComponent as Down } from "../../assets/icons/Down.svg";
import { values } from "lodash/object";
import ItinerarySelectRowEdit from "./ItinerarySelectRowEdit";
import { SyncOutlined } from "@ant-design/icons";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import ItinerarySelectType from "./ItinerarySelectType";
import { Col, Row } from "react-bootstrap";
import ItineraryTimePicker from "./ItineraryTimePicker";

function ItineraryStopRowEdit({
    itineraryRow,
    setTime,
    setDescription,
    allStops,
    updating,
    onUpdate,
    onCancel,
    stop,
    setStop,
    setTitle,
    resetRow,
    type,
    time,
    title,
    description,
}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // Set the service options when all Routes change
        setOptions(
            values(allStops)
                .filter((stop) => stop.verified > 0 && stop.duplicate < 0)
                .map((stop) => ({
                    label: stop.stopName,
                    value: stop.stopId,
                }))
        );
    }, [allStops, setOptions]);

    return (
        <>
            <div key={itineraryRow.id}>
                <Row className="row-compact">
                    <Col md="auto">
                        <ItineraryTimePicker time={time} setTime={setTime} />
                    </Col>
                    <Col className="row-col-gap" style={{ maxWidth: "calc(100% - 77px)" }}>
                        <ItinerarySelectType type={type} resetRow={resetRow} className={"inputselect-small"} />
                        <Input
                            size="small"
                            placeholder="Driver instructions"
                            className="inputtext-small"
                            value={description || ""}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <ItinerarySelectRowEdit
                            className={"inputselect-small w-100"}
                            ttTitle={"Select a point"}
                            suffixIcon={<Down />}
                            value={stop?.stopId || ""}
                            placeholder={"Select stop"}
                            onChange={(value) => {
                                const stop = allStops[value];
                                if (stop) {
                                    setStop(stop);
                                    setTitle(stop.stopName);
                                }
                                // setStopRow(stoprow => ({...stoprow, stop: stop, title: stop.stopName}))
                            }}
                            options={options}
                        />
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button
                                className="sb-done"
                                icon={<Check />}
                                disabled={updating || !stop}
                                onClick={(e) =>
                                    onUpdate(e, {
                                        ...itineraryRow,
                                        type,
                                        time: parseInt(time),
                                        title,
                                        stop,
                                        description,
                                    })
                                }
                            />
                            <Button
                                className="sb-close"
                                icon={updating && itineraryRow.needsUpdate() ? <SyncOutlined spin={true} /> : <Close />}
                                disabled={updating}
                                onClick={(e) => onCancel(e, itineraryRow)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ItineraryStopRowEdit);
