import { useEffect, useState } from "react";
import { charterModelData } from "../../services/ModelService";
import useModel from "../../hooks/useModel";
import { Charter, ItineraryShift } from "../../model/charter";
import { useAppContext } from "../../libs/contextLib";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function useItinerary() {
    const history = useHistory();
    const { apiKey, isAuthenticated } = useAppContext();

    const { model: charter } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({ charterId: "_" }),
    });

    const [itinerary, setItinerary] = useState(null);

    useEffect(() => {
        const load = async () => {
            if (charter.itineraryId && isAuthenticated) {
                let itinerary = await charterModelData.get(apiKey, charter.itineraryId);
                if (itinerary) setItinerary(new ItineraryShift(itinerary, charterModelData));
                // else {
                //     history.push("/charters");
                // }
            }
        };

        load().then(() => {
            console.log("Itinerary data fetched");
        });
    }, [apiKey, charter, history, isAuthenticated]);

    return {
        itinerary,
        setItinerary,
    };
}
