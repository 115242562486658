import {chunk, memoize, last} from "lodash";
import config from "../config";
import {dirCache} from "../model/dirCache";
import {getDistanceInMetres} from "./routes-lib";
import {ulid} from "ulid";
import {find} from "lodash/collection";
import {toKmMs} from "./formatLib";
import debounce from "debounce-promise";

export const toCoordStr = (coord, precision = 5) => `${coord.lon.toFixed(precision)},${coord.lat.toFixed(precision)}`
export const toCoordArray = (coord, precision = 5) => [parseFloat(coord.lon.toFixed(precision)), parseFloat(coord.lat.toFixed(precision))]
export const getSimpleDurations = (profile, startCoord, coordinates, speed) => {
    const metresPerSec = speed / 3.6;
    const results = coordinates.map(coord => {
        const distance = getDistanceInMetres(startCoord, coord);
        const duration = distance / metresPerSec;
        return {duration, distance}
    })
    return {durations: results.map(result => result.duration), distances: results.map(result => result.distance)}
}
export const getSimpleDurationsBetweenStops = (profile, coords, speed = 4.5) => {
    const metresPerSec = speed / 3.6;
    const distances = [], durations = [];
    coords.forEach((coord1, idx) => {
        distances[idx] = []
        durations[idx] = [];
        coords.forEach(coord2 => {
            const distance = getDistanceInMetres(coord1, coord2);
            const duration = distance / metresPerSec;
            distances[idx].push(distance);
            durations[idx].push(duration);
        })
    })
    return {durations: durations, distances: distances}
}
export const getAccurateDurationsMapbox = async (profile, startCoord, coordinates, fallbackSpeed = 5) => {

    const uniqueCoordResults = {}
    coordinates.map(c => toCoordStr(c)).forEach(coord => uniqueCoordResults[coord] = -1);

    if (Object.keys(uniqueCoordResults).length === 1 && toCoordStr(startCoord) === Object.keys(uniqueCoordResults)[0]) {
        console.log('Coord the same as start.')
        return {durations: coordinates.map(_ => 0), distances: coordinates.map(_ => 0)}
    }

    await Promise.all(chunk(Object.keys(uniqueCoordResults), 24).map(async coords => {
        //https://api.mapbox.com/directions-matrix/v1/{profile}/{coordinates}
        return fetch('https://api.mapbox.com/directions-matrix/v1/mapbox/' + profile.toLowerCase() + '/' + toCoordStr(startCoord) + ';' + coords.join(';') + '?fallback_speed=' + fallbackSpeed + '&sources=0&annotations=distance,duration&access_token=' + config.maps.mabBox, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                if (data.code === 'Ok') {
                    const durations = data.durations[0].slice(1).map(d => Math.ceil(d / 60) * 60);
                    const distances = data.distances[0].slice(1).map(d => Math.round(d / 10) * 10)
                    coords.forEach((coord, idx) => (uniqueCoordResults[coord] = {
                        duration: durations[idx],
                        distance: distances[idx]
                    }))
                }
            });
    }));

    const mappedResults = coordinates.map(c => toCoordStr(c)).map(coord => uniqueCoordResults[coord])
    return {
        durations: mappedResults.map(result => result.duration),
        distances: mappedResults.map(result => result.distance)
    };
}
export const getAccurateDurationsGeoApify = async (profile, startCoord, coordinates, fallbackSpeed = 5, apiKey = config.maps.geoapify.ctrxs) => {

    const uniqueCoordResults = {}
    coordinates.map(c => toCoordStr(c)).forEach(coord => uniqueCoordResults[coord] = -1);

    if (Object.keys(uniqueCoordResults).length === 1 && toCoordStr(startCoord) === Object.keys(uniqueCoordResults)[0]) {
        console.log('Coord the same as start.')
        return {durations: coordinates.map(_ => 0), distances: coordinates.map(_ => 0)}
    }

    await Promise.all(chunk(Object.keys(uniqueCoordResults), 10).map(async coords => {

        const data = {
            mode: profile.toLowerCase() === "walking" ? "walk" : "drive",
            sources: [{location: [startCoord.lon, startCoord.lat]}],
            targets: coords.map(coord => ({location: coord.split(',').map(parseFloat)}))
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        console.log('Getting durations from GEOAPIFY')

        return fetch('https://api.geoapify.com/v1/routematrix?apiKey=' + apiKey, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                data.sources_to_targets[0].forEach(data => {
                    const duration = Math.ceil(data.time / 60) * 60
                    const distance = Math.round(data.distance / 10) * 10
                    uniqueCoordResults[coords[data.target_index]] = {duration, distance}
                });
            })
    }));

    const mappedResults = coordinates.map(c => toCoordStr(c)).map(coord => uniqueCoordResults[coord])
    return {
        durations: mappedResults.map(result => result.duration),
        distances: mappedResults.map(result => result.distance)
    };
}
export const getAccurateDurationsBetweenStops = async (profile, coordinates, fallbackSpeed = 4.5) => {
    //https://api.mapbox.com/directions-matrix/v1/{profile}/{coordinates}
    return fetch('https://api.mapbox.com/directions-matrix/v1/mapbox/' + profile.toLowerCase() + '/' + coordinates.map(c => toCoordStr(c)).join(';') + '?fallback_speed=' + fallbackSpeed + '&sources=0&annotations=distance,duration&access_token=' + config.maps.mabBox, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            if (data.code === 'Ok') {
                return data
            }
        });
}
export const getPathMapbox = async (profile, startCoord, endCoord, speedKmHr) => {
    console.log('Getting path from %s to %s @ %dkm/hr', toCoordStr(startCoord), toCoordStr(endCoord), speedKmHr)
    const cacheDir = dirCache.getData(profile, startCoord, endCoord, speedKmHr);
    if (cacheDir) {
        return cacheDir;
    }

    let walkingSpeedStr = '';
    if (speedKmHr) {
        walkingSpeedStr = 'walking_speed=' + (speedKmHr / 3.6)
    }


    //https://api.mapbox.com/directions/v1/{profile}/{coordinates}
    return fetch('https://api.mapbox.com/directions/v5/mapbox/' + profile.toLowerCase() + '/' + toCoordStr(startCoord) + ';' + toCoordStr(endCoord) + '?' + walkingSpeedStr + '&annotations=duration&geometries=geojson&access_token=' + config.maps.mabBox, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            if (data.code === 'Ok') {
                const waypoints = data.routes[0].geometry.coordinates.map(coords => ({lat: coords[1], lon: coords[0]}))
                const dir = {
                    waypoints,
                    duration: data.routes[0].duration,
                    dist: data.routes[0].distance
                }
                dirCache.setData(profile, startCoord, endCoord, speedKmHr, dir);
                return dir;
            }
            return;
        });
}
export const getPathGeoApify = async (profile = "bus", startCoord, endCoord, speedKmHr = null, apiKey = config.maps.geoapify.ctrxs) => {
    return getCoordsPathGeoApify(profile, [startCoord, endCoord], speedKmHr, apiKey)
}

export const getCoordsPathGeoApify = async (profile = "bus", coords, speedKmHr = null, apiKey = config.maps.geoapify.ctrxs) => {
    if (coords?.length < 2) {
        console.log("Need at least start and end coord.")
        return
    }
    const coordsAsStr = coords.map(coord => toCoordStr(coord))
    console.log('Getting geoapify path along %s  @ %dkm/hr', coordsAsStr, speedKmHr);

    const straightPath = () => {
        // const start = {lat: startCoord.lat, lon: startCoord.lon}
        // const end = {lat: endCoord.lat, lon: endCoord.lon}

        const distance = coords.reduce((p, c, i) => {
            if (i === 0) {
                return 0
            }
            return p + getDistanceInMetres(p, c)
        }, 0)
        // const distance = getDistanceInMetres(start, end);

        const duration = distance / 14
        last(coords).delta = duration
        last(coords).distance = distance;
        return {waypoints: coords, duration, distance}
    }

    const wpQueryCoords = coordsAsStr.map(c => `lonlat:${c}`)

    const mode = profile.toLowerCase() === 'walking' ? 'walk' : profile.toLowerCase() === 'driving' ? 'drive' : profile.toLowerCase()
    const url = 'https://api.geoapify.com/v1/routing?mode=' + mode + '&waypoints=' + wpQueryCoords.join('|') + '&apiKey=' + apiKey;

    console.log(url);

    //https://api.geoapify.com/v1/routing?
    return fetch(url, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            try {
                console.log('Got route path data from geoapify', data);
                if (data.error) {
                    console.log(data);
                    return straightPath()
                }

                const time = data.features[0].properties.time;
                const dist = data.features[0].properties.distance;
                const avgSpd = dist / parseFloat(time);
                console.log('Avg Spd: ' + avgSpd);

                let prevWp = null, waypoints = [];
                data.features[0].geometry.coordinates.forEach(coords => {
                    coords.forEach(coord => {
                        const wp = {
                            lat: coord[1],
                            lon: coord[0], delta: 0, distance: 0
                        };
                        if (prevWp?.lat === wp.lat && prevWp?.lon === wp.lon) {
                            return
                        }
                        if (prevWp) {
                            const dist = getDistanceInMetres(prevWp, wp);
                            wp.delta = Math.round(dist / avgSpd) + prevWp.delta;
                            wp.distance = dist + prevWp.distance;
                        }
                        prevWp = wp;
                        waypoints.push(wp);
                    })
                })

                // let waypoints = data.features[0].geometry.coordinates[0].map((coords, idx) => {
                //     const wp = {
                //         lat: coords[1],
                //         lon: coords[0], delta: 0, distance: 0
                //     };
                //     if (prevWp) {
                //         const dist = getDistanceInMetres(prevWp, wp);
                //         wp.delta = Math.round(dist / avgSpd) + prevWp.delta;
                //         wp.distance = dist + prevWp.distance
                //     }
                //     prevWp = wp;
                //     return wp;
                // });
                const path = {
                    waypoints,
                    duration: data.features[0].properties.time,
                    distance: data.features[0].properties.distance,
                    geojson: data
                };
                console.log(path);
                return path;
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e, e);
            return straightPath()
        })
}
export const geocodeGeoApify = async (search, apiKey = config.maps.geoapify.ctrxs) => {
    if (config.local) {
        return Array.from(Array(5).keys()).map(min => ({
            lat: min, lon: min,
            formatted: 'Bargo ' + min,
            place_id: ulid(),
            place_name: 'Bargo ' + min,
            center: [min, min]
        })).filter(p => p.formatted.toLowerCase().includes(search.toLowerCase()))
    }
    const url = `https://api.geoapify.com/v1/geocode/search?text=${search}&format=json&filter=countrycode:au&apiKey=${apiKey}`
    return fetch(url, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(result => {
            return result.results.map(r => {
                r.place_name = r.formatted
                r.center = [r.lon, r.lat]
                return r;
            })
        })
        .catch(error => console.log('error looking up geocode', error));
}

export const getAddressGeoApify = async (search, apiKey = config.maps.geoapify.ctrxs) => {
    if (config.local) {
        return Array.from(Array(5).keys()).map(min => ({
            lat: min, lon: min,
            formatted: 'Bargo ' + min,
            place_id: ulid(),
            place_name: 'Bargo ' + min,
            center: [min, min]
        })).filter(p => p.formatted.toLowerCase().includes(search.toLowerCase()))

    }
    const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&format=json&filter=countrycode:au&apiKey=${apiKey}`
    return fetch(url, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(result => {
            return result.results.map(r => {
                r.place_name = r.formatted
                r.center = [r.lon, r.lat]
                return r;
            })
        })
        .catch(error => console.log('error looking up geocode', error));
}
function calculateDir(deg1, deg2) {
    return (360 + deg1 - deg2) % 360 > 180 ? '(R)' : '(L)'
}

function getMinTurnAngle(config) {
    return isFinite(config?.operator?.opts?.shiftbat?.minTurnAngle) && config?.operator?.opts?.shiftbat?.minTurnAngle >= 0 ? config?.operator?.opts?.shiftbat?.minTurnAngle : 30
}

export const getDirections = async (wps, apiKey = config.maps.geoapify.ctrxs) => {

    const body = {"mode": "drive", "waypoints": wps.map(wp => ({location: [wp.lon, wp.lat]}))};
    return fetch('https://api.geoapify.com/v1/mapmatching?apiKey=' + apiKey, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(res => res.json())
        .then(data => {
            if (!data.features?.length) {
                return
            }

            const legs = data.features[0]?.properties?.legs;
            let currentSt = legs[0].steps[legs[0].steps.filter(step => !step.roundabout).findIndex(step => step.name)].name
            let prevBearing = null;
            let distance = 0;
            const directions = [], waypoints = data.features[0]?.properties?.waypoints
            legs.forEach((leg, idx, legsArr) => {
                leg.steps.filter(step => !step.roundabout).forEach((step, ix, stepsArr) => {
                    const st = step.name
                    if (st === currentSt) {
                        prevBearing = step.end_bearing
                        distance += step.distance
                        return
                    }
                    currentSt = st
                    const newBearing = step.begin_bearing
                    const diff = newBearing - prevBearing;

                    if ((st?.length || distance > 0) && Math.abs(diff) > getMinTurnAngle(config)) {
                        directions.push(`${calculateDir(prevBearing, newBearing)} ${!st?.length ? `in ${toKmMs(distance, 0)}` : find(st.split(','), name => !name.match(/\d+/g)) || st.split(',')[0]}`)
                    }
                    prevBearing = step.end_bearing
                })
                distance = 0;
            })
            return {directions, waypoints}
        }).catch(e => console.log(e, e));
}


export const getCachedSimpleDurations = memoize(getSimpleDurations, (...args) => {
    return [args[0], toCoordStr(args[1]), args[2].map(c => toCoordStr(c)).join(';'), args[3]].join('_');
});
export const getCachedAccurateDurations = memoize(getAccurateDurationsGeoApify, (...args) => {
    return [args[0], toCoordStr(args[1]), args[2].map(c => toCoordStr(c)).join(';'), args[3]].join('_');
});
export const getCachedSimpleDurationsBetweenStops = memoize(getSimpleDurationsBetweenStops, (...args) => {
    return [args[0], args[1].map(c => toCoordStr(c)).join(';'), args[2]].join('_');
});
export const getCachedAccurateDurationsBetweenStops = memoize(getAccurateDurationsBetweenStops, (...args) => {
    return [args[0], args[1].map(c => toCoordStr(c)).join(';'), args[2]].join('_');
});
export const getCachedPath = memoize(getPathGeoApify, (...args) => {
    return [args[0], toCoordStr(args[1]), toCoordStr(args[2]), args[3]].join('_');
});
export const getCachedPathDebounced = debounce((type, start, end, speed) => getCachedPath(type, start, end, speed), 100)

export const getCachedAddress = memoize(getAddressGeoApify, (...args) => {
    return args[0];
});
export const getCachedAddressDebounced = debounce((search) => getCachedAddress(search), 100)

export const getCachedPathFromCoords = memoize(getCoordsPathGeoApify, (...args) => {
    return [args[0], args[1].map(coord => toCoordStr(coord)).join('|'), args[2]].join('_');
});
export const getCachedPathFromCoordsDebounced = debounce((type, coords, speed) => getCachedPathFromCoords(type, coords, speed), 100)
// export const getCachedPathDebounced_ = debounce((type, from, to, speed, updateFn) => {
//     console.log('debounced...')
//     getCachedPath(type, from, to, speed).then(updateFn)
// }, 500)
//
// rp = _.memoize(function (pageNo) {
//     return _.debounce(function () {
//         document.getElementById("underscore").innerHTML +=
//             '<br />' + pageNo + '@' + new Date().getTime();
//     }, 1000, true);
// });
// export const getCachedPathDebounced = async (wait, type, from, to, speed) => {
//     return new Promise(resolve => {
//         debounce(() => {
//             console.log('debounced...')
//             getCachedPath(type, from, to, speed).then(resolve)
//         }, wait)
//     })
// }

export const getCachedGeocode = memoize(geocodeGeoApify, (...args) => {
    return args[0];
});
