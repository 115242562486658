import React, { useCallback } from "react";
import "./Charters.css";
import { Card, Col, Menu } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAppContext } from "../../libs/contextLib";
import { ReactComponent as Calender } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as Quote } from "../../assets/icons/Quote.svg";

function getItem({ label, key, icon, children, type, disabled }) {
    return {
        key,
        icon,
        children,
        label,
        type,
        disabled,
    };
}

function CharterMenu({
    activeKey,
    charter,
    disabled = { charter: false, itinerary: false, quote: false },
    hide = { charter: false, itinerary: false, quote: false },
}) {
    const history = useHistory();
    const { setBreadcrumb } = useAppContext();
    setBreadcrumb(charter?.name || "New Charter");

    const items = useCallback(() => {
        if (!charter || !hide || !disabled) {
            return [];
        }
        const items = [];
        if (!hide.charter) {
            items.push(
                getItem({
                    label: "Charter Details",
                    key: "1",
                    icon: <Calender width={20} height={20} />,
                    disabled: disabled.charter,
                })
            );
        }
        if (!hide.itinerary) {
            items.push(
                getItem({
                    label: "Itinerary",
                    key: "2",
                    icon: <Bus width={20} height={20} />,
                    disabled: disabled.itinerary,
                })
            );
        }

        if (!hide.quote) {
            items.push(
                getItem({
                    label: "Quote",
                    key: "3",
                    icon: <Quote width={20} height={20} />,
                    disabled: disabled.quote,
                })
            );
        }

        return items;
    }, [charter, disabled, hide]);

    const onMenuSelect = useCallback(
        ({ key }) => {
            const menu = items().find((item) => item.key === key).label;

            switch (menu) {
                case "Charter Details":
                    history.push(`/charters/${charter.charterId}`);
                    break;
                case "Itinerary":
                    history.push(`/charters/${charter.charterId}/itinerary`);
                    break;
                case "Quote":
                    history.push(`/charters/${charter.charterId}/quote`);
                    break;
                default:
                    break;
            }
        },
        [history, charter, items]
    );

    return (
        <Col xs={24} lg={5}>
            <Card bordered={false} className="card-main body-pad-0" style={{ height: "auto" }}>
                <div className="profile-summary">
                    <h2>{charter?.name || "New Charter"}</h2>
                    <div className="text-secondary">{charter?.date}</div>
                </div>
                <Menu
                    className="profile-menu"
                    mode="inline"
                    theme="light"
                    items={items()}
                    selectedKeys={[activeKey]}
                    onSelect={onMenuSelect}
                />
            </Card>
        </Col>
    );
}

export default React.memo(CharterMenu);
