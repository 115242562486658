import React, { useState } from "react";
import './Dispatch.css'
import { useAppContext } from "../../libs/contextLib";
import useAllCharters from "./useAllCharters";
import { filterFn, FilterSwitches } from "../../containers/FilterTag";
import { Button, Card, Col, Input, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as BusPlus } from "../../assets/icons/BusPlus.svg";
import { ReactComponent as BusStat } from "../../assets/icons/BusStat.svg";
import { ReactComponent as BusQuote } from "../../assets/icons/BusQuote.svg";
import { debounce } from "lodash";
import { LinkContainer } from "react-router-bootstrap";
import GridLayout from "../../components/GridLayout";

const { Search } = Input;

export const filterItems = {
    completed: {
        name: "Status",
        color: "success",
        showFn: (r) => !r.status || r.status === "status",
    }
};

function Dispatch() {
    const [filterBy, setFilterBy] = useState([]);
    const [filter, setFilter] = useState("");
    const setFilterDebounced = debounce(setFilter, 250);

    return (
        <>
            <div className="dispatch-list">
                <Row gutter={[20, 20]}>
                    <Col xs={24} lg={6}>
                        <Card bordered={false} className="card-main card-stats">
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <BusPlus />
                                </Col>
                                <Col>
                                    <div>
                                        <h4>Charters</h4>
                                    </div>
                                    <div className="text-lg">28</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={6}>
                        <Card bordered={false} className="card-main card-stats">
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <BusStat />
                                </Col>
                                <Col>
                                    <div>
                                        <h4>Efficiency</h4>
                                    </div>
                                    <div className="text-lg">78%</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card bordered={false} className="card-main card-stats card-stats-profit">
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <BusQuote />
                                </Col>
                                <Col className="pl-4">
                                    <div>
                                        <h4>Revenue</h4>
                                    </div>
                                    <div className="text-lg">$1,450</div>
                                </Col>
                                <Col className="d-flex align-items-center pl-4">
                                    <div className="text-lg text-light">-</div>
                                </Col>
                                <Col className="pl-4">
                                    <div>
                                        <h4>Costs</h4>
                                    </div>
                                    <div className="text-lg">$785</div>
                                </Col>
                                <Col className="d-flex align-items-center pl-4">
                                    <div className="text-lg text-light">=</div>
                                </Col>
                                <Col className="pl-4">
                                    <div>
                                        <h4>Profit</h4>
                                    </div>
                                    <div className="text-lg">$665</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between filter-options-main mt-4">
                    <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                        <LinkContainer to="/dispatch/_new_">
                            <Button type="primary" className="icon-button" icon={<PlusOutlined />}>Vehicle Block</Button>
                        </LinkContainer>
                    </div>
                    <div className="d-flex align-items-center">
                        <Search
                            id="basic-addon1"
                            style={{
                                width: 200,
                            }}
                            allowClear={true}
                            onChange={(e) => setFilterDebounced(e.target.value)}
                        />
                        <div className="filter-switches">
                            <FilterSwitches filterItems={filterItems} filterBy={filterBy} setFilterBy={setFilterBy} />
                        </div>
                    </div>
                </div>

                <GridLayout/>
                
            </div>
        </>
    );
}

export default React.memo(Dispatch);
