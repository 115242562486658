export const VEHICLE_STATUS = {
    active: "Active", service: "Service", repair: "Repair",
}

export const VEHICLE_FUEL_TYPES = {
    petrol: 'Petrol', diesel: 'Diesel', electric: 'Electric', hybrid: 'Hybrid'
}

export class Vehicle {
    constructor(data) {
        this.vehicleId = "_";
        this.vehicleRego = null;
        this.vehicleName = null;
        this.vehicleStatus = VEHICLE_STATUS.active;
        this.capacity = 10;
        this.fuelType = 'petrol';
        this.tankage = 100;
        this.maxDistance = 500;
        Object.assign(this, data)
    }

    static clone(vehicle) {
        return new Vehicle({...vehicle});
    }

}
