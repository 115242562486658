import React, { useCallback } from "react";
import "./Customers.css";
import { customerModelData } from "../../services/ModelService";
import { Customer } from "../../model/customer";
import useModel from "../../hooks/useModel";
import SaveMenu from "../../components/SaveMenu";
import LoadMessage from "../../components/LoadMessage";
import { Card, Col, Flex, Input, Row} from "antd";
import CustomerMenu from "./CustomerMenu";
import { startCase } from "lodash";

const { TextArea } = Input;

function CustomerDetails() {
    const {
        model: customer,
        setModel: setCustomer,
        initialModelRef: initialCustomerRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: customerModelData,
        initialInstance: new Customer({ customerId: "_" }),
    });

    const setCustomerWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCustomer((customer) => {
                const newCustomer = new Customer({ ...customer });
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCustomer[prop];
                        return;
                    }
                    newCustomer[prop] = value[idx];
                });
                return newCustomer;
            });
        },
        [setCustomer]
    );

    return (
        <div className="customer-details w-secondary-menu">
            {customer ? (
                <>
                    <SaveMenu
                        save={() => {
                            customerModelData.save(customer).then(() => {
                                console.log("Customer saved.");
                                setMode({ edit: false });
                            });
                        }}
                        editMode={mode.edit}
                        id={customer.customerId}
                        setEditMode={(edit) => setMode({ edit })}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={customer}
                        initialInstanceRef={initialCustomerRef}
                        listUrl={`/customers`}
                        modelService={customerModelData}
                        validFn={() => customer.isValid()}
                        closeView={true}
                    />
                    <Row gutter={[20, 20]}>
                        {!mode.overview && (
                            <CustomerMenu
                                customer={customer}
                                activeKey={"1"}
                                setCustomer={setCustomer}
                                disabled={{
                                    customer: false,
                                    itinerary: mode.edit,
                                    quote: mode.edit,
                                }}
                                hide={{
                                    customer: false,
                                    itinerary: customer.customerId === "_",
                                    quote: customer.customerId === "_",
                                }}
                            />
                        )}
                        <Col xs={24} lg={19}>
                            <Row gutter={[20, 20]}>
                                <Col xs={12}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Customer Details</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={24} lg={24}>
                                                <label>Name</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter name"
                                                        status={!customer.name?.length ? "error" : ""}
                                                        value={customer.name}
                                                        onChange={({ target: { value } }) => setCustomerWith("name", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(customer.name)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Email</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter email"
                                                        value={customer.email}
                                                        onChange={({ target: { value } }) => setCustomerWith("email", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(customer.email)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <label>Phone number</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter phone"
                                                        value={customer.phone}
                                                        onChange={({ target: { value } }) => setCustomerWith("phone", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(customer.phone)}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={24}>
                                                <label>Additional Information</label>
                                                {mode.edit ? (
                                                    <TextArea
                                                        placeholder="Enter Additional Information"
                                                        value={customer.note}
                                                        onChange={({ target: { value } }) => setCustomerWith("note", value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(customer.note)}</strong>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Customer Details..." />
            )}
        </div>
    );
}

export default React.memo(CustomerDetails);
